// main: ../build.less
@import (reference) "../config";

@viewToolbarSelector: .view__toolbar;
@toolbarNamespace: .toolbar;

.view__toolbar
{
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	background: var(--color-theme-sidebar);
	color: var(--color-bg);

	@media @mobile {
		.toolbar__backlink { display: none; }
	}
}

.toolbar 
{
	@media @sidebar-dynamic {
		padding-left: .rem(48)[];

		.button:not(.has\:iconOnly) {
			> span { display: none; }
			padding: .rem(12)[];
			&::before { margin: 0; }
		}

		// Intermediary State Draft: clip button text on tablets
		// .button:not(.has\:iconOnly) {
		// 	> span {
		// 		text-overflow: ellipsis;
		// 		overflow: hidden;
		// 		max-width: .rem(80)[];
		// 	}
		// 	padding: .rem(12)[];
		// }
		
	}

	&__header {
		width: @desktop-sidebar-width;
		min-width: @desktop-sidebar-min-width;
		align-items: center;
		padding-left: .rem(24)[];
		
		display: none;
		@media @tablet {
			display: flex;
		}
	}
	&__logo { max-width: .rem(140)[]; }
	&__version {
		margin-left: .rem(12)[];
		color: var(--color-text-faded);
	}

	&__content {
		flex: 1 0 auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}

	&__backlink {
		padding-right: .rem(12)[];
		text-decoration: none;
		color: var(--color-primary) !important;
	}

	&__title {
		padding-left: 0;
		.font--medium;
		font-weight: bold;
	}

	&__actions {
		// margin-left: auto;
		display: flex;
		align-items: center;
		padding: 0 .rem(10)[];

		> * + * { margin-left: .rem(3)[] !important; }
	}
	// &__message {
	// 	margin-right: .rem(20)[];
	// 	border-radius: .rem(4)[];
	// 	color: @color-text-faded;
	// }
	&__status {
		color: rgba(255,255,255,.75);
		span + span { 
			margin-left: .rem(4)[];
			&::before { 
				content: " | "; 
				color: rgba(255,255,255,.35);
				margin-right: .rem(4)[];
			}
		}

		@media @mobile { display: none; }
	}
}
