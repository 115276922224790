// main: ../build.less
@import (reference) "../config";

CONFIG[sidePanel] 
{
	--sidePanel--max-width: .rem(960)[];
}

.sidePanel 
{
	&:extend(CONFIG[sidePanel] all);
	&.modal {
		--modal--stackingContext: var(--appLayer--sidePanel, 1400);
		--modal--max-height: 100vh;
		--modal--rounding: 0;
	}

	@sidePanel--showSummaryColumns: #inline.min( 640px )[];
	@sidePanel--showSummaryStacked: #inline.max( 640px )[];

	container: sidePanel / inline-size;

	left: auto;
	right: 0;
	@media @desktop { max-width: var(--sidePanel--max-width); }
	// min-width: .rem(640)[];
	// width: 60%;
	top: 0;
	bottom: 0;
	width: 100%;
	// background-color: white;
	// z-index: @z-foreground;
	// z-index: 300;
	// transition: transform 0.2s;
	// transition: transform 5s;
	// transform: translateX(100%);

	// &.is\:open {
	// 	transform: translateX(0%);
	// }

	.modal__content {
		max-width: 90vw;
		width: 100%;
		height: 100%;
		margin-right: 0;
		overflow-x:hidden;
		padding-top: .rem(40)[];
		
		.form {
			display: flex;
			flex-direction: column;
		}
		.section--projectSummary {
			
			.section__content { padding: 0 !important; }
			.showSummary { padding: .rem(1)[] !important;}
		}


		.section--projectSummary { 
			margin-top: .rem(8)[];
			order: 1;
			.table { z-index: calc(var(--viewLayer--static)); }
		}
		.section--projectPersonnel { 
			margin-top: 0;
			order: 2; 
			.table { z-index: calc(var(--viewLayer--static) + 3); }
		}
		.section--projectAssignees { 
			order: 3; 
			.table { z-index: calc(var(--viewLayer--static) + 2); }
		}
		.section--projectContacts { 
			order: 4; 
			.table { z-index: calc(var(--viewLayer--static) + 1); }
		}
	}

	&.modal .showSummary {
		width: 100%;
		max-width: 100%;

		&__eventTitle {	padding-top: .rem(24)[]; }
		&__dateLoc 	{ padding-bottom: .rem(16)[]; }
		&__headerGrouped { 
			margin-bottom: 0; 
		}
		&__metaLines { 
			--metaLines--separator: .rem(1)[] solid var(--color-bg-faded);
		}
		
		@container sidePanel (@sidePanel--showSummaryColumns) {
			&__layout {
				grid-template-columns: 1fr 1fr;
				grid-gap: 0 .rem(32)[];
				display: grid;
				align-items: center;
			}
			&__headerGrouped { 
				grid-column: 1; 
				height: 100%;
				
				--summaryGrid--width: .rem(42)[];
				--summaryGrid--spacing: .rem(16)[];
				--summaryGrid--statusTag--font-size: .rem(24)[];
			}
			&__metaLines { 
				grid-column: 2;
				padding-top: .rem(24)[];
				padding-bottom: .rem(16)[];
			}
		}
		@container sidePanel (@sidePanel--showSummaryStacked) {
	
			&__headerGrouped { 
				--summaryGrid--width: .rem(24)[];
				--summaryGrid--spacing: .rem(8)[];
				--summaryGrid--statusTag--font-size: .rem(18)[];
				--summaryGrid--offset-left: .rem(0)[];
			}
			&__metaLines,
			&__headerGrouped {
				padding-right: .rem(16)[];
			}
			&__metaLines { 
				grid-column: 2;
				margin-top: .rem(1)[];
				padding-bottom: .rem(16)[];
				padding-left: .rem(32)[];
				
				--metaLines--padding: .rem(6)[] .rem(16)[] .rem(6)[] .rem(6)[];
			}
		}
		
	}

	.toolbar {
		background-color: mix( @color-bg, @color-theme-sidebar, 20%);
		padding: .rem(3)[] .rem(12)[] .rem(3)[];
		color: var(--color-bg);

		position: fixed;
		z-index: calc(var(--appLayer--sidePanel) + 10);
		right: 0; top:0;
		width: 100%;
		max-width: 90vw;
		@media @desktop { max-width: var(--sidePanel--max-width); }
	}

	&--personnel {
		width: 100%;
		max-width: 90vw;
		@media @desktop { max-width: var(--sidePanel--max-width); }
		
	}

	
}
