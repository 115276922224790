// main: ../build.less
@import (reference) '../config';

@contextMenu-z-index: @z-tooltip;
@tooltip-min-width: 160;
@tooltip-max-width: 480;

CONFIG[contextMenu] {
	--cMenu--stackingContext: var(--viewLayer--inputs);
	--tooltip--stackingContext: var(--viewLayer--tooltip);

	--cMenu--font: inherit;
	--cMenu--font-size: .rem(14)[];
	--cMenu--panel-padding: 0;

	--cMenu--panel-border: none;
	--cMenu--panel-rounding: .em(3, 14)[];
	--cMenu--panel-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.2);

	--cMenu--item-separator: none;
	--cMenu--item-padding-x: 1em;
	--cMenu--item-icon-padding-x: 0.666em;
	--cMenu--item-padding-y: 0.75em;
	--cMenu--item-firstlast-padding-y: 1em;
	--cMenu--item-touch-min-height: .em(48, 14)[];

	--cMenu--color-bg: @color-bg-faded;
	--cMenu--color-bg-hover: @color-bg-muted;
	--cMenu--color-text: @color-text;
	--cMenu--color-text-hover: @color-text;
	--cMenu--color-icon: @color-text-muted;
	--cMenu--color-icon-hover: @color-text;

	--cMenu--transition-bg: background 0.12s ease-in-out;
	--cMenu--transition: color 0.12s ease-in-ou-textt;

	--cMenu--tooltip-font-size: .rem(14)[];
	--cMenu--tooltip-min-width: min-content;
	--cMenu--tooltip-max-width: .rem(480)[];
	--cMenu--tooltip-offset-y: -150%;
	--cMenu--tooltip-padding: .em(8, 14)[] .em(12, 14)[] .em(8, 14)[];
	
}

.contextMenu, 
[class*='contextMenu:']  {
	&:extend(CONFIG[contextMenu]);
	position: relative;
}

.contextMenu {
	
	z-index: var(--cMenu--stackingContext);

	&__panel {
		font: var(--cMenu--font);
		font-size: var(--cMenu--font-size);
		padding: var(--cMenu--panel-padding);
		background: var(--cMenu--color-bg);
		color: var(--cMenu--color-text);
		border: var(--cMenu--panel-border);
		border-radius: var(--cMenu--panel-rounding);
		box-shadow: var(--cMenu--panel-shadow);
		z-index: var(--cMenu--stackingContext);
		display: none;
		visibility: hidden;

		// @media @mobile {
		// 	position: fixed;
		// 	z-index: var(--appLayer--overlay);
		// 	bottom: auto !important;
		// 	left: 5vw !important;
		// 	top: 50vh !important;
		// 	right: 5vw !important;
		// 	transform: translate3d(0, 0, 0) !important;
		// 	width: 90vw;
		// 	max-height: 60vh;
		// }
		// @media @tablet {
		// 	position: absolute;
		// 	z-index: var(--cMenu--stackingContext);
		// 	top: 100%;
		// }
	}

	&.is\:open {
		--cMenu--stackingContext: var(--viewLayer--isOpen);
	}
	&.is\:open &__panel {
		display: block;
		visibility: visible;
	}

	a {
		text-decoration: none;
		color: var(--cMenu--color-text);
		&:hover {
			color: var(--cMenu--color-text-hover);
		}
	}

	&__item {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		white-space: nowrap;
		user-select: none;
		transition: var(--cMenu--transition-bg), var(--cMenu--transition-text);

		padding: var(--cMenu--item-padding-y) var(--cMenu--item-padding-x);
		min-height: var(--cMenu--item-touch-min-height);
		@media @desktop {
			min-height: auto;
		}

		&:first-child { 
			padding-top: var(--cMenu--item-firstlast-padding-y);
			border-top-left-radius: var(--cMenu--panel-rounding);
			border-top-right-radius: var(--cMenu--panel-rounding);
		}
		&:last-child { 
			padding-bottom: var(--cMenu--item-firstlast-padding-y);
			border-bottom-left-radius: var(--cMenu--panel-rounding);
			border-bottom-right-radius: var(--cMenu--panel-rounding);
		}

		&:not(:first-child) { border-top: var(--cMenu--item-separator); }

		&[class*="icon:"] {
			padding-left: var(--cMenu--item-icon-padding-x);
		}
		&::before {
			margin-right: 0.333em;
			color: var(--cMenu--color-icon) !important;
			transition: var(--cMenu--text-transition);
		}
		&:hover::before {
			color: var(--cMenu--color-icon-hover) !important;
		}
		background: var(--cMenu--color-bg);
		&:hover {
			cursor: pointer;
			background: var(--cMenu--color-bg-hover);
		}
	}

	&__trigger {
		background-color: transparent;
	}

	&.is\:open .contextMenu,
	.punchCard[checked='checked'] + & > &,
	&\:tooltip:hover .contextMenu {
		--cMenu--stackingContext: var(--viewLayer--popover);
		&__panel {
			visibility: visible;
		}
		&__trigger {
			background: var(--cMenu--color-bg);
			color: var(--cMenu--color-text);
		}
	}

	&\:tooltip &__panel {
		display: flex;
		align-items: center;
		text-align: center;
		border: none;
		background: var(--cMenu--color-bg);

		@media @mobile {
			position: fixed;
			z-index: var(--tooltip--stackingContext);
			top: 10vh;
			left: 50%;
			bottom: auto;
			transform: translateX(-50%);
			width: 80vw;
			max-height: 15vh;
			padding: .em(16)[];
			box-shadow: 0.25rem 0.5rem 2rem rgba(0, 0, 0, 0.4);
		}
		@media @tablet {
			position: absolute;
			z-index: var(--tooltip--stackingContext);
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(var(--cMenu--tooltip-offset-y));
			min-width: var(--cMenu--tooltip-min-width);
			max-width: var(--cMenu--tooltip-max-width);
			padding: var(--cMenu--tooltip-padding);
			border-radius: var(--cMenu--panel-rounding);
			font-size: var(--cMenu--tooltip-font-size);
			line-height: 1;

			&::before {
				content: '';
				background: var(--cMenu--color-bg);
				width: .rem(10)[];
				height: .rem(10)[];
				position: absolute;
				z-index: -1;
				left: 50%;
				top: 75%;
				transform: translateX(-80%) rotate(45deg);
			}
		}
	}

	&\:tooltip--infoList &__panel {
		--cMenu--tooltip-min-width: max-content;
		--cMenu--tooltip-max-width: .rem(320)[];
		--cMenu--tooltip-offset-y: -100%;
		--cMenu--tooltip-padding: .em(2, 14)[] .em(4, 14)[];
		--cMenu--item-separator: 1px solid fade(@color-text, 20%);
		--cMenu--item-padding-y: 0.5em;
		--cMenu--item-padding-x: 0.75em;
		--cMenu--item-firstlast-padding-y: 0.5em;
		flex-flow: column nowrap;
		align-items: stretch;
		text-align: left;
	}

	&\:tooltip--infoList &__listTitle {
		font-size: .em(12, 14)[];
		padding: var(--cMenu--item-padding-y) var(--cMenu--item-padding-x);
		border-bottom: var(--cMenu--item-separator);
		color: var(--color-text-faded);
	}

	&\:tooltip--infoList &__item {
		&:hover { background-color: initial; }
	}
}

// experimental simple tooltip
[data-tooltip] {
	&:extend(CONFIG[contextMenu]);
	--cMenu--tooltip-offset-y: -125%;
	--cMenu--font-size: .rem(12)[];

	@media @FormFactor--tablet {	
		&:hover { position: relative; }
		&:hover::after {
			display: block;
			content: attr(data-tooltip);
			white-space: nowrap;
			z-index: var(--tooltip--stackingContext);
			position: absolute;
			top: 0;
			left: 50%;
			transform: scale(1) translateX(-50%) translateY(var(--cMenu--tooltip-offset-y));
			min-width: var(--cMenu--tooltip-min-width);
			max-width: var(--cMenu--tooltip-max-width);
			padding: var(--cMenu--tooltip-padding);
			
			font: var(--cMenu--font) !important;
			font-size: var(--cMenu--font-size) !important;
			font-weight: 400 !important;
			line-height: 1;
			color: var(--color-text) !important;
			background-color: var(--color-bg);
			border-radius: var(--cMenu--panel-rounding);
			box-shadow: var(--cMenu--panel-shadow);
		}
	}
}