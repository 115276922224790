// main: ../build.less
@import (reference) "../config.less";


// draft styles for fancy date pickers

.fancyTime {
	// width: auto !important;
	width: .rem(114)[];
	padding: 0 0 0 .rem(10)[] !important;

	&::-webkit-datetime-edit-fields-wrapper {
		display: flex;
		align-items: center;
	}


	&::-webkit-datetime-edit-text {
		padding: .rem(0)[] .rem(2)[];
		background: var(--input--color-bg);
		position: relative;
		z-index: 10;
	}


	&::-webkit-datetime-edit-hour-field,
	&::-webkit-datetime-edit-minute-field,
	&::-webkit-datetime-edit-ampm-field {
		border-left: 1px solid var(--input--color-border);
		text-align: center;
		letter-spacing: +0.1em;
		padding: .rem(8)[] .rem(5)[];
		min-width: .rem(30)[];
	}

	/* Hour */
	&::-webkit-datetime-edit-hour-field {
	}

	/* Minute */
	&::-webkit-datetime-edit-minute-field {
		margin-left: .rem(-4)[];
		position: relative;
		z-index: 0;
	}

	/* 'X' button for resetting/clearing time */
	&::-webkit-clear-button {
		display: none;
	}

	/* Up/Down arrows for incrementing/decrementing the value */
	&::-webkit-inner-spin-button {
		display: none;
		// height: .rem(40)[];
		// margin-left: .rem(4)[];
	}
}
