// main: ../build.less
@import (reference) "../config";


.optionsMatrix
{
	margin: .rem(24)[] 0 .rem(24)[];
	&__header {
		font-weight: bold;
	}
	&__header,
	&__row {
		// width: 100%;
		display: grid;
		grid-template-columns: 1fr repeat(3, 14rem);
		grid-template-rows: 1fr;
	}
	&__row {
		border-top: .rem(1)[] solid var(--color-bg-muted);

	}
	&__label,
	&__option {
		display: flex;
		align-items: center;
	}
	&__label {
		justify-content: flex-start;
		padding: .rem(10)[] .rem(12)[] .rem(10)[];
		.statusTag { margin-right: .rem(8)[]; }
	}
	
	&__option { 
		justify-content: center;
		text-align: center; 
		&:nth-child(2) { background-color: mix(@color-bg, @color-text, 99% );}
		&:nth-child(3) { background-color: mix(@color-bg, @color-text, 94% );}
		&:nth-child(4) { background-color: mix(@color-bg, @color-text, 90% );}
	}
	
	.field--radio .field__label { display: none; }
	.field--radio .field__input { 
		padding: .rem(10)[] .rem(12)[] .rem(10)[];
		justify-content: center; 
	}
	
}