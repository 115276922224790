// main: index.less
@import (reference) "lib/_lib.less";
@import (reference) "app/icons.less";
@import (reference) "_shared/utility/_utility.less";

// Build configuration
@__DEBUG: true;
@__REM_BASE: 16;
@__EM_BASE: 16;
@__REM_SCALING: 16; // deprecated, but still in use by #set lib (tb cleaned)


// Assets
@__ASSETS_PATH_DEVELOPMENT: "/static/";
@__ASSETS_PATH: @__ASSETS_PATH_DEVELOPMENT;
@__FONT_PATH: "@{__ASSETS_PATH}fonts";
@__ICON_FONT_PATH: @__FONT_PATH;
@__ICON_FONT_NAME: "showmanager-icons";
@__FONT_NAME: @font-family;
@font-family: 'Fira Sans', sans-serif;
@font-icons: @__ICON_FONT_NAME;




//-------------------------------------------------
// # BREAKPOINTS & MEDIA QUERY HELPERS (draft)

// ## CORE LAYOUT/UI PATTERNS:
// App-wide: Basic abstractions of general UI patterns
// - Basic UI: layout/content keep simple structure & adapt where possible
// - Fluid UI: layout adapts to available space, content reflows as needed
// - Fixed UI: Layout keeps predefined structure, content may adapt
@AppUI--basic:  #viewport.max( 550px )[]; 
@AppUI--fluid:  #viewport.max( 1024px )[];
// @AppUI--hybrid: #viewport.minmax( 800px, 1280px )[];
@AppUI--fixed:  #viewport.min( 1024px )[]; // replaces @tablet-large
@AppUI--extra:  #viewport.min( 1440px )[];

// Sidebar: overlay up to large screens, then fixed in layout
@AppUI--sidebar--fluid: #viewport.max( 1440px )[];
@AppUI--sidebar--fixed: #viewport.min( 1440px )[];
// FilterBar: collapsed on handheld devices, then horizontal toolbar
@AppUI--filterBar--fluid: #viewport.max( 1024px )[];
@AppUI--filterBar--fixed: #viewport.min( 1024px )[];

@AppUI--layoutSide--stacked: #viewport.max( 1280px )[];
@AppUI--layoutSide--sidebar: #viewport.min( 1280px )[];

// ## LAYOUT TWEAKPOINTS:
// Abstractions for general device size categories / screensize ranges
@FormFactor--minimal:  #viewport.max( 240px )[];
@FormFactor--narrow:   #viewport.max( 380px )[]; // replaces @mobile
@FormFactor--pocket:   #viewport.max( 460px )[]; // replaces @mobile
@FormFactor--compact:  #viewport.max( 700px )[]; // replaces @mobile
@FormFactor--medium:   #viewport.min( 700px )[];
@FormFactor--tablet:   #viewport.min( 820px )[]; // replaces @tablet
@FormFactor--hybrid:   #viewport.min( 1024px )[];
@FormFactor--desktop:  #viewport.min( 1280px )[]; // replaces @desktop
@FormFactor--advanced: #viewport.min( 1600px )[]; // replaces @large
@FormFactor--extended: #viewport.min( 1920px )[]; // replaces @large

// ## UTILITY QUERIES:
// User preferences, device context, input modes, etc
@InputMode--touch: ~"(pointer: coarse) and (hover: none)";
@InputMode--mouse: ~"(pointer: fine) and (hover: hover)";


//-------------------------------------------------
// LEGACY BREAKPOINTS (to be phased out)
@mobile: ~"(max-width: 811px)";
@tablet: ~"(min-width: 812px)";
@tablet-large: ~"(min-width: 1060px)";
@desktop: ~"(min-width: 1366px)";
@desktop-medium: ~"(min-width: 1366px) and (max-width: 1600px)";
@large: ~"(min-width: 1600px)";

@sidebar-dynamic: ~"(max-width: 1440px)";
@sidebar-static: ~"(min-width: 1441px)";

@fluid: @mobile;
@trans: @tablet;
@fixed: @desktop;

// App Level z-index stacking
@z-auto: auto;
@z-base: 1;

@z-containerResolve: 50;
@z-tooltip: 100;
@z-toolbar: 200;
@z-appToolbar: 300;
@z-appMenu: 350;
@z-modal: 400;
@z-toast: 450;

// [Z-Index Stacking System](./docs/stacking-contexts.md)
@__Z: {
	@app: {
		app-dialogue:   9999;
		app-navigation: 1600;
		feedback-notif: 1500;
		modal-dialogue: 1450;
		modal-content:  1400;
		app-toolbar:    1200;
		prio-section:   1100;
		view-section:   1000;
	};
	@view: {
		priority-overlay: 700;
		popover-is-open:  650;
		trigger-popover:  600;
		passive-popover:  500;
		control-toolbar:  400;
		utility-toolbar:  300;
		promoted-content: 100;
		input-elements:    20;
		input-elements:    20;
		static-content:    10;
		behind-content:    -1;
		revert-z-index:  auto;
	};
};

#AddVars() {

	.root(@c) when (@c = 'stackingContext') 
	{
		--appLayer--overlay:   @__Z[@app][app-dialogue];
		--appLayer--menuPanel: @__Z[@app][app-navigation];
		--appLayer--notif:     @__Z[@app][feedback-notif];
		--appLayer--modal:     @__Z[@app][modal-dialogue];
		--appLayer--sidePanel: @__Z[@app][modal-content];
		--appLayer--toolbar:   @__Z[@app][app-toolbar];
		--appLayer--promoted:  @__Z[@app][prio-section];
		--appLayer--viewMain:  @__Z[@app][view-section];

		--viewLayer--overlay:  @__Z[@view][priority-overlay];
		--viewLayer--isOpen:   @__Z[@view][popover-is-open];
		--viewLayer--popover:  @__Z[@view][trigger-popover];
		--viewLayer--tooltip:  @__Z[@view][passive-popover];
		--viewLayer--toolbar:  @__Z[@view][control-toolbar];
		--viewLayer--utility:  @__Z[@view][utility-toolbar];
		--viewLayer--promoted: @__Z[@view][promoted-content];
		--viewLayer--inputs:   @__Z[@view][input-elements];
		--viewLayer--static:   @__Z[@view][static-content];
		--viewLayer--behind:   @__Z[@view][behind-content];
		--viewLayer--revert:   @__Z[@view][revert-z-index];
	}
}


#AddVars() {
	.root() {
		/* Global Variables */
		--font-family: @font-family;
	}
	.root(@c) when ( @c = 'colors' ) {
		/* Color Swatches */
		--color-bg: @color-bg;
		--color-bg-muted: @color-bg-muted;
		--color-bg-faded: @color-bg-faded;
		--color-bg-faint: @color-bg-faded;

		--color-text: @color-text;
		--color-text-muted: @color-text-muted;
		--color-text-faded: @color-text-faded;
		--color-text-faint: @color-text-faded;

		--color-bg-highlight: @color-bg-highlight;
		--color-bg-error: @color-bg-error;
		--color-bg-error-faded: fade(@color-bg-error, 20);
		--color-bg-success: @color-bg-success;
	


		--color-text-highlight: @color-text-highlight;
		--color-text-highlight-faded: @color-text-highlight-faded;
		--color-text-error: @color-text-error;
		--color-text-error-faded: @color-text-error-faded;
		--color-text-success: @color-text-success;
	
		--color-primary: @color-primary;
		--color-primary-faded: @color-primary-faded;
		--color-primary-muted: @color-primary-muted;
		--color-secondary: @color-secondary;
		--color-secondary-faded: @color-secondary-faded;
		--color-secondary-muted: @color-secondary-muted;

		// From FilterBar MVP Styles
		--color-highlight: @color-bg-highlight;
		--color-primary-soft: fade(@color-primary, 25);
		--color-highlight-soft: fade(@color-bg-highlight, 25);
		--color-highlight-faint: fade(@color-bg-highlight, 15);
		
		// Status Colors
		--color-status-tbc: @color-status-tbc;
		--color-status-con: @color-status-con;
		--color-status-tba: @color-status-tba;
		--color-status-ann: @color-status-ann;
		--color-status-arx: @color-status-arx;
		--color-status-abs: @color-status-abs;

		// Temperature Adjustment of Neutral Palette (Sonnet Gen)
		--palette--neutral-1000: #0b0c11;  // Darkest, slightly cooler
		--palette--neutral-950: #0f1015;   // Adjusted toward new tone
		--palette--neutral-900: #14161E;   // New reference color
		--palette--neutral-850: #1b1d26;   // Matched to new temperature
		--palette--neutral-800: #23262f;   // More blue undertone
		--palette--neutral-750: #2d3038;   // Following new hue
		--palette--neutral-700: #3b3e47;   // Preserved luminance
		--palette--neutral-650: #484b55;   // Slight blue shift
		--palette--neutral-600: #585b66;   // Matched temperature
		--palette--neutral-550: #666974;   // Following new tone
		--palette--neutral-500: #757883;   // Middle grey aligned
		--palette--neutral-450: #858792;   // Consistent undertone
		--palette--neutral-400: #9699a3;   // Preserved luminance
		--palette--neutral-350: #a7aab3;   // Matched temperature
		--palette--neutral-300: #b9bbc3;   // Following new hue
		--palette--neutral-250: #c7c9d0;   // Slight blue shift
		--palette--neutral-200: #d6d8de;   // Cooler tone
		--palette--neutral-150: #e1e2e7;   // Following new tone
		--palette--neutral-100: #eaebed;   // Light end aligned
		--palette--neutral-050: #f5f5f7;   // Slight blue tint
		--palette--neutral-000: #ffffff;   // Pure white anchor

		// Extended Greyscale palette
		// --palette--neutral-1000: #0b0c0d;
		// --palette--neutral-950: #121315;
		// --palette--neutral-900: #191a1c;
		// --palette--neutral-850: #202123;
		// --palette--neutral-800: #28292c;
		// --palette--neutral-750: #323437;
		// --palette--neutral-700: #404144;
		// --palette--neutral-650: #4d4e52;
		// --palette--neutral-600: #5d5d62;
		// --palette--neutral-550: #6a6a6f;
		// --palette--neutral-500: #78787d;
		// --palette--neutral-450: #868689;
		// --palette--neutral-400: #96969a;
		// --palette--neutral-350: #a7a7aa;
		// --palette--neutral-300: #b8b9bb;
		// --palette--neutral-250: #c6c7c9;
		// --palette--neutral-200: #d5d7d8;
		// --palette--neutral-150: #e0e1e2;
		// --palette--neutral-100: #eaecea;
		// --palette--neutral-050: #f5f5f4;
		// --palette--neutral-000: #ffffff;

		// greyscale
		// --palette--neutral-1000: #0b0c0d;
		// --palette--neutral-900: #191a1c;
		// --palette--neutral-800: #28292c;
		// --palette--neutral-700: #404144;
		// --palette--neutral-600: #5d5d62;
		// --palette--neutral-500: #78787d;
		// --palette--neutral-400: #96969a;
		// --palette--neutral-300: #b8b9bb;
		// --palette--neutral-200: #d5d7d8;
		// --palette--neutral-100: #eaecea;
		// --palette--neutral-000: #fff;
		
	}
	.root(@c) when ( @c = 'theme' ) {
		/* App Theme Colors */
		--color-theme-bg: @color-theme-bg;
		--color-theme-bg-faded: @color-theme-bg-faded;
		--color-theme-bg-main: @color-theme-bg-faded;
		--color-theme-sidebar: @color-theme-sidebar;
		--color-ui-border: @color-ui-border;

		// Updated Theming Color Vars for further consolidation
		// (draft, only updated in some components that used the 
		//  new neutral palette swatches explicitly so far)
		--theme-color-bg: var(--palette--neutral-000);
		--theme-color-bg-muted: var(--palette--neutral-100);
		--theme-color-bg-faded: var(--palette--neutral-200);
		--theme-color-bg-faint: var(--palette--neutral-300);

		--theme-color-text: var(--palette--neutral-1000);
		--theme-color-text-muted: var(--palette--neutral-600);
		--theme-color-text-faded: var(--palette--neutral-500);
		--theme-color-text-faint: var(--palette--neutral-400);

		--invert-color-bg: var(--palette--neutral-900);
		--invert-color-bg-muted: var(--palette--neutral-800);
		--invert-color-bg-faded: var(--palette--neutral-700);
		--invert-color-bg-faint: var(--palette--neutral-600);

		--invert-color-text: var(--palette--neutral-100);
		--invert-color-text-subtle: var(--palette--neutral-200);
		--invert-color-text-muted: var(--palette--neutral-350);
		--invert-color-text-faded: var(--palette--neutral-450);
		--invert-color-text-faint: var(--palette--neutral-550);

	}
}


@color-status-tbc: #e0e0e0;
@color-status-con: #FFB648;
@color-status-tba: #FFE874;
@color-status-ann: #A6E97A;
@color-status-arx: #CCBCFF;
@color-status-abs: #df786c;

@color-text: #000;
@color-text-muted: #666;
@color-text-faded: #999;
// tbd: define final highlight/error/success colors
@color-text-highlight: #978017;
@color-text-highlight-faded: #c7ae40;
@color-text-error: #c12414;
@color-text-error-faded: #d84c3c;
@color-text-success: @color-secondary;

@color-bg: #fff;
@color-bg-faded: #e0e0e0;
@color-bg-muted: #aaa;
// tbd: define final highlight/error/success colors
@color-bg-highlight: #FFF4C7;
@color-bg-error: #ffdeda;
@color-bg-success: mix( @color-status-ann, @color-bg, 40% );

@color-primary: #A5CC2E;
@color-primary-faded: #B6E032;
@color-primary-muted: #799621;

@color-secondary: #006A58;
@color-secondary-faded: #259887;
@color-secondary-muted: #005B47;

@color-theme-bg: #fff;
@color-theme-bg-faded: #e0e0e0;
@color-theme-bg-main: #e0e0e0;
@color-theme-sidebar: #14161E;

// tbd: global color swatches for ui chrome
@color-ui-border: #ccc;



