// main: ../build.less
@import (reference) "../config";

.showSummary
{
	--metaLines--separator: .rem(1)[] solid var(--color-bg-muted);
	--metaLines--padding: .rem(6)[] .rem(12)[];
	--summaryGrid--width: .rem(32)[];
	--summaryGrid--spacing: .rem(12)[];
	--summaryGrid--statusTag--font-size: .rem(20)[];
	--summaryGrid--offset-left: .rem(0)[];

	.modal & {
		width: .rem(540)[];
		max-width: 90vw;
		padding: .rem(10)[] .rem(30)[];
	}
	&__eventTitle {
		font: 700 var(--font--xlarge);
		padding: .rem(8)[] .rem(0)[] .rem(6)[];
		@media @FormFactor--compact {
			font: 700 var(--font--large);
		}

	}

	&__dateLoc {
		font: var(--font--medium);
		margin-bottom: .rem(8)[];
		display: flex;
		align-items: center;
	}
	&__venue {
		margin-left: .rem(8)[];
		padding-left: .rem(8)[];
		border-left: .rem(1)[] solid var(--color-bg-muted);
	}
	
	&__meta {
		border-top: var(--metaLines--separator);
		padding: var(--metaLines--padding);
	}

	&__doors {}
	&__begin { margin-left: .rem(8)[]; }

	&__presaleStart {}

	&__showStatus {
		background-color: @color-bg;
		margin: .rem(4)[] .rem(0)[] .rem(13)[];
		padding: .rem(12)[] .rem(12)[];

		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: .rem(12)[];

		.statusTag { 
			grid-area: tag;
			place-self: center start;
		}
		.entity__title {
			grid-area: status;
			font-weight: 500;
			place-self: center start;
		}
		.entity__meta { 
			grid-area: changes; 
		}
	}

	&__headerGrouped {
		
		display: grid;	
		margin-bottom: .rem(16)[];
		grid-gap: 0 var(--summaryGrid--spacing);
		margin-left: var(--summaryGrid--offset-left); 
		grid-template: 
			"statusTag eventTitle" auto
			"statusTag statusLines" auto
			"statusTag dateLoc" auto
			/ var(--summaryGrid--width) 1fr;

		@media @FormFactor--pocket {
			--summaryGrid--spacing: .rem(8)[];
			.modal & {
				--summaryGrid--offset-left: .rem(-40)[];
			}
		}
	}
	&__headerGrouped &__eventTitle {
		grid-area: eventTitle;
		padding: 0.125em 0 0;
	}
	&__headerGrouped &__dateLoc {
		grid-area: dateLoc;
		padding: 0.25em 0 0.125em;
		margin: 0;
	}
	&__headerGrouped &__eventDate {
		font-weight: 500;
		
		// &::before {
		// 	&:extend(.iconLike all);
		// 	content: @icon-event_available;
		// 	color: var(--palette--neutral-300);
		// 	font-size: .rem(21)[];
		// 	margin-right: 0.125em;
		// }
	}
	&__headerGrouped &__venue, 
	&__headerGrouped &__eventDate {
		display: flex;
		align-items: center;
	}
	&__headerGrouped &__showStatus { display: contents; }
	&__headerGrouped .statusLines {
		padding: 0.333em 0.25em;
		grid-area: statusLines;
		border-top: .rem(1)[] solid var(--color-bg-faded);
		border-bottom: .rem(1)[] solid var(--color-bg-faded);
		color: var(--palette--neutral-600);
		
		display: block;
		display: flex;
		flex-flow: row wrap;
		align-items: baseline;
		gap: 0.25em 0.5em;
	}
	.statusTag--newStyle {
		grid-area: statusTag;
		writing-mode: vertical-lr;
		text-orientation: mixed;
		width: 100%;
		height: 100%;
		padding: 0.666em 0.333em;
		justify-content: start;
		font-size: var(--summaryGrid--statusTag--font-size);
		line-height: 1;
		border-radius: 0;
		// position: absolute;
		// 		top: .rem(1)[];
		// 		bottom: .rem(1)[];
		// right: .rem(1)[];
		// transform: rotate(180deg);
	}

	&__assignees {
		display:flex;
		align-items: center;

		.assigneeList {
			margin: 0 .rem(8)[];
			display:flex;
		}
	}
}
