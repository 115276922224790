// main: ../build.less
@import (reference) '../config';

CONFIG[fileUpload] 
{
	--fileUpload--border: 1px solid #ccc;
	--fileUpload--background: #cfcfcf;
	--fileUpload--border-radius: .rem(6)[];

	--fileUpload--thumb-background: #aaa;
	--fileUpload--thumb-color: #ccc;

	--fileUpload--dropZone-color: #222;
	--fileUpload--dropZone-background: #eee;
	--fileUpload--dropZone-blur: .rem(2)[];
	--fileUpload--dropZone-opacity: 0.7;

	--fileUpload--controls-heigth: .rem(36)[];
}

.fileUpload {

	&:extend(CONFIG[fileUpload]);

	padding: .rem(12)[];
	border: var(--fileUpload--border);
	background: var(--fileUpload--background);
	border-radius: var(--fileUpload--border-radius);
	position: relative;

	&__button {
		.resetAppearance;
		
		&:not(:first-child) {
			margin-top: .rem(16)[];
		}
		width: 100%;
		display: flex;
		justify-content: center;
		i {
			margin-right: .rem(4)[];
		}
	}

	&__dropZone {
		.coverParent;
		z-index: var(--fileUpload--stackingContext);
		display: none;
		justify-content: center;
		align-items: center;
		border-radius: var(--fileUpload--border-radius);
		color: var(--fileUpload--dropZone-color);
		background: var(--fileUpload--dropZone-background);
		outline: 2px var(--fileUpload--dropZone-color) dashed;
		outline-offset: -0.75rem;
		opacity: var(--fileUpload--dropZone-opacity);

		&::before {
			font-family: @__ICON_FONT_NAME;
			content: @icon-add;
			font-size: .rem(48)[];
			line-height: .rem(48)[];
		}
	}

	&.has\:dropZone &__dropZone {
		display: flex;
	}
	&.has\:dropZone > *:not(&__dropZone) {
		filter: blur(var(--fileUpload--dropZone-blur));
	}

	&__files {
		min-height: .rem(80)[];
	}

	&__file {
		background: var(--color-bg);
		border-radius: calc(var(--fileUpload--border-radius) * 0.75);
		display: grid;
		grid-template-areas:
			'thumb desc desc'
			'thumb menu action';
		grid-template-columns: .rem(96)[] 1fr var(--fileUpload--controls-heigth);
		grid-template-rows: auto var(--fileUpload--controls-heigth);

		& + & {
			margin-top: .rem(10)[];
		}
	}
}

.file {
	&__illustration {
		grid-area: thumb;
		padding: .rem(10)[];
		border-right: 1px solid var(--fileUpload--background);
	}

	&__thumb {
		height: 100%;
		overflow: hidden;
		background: var(--fileUpload--thumb-background);
		color: var(--fileUpload--thumb-color);

		img {
			width: 100%;
			min-height: 100%;
			object-fit: contain;
		}

		&:empty::before {
			font-family: @__ICON_FONT_NAME;
			content: @icon-photo;
			font-size: .rem(32)[];
			line-height: .rem(32)[];
		}
	}

	&__desc {
		grid-area: desc;
		justify-self: stretch;
		align-self: center;
		padding: .rem(8)[];
		border-bottom: 1px solid var(--fileUpload--background);
		overflow: hidden;
		white-space: nowrap;
	}

	&__menu {
		.select {
			--select--height: var(--fileUpload--controls-heigth);
			--select--border: none;
			--select--line-height: var(--fileUpload--controls-heigth);
		}
		grid-area: menu;
		justify-self: stretch;
		align-self: stretch;
		// .resetAppearance;
		// .font--small;
		height: var(--fileUpload--controls-heigth);
		border-right: 1px solid var(--fileUpload--background);

		.select__control {
			// .resetAppearance;
			min-height: var(--fileUpload--controls-heigth);
		}
		.select__input,
		.select__option,
		.select__value {
			.font--small;
		}
	}

	&__action {
		grid-area: action;
		justify-self: center;
		align-self: center;
		width: 100%;

		.contextMenu__trigger {
			width: 100%;
			height: var(--fileUpload--controls-heigth);
			.button {
				--button--height: var(--fileUpload--controls-heigth);
				--button--padding-y: 0;
				--button--padding-x: 0;
			}
		}

		&::before {
			color: #222;
			background: none;
		}
	}

	&__action.is\:open {
		&::before {
			background-color: var(--color-bg);
		}
	}

	&__name {
		.font--small;
		font-weight: bold;
	}

	&__meta {
		font-size: .rem(13)[];
		line-height: .rem(18)[];
		color: var(--color-text-muted);
	}
}

.form.is\:disabled .fileUpload {
	pointer-events: none;

	.file__illustration {
		pointer-events: all;
	}

	.file__name {
		color: var(--color-text-muted);
	}
}