// out: .test/buttons.css
// main: ../build.less
@import (reference) "../config";

#AddVars() { .root() {
	// --button--stackingContext: var(--viewLayer--inputs, 20);
	--button--stackingContext: var(--viewLayer--revert);
}}

CONFIG[button] 
{
	// ------------------------------------------------------
	// COMPONENT DEFAULTS:
	--button--font: inherit;
	--button--font-size: .rem(16)[];
	--button--icon-size: .rem(20)[];
	--button--height: var(--input--height);
	--button--padding-y: .rem(8)[];
	--button--padding-x: .rem(24)[];
	--button--padding-x-icon: .rem(18)[];
	--button--icon-spacing: 0.333em;
	--button--spacing: .rem(2)[];
	
	--button--color-bg: #444;
	--button--color-bg-hover: #333;
	--button--color-bg-active: #222;
	
	--button--color-text: #fff;
	--button--color-text-hover: #fff;
	--button--color-text-active: #fff;
	--buttin--color-icon: inherit;
	--button--color-icon-hover: inherit;
	--button--color-icon-active: inherit;
	
	--button--rounding: .rem(3)[];
	--button--border: none;
	--button--color-border-hover: none;
	--button--color-border-active: none;

	--button--press-depth: .rem(1)[];
	--button--transition:
		transform .1s ease-out,
		color .15s ease-out,
		background .25s ease-out,
		border-color .25s ease-out;
}

button {
	border: none;
	font-family: var(--font-family);
}


.button
{
	// ------------------------------------------------------
	// CONFIG VAR ACCESS
	&:extend(CONFIG[button]);
	&:extend(CONFIG[input]);


	// ------------------------------------------------------
	// BASE STYLES
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	height: var(--button--height);
	padding: var(--button--padding-y) var(--button--padding-x);

	cursor: pointer;
	backface-visibility: hidden;
	z-index: var(--button--stackingContext);
	transform: translate3d(0, 0, 0);
	transition: var(--button--transition);

	// Visual Styles
	font: var(--button--font);
	font-size: var(--button--font-size);
	white-space: nowrap;
	a& { text-decoration: none; }
	
	color: var(--button--color-text);
	background: var(--button--color-bg);
	border: var(--button--border);
	border-radius: var(--button--rounding);

	// Icon Styling
	&::before { 
		color: var(--button--color-icon); 
		font-size: var(--button--icon-size);
	}
	&.has\:iconOnly {
		width: var(--button--height);
		padding: 0;
	}
	&[class*="icon:"]:not(.has\:iconOnly) {
		// padding-left: var();
		padding-left: var(--button--padding-x-icon);
		&::before { margin-right: var(--button--icon-spacing); }
	}

	// TODO: uniform focus outline for all components
	&:focus  { outline: none; }

	// STATE: Hovered
	&:hover {
		color: var(--button--color-text-hover); 
		background: var(--button--color-bg-hover);
		border-color: var(--button--color-border-hover);
		&::before { color: var(--button--color-icon-hover); }
	}

	// STATE: Pressed
	&:active,
	&.is\:active {
		color: var(--button--color-text-active); 
		background: var(--button--color-bg-active);
		border-color: var(--button--color-border-active);
		&::before { 
			transform: translate3d(0, var(--button--press-depth), 0);
			color: var(--button--color-icon-active); 
		}
	}

	// Adjacent Spacing
	& + & { margin-left: var(--button--spacing); }

	// ------------------------------------------------------
	// SIZE MODIFIER
	&\:small {
		--button--font-size: .rem(14)[];
		--button--icon-size: .rem(18)[];
		--button--height: .rem(30)[];
		--button--padding-y: .rem(4)[];
		--button--padding-x: .rem(12)[];
		--button--padding-x-icon: .rem(10)[];
	}
	&\:micro {
		--button--font-size: .rem(12)[];
		--button--icon-size: .rem(12)[];
		--button--height: .rem(22)[];
		--button--padding-y: .rem(3)[];
		--button--padding-x: .rem(8)[];
		--button--padding-x-icon: .rem(6)[];
	}
	&\:large {
		--button--font-size: .rem(20)[];
		--button--icon-size: .rem(24)[];
		--button--height: .rem(45)[];
		--button--padding-y: .rem(10)[];
		--button--padding-x: .rem(32)[];
	}

	// ------------------------------------------------------
	// STYLING MODIFIERS
	&\:naked {
		--button--color-bg: transparent;
		--button--color-bg-hover: inherit;
		--button--color-bg-active: transparent;
		--button--color-text: #14161E;
		--button--color-text-hover: #14161E;
		--button--color-text-active: #14161E;
	}
	&\:primary {
		--button--color-bg: var(--color-primary);
		--button--color-bg-hover: var(--color-primary-faded);
		--button--color-bg-active: var(--color-primary);
		--button--color-text: var(--color-secondary-muted);
		--button--color-text-hover:  var(--color-secondary-muted);
		--button--color-text-active: var(--color-secondary-muted);
	}
	&\:secondary {
		--button--color-bg: var(--color-secondary);
		--button--color-bg-hover: var(--color-secondary-muted);
		--button--color-bg-active: var(--color-secondary);
		--button--color-text: #E6EAF0;
		--button--color-text-hover:  #E6EAF0;
		--button--color-text-active: #E6EAF0;
	}
	&\:danger {
		--button--color-bg: var(--color-text-error-faded);
		--button--color-bg-hover: var(--color-text-error);
		--button--color-bg-active: var(--color-text-error-faded);
		--button--color-text: var(--color-bg);
		--button--color-text-hover:  var(--color-bg);
		--button--color-text-active: var(--color-bg);
	}
	&\:outline {
		--button--color-bg: transparent;
		--button--color-bg-hover: inherit;
		--button--color-bg-active: transparent;
		--button--color-border: currentColor;
		--button--color-border-hover: currentColor;
		--button--color-border-active: currentColor;
		--button--border: .rem(1)[] solid currentColor;
		--button--color-text: var(--color-text-muted);
		--button--color-text-hover: var(--color-text-faded);
		--button--color-text-active: var(--color-text-faded);
	}
	&\:outline&\:primary {
		--button--color-text: var(--color-primary);
		--button--color-text-hover: var(--color-primary-faded);
		--button--color-text-active: var(--color-primary-muted);
	}
	&\:outline&\:secondary {
		--button--color-text: var(--color-secondary);
		--button--color-text-hover: var(--color-secondary-faded);
		--button--color-text-active: var(--color-secondary-muted);
	}
}