// main: ../build.less
@import (reference) "../config";

#AddVars() {.root() {
	--pagingBar--stackingContext: var(--viewLayer--utility, 300);
}}
CONFIG[pagingBar] {
	--pagingBar--top-separator: 1px solid var(--color-bg-faded);
	--pagingBar--top-padding: .em(6)[] .em(16)[] .em(6)[] .em(16)[];

	--pagingBar--bottom-separator: 1px solid var(--color-bg-faded);
	--pagingBar--bottom-padding: .em(8)[] .em(16)[] .em(4)[] .em(16)[];
}

.pagingBarTable {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	min-height: 100%;
}

@pagingBar--noLabels: #inline.max( 640 )[];
@pagingBar--lessNumbers: #inline.minmax( 450, 640 )[];
@pagingBar--prevNextOnly: #inline.max( 450 )[];

.pagingBar 
{
	&:extend(CONFIG[pagingBar]);

	container: pagingBar / inline-size;
	position: relative;
	z-index: var(--pagingBar--stackingContext, 1);

	display: flex;
	align-items: center;
	
	font-size: .rem(14)[];
	padding: var(--pagingBar--top-padding);
	border-bottom: var(--pagingBar--top-separator);
	
	&--bottom {
		padding: var(--pagingBar--top-padding);
		margin-top: .em(32)[];
		border-bottom: none;
		border-top: var(--pagingBar--bottom-separator);
	}
	.pageSize {
		max-width: .em(60)[];

		.select{
			&__control {
				border: none;
				min-height: .em(24)[];
			}
		}
	}
	&__indicator {
		color: @color-text-faded;
		font-size: .rem(14)[];
		em, b { 
			font-weight: normal;
			text-decoration: none;
		}
		em { font-weight: 500; }
	}
	@container pagingBar (@pagingBar--noLabels) {
		.pageSize { margin-left: .rem(-8)[];}
		b { display: none; }
		em {
			&::before { content: "("; }
			&::after { content: ")"; }
		}
	}

	.pagination {
		margin-left: auto;
		margin-bottom: 0;
	}
}