// main: ../build.less
@import (reference) "../config";


CONFIG[viewSidebar] 
{
	--viewSidebar--color-bg: var(--invert-color-bg);
	--viewSidebar--color-text: var(--invert-color-text-subtle);
	--viewSidebar--color-link: var(--invert-color-text-muted);
	--viewSidebar--color-link-hover: var(--invert-color-text);
	--viewSidebar--color-menuToggle: var(--color-primary);

	--viewSidebar--mobile--width-max: .rem(320)[];
	--viewSidebar--mobile--width: 80vw;
	--viewSidebar--mobile--shadow: 5vw 0 15vw rgba(0,0,0,.7);
}

.view__sidebar
{
	&:extend(CONFIG[view] all);
	&:extend(CONFIG[viewSidebar] all);

	background: var(--viewSidebar--color-bg);
	color: var(--viewSidebar--color-text);

	a { color: var(--viewSidebar--color-link); }
	a:hover { color: var(--viewSidebar--color-link-hover); }

	
	@media @AppUI--sidebar--fluid
	{
		position: fixed;
		top: 0; right: auto; bottom: 0; left: 0;
		width: var(--viewSidebar--mobile--width);
		max-width: var(--viewSidebar--mobile--width-max);
		z-index: var(--viewSidebar--stackingContext);
		box-shadow: var(--viewSidebar--mobile--shadow);

		display: none;
		visibility: hidden;
		.sidebar\:open & {
			display: block;
			visibility: visible;
		}
	}
}

.sidebar
{

	&__logo {
	}

	&__menutoggle {

		&.button { 
			--button--color-text-hover: currentColor;
			--button--color-text-active: currentColor;
		}
		&.button::before { 
			color: var(--viewSidebar--color-menuToggle); 
		}
		float: right;
		margin: .rem(4)[] .rem(8)[];

		@media @AppUI--sidebar--fixed {
			display: none;
			visibility: hidden;
		}
	}

	
	&__header { 
		padding: .rem(16)[];

		@media @AppUI--sidebar--fixed {
			display: none;
		}
	}

	&__logo  { max-width: .rem(220)[]; }

	&__profile {
		padding: .rem(16)[] .rem(8)[]; 
		overflow: hidden;
		display: grid;
		grid-template: 
			"image greeting" auto
			"image meta" auto
			/ auto 1fr;
		grid-gap: 0.125em 0.75em;

	}
	&__profileAvatar {
		grid-area: image;
		place-self: center;
		max-width: .rem(100)[];
		&.avatar {
			--avatar--size: .rem(42)[];
			--avatar--placeholder-size: .rem(21)[];
			--avatar--hover-scale: 1;
		}
	}
	&__profileGreeting { grid-area: greeting;}
	&__profileMeta { 
		grid-area: meta;
		font-size: .rem(14)[];
		a::before { opacity: 0.5; margin-left: -0.25em;}
	}
}