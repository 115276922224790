// main: ../build.less
@import (reference) "../config";

.entity {
	flex-flow: column nowrap;
	align-items: flex-start;

	--entity--title-font: bold .fslh(16, 20)[] var(--font-family);
	--entity--meta-font: .fslh(13, 20)[] var(--font-family);

	--entity--soldOut-icon: @icon-star-on;
	--entity--soldOut-color: var(--color-status-con);
	--entity--soldOut-font-size: .rem(16)[];

	--entity--showSold-icon: @icon-link-out;
	--entity--showSold-color: var(--color-text-faded);
	--entity--showSold-font-size: .rem(16)[];

	&__title  { 
		font: var(--entity--title-font);

		&[class*="icon:"]{
			display: flex;
			// align-items: center;
			flex-flow: row nowrap;
			gap: 0.3em;
			&::before {
				font-size: 1.25em !important;
				line-height: 0.875 !important;
				// opacity: 0.375;
			}
		}
	}
	&__meta   { 
		font: var(--entity--meta-font);
		color: var(--color-text-muted); 
		em { font-weight: 500;}
	}

	&__title {
		&.icon\:help::before { color: var(--palette--neutral-200); }
		&.icon\:accept::before { color: var(--palette--neutral-350); }
		&.icon\:date::before { color: var(--color-text-highlight-faded); }
		&.icon\:public::before { color: var(--color-primary); }
		&.icon\:directions::before { color: var(--color-primary); }
	}

	&.is\:soldOut &__title,
	&.is\:OwnershipSold &__title {
		display: flex;
		align-items: center;
		gap: 0.125em;
		&::before, &::after {
			order: 1;
			margin-top: -0.1em;
		}
	}
	// TODO: is this too much? soldout is listed in the status cell
	&.is\:soldOut &__title {
		&::after {
			&:extend(.iconLike all);
			content: var(--entity--soldOut-icon);
			color: var(--entity--soldOut-color);
			font-size: var(--entity--soldOut-font-size);
			line-height: 1 !important;
		}
	}
	&.is\:OwnershipSold &__title {
		// color:var(--color-text-muted);
		&::before {
			&:extend(.iconLike all);
			content: var(--entity--showSold-icon);
			color: var(--entity--showSold-color);
			font-size: var(--entity--showSold-font-size);
			line-height: 1 !important;
			margin-left: 0.125em;
			
		}
	}
	// // displayed in title cell
	// &.is\:soldOut &__title::after {
	// }
	// // displayed in status cell
	// &__title.is\:soldOut {
	// 	display: inline-flex;
	// 	align-items: center;
	// 	flex-flow: row-reverse nowrap;
	// 	&::after { margin-right: 0.25em; }
	// }
}
