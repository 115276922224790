// main: ../build.less
@import (reference) '../config';

CONFIG[select]
{
	--select--stackingContext: var(--viewLayer--inputs, 20);
	--select--font: inherit;
	--select--font-size: 1em;	
	--select--line-height: .em(26)[];
	--select--height: .em(42)[];
	--select--padding-y: .em(8)[];
	--select--padding-x: .em(12)[];
	--select--menu-min-height: .em(36)[];

	--select--border-radius: .em(3)[];
	--select--border-width: .rem(1)[];
	--select--border: var(--select--border-width) solid var(--select--color-border);
	--select--border-menu: var(--select--border-width) solid var(--select--color-border);

	--select--color-bg: #fff;
	--select--color-bg-hover: #fff;
	--select--color-bg-active: #fff;

	--select--color-border: #999;
	--select--color-border-hover: #999;
	--select--color-border-active: #111;

	--select--color-text: #222;
	--select--color-text-hover: #111;
	--select--color-text-active: #000;
	--select--color-text-error: @color-text-error;
	--select--color-placeholder: #666;

	--select--icon-size: .em(20)[];
	--select--icon-area-width: .em(24)[];
	--select--color-icon: #999;
	--select--color-icon-hover: #777;
	--select--color-icon-active: #444;

	--select--item-color-bg: #f6f6f6;
	--select--item-color-bg-hover: #f9f9f9;
	--select--item-color-bg-active: #f9f9f9;
}

.select 
{
	&:extend(CONFIG[select]);

	width: 100%;
	position: relative;
	z-index: var(--select--stackingContext);
	&.is\:open { --select--stackingContext: var(--viewLayer--isOpen); }


	&__control {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;

		min-height: var(--select--height);
		position: relative;
		z-index: @z-tooltip + 15;

		font: var(--select--font);
		font-size: var(--select--font-size);
		line-height: var(--select--line-height);

		border: var(--select--border);
		border-radius: var(--select--border-radius);
	}

	&__icon {
		font-size: var(--select--icon-size);
		width: var(--select--icon-area-width);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: .em(4)[] .em(8)[];
	}

	&__indicators {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: var(--select--padding-x);
	}

	&__value {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: @z-tooltip + 20;
		font-size: var(--select--font-size);
		line-height: var(--select--line-height);
		padding: 0 var(--select--padding-x);

		.button {
			position: relative;
			z-index: @z-tooltip + 25;
		}
	}

	&__valueLabel {
		display: none;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}

	&__input {
		width: 100%;
		background: none;
		border: none;
		border-radius: 0;
		outline: none;
		height: auto;
		box-sizing: border-box;
		font-size: var(--select--font-size);
		line-height: var(--select--line-height);
		padding: 0 1.5em 0 var(--select--padding-x);
		cursor: pointer;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		&:focus { cursor: initial; }
	}

	&__menu {
		position: absolute;
		width: var(--select--width);
		z-index: @z-modal + 10;
		background-color: white;
		border: var(--select--border-menu);
		border-radius: var(--select--border-radius);
		
		overflow: hidden;
		display: none;
		visibility: hidden;

		.menu__items {
			max-height: calc(var(--select--menu-max-height) + 1px);
			overflow-y: auto;
		}
	}

	&__menu-notice {
		font-weight: bold;
	}

	&__message {
		font-size: var(--select--font-size);
		line-height: var(--select--line-height);
		padding: var(--select--padding-y) var(--select--padding-x);
		cursor: pointer;
		&:hover { background-color: var(--select--item-color-bg-active); }
	}

	&__option {
		background-color: transparent;
		color: inherit;
		cursor: default;
		display: block;
		font-size: var(--select--font-size);
		line-height: var(--select--line-height);
		padding: var(--select--padding-y) var(--select--padding-x);
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: pre-wrap;
		user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		box-sizing: border-box;

		&.is\:focus,
		&--is-focused {
			background-color: var(--select--item-color-bg);
		}
		&.is\:selected {
			font-weight: 700;
		}
		&:hover {
			background-color: var(--select--item-color-bg-hover);
		}
		&.has\:conflict { 
			color: var(--select--color-text-error);
			&::before { float: right; }
		}
	}

	&__moreItemsHint {
		font-size: var(--select--font-size);
		line-height: var(--select--line-height);
		padding: var(--select--padding-y) var(--select--padding-x);
		border-top: 1px solid lighten(@input-border-color, 5%);
		cursor: none;
	}

	// States
	&.has\:value &__input { opacity: 0; }
	&.has\:value &__input::placeholder { color: inherit; }
	&.has\:value &__input:disabled::placeholder { color: var(--select--color-text); }
	&.has\:value &__value { color: initial; }
	&.has\:value &__valueLabel { display: inline-block; }

	&.is\:open &__input { opacity: 1; }
	&.is\:open &__value { visibility: hidden; pointer-events: none; } 
	&.is\:open &__menu { display:block; visibility: visible; }

	&.is\:focus &__control { border-color: var(--select--color-border-active); }

	&.has\:noOptions &__menu { display: none !important; }

	&.is\:disabled { pointer-events: none; }
}

// Variants
.select--entity {
	&.has\:value .select {
		&__input::placeholder {
			color: var(--select--color-placeholder);
		}
	}
}

.select--multi {
	.select {
		&__value { display: none; }
		&__input { padding: 0; }
	}

	&.has\:value .select {
		&__input {
			opacity: 1;
			padding-left: 0.333em;
		}
		&__input::placeholder,
		&__input:disabled::placeholder {
			color: var(--select--color-placeholder);
		}
	}
}

.select--compact {
	--select--menu-width-min: 21em;
	--select--menu-width-max: 36em;
	width: auto;
	display: block;
	white-space: nowrap;

	.select {
		&__control {
			border: none;
			min-height: auto;
			display: inline-flex;
			flex-wrap: nowrap;
		}
		&__input { opacity: 1; }
		&__indicators { position: static; }
		&__value {
			position: static;
			display: block;
			height: auto;
			width: auto;
		}
		&__valueCount { font-weight: bold; }
		
		&__menu {
			min-width: var(--select--menu-width-min);
			max-width: var(--select--menu-width-max);
		}
		&__search {
			border: none;
			border-bottom: 1px solid lighten(@input-border-color, 5%);
			border-radius: 0;
			.select__input {
				margin: .em(4)[];
				margin-left: 0;
			}
		}
	}

	&.is\:focus .select {
		&__value { display: block; }
	}

	.tagPicker {
		&__tagLabel {
			max-width: .rem(128)[];
		}
	}
}

.statusSelect {
	.statusTag {
		margin-right: .rem(3)[];
	}
	.select__option {
		white-space: nowrap;
	}
}