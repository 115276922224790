// out: .test/propList.css
// main: ../build.less
@import (reference) "../config";

.propList 
{
	--propList--container-min: .rem(420)[];
	--propList--label-min: .rem(180)[];
	--propList--label-icon: @icon-tune;
	--propList--item--gap: 1em;
	--propList--item--padding: 0.5em;
	--propList--item--separator: 1px solid var(--color-theme-bg-faded);

	--propList--color-label: var(--color-text);
	--propList--color-icon: var(--color-primary);
	--propList--color-desc: var(--color-text);

	container-name: propList;
	container-type: inline-size;

	* + & { margin-top: 1em; }

	display: block;
	width: 100%;
	list-style: none !important;
	
	&__item {
		// color: var(--color-text-faded);
		
		display: flex;
		flex-flow: row wrap;
		align-items: baseline;
		padding: var(--propList--item--padding) 0;
		border-top: var(--propList--item--separator);

		&Label {
			flex: 0 1 var(--propList--label-min);
			min-width: var(--propList--label-min);
			color: var(--propList--color-label);
			display: inline-flex;
			align-items: center;
			&::before {
				&:extend(.iconLike all);
				content: var(--propList--label-icon);
				color: var(--propList--color-icon);
				margin: 0 0.35em;
			}
		}
		&Desc {
			flex: 1 1 calc(var(--propList--container-min) - var(--propList--label-min));
			min-width: calc(var(--propList--container-min) - var(--propList--label-min));
			padding-left: 2.2em;
			color: var(--propList--color-desc);
		}
	}
}