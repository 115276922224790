// out: .test/tables.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


@container listTable (@listTable--expanded) {

	.table--rowControls .table__row {
		grid-template-columns:
			[hand]  var(--td--hand)
				repeat(auto-fit, minmax(var(--td--std), 1fr ))
			[ctrl] var(--td--ctrl);
	}

	.table--withPrimary .table__row {
		grid-template-columns:
			[hand]  var(--td--hand)
			[primary] minmax(var(--td--LG), 2fr)
			repeat(auto-fit, minmax(var(--td--std), 1fr ))
			[ctrl] var(--td--ctrl);
	}

	// .table--assignees .table__row {
	// 	grid-template-columns:
	// 		repeat(auto-fit, minmax(var(--td--std), 1fr ))
	// 		[ctrl] var(--td--ctrl);
	// }

	// .table--contacts .table__row {
	// 	grid-template-columns:
	// 		repeat( auto-fit, minmax(var(--td--std), 1fr ))
	// 		[ctrl] var(--td--ctrl);
	// }

	.table--contactList .table__row,
	.table--contactList .table__header {
		--row--height: .rem(48)[];
		grid-template-columns:
			[id]  var(--td--ctrl)
			[mail]  minmax(var(--td--LG), 2fr)
			[name]  minmax(var(--td--LG), 2fr)
			[menu]  var(--td--XS);
	}

	.table--announcementList .table__row,
	.table--announcementList .table__header
	{

		grid-template-columns:
			[status] minmax(var(--td--2XL), 4fr)
			[show]   minmax(var(--td--2XL), 4fr)
			[date]   minmax(var(--td--LG), 2.5fr)
			repeat(auto-fit, minmax(var(--td--std), 2fr ))
			[menu]   minmax(var(--td--menu), 1fr);
	}
	.table--announcementList .table__row { grid-template-rows: .rem(60)[]; }

	.table--calendarTokenList .table__row,
	.table--calendarTokenList .table__header {
		grid-template-columns:
			[token]    minmax(var(--td--LG), 2.5fr)
			[created]  minmax(var(--td--std), 1fr)
			[edited]   minmax(var(--td--std), 1fr)
			[menu]     var(--td--menu);

		// grid-template-rows: .rem(60)[];
	}
	.table--calendarTokenList + .pagingBar {
		border-top: .rem(2)[] solid var(--color-ui-border);
		padding-top: 1em;
	}
}
@container fieldTable (@fieldTable--expanded) {

	
	.table--internals\.ticketOffers .table__row
	{
		grid-template-columns:
			[hand]       var(--td--hand)
			[category]     minmax(var(--td--std), 1fr )
			[status]       minmax(var(--td--std), 1fr )
			[availability] minmax(var(--td--2XS), 1.2fr )
			[basePrice]    minmax(var(--td--std), 0.4fr )
			[finalPrice]   minmax(var(--td--std), 0.4fr )
			[ctrl]      var(--td--ctrl);
	}

	// Form Content Tables
	.table--stages .table__row,
	.table--stages .table__header,
	.table--socials .table__row,
	.table--socials .table__header
	{
		grid-template-columns:
			[hand]  var(--td--hand)
			[name]  minmax(var(--td--LG), 2fr)
			[type]  minmax(var(--td--LG), 2fr)
			[ctrl]  var(--td--ctrl);
	}



	.table--properties .table__row,
	.table--properties .table__header {
		grid-template-columns:
			[label]  minmax(var(--td--XL), 1fr)
			[value] minmax(var(--td--3XL), 2.5fr)
			[ctrl]  var(--td--ctrl);
	}
}

// halve tables: 12 cols ~ 30-40px -> 360-480 default
//
// Form tables: 24 cols ~ 40px -> 960 default
//
// zB: billing
// control: 1c
// small: 4c
// medium: 6c
// large: 8c
//
//
// Full tables: 48 cols ~ 30px -> 1440 default (1150 @24px)
//
// zB: my shows
// date: 5c
// showinfo: 10-11c
// status: 10-11c
// assignments: 5c
// controls: 2-3c
