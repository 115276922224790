// out: .test/table--showList.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


.table--showList 
{
	@showListTable--tweakExpanded:  #inline.minmax( 820px, 1060px )[];
	@showListTable--tweakTransition:  #inline.minmax( 820px, 880px )[];
	@showListTable--tweakAdaptive:  #inline.minmax( 550px, 820px )[];
	
	--row--height--collapsed-primary: .rem(60)[];
	--row--height--collapsed: .rem(60)[];
	--row--height--statusLineSolo: .rem(48)[];
	--row--height: .rem(54)[];
	--td--team: .rem(260)[];
	
	.label\:lastEdited,
	.field\:lastEdited { justify-content: center;}
	.field\:lastEdited { 
		color: var(--color-text-faded); 
		font-size: .rem(14)[];
	}

	
	@container listTable (@listTable--expanded) {
		.table__row,
		.table__header {
			grid-template-columns:
				[date]      minmax(var(--td--LG), 2.75fr)
				[show]      minmax(var(--td--LG), 3.5fr)
				[status]    minmax(var(--td--LG), 3.5fr)
				[assignees] minmax(var(--td--2XL), 3fr)
				[edited]    minmax(var(--td--std), 1.5fr)
				[menu]      minmax(var(--td--ctrl), var(--td--menu));
		}

		// .field\:date.has\:statusTag { 
		// 	.statusTag {
		// 		--statusTag--font-size: .rem(16)[];
		// 		writing-mode: vertical-lr;
		// 		text-orientation: mixed;
		// 		padding: 0.666em 0.333em;
		// 		justify-content: start;
		// 		line-height: 1;
		// 		border-radius: 0;
		// 	}
		// }

		.table__cell {
			// padding: var(--cell--pad-x);
			&.field\:lastEdited { white-space: nowrap; }
			// &.has\:statusTag { padding-left: 0.333em; }

			&.has\:infoPills {
				padding: 0.1em 0.333em 0.25em var(--cell--pad-x);
				.entity { padding-top: 0.25em; padding-bottom: 0.125em;}
				.pills {
					margin: 0 0 0 auto;
					margin-left: auto;
					margin-right: 0;
					flex-flow: column;
					align-items: flex-end;
				}
			}
		}
	}

	// remove last edited to make room near the listTable breakpoint
	@container listTable (@showListTable--tweakExpanded) {	
		.table__row,
		.table__header {
			grid-template-columns:
				[date]      minmax(var(--td--LG), 2.5fr)
				[show]      minmax(var(--td--LG), 3.5fr)
				[status]    minmax(var(--td--LG), 3.5fr)
				[assignees] minmax(var(--td--2XL), 3fr)
				[menu]      minmax(var(--td--ctrl), var(--td--menu));
		}

		.label\:lastEdited,
		.field\:lastEdited {
			display: none;
			visibility: hidden;
		}



		.field\:status.has\:infoPills {
			padding: 0.25em var(--cell--pad-x);
			flex-flow: column;
			gap: 0.333em;
			align-items: start;
			justify-content: center;
			
			.pills { 
				margin: 0 0 0 -0.2em;
				flex-flow: row wrap;
			}
		}
	}

	// hide additional action to edge out space
	@container listTable (@showListTable--tweakTransition) {
		.table__cell {
			&.for\:editActions {
				> .icon\:edit { display: none; visibility: hidden; }
			}
		}
	}

	// Template for Single Column Collapse all the way down
	@container listTable (@listTable--collapsed) {

		.table__row {
			grid-template-rows:
				[header] var(--row--height--collapsed-primary)
				[date]   minmax(var(--row--height--collapsed), min-content)
				[status] minmax(var(--row--height--collapsed), min-content)
				[footer] minmax(var(--row--height--collapsed), min-content);
		}
		.table__row {
			background: transparent;
			&.is\:open {
				.field\:assignees,
				.field\:date .entity,
				.field\:status,
				.has\:menu { 
					background-color: fade(@color-bg-faded, 20);
				}
			}
		}

		.table__cell {

			&.field\:lastEdited,
			&.has\:menu .contextMenu,
			&.is\:primary .contextMenu__panel {
				display: none; 
				visibility: hidden;
			}
			&.field\:title, &.field\:date { 
				grid-column: A / span all;
			}
			
			&.field\:status {
				// display: contents;
				
				grid-row: status; 
				grid-column: A / span 2; 
				flex-flow: column;
				gap: 0.25em;
				align-items: start;
				justify-content: center;

				.entity {
					--entity--title-font: 500 .fslh(14, 18)[] var(--font-family);
					--entity--meta-font: .fslh(12, 18)[] var(--font-family);
				}
					
				
				// .entity { 
				// 	height: var(--row--height--statusLineSolo);
				// 	width: 100%;
				// 	border-left: var(--cell--separator);
				// 	border-top: var(--cell--separator);
				// 	padding: var(--cell--pad-x);
				// 	display: flex;
				// 	align-items: start;
				// 	justify-content: center;
				// 	text-align: left;
				// }
				// .entity__title { font-weight: 500; }
			}
			&.field\:date {
				display: contents;
				.statusTag { 
					grid-column: menu; grid-row: header; 
					place-self: center center;
					writing-mode: horizontal-tb;
					justify-content: center;
					--statusTag--font-size: .rem(14)[]; 
					--statusTag--width: .rem(36)[];
					--statusTag--height: .rem(28)[];
				}
				.entity { 
					grid-row: date; 
					grid-column: A / span 2; 
					height: var(--row--height--collapsed-primary);
					width: 100%;
					border-left: var(--cell--separator);
					border-top: var(--cell--separator);
					padding: var(--cell--pad-x);
					display: flex;
					align-items: start;
					justify-content: center;
					text-align: left;
				}
			}
			&.has\:userEntities {
				grid-column: A;
				grid-row: footer;
			}
			
		}
	}

	// Intermediary override with 2 columns & visible date
	@container listTable (@showListTable--tweakAdaptive) {
		.table__header,
		.table__row {
			grid-template-columns: 
				[ctrl] var(--td--ctrl) 
				[A] minmax(var(--td--2XL), 1.5fr)
				[B] minmax(var(--td--XL), 1fr)
				[menu] auto;
		}
		.table__row {
			grid-template-rows:
				[header] var(--row--height--collapsed-primary)
				[footer] minmax(var(--row--height--collapsed), min-content);
		}
		.table__cell {
			&.field\:title { grid-column: A; grid-row: header; }
			&.field\:date .entity { 
				grid-column: B; grid-row: header; 
				border-right: var(--cell--separator);
				background: transparent !important;
			}
			&.field\:status { 
				grid-column: B; grid-row: footer; 
				// height: var(--row--height--collapsed);
				.entity { 
				}
			}
			
			&.field\:assignees { grid-column: A ; grid-row: footer; }
		}
	}

	// Moves Status Field to edge to ensure title readability
	@container listTable (@listTable--minimal) {
		.table__cell {
			&.has\:statusTag {
				.statusTag { 
					place-self: center end;
					margin-right: 0.25em;
					--statusTag--font-size: .rem(12)[]; 
					--statusTag--width: .rem(32)[];
					--statusTag--height: .rem(20)[];
				}
			}
		}
	}


	// @container listTable (@listTable--expanded) {

		// Experimental, for next one
		// looks dope, but should be at row start
		// .table__cell {
			// &.has\:statusTag {
			// 	padding-right: 1.75rem;
			// 	padding-left: 0;
			// 	position: relative;
			// 	.statusTag {
			// 		position: absolute;
			// 		top: .rem(1)[];
			// 		bottom: .rem(1)[];
			// 		transform: rotate(180deg);
			// 		right: .rem(1)[];
			// 		writing-mode: vertical-lr;
			// 		text-orientation: mixed;
			// 		width: 1.5rem;
			// 		border-radius: 0;
			// 		height: calc(100% - .rem(2)[]);
			// 		font-size: .rem(14)[];
			// 		line-height: 1;
			// 		justify-content: start;
			// 		padding: 0.666em 0.333em;
			// 	}
			// }
	// }

}