// main: ../build.less
@import (reference) '../config';

.announcements {
	// margin-top: .rem(10)[];

	&__head {
		position: relative;
		padding: .rem(8)[] .rem(16)[] .rem(8)[] .rem(64)[];
		// display: grid;
		// grid-template-columns: 2rem 5rem 1fr;
		// grid-template-rows: 2rem 2rem;
		// grid-template-areas: "toggle tag label" " toggle tag meta";

		.statusTag {
			position: absolute;
			z-index: @z-auto;
			left: .rem(8)[];
			top: 50%;
			transform: translateY(-50%);
		}

		&.status\:announced { background-color: var(--color-status-ann); }
		&.status\:pendingAnnouncement { background-color: var(--color-status-tba); }
		&.status\:pendingConfirmation { background-color: var(--color-status-tbc); }
		&.status\:confirmed { background-color: var(--color-status-con); }
		&.status\:archived { background-color: var(--color-status-arx); }

		&:not(.is\:active) { background-color: var(--color-bg); }
	}
	// &__toggle {
	// 	grid-area: toggle;
	// 	align-self: center;
	// 	justify-self: center;
	// 	#icon.set( 'unfold', 18 );
	// 	cursor:pointer;

	// 	&::before { color: @color-text-faded; }

	// 	.is\:open &::before { content: @icon-fold; }
	// }
	&__label,
	&__meta { padding-left: .rem(12)[]; }

	&__label {
		grid-area: label;
		display: flex;
		justify-content: space-between;
	}
	&__meta  {
		grid-area: meta;
		font-size: .rem(14)[];  line-height: .rem(21)[];
		color: @color-text-muted;

		* + * { padding-left: .rem(5)[]; }
	}

	&__labelName { font-weight: bold; }

	&__changes {
		background-color: mix( @color-bg, @color-bg-faded, 50%);
		overflow: hidden;
		height: 0;
	}
	.metaBox__section.is\:open &__changes { height: auto; }

}