// out: .test/table--ticketVendors.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";



	.table--ticketVendors {
		// breakpoint override
		@ticketVendorsTable--minimal:  #inline.max( 580px )[];
	
		// Expanded Layout
		@container fieldTable (@fieldTable--expanded) {
			.table__header,
			.table__row {
				grid-template-columns:
					[hand] var(--td--hand)
					[name] minmax(var(--td--std), 1.5fr)
					[link] minmax(var(--td--2XL), 3fr)
					[date] minmax(var(--td--2XL), 2fr)
					[ctrl] var(--td--ctrl);
			}
			.table__row { grid-template-rows: var(--row--height); }
	
			.table__cell {
				&.field\:label  { grid-column: label; }
				&.field\:date   { grid-column: date; }
				&.field\:extra  { grid-column: extra; }
				&.for\:onDelete { grid-column: ctrl; }
			}
		}
	
		// Collapsed Layout
		@container fieldTable (@fieldTable--collapsed) {
			.table__row {
				grid-template-columns:
					[hand] var(--td--hand) [A] 1fr [B] 2fr [ctrl] var(--td--ctrl);
				grid-template-rows: 
					[header] var(--row--height--collapsed-primary)
					[date] var(--row--height--collapsed);
			}
			.table__cell {
				&.field\:vendor { 
					grid-column: A;  
					border-bottom: var(--cell--separator); 
				}
				&.field\:url    { grid-column: B;}
				&.field\:availableFrom { 
					grid-column: B; 
					grid-row: date;
				}
				&.field\:availableFrom,
				&.for\:onDelete { border-right: var(--cell--separator); }
	
			}
		}
		@container fieldTable (@fieldTable--minimal) {
			.table__row {
				grid-template-columns:
					[hand] var(--td--hand) [A] 1fr [ctrl] var(--td--ctrl);
				grid-template-rows: 
					[header] var(--row--height--collapsed-primary)
					repeat(auto-fill, var(--row--height--collapsed));
			}
			.table__cell {
				&.has\:input { grid-column: A; grid-row:auto; }
				&.field\:url { grid-row: header;}
			}
		}
	}	

