// special publishing targets select

.publishingTargets {
	&__items {
	}

	&__item {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}

	.select .select__input {
		padding-left: 0;
	}

	// .select__option.is\:selected {
	// 	display: none;
	// }
}
