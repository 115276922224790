// out: .test/forms.css
// main: ../build.less
@import (reference) '../config';

// general form input defaults
// EoL, but not all inputLikes are refactored yet
@input-line-height: 26;
@input-padding-vertical: 8;
@input-padding-horizontal: 12;
@input-default-height: @input-line-height + (@input-padding-vertical * 2);

@input-border-radius: 3;
@input-border-width: 1;
@input-border-color: #999;
@input-border-focus-color: #000;
@input-background-color: #fff;
@input-placeholder-color: @color-text-faded;

@input-icon-size: 18;
@input-icon-color: @input-border-color; // #999

// select-specific
@select-border-radius: @input-border-radius;
@select-border-width: @input-border-width;


#AddVars() { .root() {
	// --input--stackingContext: var(--viewLayer--inputs, 20);
	--input--stackingContext: var(--viewLayer--revert, 20);
}}

CONFIG[input] 
{
	--input--padding-y: .rem(8)[];
	--input--padding-x: .rem(12)[];
	--input--height: calc(.rem(26)[] + (.rem(8)[] * 2));
	--input--line-height: .rem(26)[];
	
	--input--border: var(--input--border-width) solid var(--input--color-border);
	--input--rounding: .rem(3)[];
	--input--border-width: .rem(1)[];
	--input--color-border: #999;

	--input--color-border-focus: #000;
	--input--color-bg: #fff;
	--input--color-placeholder: #666;
	
	--input--icon-size: .rem(18)[];
	--input--color-icon: var(--input--color-border);
}

PROTO[input]:extend( CONFIG[input] all ) 
{
	display: inline-block;
	// position: relative;
	// z-index: var(--input--stackingContext);

	font-size: 1em;
	padding: var(--input--padding-y) var(--input--padding-x);
	height: var(--input--height);
	border-radius: var(--input--rounding);
	border: var(--input--border-width) solid var(--input--color-border);
	background-color: var(--input--color-bg);
}

	// EOL, failsafe for build
	// .inputLike { &:extend(PROTO[input] all); }

input,
select {
	&:extend( PROTO[input] all);
	// z-index: @z-auto;
	font-family: inherit;
}

input {
	&::placeholder {
		color: var(--input--color-placeholder);
	}
}
.input--checkbox {
	--input--height: .rem(18)[];
	width: var(--input--height);
	--input--border-width: .rem(2)[];
}

textarea {
	width: 100%;
	font-family: inherit;
	padding: var(--input--padding-y) var(--input--padding-x);
}

.form__field {
	display: flex;
	flex-flow: column wrap;
	align-items: stretch;
}

.form__errors {
	color: var(--color-text-error);
	font-size: .rem(14)[];
	line-height: .rem(18)[];
	min-height: .rem(24)[];
	margin-top: .rem(16)[];
	display: flex;
	align-items: center;
}

.field {
	&__label {
		display: block;
		padding: .rem(20)[] 0 .rem(4)[];
		font-weight: bold;
		color: #444;
	}

	&__errors {
		color: var(--color-text-error);
		font-size: .rem(13)[];
		line-height: .rem(18)[];
		min-height: .rem(24)[];
		display: flex;
		align-items: center;
	}

	&__input--checkbox,
	&__input--radio {
		&:extend( CONFIG[input] );
		display: inline-flex;
		align-items: center;
		min-height: var(--input--height);

		&:has(input:checked) {
			font-weight: 500;
			text-decoration: underline;
		}

		input {
			// height: .rem(16)[];
			margin: 0 0.375em 0 0.5em;
		}
	}

	&.has\:pendingChanges {
		input {
			background-color: blue;
			border-color: lightblue;
		}
	}
	&.has\:error {
		input,
		.select__control {
			background-color: var(--color-bg-error);
			border-color: var(--color-text-error);
		}
	}
}

.field .file.has\:error {
	input,
	.select__control {
		background-color: var(--color-bg-error);
		border-color: var(--color-text-error);
	}
}

.field.has\:error {
	position: relative;
	z-index: var(--input--stackingContext);

	.field__errors {
		position: absolute;
		z-index: var(--input--stackingContext);
		left: 0;
		bottom: .rem(46)[];
		background-color: var(--color-bg);
	}
}

input[type='time'] {
	padding: .rem(8)[] .rem(10)[];

	&::-webkit-datetime-edit-hour-field,
	&::-webkit-datetime-edit-minute-field,
	&::-webkit-datetime-edit-ampm-field {
		padding: 0 .rem(3)[];
	}
	&::-webkit-clear-button {
		display: none;
	}
	&::-webkit-inner-spin-button {
		display: none;
	}
}