// out: .test/error.css
// main: ../build.less
@import (reference) "../config";

.view--error {
	display: grid;
	place-items: center;
	grid-template-areas: 'main';
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	background-color: @color-theme-sidebar;
	margin-top: 0;

	.view__main {
		// color: @color-text;
		background-color: rgba(255, 255, 255, 0.01);
		border: 0.2rem solid rgba(255, 255, 255, 0.1);
		max-width: .rem(700)[];
		padding: .rem(32)[];
		color: @color-bg-muted;
	}

	.error__description {
		color: @color-bg-muted;
	}

	.error__title {
		// color: @color-primary-muted;
		// font-size: .rem(22)[];  line-height: .rem(20)[];
	}

	.error__code {
		color: @color-primary;
		font-size: .rem(30)[];  line-height: .rem(36)[];
		font-weight: bold;
		margin-right: .rem(6)[];
	}

	.error__message {
		color: @color-primary-muted;
		font-size: .rem(22)[];  line-height: .rem(36)[];
		// font-weight: bold;
	}

	.error__actions {
		display: flex;
		justify-content: flex-end;
		margin-top: .rem(24)[];

		.button.button\:naked {
			// color: @color-text-faded;
			color: @color-primary-muted;
		}
	}

	.errorReport {
		margin-top: .rem(18)[];

		&__contextLabel {
			color: @color-bg-faded;
		}
	}

}