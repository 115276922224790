// out: .test/fonts.css
// main: ../build.less
// @import (css) url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700;800&display=swap');
// @import (css) url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
// @import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800');

@font-face {
	font-family: 'Fira Sans';
	src:
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-regular.woff2') format('woff2'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-regular.woff') format('woff'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Fira Sans';
	src:
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-500.woff2') format('woff2'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-500.woff') format('woff'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Fira Sans';
	src:
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-700.woff2') format('woff2'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-700.woff') format('woff'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	// font-display: block;
}

@font-face {
	font-family: 'Fira Sans';
	src:
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-800.woff2') format('woff2')
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-800.woff') format('woff'),
		url('@{__FONT_PATH}/fira-sans-v11-latin-ext_latin-800.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	// font-display: block;
}

// Print @font-face declaration from icon file
@import (reference) 'icons';
@DETACHED_ICONFONT_REGISTRATION();