// main: ../build.less
@import (reference) "../config";


.table--internals\.jobs {
	.has\:conflict {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;

		a:hover,
		strong { color: @color-text-error; }
	}
}

.showPersonnel
{
	
}

.table--showPersonnel {
	.showStatus {
		.font--small;
		max-height: 4em;
		overflow: hidden;
		text-overflow: ellipsis;
		opacity: 0.75;
	}
}

.showJobsPreview {
	
}