// out: .test/avatar.css
// main: ../build.less
@import (reference) '../config';
@import (reference) 'avatarColors';


// util to keep theming choices visible at top of file, 
// but print styles after component for specificity
#AvatarColorClasses() 
{
	[class*="avatarColor--"] { #AvatarColors.v5(); }

	.avatarColor { 
		.printIndexedColorClassVariants(
			#AvatarColors.v5()[@names];
			--avatar--color-text; 
			--avatar--color-bg;
		);
	}
}

CONFIG[avatar] {
	--avatar--size: .rem(30)[];
	--avatar--size--small: .rem(24)[];
	--avatar--size--large: .rem(36)[];
	--avatar--spacing: .rem(4)[];
	--avatar--placeholder-font: inherit;
	--avatar--placeholder-size: 1em;
	--avatar--placeholder-weight: 500;

	--avatar--color-bg: #ccc;
	--avatar--color-text: #fff;

	--avatar--hover-scale: 1.1;
	--avatar--hover-transition: transform .2s ease;
}

.avatar {

	&:extend(CONFIG[avatar]);

	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	cursor:default;
	
	border-radius: 100%;
	width: var(--avatar--size);
	height: var(--avatar--size);
	margin-right: var(--avatar--spacing);

	color: var(--avatar--color-text);
	background: var(--avatar--color-bg);

	img {
		width: 100%;
		object-fit: cover;
	}

	&--placeholder {
		font: var(--avatar--placeholder-font);
		font-size: var(--avatar--placeholder-size);
		font-weight: var(--avatar--placeholder-weight);
	}

	&:hover {
		transform: scale(var(--avatar--hover-scale));
		transition: var(--avatar--hover-transition);
	}

	// VARIANT: Smaller Avatar
	&--small {
		--avatar-size: var(--avatar--size--small);
	}

	// VARIANT: Larger Avatar
	&--large {
		--avatar-size: var(--avatar--size--large);
	}
}

// UTILITY: Placeholder Color Theming Presets
#AvatarColorClasses();