// out: .test/errorReport.css
// main: ../build.less
@import (reference) "../config";

.errorReport {

	// padding: .rem(8)[] .rem(14)[];

	&__context {
		display: flex;
		flex-flow: row wrap;
	}

	&__contextItem {
		width: 100%;
	}

	&__contextItem + &__contextItem {
		margin-top: .rem(12)[];
	}

	&__contextLabel {
		font-weight: bold;
		font-size: .rem(14)[];  line-height: .rem(16)[];
	}

	&__contextData {
		margin-top: .rem(4)[];
		font-family: monospace;
		font-size: .rem(12)[];  line-height: .rem(16)[];
		word-break: break-all;

		// max-width: 50%;
	}

}

.stacktrace {
	display: flex;
	flex-flow: column;
	// .font--small;
	font: var(--font-small);

	&__item {
		font-family: monospace;
		white-space: nowrap;
		text-align: left;
		// overflow: hidden;
		// max-width: 100rem;
		// text-overflow: ellipsis;
	}

	&__column,
	&__line,
	&__method {
		font-weight: bold;
	}
}
