// main: ../build.less
@import (reference) "../config";

@pageMenuSelector: .pageMenu;

@{pageMenuSelector}
{
	a {
		color: @color-text;
		text-decoration: none;
		display: block;
	}
	&__title {
		font-size: .rem(21)[];
		line-height: .rem(24)[];
		font-weight: bold;
		margin-bottom: .rem(16)[];
	}
	&__list {
	}

	&__item {
		padding: .rem(8)[] .rem(16)[];
		border-top: .rem(1)[] solid rgba(0,0,0,.1);

		&:hover { 
			background-color: rgba( 255,255,255, .25);
		}
		&.is\:active {
			background-color: var(--color-bg);
		}
	}
}

