// main: build.less
@import (reference) "config";

@media @AppUI--basic {
	// experimental fullscreen "native" selects for mobile
	.select.is\:open .select__menu,
	.contextMenu.is\:open .contextMenu__panel {
		position: fixed !important;
		z-index: var(--appLayer--overlay) !important;
		top: 50vh !important;
		transform: translateY(-50%) translate3d(0, 0, 0) !important;
		bottom: auto !important;
		max-height: 80vh !important;
		max-height: 80svh !important;
		left: 2em !important;
		right: 2em !important;
		width: auto !important;
		box-shadow: 
			0 0.25em 0.25em rgba(0,0,0,.1),
			0 0.5em 2em rgba(0,0,0,.2) !important;
		
	}
	.dateInput {
		&.is\:open .datepicker{
			z-index: var(--appLayer--overlay) !important;
			box-shadow: 
				0 0.25em 0.25em rgba(0,0,0,.1),
				0 0.5em 2em rgba(0,0,0,.2) !important;
		}
	}
	.select, 
	.dateInput,
	.contextMenu
	{
		// backdrop
		&.is\:open::before {
			pointer-events: none;
			content: '';
			position: fixed;
			z-index: calc(var(--appLayer--overlay) - 10);
			top: 0; right: 0; bottom: 0; left: 0;
			background: rgba(0,0,0,.5);
			backdrop-filter: blur(0.05em);
		}
	}
}

.metaBox
{
	& + & { margin-top: .rem(24)[]; }

	background: @color-bg;

	&__label {
		background-color: @color-text;
		color: @color-bg-faded;
		padding: .rem(4)[] .rem(12)[];
	}
	&__content {
		padding: .rem(12)[] .rem(16)[] .rem(16)[];
	}
	&__section {
		& + & { border-top: 1px solid #ccc; }
	}

	&__mainAction {
		display: flex;
		.button { width: 100%; }
	}

	@media @mobile {
		&__content,
		&__section {
			display: none;
			visibility: hidden;
		}
		&__label {
			position: relative;
			// #icon.set( 'drop-down', 16, currentColor, 10, after );
			&::after {
				&:extend(.iconLike all);
				content: @icon-drop-down;
				font-size: .rem(16)[];
				margin-left: .rem(10)[];
				position: absolute;
				right: .rem(8)[];
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&.is\:open &__label::after { content: @icon-drop-up; }

		&.is\:open &__content,
		&.is\:open &__section {
			display: block;
			visibility: visible;
		}
	}
}

.changes {
	&__items {
		.is\:open & { padding: .rem(8)[] .rem(16)[]; }
		font-size: .rem(12)[];  line-height: .rem(21)[];
	}
	&__item {
		color: @color-text-muted;
		& + & {
			margin-top: .rem(3)[];
			padding-top: .rem(3)[];
			border-top: .rem(1)[] solid var(--color-bg-fade);

		}
	}
	&__field  { padding-left: .rem(8)[]; }
	&__values { padding-left: .rem(24)[]; }
}

.activeMarker {
	background: @color-bg-muted;
	padding: .rem(2)[] .rem(16)[];
}

// header tabs
.view__tabs
{
	background-color: @color-theme-sidebar;

	.tabNav
	{
		&__item {
			display: flex;
			align-items: center;
			padding: .rem(8)[] .rem(36)[];
			background-color: mix( @color-bg-faded, @color-theme-sidebar, 70%);


			&.is\:active {
			   background-color: @color-bg-faded;
			}
			&.has\:error {
				border-top: .rem(4)[] solid var(--color-text-error);

				padding-top: .rem(4)[];
				padding-left: .rem(10)[];
				// #icon.set( warning, 16, @color-text-error, 10, before );
				&::before {
					&:extend(.iconLike all);
					content: @icon-warning;
					color: var(--color-text-error);
					margin-right: .rem(10)[];
				}
		   }
		}
	}
}

.entityVariants
{
	&__item {
		display: flex;
		align-items: center;
		padding: .rem(8)[] .rem(8)[] .rem(8)[] .rem(16)[];
		border-bottom: .rem(1)[] solid #ccc;
		font-size: .rem(16)[];  line-height: .rem(30)[];

		&:hover {
			background-color: mix( @color-bg, @color-bg-faded, 70%);
		}
		&.is\:active {
			background-color: @color-primary;
			color: @color-text;
			cursor: default;
		}
	}

	&__edit {
		margin-left: .rem(8)[];
		visibility: hidden;
	}
	&__item:hover:not(.is\:active) &__edit { visibility: visible; }

	&__delete {
		margin-left: auto !important;
		margin-right: 0;
		color: @color-text-faded;

		.is\:active & { color: @color-primary-muted; }
	}
}


