// out: .test/table--showList.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


.table--announcementList 
{
	.field\:announcementInfo {
		.entity {
			--entity--meta-font: 500 .fslh(15, 24)[] var(--font-family);
			--entity--title-font: .fslh(12, 18)[] var(--font-family);

			&__title { 
				color: var(--color-text-muted);
				&::before { display: none;}
			}
			&__meta { color: var(--color-text); }
		}
	}
}