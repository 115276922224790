// out: .test/globals.css
// main: ../build.less
@import (reference) "../config";
@import (reference) "typography";

// Styling Layers override order for components
@layer tableDefaults, base, type, variant, modifier;

:root 
{
	// Selectively print guarded config sets
	#AddVars.root('colors');
	#AddVars.root('theme');
	#AddVars.root('stackingContext');

	// Print all other vars in import order
	#AddVars.root();
}


// Global CSS Variable Collector
// - Used throughout the app to merge declarations in the :root ruleset above
// - All declarations of the .root() mixins will be merged and triaged on build
// - Redeclarate the block below anywhere else to append rules:
#AddVars() {
	.root() {
		// --appended-to: ':root {}';
	}
}

// Encapsulating Variable Sets
// - Used to selectively print sets of vars, or reuse them in other contexts
// - Guading .add declarations is preferred for clarity and prevents dupes / overwrites
// - Param names can be anything & don't need a default value
#AddVars() {
	.root(@set) when (@set = 'identifier') { 
		// --only-appends-if-called-as: '#AddVars.root("identifier")';
	}
}


//-------------------------------------------
// Failsafe Declaration, voids need for param param default
#AddVars() { .root( @build-safe: default );}


// .group__section {
// 	.field { z-index: var(--viewLayer--static); }
// 	&:nth-child(1) .table { z-index: calc(var(--viewLayer--inputs)); }
// 	&:nth-child(4) .table { z-index: calc(var(--viewLayer--inputs) + 2); }
// 	&:nth-child(3) .table { z-index: calc(var(--viewLayer--inputs) + 3); }
// 	&:nth-child(2) .table { z-index: calc(var(--viewLayer--inputs) + 4); }
	
// 	.table:has(.is\:open:is(.select, .input)) { 
// 		z-index: calc(var(--viewLayer--promoted)); 
// 	}
// }