// main: ../build.less
@import (reference) '../config';

.view {
	// draft code, generalize view/layout system
	&--login {
		display: grid;
		place-items: center;
		grid-template-areas: 'main';
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;
		background-color: var(--invert-color-bg);
		margin-top: 0;
	}
	&--login &__main {
		background-color: var(--invert-color-bg);

	}
	.loginBox {
		background-color: var(--invert-color-bg-muted);
		min-width: .rem(280)[];
		width: .rem(360)[];
		max-width: 80vw;
		padding: .rem(42)[] .rem(36)[] .rem(48)[];
		border-radius: .rem(8)[];
		box-shadow: 
			0 0.50em 0.50em rgba(0,0,0,.35),
			0 0.75em 1.75em rgba(0,0,0,.25);

		&__header {
			display: flex;
			justify-content: center;
			img { max-width: .rem(220)[]; }
		}
	}
	&--redirect {
		color: var(--invert-color-text);
		a:visited { color: var(--invert-color-text-faded); }
		a:hover { color: var(--invert-color-text); }
	}
}

.form {
	&--login {
		margin-top: .rem(18)[];

		.field__label {
			color: var(--invert-color-text-faded);
			font-weight: 400;
			font-size: .rem(14)[];
			padding-top: .rem(12, 14)[];
			padding-left: .rem(4)[];
		}
		.input {
			--input--color-bg: var(--palette--neutral-850);
			color: var(--palette--neutral-200);
			box-shadow: inset 0 0 0.5em rgba(0,0,0,.35);
			--input--color-border: var(--palette--neutral-650);
			--input--color-border-focus: var(--palette--neutral-600);
			&:focus {
				--input--color-bg: var(--palette--neutral-900);
			}
		}
		.has\:error .input {
			--input--color-placeholder: var(--color-text-error);
			color: var(--color-text-error);
		}
		.has\:error .field__errors {
			min-height: .rem(18)[];
			background-color: transparent;
			right: 0;
			left: auto;
		}
	}

	&--login &__actions {
		margin-top: .rem(24)[];
		.button {
			width: 100%;
			font-weight: 500;
		}
	}
}

.ms-login {
	//margin-top: .rem(2)[];
	width: 100%;
}

.login-separator {
	color: var(--invert-color-text-muted);
	text-align: center;
	margin:  .rem(6)[] .rem(6)[];
}

.redirect-message {
	//font-size: .rem(18)[];  line-height: .rem(24)[];
	margin: .rem(12)[] .rem(0)[] .rem(12)[] .rem(0)[];
}
