// out: .test/copyBox.css
// main: ../build.less
@import (reference) "../config";



.copyBox
{
	--copyBox--font: inherit;
	--copyBox--color-bg: mix(@color-primary, @color-bg, 15);
	--copyBox--color-text: mix(@color-primary, @color-text, 70);
	--copyBox--border: .rem(2)[] solid mix(@color-primary-muted, @color-bg, 50);

	--copyBox--color-bg: mix(@color-bg-success, @color-bg, 80);
	--copyBox--color-text: mix(@color-text-success, @color-text, 90);
	--copyBox--border: .rem(2)[] solid mix(@color-text-success, @color-bg, 40);

	--copyBox--padding-y: .rem(10)[];
	--copyBox--padding-x: .rem(12)[];
	--copyBox--rounding: .rem(5)[];

	color: var(--copyBox--color-text);
	background: var(--copyBox--color-bg);
	border: var(--copyBox--border);
	border-radius: var(--copyBox--rounding);
	padding: var(--copyBox--padding-y) var(--copyBox--padding-x);

	// display: grid;
	// grid-template-columns: 1fr auto;
	// gap: 1em;
	// align-items: center;
	position: relative;
	z-index: 1;

	&__action {
		position: absolute;
		z-index: 3;
		top: 50%; 
		transform: translateY(-50%);
		right: var(--copyBox--padding-y);
		transition: all 0.25s ease-out;
	}
	.button {
		--button--color-bg: transparent;
		--button--color-text: mix(@color-text-success, @color-text, 90);
		--button--border: 1px solid transparent;
		--button--color-bg-hover: var(--copyBox--color-text);
		--button--color-text-hover: var(--copyBox--color-bg);
		--button--color-border-hover: var(--copyBox--color-text);
		--button--color-bg-active: var(--copyBox--color-text);
		--button--color-text-active: var(--copyBox--color-bg);
		--button--color-border-active: var(--copyBox--color-text);
	}
	@media (hover:hover) {
		// &__action { visibility: hidden; }
		// &:hover &__action { visibility: visible; }
		&__action { opacity: 0.75; }
		&:hover &__action { opacity: 1; }
	}

	&__payload {
		// white-space: nowrap;
		width: 100%;
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: .rem(32)[];
		height: .rem(32)[];
		padding: 0 0 0 !important;
		border: 0 !important;
		background: transparent !important;
		outline: none !important;
		color: var(--copyBox--color-text);
		&::selection {
			background: var(--copyBox--color-text);
			color: var(--copyBox--color-bg);
		}
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: 2;
		right: 0; top: 0; bottom: 0;
		border-top-right-radius: var(--copyBox--rounding);
		border-bottom-right-radius: var(--copyBox--rounding);
		width: 5rem;
		background: linear-gradient(to right, transparent, var(--copyBox--color-bg) 50%);
	}
}