// main: ../build.less
@import (reference) '../config';


// give dateTimeInput var access
CONFIG[timeInput]
{
	--timeInput--icon-size: .rem(20)[];
	--timeInput--icon-width: .rem(36)[];
	--timeInput--icon-separator: .rem(2)[] solid var(--palette--neutral-200);

	--timeInput--icon-spacing: .rem(8)[];
	--timeInput--input-width: .rem(18)[];
	--timeInput--input-margin: .rem(8)[];
	--timeInput--icon-opacity: 0.5;

	--timeInput--separator-font-size: .rem(16)[];
	--timeInput--separator-color-line: var(--palette--neutral-200);
	--timeInput--separator-color-text: var(--palette--neutral-400);
}

.timeInput 
{

	// access input vars
	&:extend(CONFIG[timeInput]);
	&:extend( PROTO[input] all);

	display: inline-flex;
	white-space: nowrap;
	align-items: center;
	flex-flow: row nowrap;
	width: auto;
	align-self: flex-start;
	padding: 0;
	z-index: 1;
	padding-right: var(--input--padding-x);

	&::before {
		&:extend(.iconLike all);
		content: @icon-time;
		font-size: var(--timeInput--icon-size);
		height: var(--input--height);
		width: var(--timeInput--icon-width);
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--input--color-border);
		opacity: var(--timeInput--icon-opacity);
		margin-right: var(--timeInput--icon-spacing);
		border-right: var(--timeInput--icon-separator);
	}

	&__input {
		.resetAppearance;
		width: var(--timeInput--input-width);
		border: none;
		background-color: transparent;
		padding: 0;
		margin-left: var(--timeInput--input-margin);
		margin-right: var(--timeInput--input-margin);
		text-align: center;
		height: auto;
	}

	&__input:first-of-type { margin-left: 0; }
	&__input:last-of-type { margin-right: 0; }
	&__input:focus { outline: none; }

	&__separator {
		font-size: var(--timeInput--separator-font-size);
		line-height: var(--input--line-height);
		padding: var(--input--padding-y) 0;
		border-right: .rem(1)[] solid var(--timeInput--separator-color-line);

		&::before {
			content: ':';
			display: block;
			background-color: var(--input--color-bg);
			color: var(--timeInput--separator-color-text);
			margin-right: .rem(-2)[];
		}
		.table__cell & {
			border: 0;
			&::before {
				background-color: transparent;
			}
		}
	}

	&.is\:focus {
		border-color: var(--input--color-border-focus);
	}
}
