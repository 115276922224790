// main: ../build.less
@import (reference) "../config";

@mobile-sidebar-height: unit((48 / @__REM_SCALING), rem );
@mobile-toolbar-height: unit((48 / @__REM_SCALING), rem );


@desktop-sidebar-width: 13vw;
@desktop-sidebar-min-width: .rem(180)[];
@desktop-toolbar-height: .rem(54)[];

#AddVars() { .root() {
	--viewToolbar--stackingContext: var(--appLayer--toolbar, 1200);
	--viewMain--stackingContext: var(--appLayer--viewMain, 1000);
	// viewSidebar uses the viewMain stackingContext when not overlayed as panel
	--viewSidebar--stackingContext: var(--appLayer--menuPanel, 1600);
}}

CONFIG[view] 
{
	--viewMobile--toolbar--height: .rem(48)[];
	--viewMobile--sidebar--width: 80vw;
	--viewMobile--sidebar--width-max: 80vw;
	--viewMobile--sidebar--height: var(--viewMobile--toolbar--height);
	
	--viewStatic--toolbar--height: .rem(54)[];
	--viewStatic--sidebar--width: 13vw;
	--viewStatic--sidebar--width-min: .rem(240)[];
	--viewStatic--sidebar--width-max: .rem(320)[];
}

.viewport {
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100%;
}

.view 
{
	&:extend(CONFIG[view] all);
	&:extend(CONFIG[viewSidebar] all);

	min-height: 100%;
	display: flex;
	flex-flow: column;
	position: relative;
	overflow: hidden;

	&__sidebar {
		// flex: 0 0 @mobile-sidebar-height;
		flex: 0 0 var(--viewMobile--sidebar--height);
		order: 1;
		padding-bottom: .rem(36)[];
	}
	&__toolbar {
		flex: 0 0 var(--viewMobile--toolbar--height);
		order: 2;
	}
	&__main {
		flex: 1 0 100%;
		background: var(--color-theme-bg-faded);
		order: 3;
		padding: .rem(12)[] 0;
	}

	&--editEvent .form { 
		order: 3;
	}

	@media @tablet {
		&__main {
			padding: .rem(15)[] .rem(20)[];
		}
	}

	@media @AppUI--sidebar--fixed {
		display: grid;
		grid-template-areas: "sidebar main";
		grid-template-rows: 1fr;
		grid-template-columns: minmax(var(--viewStatic--sidebar--width-min), var(--viewStatic--sidebar--width)) 1fr;
		padding-top: var(--viewStatic--toolbar--height);

		&__toolbar {
			width: 100%;
			position: fixed;
			top: 0;
			bottom: auto;
			left: 0;
			right: 0;
			z-index: var(--viewToolbar--stackingContext);
			height: var(--viewStatic--toolbar--height);
		}

		&__sidebar {
			grid-area: sidebar;
			position: relative;
			z-index: var(--viewMain--stackingContext);
		}
		&__main {
			grid-area: main;
			padding: .rem(30)[];
			position: relative;
			z-index: var(--viewMain--stackingContext);
		}
		&__main--flush {
			padding: 0;
		}
	}

	&__menuTrigger {
		.button {
			--button--color-text-hover: var(--color-primary-faded);
			--button--color-text-active: var(--color-primary-faded);
		}
		@media @AppUI--sidebar--fluid {
			position: absolute;
			top: .rem(6)[];
			left: .rem(4)[];
			z-index: var(--appLayer--toolbar, 400);
			color: var(--viewSidebar--color-menuToggle);
		}
		@media @AppUI--sidebar--fixed {
			display: none;
			visibility: hidden;
		}
	}
}

.layout {
	&__main {
		grid-area: form;
		height: auto;
	}
	&__side {
		grid-area: meta;
	}
	@media @AppUI--fluid {
		&__side {
			padding-left: .rem(12)[];
			padding-right: .rem(12)[];
		}
	}

	&\:page {
		.layout__main {
			margin: 0 auto;
			max-width: .rem(1100)[];
		}
	}

	&\:table {
		padding: 0;
		padding-bottom: .rem(30)[];
		background-color: var(--color-theme-bg);
		
		.pagingBar { --pagingBar--top-separator: none; }
	}

	&\:forms {
		display: flex;
		flex-flow: column nowrap;

		.layout__main {
			order: 2;
		}
		.layout__side {
			order: 1;
			margin-bottom: .rem(16)[];
		}

		.table {
			--table--blockgap: .rem(24)[];
		}

		@media @FormFactor--tablet {
			display: grid;
			grid-template-areas: "meta" "form";
			grid-template-rows: auto 1fr;
			grid-gap: .rem(24)[];
		}

		@media @FormFactor--desktop {
			grid-template-areas: "form meta";
			grid-template-columns: minmax(860px, 1100px) minmax(380px, 1fr);
		}
	}

	&\:myshows {
		display: flex;
		flex-flow: column nowrap;

		.layout__main {
			order: 2;
		}
		.layout__side {
			order: 1;
			margin-bottom: .rem(16)[];
		}

		@media @tablet {
			display: grid;
			grid-template-areas: "meta" "form";
			grid-template-rows: .rem(60)[], 1fr;
			grid-gap: .rem(24)[];

			.layout__main {
				padding-right: .rem(24)[];
				padding-left: .rem(12)[];
				border-right: 1px solid var(--color-bg-muted);
			}
		}

		@media @desktop {
			grid-template-areas: "form meta";
			grid-template-columns: minmax(960px, 2fr) minmax(360px, 1fr);
			
			.layout__main {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto 1fr;
				grid-template-areas: "header header" "list-01 list-02";
				column-gap: .rem(24)[];
				.dashboard__header {
					grid-area: header;
				}
			}
		}
	}

	&\:settings {
		@media @tablet {
			display: grid;
			grid-template-areas: "meta" "form";
			grid-template-rows: .rem(60)[], 1fr;
			grid-gap: .rem(24)[];
		}

		@media @desktop {
			grid-template-areas: "meta form";
			grid-template-columns: minmax(.rem(240)[], .rem(320)[]) 1fr;
		}
	}
}

.main {
	&__group {
		background: var(--color-theme-bg);
		padding-bottom: .rem(20)[];

		.view__main--flush & {
			padding: 0;
		}

		& + & {
			margin-top: .rem(80)[];
		}
	}
	@media @tablet {
		margin: 0 auto;
	}
	@media @desktop {
		margin: 0;
	}
}

.group {
	&__tabNav {
		background: var(--color-theme-bg-faded);
	}
	&__section {
		&:first-of-type {
			margin-top: .rem(10)[];
		}
		& + & {
			margin-top: .rem(36)[];
			border-top: 1px solid var(--color-ui-border);
		}
		#edit-userProfile + & {
			margin-top: .rem(16)[];
			border-top: 1px solid var(--color-ui-border);
		}
	}
	&__header {
		background-color: var(--color-bg-muted);
		color: rgba(0,0,0,.5);
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		font-size: .rem(14)[];
		line-height: .rem(21)[];
		padding: .rem(4)[] .rem(3)[] .rem(4)[] .rem(12)[];

		.button\:outline {
			border-color: currentColor;
			color: inherit;
			&::before {
				color: inherit;
			}
		}

		&.status\:announced {
			background-color: var(--color-status-ann);
			color: rgba(0,0,0,.5);
		}
		&.status\:pendingAnnouncement {
			background-color: var(--color-status-tba);
			color: rgba(0,0,0,.5);
		}
		&.status\:pendingConfirmation {
			background-color: var(--color-status-tbc);
			color: rgba(0,0,0,.5);
		}
		&.status\:confirmed {
			background-color: var(--color-status-con);
			color: rgba(0,0,0,.5);
		}
		&.status\:archived {
			background-color: var(--color-status-arx);
			color: rgba(0,0,0,.5);
		}
	}

	&__toolbar {
		display: flex;
		flex-flow: space-between;
		align-items: center;
	}
}

.section {
	&__label {
		.font--small;
		color: var(--color-text-faded);
		padding: .rem(8)[] .rem(16)[] 0;
	}
	&__content {
		padding: .rem(8)[] .rem(16)[] .rem(16)[];
		@media @tablet {
			padding: .rem(8)[] .rem(48)[] .rem(16)[];
		}
		@media @desktop {
			padding: .rem(8)[] .rem(60)[] .rem(16)[];
		}
		@media @desktop-medium {
			padding: .rem(8)[] .rem(36)[] .rem(16)[];
		}
	}

	.layout\:table &__content {
		@media @desktop {
			padding: .rem(12)[] .rem(48)[] .rem(16)[];
		}
	}
}