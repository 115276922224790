// main: ../build.less
@import (reference) '../config';

@messageBoxSelector: .messageBox;

@{messageBoxSelector}
{
	grid-column: 1 / span all;

	background-color: @color-bg-highlight;
	color: @color-text-highlight;
	// #set.border( 1, solid @color-text-highlight );

	font-size: .rem(14)[];  line-height: .rem(22)[];

	padding: .rem(16)[] .rem(24)[];
	margin: .rem( 8 )[];

	&__header {
		margin: .rem(-16)[] .rem(-24)[] .rem(10)[];
		padding: .rem(5)[] .rem(12)[];
		background-color: @color-text-highlight;
		color: @color-bg-highlight;
	}
	&__items {
		list-style: inside;
	}

	label { 
		font-weight: bold; 
		text-decoration: underline;
		cursor: pointer;
		&:hover { text-decoration: none; }
	}

	&--error {
		background-color: @color-bg-error;
		color: @color-text-error;
		border-color: @color-text-error;
	}
	&--error &__header {
		background-color: @color-text-error;
		color: @color-bg-error;
	}
}