// out: .test/userEntity.css
// main: ../build.less
@import (reference) '../config';

.userEntity {
	display: flex;
	align-items: center;

	--unassignedUsers--color-bg: transparent;
	--unassignedUsers--color-text: @color-text-error;
	--unassignedUsers--color-border: fade(@color-text-error, 70%);

	--additionalUsers--color-bg: transparent;
	--additionalUsers--color-text: @color-text-success;
	--additionalUsers--color-border: fade(@color-text-success, 60%);

	&__name {
		font-weight: bold;
	}

	&--infoCounter 
	{
		.avatar { 
			border: .em(2)[] solid var(--avatar--color-border);
		}

		&.is\:unassigned .avatar { 
			--avatar--color-bg: var(--unassignedUsers--color-bg);
			--avatar--color-text: var(--unassignedUsers--color-text);
			--avatar--color-border: var(--unassignedUsers--color-border);
			border-style: dotted;
		}
		&.is\:additional .avatar { 
			--avatar--color-bg: var(--additionalUsers--color-bg);
			--avatar--color-text: var(--additionalUsers--color-text);
			--avatar--color-border: var(--additionalUsers--color-border);

			> span { margin-left: -0.125em; }
		}
	}
	&--avatar + &--infoCounter {
		margin-left: .rem(8)[];
	}
}




