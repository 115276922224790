// out: .test/buttonGroup.css
// main: ../build.less
@import (reference) "../config";
@import (reference) "buttons";

@buttonGroupSelector: .buttonGroup;

.buttonGroup
{
	display: inline-flex;

	margin: 0 .rem(4)[];
	&:last-child { margin-right: 0; }
	&:first-child { margin-left: 0; }

	.button {
		border-radius: 0;

		&:not(:first-child) {
			margin: 0 0 0 .rem(1)[];
		}
		&:first-child {
			border-top-right-radius: var(--button--rounding);
			border-bottom-right-radius: var(--button--rounding);
		}
		&:last-child {
			border-top-left-radius: var(--button--rounding);
			border-bottom-left-radius: var(--button--rounding);
		}
	}
}