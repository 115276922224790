// main: ../build.less
@import (reference) '../config.less';

@tagPickerSelector: .tagPicker;
@autocompletePanelSelector: .autocomplete;

@{tagPickerSelector} 
{
	--tagPicker-padding: .rem(2)[] .rem(2)[];

	--tag--color-bg: var(--palette--neutral-200);
	--tag--color-text: var(--color-text);
	--tag--color-icon: var(--palette--neutral-500);
	--tag--rounding: .rem(2)[];
	--tag--outer-spacing: .rem(3)[];
	--tag--font-size: .rem(14)[];
	--tag--label-padding: .rem(5)[] .rem(8)[];
	--tag--label-max-width: .rem(145)[];
	--tag--label-height: .rem(16)[];
	
	--tag--control-separator: 1px solid var(--palette--neutral-100);
	--tag--control-color-bg-hover: var(--palette--neutral-300);
	--tag--control-width: .rem(28)[];
	--tag--control-icon-size: .rem(17)[];

	&:extend( PROTO[input] all);

	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	flex-wrap: wrap;
	height: auto;

	padding: var(--tagPicker-padding);

	&__tag {
		display: inline-flex;
		white-space: nowrap;
		margin: var(--tag--outer-spacing);
		font-size: var(--tag--font-size);
		line-height: var(--tag--label-height);
		color: var(--tag--color-text);
		background: var(--tag--color-bg);
		border-radius: var(--tag--rounding);
	}

	&__tagLabel {
		white-space: nowrap;
		padding: var(--tag--label-padding);
		max-width: var(--tag--label-max-width);
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__tagRemove {
		border-left: var(--tag--control-separator);
		height: auto;
		display: flex;
		align-items: center;
		width: var(--tag--control-width) !important;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		&:hover {
			background-color: var(--tag--control-color-bg-hover);
		}
		&::before {
			color: var(--tag--color-icon);
			font-size: var(--tag--control-icon-size);
		}
	}

	&__inlineSearch.select__input {
		.resetAppearance;
		flex: 1;
		min-width: .rem(96)[];
		min-height: auto;
		margin: var(--tag--outer-spacing);
		padding-left: calc(var(--input--padding-x) - var(--tag--outer-spacing));
		&::placeholder {
			color: var(--input--color-placeholder);
		}
		&:focus {
			outline: none;
		}
	}

	&__tag + &__inlineSearch {
		// #set.padding(left, @input-padding-horizontal - @tag-spacing )
		// background-color: red;
	}

	&__action {
		margin-left: auto;
	}

	&__autocomplete {
		display: none;
		left: 0;
		top: calc(var(--input--height) - 2px);
		position: absolute;
		width: 100%;

		background: var(--input--color-bg);
		border: var(--input--border);
		// border-top-color: @input-background-color;
		border-top-color: #eee;

		.is\:open & {
			display: block;
		}
	}
}

@{autocompletePanelSelector} {
	&__option {
		padding: .rem(4)[] .rem(12)[];
		cursor: pointer;
		&:hover {
			background: var(--palette--neutral-200);
		}
	}
	&__search {
		padding: .rem(8)[] .rem(8)[] .rem(6)[];
		// &:before {
		// 	font-family: @__ICON_FONT_NAME;
		// 	content: @icon-search;
		// }
		input {
			padding: .rem(2)[] .rem(8)[];
			height: auto;
			.resetAppearance;
			border-bottom: 2px solid #ccc;
		}
	}
}
