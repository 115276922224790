// main: ../build.less
@import (reference) '../config';
@import (reference) 'timeInput';

.dateTimeInput {
	// position: relative;

	--dateTime--date-flex-basis: .rem(198)[];
	--dateTime--date-spacing: .rem(24)[];
	--dateTime--date-icon-size: .rem(20)[];

	--dateTime--time-flex-basis: .rem(84)[];

	// var access
	&:extend( PROTO[input] all);
	
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	position: relative;

	&.is\:open {
		z-index: var(--viewLayer--isOpen);
	}

	&__date,
	&__time {
		display: inline-flex;
		flex-flow: row nowrap;
		white-space: nowrap;
		align-items: center;
	}

	&__date {
		width: auto;
		margin-right: var(--dateTime--date-spacing);
		flex-basis: var(--dateTime--date-flex-basis);

		&::before {
			&:extend(.iconLike all);
			content: @icon-date;
			color: var(--input--color-border);
			font-size: var(--dateTime--date-icon-size);
			margin-right: var(--input--padding-x);
			line-height: 1;
			display: block;
			opacity: 0.5;

			.table__cell & {
				border: none;
			}
		}

		.dateInput__calendar {
			display: none;
			overflow: hidden;
		}
		&.is\:open .dateInput__calendar {
			display: block;
			visibility: visible;
		}

		&.has\:value .dateInput__value {
			display: block;
		}

		&.has\:value .dateInput__input::placeholder {
			color: inherit;
		}

		&.has\:value .dateInput__input {
			display: none;
		}

		&.is\:focus .dateInput__value {
			display: none;
		}

		&.is\:focus .dateInput__input {
			display: block;
		}

		.has\:error > & {
			border-color: var(--color-text-error);
			background-color: var(--color-bg-error);
		}
	}

	&__time {
		&:extend(CONFIG[timeInput]);
		flex-basis: var(--dateTime--time-flex-basis);
		padding-right: .rem(16)[];

		&::before {
			&:extend(.iconLike all);
			content: @icon-time;
			font-size: var(--timeInput--icon-size);
			color: var(--input--color-border);
			margin-right: .rem(5)[];
			opacity: 0.5;
		}
	}

	&.is\:focus {
		border-color: var(--input--color-border-focus);
	}
}
