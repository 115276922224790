@font-face {
  font-family: 'Fira Sans';
  src: url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-regular.woff2') format('woff2'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-regular.woff') format('woff'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-500.woff2') format('woff2'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-500.woff') format('woff'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-700.woff2') format('woff2'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-700.woff') format('woff'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-800.woff2') format('woff2') url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-800.woff') format('woff'), url('https://showmanager.bcmc.at/static/fonts/fira-sans-v11-latin-ext_latin-800.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'showmanager-icons';
  src: url('https://showmanager.bcmc.at/static/fonts/showmanager-icons.woff2#v12') format('woff2'), url('https://showmanager.bcmc.at/static/fonts/showmanager-icons.ttf#v12') format('truetype'), url('https://showmanager.bcmc.at/static/fonts/showmanager-icons.woff#v12') format('woff'), url('https://showmanager.bcmc.at/static/fonts/showmanager-icons.svg#v12') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
div:focus {
  outline: none !important;
}
* {
  box-sizing: border-box;
}
:root,
html {
  font-size: 100%;
}
body {
  font: 1em / 1.5 'Fira Sans', sans-serif;
}
html,
body,
#__next {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
}
@layer tableDefaults, base, type, variant, modifier;
:root {
  /* Color Swatches */
  --color-bg: #fff;
  --color-bg-muted: #aaa;
  --color-bg-faded: #e0e0e0;
  --color-bg-faint: #e0e0e0;
  --color-text: #000;
  --color-text-muted: #666;
  --color-text-faded: #999;
  --color-text-faint: #999;
  --color-bg-highlight: #FFF4C7;
  --color-bg-error: #ffdeda;
  --color-bg-error-faded: rgba(255, 222, 218, 0.2);
  --color-bg-success: #dbf6ca;
  --color-text-highlight: #978017;
  --color-text-highlight-faded: #c7ae40;
  --color-text-error: #c12414;
  --color-text-error-faded: #d84c3c;
  --color-text-success: #006A58;
  --color-primary: #A5CC2E;
  --color-primary-faded: #B6E032;
  --color-primary-muted: #799621;
  --color-secondary: #006A58;
  --color-secondary-faded: #259887;
  --color-secondary-muted: #005B47;
  --color-highlight: #FFF4C7;
  --color-primary-soft: rgba(165, 204, 46, 0.25);
  --color-highlight-soft: rgba(255, 244, 199, 0.25);
  --color-highlight-faint: rgba(255, 244, 199, 0.15);
  --color-status-tbc: #e0e0e0;
  --color-status-con: #FFB648;
  --color-status-tba: #FFE874;
  --color-status-ann: #A6E97A;
  --color-status-arx: #CCBCFF;
  --color-status-abs: #df786c;
  --palette--neutral-1000: #0b0c11;
  --palette--neutral-950: #0f1015;
  --palette--neutral-900: #14161E;
  --palette--neutral-850: #1b1d26;
  --palette--neutral-800: #23262f;
  --palette--neutral-750: #2d3038;
  --palette--neutral-700: #3b3e47;
  --palette--neutral-650: #484b55;
  --palette--neutral-600: #585b66;
  --palette--neutral-550: #666974;
  --palette--neutral-500: #757883;
  --palette--neutral-450: #858792;
  --palette--neutral-400: #9699a3;
  --palette--neutral-350: #a7aab3;
  --palette--neutral-300: #b9bbc3;
  --palette--neutral-250: #c7c9d0;
  --palette--neutral-200: #d6d8de;
  --palette--neutral-150: #e1e2e7;
  --palette--neutral-100: #eaebed;
  --palette--neutral-050: #f5f5f7;
  --palette--neutral-000: #ffffff;
  /* App Theme Colors */
  --color-theme-bg: #fff;
  --color-theme-bg-faded: #e0e0e0;
  --color-theme-bg-main: #e0e0e0;
  --color-theme-sidebar: #14161E;
  --color-ui-border: #ccc;
  --theme-color-bg: var(--palette--neutral-000);
  --theme-color-bg-muted: var(--palette--neutral-100);
  --theme-color-bg-faded: var(--palette--neutral-200);
  --theme-color-bg-faint: var(--palette--neutral-300);
  --theme-color-text: var(--palette--neutral-1000);
  --theme-color-text-muted: var(--palette--neutral-600);
  --theme-color-text-faded: var(--palette--neutral-500);
  --theme-color-text-faint: var(--palette--neutral-400);
  --invert-color-bg: var(--palette--neutral-900);
  --invert-color-bg-muted: var(--palette--neutral-800);
  --invert-color-bg-faded: var(--palette--neutral-700);
  --invert-color-bg-faint: var(--palette--neutral-600);
  --invert-color-text: var(--palette--neutral-100);
  --invert-color-text-subtle: var(--palette--neutral-200);
  --invert-color-text-muted: var(--palette--neutral-350);
  --invert-color-text-faded: var(--palette--neutral-450);
  --invert-color-text-faint: var(--palette--neutral-550);
  --appLayer--overlay: 9999;
  --appLayer--menuPanel: 1600;
  --appLayer--notif: 1500;
  --appLayer--modal: 1450;
  --appLayer--sidePanel: 1400;
  --appLayer--toolbar: 1200;
  --appLayer--promoted: 1100;
  --appLayer--viewMain: 1000;
  --viewLayer--overlay: 700;
  --viewLayer--isOpen: 650;
  --viewLayer--popover: 600;
  --viewLayer--tooltip: 500;
  --viewLayer--toolbar: 400;
  --viewLayer--utility: 300;
  --viewLayer--promoted: 100;
  --viewLayer--inputs: 20;
  --viewLayer--static: 10;
  --viewLayer--behind: -1;
  --viewLayer--revert: auto;
  /* Typographic Presets */
  --font--mini: 0.75em / 1.3333 'Fira Sans', sans-serif;
  --font--small: 0.875em / 1.2857 'Fira Sans', sans-serif;
  --font--normal: 1em / 1.5 'Fira Sans', sans-serif;
  --font--label: 0.9375em / 1.2667 'Fira Sans', sans-serif;
  --font--medium: 1.125em / 1.3333 'Fira Sans', sans-serif;
  --font--large: 1.3125em / 1.3333 'Fira Sans', sans-serif;
  --font--xlarge: 1.5em / 1.25 'Fira Sans', sans-serif;
  --font--heading: 1.75em / 1.2857 'Fira Sans', sans-serif;
  --font--display: 2.5em / 1.5 'Fira Sans', sans-serif;
  --input--stackingContext: var(--viewLayer--revert, 20);
  --button--stackingContext: var(--viewLayer--revert);
  --filterBar--stackingContext: var(--viewLayer--toolbar, 400);
  --pagingBar--stackingContext: var(--viewLayer--utility, 300);
  --toasts--stackingContext: var(--appLayer--notif, 600);
  --viewToolbar--stackingContext: var(--appLayer--toolbar, 1200);
  --viewMain--stackingContext: var(--appLayer--viewMain, 1000);
  --viewSidebar--stackingContext: var(--appLayer--menuPanel, 1600);
  /* Global Variables */
  --font-family: 'Fira Sans', sans-serif;
}
.HIDE_DEBUG {
  display: none;
}
.view__sidebar {
  background: #444;
}
.view__toolbar {
  background: #efefef;
}
body {
  font-family: var(--font-family);
  background-color: var(--color-theme-bg-faded);
  color: var(--color-text);
}
a:not(.blockLink, .button) {
  color: var(--color-secondary);
}
a:not(.blockLink, .button):hover {
  color: var(--color-secondary-faded);
}
a.blockLink {
  color: inherit;
  text-decoration: inherit;
}
a[class*="icon:"] {
  text-decoration: none;
}
a[class*="icon:"]::before {
  margin-right: 0.2em;
  font-size: inherit;
}
strong {
  font-weight: bold;
}
b {
  font-weight: 500;
}
i {
  font-style: italic;
}
del {
  text-decoration: line-through;
  opacity: 0.666;
}
code {
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 0.125em 0.333em 0.125em;
  border-radius: 0.125em;
}
.nobr {
  white-space: nowrap;
}
.timestamp > * {
  letter-spacing: 0.05em;
}
.timestamp b {
  font-weight: bold;
  opacity: 0.25;
}
.dateTimeStamp {
  font-size: 0.875em;
  font-weight: 500;
  letter-spacing: 0.0333em;
}
.dateTimeStamp em {
  color: var(--palette--neutral-500);
}
.dateTimeStamp i {
  color: var(--palette--neutral-200);
}
.dateTimeStamp b {
  color: var(--palette--neutral-300);
}
.dateTimeStamp b[class*='icon:']::before {
  font-size: 1em !important;
  margin: 0 0.2em 0 0.5em;
  opacity: 0.666;
}
.bigNumber {
  font-weight: 900;
  font-size: 2.625rem;
  line-height: 1;
}
.copy\:micro {
  font-size: 0.75rem;
}
.copy\:small {
  font-size: 0.875rem;
}
.copy\:large {
  font-size: 1.188rem;
}
.editorialCopy {
  --copy--padding-x: 1rem;
  padding: 0 var(--copy--padding-x);
}
.editorialCopy :where(h2) {
  font: 500 var(--font--large);
}
.editorialCopy :where(h3) {
  font: 500 1.25em / 1.2 var(--font-family);
}
.editorialCopy :where(h4) {
  font: 500 var(--font--normal);
}
.editorialCopy :where(h2, h3, h4) + p {
  margin-top: 0.625rem;
}
.editorialCopy :where(h2, h3, h4)::before {
  margin-right: 0.33rem;
}
.editorialCopy [class*='icon:']::before {
  margin-right: 0.2em;
}
.editorialCopy em {
  font-style: italic;
}
.editorialCopy p + p,
.editorialCopy p + ul,
.editorialCopy p + ol,
.editorialCopy ul + p,
.editorialCopy ul + ul,
.editorialCopy ol + p,
.editorialCopy ol + ol {
  margin-top: 1em;
}
.editorialCopy ul:not(.propList) {
  list-style: circle;
  margin-left: 1em;
}
.editorialCopy ul:not(.propList) li::marker {
  color: var(--color-text-muted);
}
.instructions__stepLabel {
  margin-bottom: 0.5em;
}
.instructions__step {
  border-top: 1px solid var(--color-ui-border);
  padding: 1em 0.75em;
}
@media (min-width: 812px) {
  .instructions__step {
    display: grid;
    grid-template-columns: 7.5rem 1fr;
    gap: 1em;
  }
}
.instructions__step p {
  margin-top: 0;
}
.instructions__step ol {
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 1.5em;
}
.instructions__step ol li {
  padding-left: 0.5em;
}
.instructions__step ol li + li {
  margin-top: 0.5em;
}
.instructions__step ol li::marker {
  color: var(--color-text-faded);
  font-weight: 500;
}
.font--mini {
  font: 0.75em / 1.3333 'Fira Sans', sans-serif;
}
.font--small {
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
}
.font--normal {
  font: 1em / 1.5 'Fira Sans', sans-serif;
}
.font--label {
  font: 0.9375em / 1.2667 'Fira Sans', sans-serif;
}
.font--medium {
  font: 1.125em / 1.3333 'Fira Sans', sans-serif;
}
.font--large {
  font: 1.3125em / 1.3333 'Fira Sans', sans-serif;
}
.font--heading {
  font: 1.75em / 1.2857 'Fira Sans', sans-serif;
}
.font--display {
  font: 2.5em / 1.5 'Fira Sans', sans-serif;
}
CONFIG[input],
PROTO[input],
.field__input--checkbox,
.field__input--radio,
.button,
.dateInput,
.datepicker,
input,
select,
.tagPicker,
.richText__editor,
.richText__htmlEditor,
.dateInput,
.timeInput,
.dateTimeInput {
  --input--padding-y: 0.5rem;
  --input--padding-x: 0.75rem;
  --input--height: calc(1.625rem + (0.5rem * 2));
  --input--line-height: 1.625rem;
  --input--border: var(--input--border-width) solid var(--input--color-border);
  --input--rounding: 0.188rem;
  --input--border-width: 0.063rem;
  --input--color-border: #999;
  --input--color-border-focus: #000;
  --input--color-bg: #fff;
  --input--color-placeholder: #666;
  --input--icon-size: 1.125rem;
  --input--color-icon: var(--input--color-border);
}
PROTO[input],
input,
select,
.tagPicker,
.richText__editor,
.richText__htmlEditor,
.dateInput,
.timeInput,
.dateTimeInput {
  display: inline-block;
  font-size: 1em;
  padding: var(--input--padding-y) var(--input--padding-x);
  height: var(--input--height);
  border-radius: var(--input--rounding);
  border: var(--input--border-width) solid var(--input--color-border);
  background-color: var(--input--color-bg);
}
input,
select {
  font-family: inherit;
}
input::placeholder {
  color: var(--input--color-placeholder);
}
.input--checkbox {
  --input--height: 1.125rem;
  width: var(--input--height);
  --input--border-width: 0.125rem;
}
textarea {
  width: 100%;
  font-family: inherit;
  padding: var(--input--padding-y) var(--input--padding-x);
}
.form__field {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
}
.form__errors {
  color: var(--color-text-error);
  font-size: 0.875rem;
  line-height: 1.125rem;
  min-height: 1.5rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.field__label {
  display: block;
  padding: 1.25rem 0 0.25rem;
  font-weight: bold;
  color: #444;
}
.field__errors {
  color: var(--color-text-error);
  font-size: 0.813rem;
  line-height: 1.125rem;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
}
.field__input--checkbox,
.field__input--radio {
  display: inline-flex;
  align-items: center;
  min-height: var(--input--height);
}
.field__input--checkbox:has(input:checked),
.field__input--radio:has(input:checked) {
  font-weight: 500;
  text-decoration: underline;
}
.field__input--checkbox input,
.field__input--radio input {
  margin: 0 0.375em 0 0.5em;
}
.field.has\:pendingChanges input {
  background-color: blue;
  border-color: lightblue;
}
.field.has\:error input,
.field.has\:error .select__control {
  background-color: var(--color-bg-error);
  border-color: var(--color-text-error);
}
.field .file.has\:error input,
.field .file.has\:error .select__control {
  background-color: var(--color-bg-error);
  border-color: var(--color-text-error);
}
.field.has\:error {
  position: relative;
  z-index: var(--input--stackingContext);
}
.field.has\:error .field__errors {
  position: absolute;
  z-index: var(--input--stackingContext);
  left: 0;
  bottom: 2.875rem;
  background-color: var(--color-bg);
}
input[type='time'] {
  padding: 0.5rem 0.625rem;
}
input[type='time']::-webkit-datetime-edit-hour-field,
input[type='time']::-webkit-datetime-edit-minute-field,
input[type='time']::-webkit-datetime-edit-ampm-field {
  padding: 0 0.188rem;
}
input[type='time']::-webkit-clear-button {
  display: none;
}
input[type='time']::-webkit-inner-spin-button {
  display: none;
}
.iconLike,
[class^="icon:"]::before,
[class*=" icon:"]::before,
i.icon,
.appMenu__label::before,
.entity.is\:soldOut .entity__title::after,
.entity.is\:OwnershipSold .entity__title::before,
.calendarSyncModal .modal__title::before,
.dateInput::before,
.timeInput::before,
.dateTimeInput__date::before,
.dateTimeInput__time::before,
.propList__itemLabel::before,
.pill::before,
.view__tabs .tabNav__item.has\:error::before {
  font-family: 'showmanager-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.125rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i.icon {
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
}
.icon\:explicit::before {
  content: "\e9ef";
}
.icon\:fast_forward::before {
  content: "\e9f0";
}
.icon\:fast_rewind::before {
  content: "\e9f1";
}
.icon\:library_add::before {
  content: "\e9f2";
}
.icon\:library_books::before {
  content: "\e9f3";
}
.icon\:repeat::before {
  content: "\e95e";
}
.icon\:subtitles::before {
  content: "\e9f4";
}
.icon\:fiber_new::before {
  content: "\e9f5";
}
.icon\:art_track::before {
  content: "\e9f6";
}
.icon\:subscriptions::before {
  content: "\e9f7";
}
.icon\:playlist_add_check::before {
  content: "\e9f8";
}
.icon\:chat::before {
  content: "\e9f9";
}
.icon\:comment::before {
  content: "\e9fa";
}
.icon\:contacts::before {
  content: "\e9fb";
}
.icon\:email::before {
  content: "\e9fc";
}
.icon\:message::before {
  content: "\e9fd";
}
.icon\:chat_bubble::before {
  content: "\e9fe";
}
.icon\:chat_bubble_outline::before {
  content: "\e9ff";
}
.icon\:mail_outline::before {
  content: "\ea00";
}
.icon\:alternate_email::before {
  content: "\ea01";
}
.icon\:cancel_presentation::before {
  content: "\ea02";
}
.icon\:pause_presentation::before {
  content: "\ea03";
}
.icon\:list_alt::before {
  content: "\e9ca";
}
.icon\:add1::before {
  content: "\ea04";
}
.icon\:add_circle::before {
  content: "\e934";
}
.icon\:add_circle_outline::before {
  content: "\e935";
}
.icon\:block::before {
  content: "\e936";
}
.icon\:clear1::before {
  content: "\ea05";
}
.icon\:content_copy::before {
  content: "\e937";
}
.icon\:content_paste::before {
  content: "\ea06";
}
.icon\:filter_list::before {
  content: "\e938";
}
.icon\:forward1::before {
  content: "\ea07";
}
.icon\:link1::before {
  content: "\e9cb";
}
.icon\:remove_circle::before {
  content: "\e939";
}
.icon\:remove_circle_outline::before {
  content: "\e93a";
}
.icon\:reply::before {
  content: "\e93b";
}
.icon\:reply_all::before {
  content: "\ea08";
}
.icon\:save1::before {
  content: "\ea09";
}
.icon\:select_all::before {
  content: "\e9cc";
}
.icon\:send1::before {
  content: "\e93c";
}
.icon\:delete_sweep::before {
  content: "\ea0a";
}
.icon\:low_priority::before {
  content: "\ea0b";
}
.icon\:link_off::before {
  content: "\e9cd";
}
.icon\:save_alt::before {
  content: "\e93d";
}
.icon\:ballot::before {
  content: "\e9ce";
}
.icon\:file_copy::before {
  content: "\ea0c";
}
.icon\:how_to_vote::before {
  content: "\ea0d";
}
.icon\:add_link::before {
  content: "\e93e";
}
.icon\:add_alarm::before {
  content: "\ea0e";
}
.icon\:devices::before {
  content: "\ea0f";
}
.icon\:dvr::before {
  content: "\ea10";
}
.icon\:now_wallpaper::before {
  content: "\e93f";
}
.icon\:now_widgets::before {
  content: "\e9cf";
}
.icon\:add_to_home_screen::before {
  content: "\e940";
}
.icon\:mobile_friendly::before {
  content: "\e941";
}
.icon\:insert_comment::before {
  content: "\e942";
}
.icon\:insert_invitation::before {
  content: "\e943";
}
.icon\:monetization_on::before {
  content: "\ea11";
}
.icon\:insert_chart_outlined::before {
  content: "\ea12";
}
.icon\:cloud_off::before {
  content: "\e9d0";
}
.icon\:cloud_queue::before {
  content: "\e9d1";
}
.icon\:file_download::before {
  content: "\e944";
}
.icon\:file_upload::before {
  content: "\e945";
}
.icon\:folder_open::before {
  content: "\e946";
}
.icon\:folder_shared::before {
  content: "\e9d2";
}
.icon\:create_new_folder::before {
  content: "\e9d3";
}
.icon\:keyboard_arrow_down::before {
  content: "\e947";
}
.icon\:keyboard_arrow_left::before {
  content: "\e948";
}
.icon\:keyboard_arrow_right::before {
  content: "\e949";
}
.icon\:keyboard_arrow_up::before {
  content: "\e94a";
}
.icon\:laptop_chromebook::before {
  content: "\e94b";
}
.icon\:memory::before {
  content: "\ea13";
}
.icon\:phone_android::before {
  content: "\e94c";
}
.icon\:security::before {
  content: "\ea14";
}
.icon\:assistant::before {
  content: "\ea15";
}
.icon\:camera_front::before {
  content: "\ea16";
}
.icon\:camera_rear::before {
  content: "\ea17";
}
.icon\:center_focus_strong::before {
  content: "\e9d4";
}
.icon\:center_focus_weak::before {
  content: "\e9d5";
}
.icon\:collections::before {
  content: "\e94d";
}
.icon\:color_lens::before {
  content: "\ea18";
}
.icon\:compare::before {
  content: "\e94e";
}
.icon\:control_point_duplicate::before {
  content: "\e94f";
}
.icon\:crop::before {
  content: "\e950";
}
.icon\:crop_free::before {
  content: "\e9d6";
}
.icon\:crop_original::before {
  content: "\e951";
}
.icon\:filter_center_focus::before {
  content: "\e9d7";
}
.icon\:filter_frames::before {
  content: "\e9d8";
}
.icon\:flip::before {
  content: "\ea19";
}
.icon\:picture_as_pdf::before {
  content: "\ea1a";
}
.icon\:portrait::before {
  content: "\e9d9";
}
.icon\:visible::before {
  content: "\e952";
}
.icon\:style::before {
  content: "\e9da";
}
.icon\:tune::before {
  content: "\e953";
}
.icon\:view_comfy::before {
  content: "\e9db";
}
.icon\:view_compact::before {
  content: "\e954";
}
.icon\:collections_bookmark::before {
  content: "\e955";
}
.icon\:crop_rotate::before {
  content: "\e956";
}
.icon\:photo_filter::before {
  content: "\ea1b";
}
.icon\:burst_mode::before {
  content: "\ea1c";
}
.icon\:add_photo_alternate::before {
  content: "\e957";
}
.icon\:image_search::before {
  content: "\e958";
}
.icon\:directions::before {
  content: "\ea1d";
}
.icon\:directions_subway::before {
  content: "\ea1e";
}
.icon\:local_atm::before {
  content: "\ea1f";
}
.icon\:local_attraction::before {
  content: "\ea20";
}
.icon\:local_bar::before {
  content: "\ea21";
}
.icon\:local_grocery_store::before {
  content: "\ea22";
}
.icon\:local_offer::before {
  content: "\e9dc";
}
.icon\:local_printshop::before {
  content: "\ea23";
}
.icon\:pin_drop::before {
  content: "\e959";
}
.icon\:near_me::before {
  content: "\e95a";
}
.icon\:zoom_out_map::before {
  content: "\e95b";
}
.icon\:category::before {
  content: "\ea24";
}
.icon\:apps::before {
  content: "\e964";
}
.icon\:arrow_back::before {
  content: "\e95c";
}
.icon\:arrow_forward::before {
  content: "\e95d";
}
.icon\:fullscreen::before {
  content: "\e960";
}
.icon\:fullscreen_exit::before {
  content: "\e961";
}
.icon\:keyboard_control::before {
  content: "\e962";
}
.icon\:more_vert::before {
  content: "\e963";
}
.icon\:unfold_less::before {
  content: "\e965";
}
.icon\:unfold_more::before {
  content: "\e966";
}
.icon\:arrow_upward::before {
  content: "\e967";
}
.icon\:arrow_downward::before {
  content: "\e968";
}
.icon\:arrow_back_ios::before {
  content: "\e969";
}
.icon\:arrow_forward_ios::before {
  content: "\e96a";
}
.icon\:do_not_disturb_alt::before {
  content: "\e96b";
}
.icon\:event_available::before {
  content: "\e96c";
}
.icon\:event_busy::before {
  content: "\e96d";
}
.icon\:event_note::before {
  content: "\e9c1";
}
.icon\:folder_special::before {
  content: "\e9dd";
}
.icon\:confirmation_number::before {
  content: "\e96e";
}
.icon\:ondemand_video::before {
  content: "\e96f";
}
.icon\:enhanced_encryption::before {
  content: "\e9de";
}
.icon\:no_encryption::before {
  content: "\e9df";
}
.icon\:pie_chart::before {
  content: "\e970";
}
.icon\:pie_chart_outlined::before {
  content: "\e971";
}
.icon\:notifications::before {
  content: "\e972";
}
.icon\:notifications_none::before {
  content: "\e973";
}
.icon\:notifications_off::before {
  content: "\e974";
}
.icon\:notifications_on::before {
  content: "\e975";
}
.icon\:notifications_paused::before {
  content: "\e976";
}
.icon\:people_outline::before {
  content: "\e9e0";
}
.icon\:person::before {
  content: "\e977";
}
.icon\:person_add::before {
  content: "\e978";
}
.icon\:person_outline::before {
  content: "\e979";
}
.icon\:public::before {
  content: "\e97a";
}
.icon\:share::before {
  content: "\e97b";
}
.icon\:check_box::before {
  content: "\e97c";
}
.icon\:check_box_outline_blank::before {
  content: "\e97d";
}
.icon\:star::before {
  content: "\e97e";
}
.icon\:star_half::before {
  content: "\e97f";
}
.icon\:star_outline::before {
  content: "\e980";
}
.icon\:alarm_off::before {
  content: "\e981";
}
.icon\:assignment::before {
  content: "\e982";
}
.icon\:assignment_returned::before {
  content: "\e9c2";
}
.icon\:assignment_turned_in::before {
  content: "\e9c3";
}
.icon\:book::before {
  content: "\e9e1";
}
.icon\:bookmark::before {
  content: "\e983";
}
.icon\:bookmark_outline::before {
  content: "\e984";
}
.icon\:cached::before {
  content: "\e9c4";
}
.icon\:chrome_reader_mode::before {
  content: "\e985";
}
.icon\:code::before {
  content: "\e9c5";
}
.icon\:dashboard::before {
  content: "\e986";
}
.icon\:delete::before {
  content: "\e9e2";
}
.icon\:description::before {
  content: "\e9c6";
}
.icon\:done::before {
  content: "\e987";
}
.icon\:done_all::before {
  content: "\e988";
}
.icon\:exit_to_app::before {
  content: "\e989";
}
.icon\:favorite::before {
  content: "\e98a";
}
.icon\:favorite_outline::before {
  content: "\e98b";
}
.icon\:flip_to_back::before {
  content: "\e98c";
}
.icon\:flip_to_front::before {
  content: "\e98d";
}
.icon\:highlight_remove::before {
  content: "\e98e";
}
.icon\:history::before {
  content: "\e98f";
}
.icon\:hourglass_empty::before {
  content: "\e990";
}
.icon\:https::before {
  content: "\e930";
}
.icon\:info::before {
  content: "\e991";
}
.icon\:info_outline::before {
  content: "\e931";
}
.icon\:input::before {
  content: "\e9e3";
}
.icon\:label::before {
  content: "\e992";
}
.icon\:label_outline::before {
  content: "\e993";
}
.icon\:lock_open::before {
  content: "\e932";
}
.icon\:lock_outline::before {
  content: "\e933";
}
.icon\:open_in_browser::before {
  content: "\e994";
}
.icon\:perm_media::before {
  content: "\e9e4";
}
.icon\:settings1::before {
  content: "\e995";
}
.icon\:settings_applications::before {
  content: "\e996";
}
.icon\:settings_backup_restore::before {
  content: "\ea25";
}
.icon\:settings_input_svideo::before {
  content: "\e9e5";
}
.icon\:swap_horiz::before {
  content: "\e997";
}
.icon\:swap_vert::before {
  content: "\e998";
}
.icon\:system_update_tv::before {
  content: "\e95f";
}
.icon\:today::before {
  content: "\e9e6";
}
.icon\:verified_user::before {
  content: "\e999";
}
.icon\:view_headline::before {
  content: "\e99a";
}
.icon\:view_module::before {
  content: "\e99b";
}
.icon\:visibility_off::before {
  content: "\e99c";
}
.icon\:zoom_in::before {
  content: "\e99d";
}
.icon\:zoom_out::before {
  content: "\e99e";
}
.icon\:indeterminate_check_box::before {
  content: "\e99f";
}
.icon\:copyright::before {
  content: "\e9a0";
}
.icon\:fingerprint::before {
  content: "\e9a1";
}
.icon\:lightbulb_outline::before {
  content: "\e9a2";
}
.icon\:touch_app::before {
  content: "\e9a3";
}
.icon\:date_range::before {
  content: "\e9e7";
}
.icon\:line_style::before {
  content: "\e9a4";
}
.icon\:line_weight::before {
  content: "\e9c7";
}
.icon\:update::before {
  content: "\e9a5";
}
.icon\:watch_later::before {
  content: "\e9a6";
}
.icon\:pan_tool::before {
  content: "\e9a7";
}
.icon\:delete_forever::before {
  content: "\e9a8";
}
.icon\:check_circle_outline::before {
  content: "\e9a9";
}
.icon\:delete_outline::before {
  content: "\ea26";
}
.icon\:offline_bolt::before {
  content: "\ea27";
}
.icon\:calendar_today::before {
  content: "\ea28";
}
.icon\:drag_indicator::before {
  content: "\e9aa";
}
.icon\:horizontal_split::before {
  content: "\ea29";
}
.icon\:label_important_outline::before {
  content: "\ea2a";
}
.icon\:vertical_split::before {
  content: "\e9ab";
}
.icon\:account_tree::before {
  content: "\ea2b";
}
.icon\:add_chart::before {
  content: "\e9e8";
}
.icon\:add_ic_call::before {
  content: "\e9ac";
}
.icon\:bookmarks::before {
  content: "\ea2c";
}
.icon\:circle_notifications::before {
  content: "\e9ad";
}
.icon\:dashboard_customize::before {
  content: "\e9c8";
}
.icon\:drive_file_move_outline::before {
  content: "\ea2d";
}
.icon\:drive_file_rename_outline::before {
  content: "\ea2e";
}
.icon\:grid_view::before {
  content: "\e9ae";
}
.icon\:logout::before {
  content: "\e9af";
}
.icon\:margin::before {
  content: "\e9e9";
}
.icon\:padding::before {
  content: "\e9ea";
}
.icon\:thumb_down_off_alt::before {
  content: "\e9b0";
}
.icon\:thumb_up_off_alt::before {
  content: "\e9b1";
}
.icon\:toggle_off::before {
  content: "\e9b2";
}
.icon\:toggle_on::before {
  content: "\e9b3";
}
.icon\:height::before {
  content: "\e9b4";
}
.icon\:sync_alt::before {
  content: "\e9b5";
}
.icon\:menu_book::before {
  content: "\e9b6";
}
.icon\:post_add::before {
  content: "\e9eb";
}
.icon\:flip_camera_android::before {
  content: "\ea2f";
}
.icon\:double_arrow::before {
  content: "\ea30";
}
.icon\:add_location_alt::before {
  content: "\e9b7";
}
.icon\:addchart::before {
  content: "\ea31";
}
.icon\:app_registration::before {
  content: "\ea32";
}
.icon\:arrow_circle_down::before {
  content: "\e9b8";
}
.icon\:arrow_circle_up::before {
  content: "\e9b9";
}
.icon\:article::before {
  content: "\e9ec";
}
.icon\:auto_awesome::before {
  content: "\ea33";
}
.icon\:auto_awesome_motion::before {
  content: "\e9ed";
}
.icon\:auto_stories::before {
  content: "\ea34";
}
.icon\:backup_table::before {
  content: "\ea35";
}
.icon\:corporate_fare::before {
  content: "\e9ba";
}
.icon\:forward_to_inbox::before {
  content: "\ea36";
}
.icon\:help_center::before {
  content: "\e9bb";
}
.icon\:hourglass_bottom::before {
  content: "\e9bc";
}
.icon\:hourglass_top::before {
  content: "\e9bd";
}
.icon\:local_police::before {
  content: "\e9be";
}
.icon\:mediation::before {
  content: "\e9bf";
}
.icon\:miscellaneous_services::before {
  content: "\ea37";
}
.icon\:multiple_stop::before {
  content: "\ea38";
}
.icon\:push_pin::before {
  content: "\e9ee";
}
.icon\:qr_code::before {
  content: "\e9c9";
}
.icon\:disabled_by_default::before {
  content: "\e9c0";
}
.icon\:warning::before {
  content: "\e900";
}
.icon\:add-alert::before {
  content: "\e901";
}
.icon\:notification-alert::before {
  content: "\e902";
}
.icon\:refresh::before {
  content: "\e903";
}
.icon\:library-add::before {
  content: "\e904";
}
.icon\:publishing::before {
  content: "\e905";
}
.icon\:add-circle::before {
  content: "\e906";
}
.icon\:add::before {
  content: "\e907";
}
.icon\:clear::before {
  content: "\e925";
}
.icon\:copy::before {
  content: "\e926";
}
.icon\:edit::before {
  content: "\e908";
}
.icon\:flag::before {
  content: "\e909";
}
.icon\:link::before {
  content: "\e90a";
}
.icon\:save::before {
  content: "\e91f";
}
.icon\:send::before {
  content: "\e90b";
}
.icon\:alarm::before {
  content: "\e90c";
}
.icon\:time::before {
  content: "\e90d";
}
.icon\:date::before {
  content: "\e90e";
}
.icon\:photo::before {
  content: "\e90f";
}
.icon\:download::before {
  content: "\e910";
}
.icon\:adjust::before {
  content: "\e927";
}
.icon\:add-photo::before {
  content: "\e911";
}
.icon\:handle::before {
  content: "\e920";
}
.icon\:back::before {
  content: "\e921";
}
.icon\:drop-down::before {
  content: "\e912";
}
.icon\:drop-up::before {
  content: "\e913";
}
.icon\:forward::before {
  content: "\e922";
}
.icon\:cancel::before {
  content: "\e914";
}
.icon\:check::before {
  content: "\e92f";
}
.icon\:chevron-up::before {
  content: "\e915";
}
.icon\:chevron-down::before {
  content: "\e916";
}
.icon\:menu::before {
  content: "\e917";
}
.icon\:more::before {
  content: "\e918";
}
.icon\:fold::before {
  content: "\e919";
}
.icon\:unfold::before {
  content: "\e91a";
}
.icon\:date-remove::before {
  content: "\e928";
}
.icon\:notification::before {
  content: "\e91b";
}
.icon\:star-on::before {
  content: "\e929";
}
.icon\:star-off::before {
  content: "\e92a";
}
.icon\:user::before {
  content: "\e92b";
}
.icon\:accept::before {
  content: "\e923";
}
.icon\:link-out::before {
  content: "\e92c";
}
.icon\:extension::before {
  content: "\e92d";
}
.icon\:help::before {
  content: "\e91c";
}
.icon\:remove::before {
  content: "\e91d";
}
.icon\:search::before {
  content: "\e92e";
}
.icon\:settings::before {
  content: "\e91e";
}
.icon\:euro::before {
  content: "\e924";
}
@media (min-width: 812px) {
  [class*="columns:"] {
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 1.5rem;
  }
  .columns\:2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .columns\:3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .columns\:4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .columns\:5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .columns\:6 {
    grid-template-columns: repeat(6, 1fr);
  }
  .columns\:7 {
    grid-template-columns: repeat(7, 1fr);
  }
  .columns\:8 {
    grid-template-columns: repeat(8, 1fr);
  }
  .columns\:9 {
    grid-template-columns: repeat(9, 1fr);
  }
  .columns\:10 {
    grid-template-columns: repeat(10, 1fr);
  }
  .columns\:11 {
    grid-template-columns: repeat(11, 1fr);
  }
  .columns\:12 {
    grid-template-columns: repeat(12, 1fr);
  }
  .columns\:1\+11 {
    grid-template-columns: 1fr 11fr;
  }
  .columns\:2\+10 {
    grid-template-columns: 2fr 10fr;
  }
  .columns\:3\+9 {
    grid-template-columns: 3fr 9fr;
  }
  .columns\:4\+8 {
    grid-template-columns: 4fr 8fr;
  }
  .columns\:5\+7 {
    grid-template-columns: 5fr 7fr;
  }
  .columns\:6\+6 {
    grid-template-columns: 6fr 6fr;
  }
  .columns\:7\+5 {
    grid-template-columns: 7fr 5fr;
  }
  .columns\:8\+4 {
    grid-template-columns: 8fr 4fr;
  }
  .columns\:9\+3 {
    grid-template-columns: 9fr 3fr;
  }
  .columns\:10\+2 {
    grid-template-columns: 10fr 2fr;
  }
  .columns\:11\+1 {
    grid-template-columns: 11fr 1fr;
  }
  .col\:1 {
    grid-column: span 1;
  }
  .col\:2 {
    grid-column: span 2;
  }
  .col\:3 {
    grid-column: span 3;
  }
  .col\:4 {
    grid-column: span 4;
  }
  .col\:5 {
    grid-column: span 5;
  }
  .col\:6 {
    grid-column: span 6;
  }
  .col\:7 {
    grid-column: span 7;
  }
  .col\:8 {
    grid-column: span 8;
  }
  .col\:9 {
    grid-column: span 9;
  }
  .col\:10 {
    grid-column: span 10;
  }
  .col\:11 {
    grid-column: span 11;
  }
  .col\:12 {
    grid-column: span 12;
  }
}
CONFIG[viewSidebar],
.view__sidebar,
.view {
  --viewSidebar--color-bg: var(--invert-color-bg);
  --viewSidebar--color-text: var(--invert-color-text-subtle);
  --viewSidebar--color-link: var(--invert-color-text-muted);
  --viewSidebar--color-link-hover: var(--invert-color-text);
  --viewSidebar--color-menuToggle: var(--color-primary);
  --viewSidebar--mobile--width-max: 20rem;
  --viewSidebar--mobile--width: 80vw;
  --viewSidebar--mobile--shadow: 5vw 0 15vw rgba(0, 0, 0, 0.7);
}
.view__sidebar {
  background: var(--viewSidebar--color-bg);
  color: var(--viewSidebar--color-text);
}
.view__sidebar a {
  color: var(--viewSidebar--color-link);
}
.view__sidebar a:hover {
  color: var(--viewSidebar--color-link-hover);
}
@media screen and (width < 90rem) {
  .view__sidebar {
    position: fixed;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    width: var(--viewSidebar--mobile--width);
    max-width: var(--viewSidebar--mobile--width-max);
    z-index: var(--viewSidebar--stackingContext);
    box-shadow: var(--viewSidebar--mobile--shadow);
    display: none;
    visibility: hidden;
  }
  .sidebar\:open .view__sidebar {
    display: block;
    visibility: visible;
  }
}
.sidebar__menutoggle {
  float: right;
  margin: 0.25rem 0.5rem;
}
.sidebar__menutoggle.button {
  --button--color-text-hover: currentColor;
  --button--color-text-active: currentColor;
}
.sidebar__menutoggle.button::before {
  color: var(--viewSidebar--color-menuToggle);
}
@media screen and (90rem <= width) {
  .sidebar__menutoggle {
    display: none;
    visibility: hidden;
  }
}
.sidebar__header {
  padding: 1rem;
}
@media screen and (90rem <= width) {
  .sidebar__header {
    display: none;
  }
}
.sidebar__logo {
  max-width: 13.75rem;
}
.sidebar__profile {
  padding: 1rem 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template: "image greeting" auto "image meta" auto / auto 1fr;
  grid-gap: 0.125em 0.75em;
}
.sidebar__profileAvatar {
  grid-area: image;
  place-self: center;
  max-width: 6.25rem;
}
.sidebar__profileAvatar.avatar {
  --avatar--size: 2.625rem;
  --avatar--placeholder-size: 1.313rem;
  --avatar--hover-scale: 1;
}
.sidebar__profileGreeting {
  grid-area: greeting;
}
.sidebar__profileMeta {
  grid-area: meta;
  font-size: 0.875rem;
}
.sidebar__profileMeta a::before {
  opacity: 0.5;
  margin-left: -0.25em;
}
.view__toolbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background: var(--color-theme-sidebar);
  color: var(--color-bg);
}
@media (max-width: 811px) {
  .view__toolbar .toolbar__backlink {
    display: none;
  }
}
@media (max-width: 1440px) {
  .toolbar {
    padding-left: 3rem;
  }
  .toolbar .button:not(.has\:iconOnly) {
    padding: 0.75rem;
  }
  .toolbar .button:not(.has\:iconOnly) > span {
    display: none;
  }
  .toolbar .button:not(.has\:iconOnly)::before {
    margin: 0;
  }
}
.toolbar__header {
  width: 13vw;
  min-width: 11.25rem;
  align-items: center;
  padding-left: 1.5rem;
  display: none;
}
@media (min-width: 812px) {
  .toolbar__header {
    display: flex;
  }
}
.toolbar__logo {
  max-width: 8.75rem;
}
.toolbar__version {
  margin-left: 0.75rem;
  color: var(--color-text-faded);
}
.toolbar__content {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.toolbar__backlink {
  padding-right: 0.75rem;
  text-decoration: none;
  color: var(--color-primary) !important;
}
.toolbar__title {
  padding-left: 0;
  font: 1.125em / 1.3333 'Fira Sans', sans-serif;
  font-weight: bold;
}
.toolbar__actions {
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
}
.toolbar__actions > * + * {
  margin-left: 0.188rem !important;
}
.toolbar__status {
  color: rgba(255, 255, 255, 0.75);
}
.toolbar__status span + span {
  margin-left: 0.25rem;
}
.toolbar__status span + span::before {
  content: " | ";
  color: rgba(255, 255, 255, 0.35);
  margin-right: 0.25rem;
}
@media (max-width: 811px) {
  .toolbar__status {
    display: none;
  }
}
.view.has\:overlay {
  position: relative;
}
.view.has\:overlay > *:not(.view__overlay) {
  filter: blur(0.15rem);
}
.view.has\:overlay .view__overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20vh;
}
.view__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 900;
  display: none;
}
.overlay__dialogue {
  background: #fff;
  box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  padding: 1.875rem;
  min-width: 22.5rem;
  max-width: 45rem;
}
.tabNav {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;
}
.tabNav__item {
  display: flex;
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  margin-right: 0.188rem;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.3);
  transition: background 0.3s ease-out;
}
.tabNav__item:hover {
  background: rgba(255, 255, 255, 0.8);
}
.tabNav__item.is\:active {
  cursor: auto;
  background: var(--color-bg);
}
.tabNav__icon {
  margin-right: 0.625rem;
}
.tabNav__label {
  display: flex;
  flex-flow: column nowrap;
}
.tabNav .label__title,
.tabNav .label__desc {
  white-space: nowrap;
}
.tabNav .label__title {
  font-size: 1rem;
  line-height: 1.313rem;
  font-weight: bold;
}
.tabNav .label__desc {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--color-text-faded);
}
CONFIG[appMenu],
.appMenu {
  --appMenu--color-text: var(--invert-color-text-muted);
  --appMenu--color-link: var(--invert-color-text-subtle);
  --appMenu--color-link-hover: var(--invert-color-text);
  --appMenu--color-link-active: var(--color-primary);
  --appMenu--label-font: 0.875rem / 1.5rem 'Fira Sans', sans-serif;
  --appMenu--item-font: 0.938rem / 1.375rem 'Fira Sans', sans-serif;
  --appMenu--item-padding: 0.375rem 0.5rem;
  --appMenu--item-separator: 1px solid rgba(255, 255, 255, 0.15);
  --appMenu--item-bg: rgba(255, 255, 255, 0.05);
  --appMenu--item-bg-hover: rgba(255, 255, 255, 0.1);
  --appMenu--subMenu-padding: 0.375rem 0 1rem;
  --appMenu--subMenu-rounding: 0.188rem;
  --appMenu--subMenu-item-padding: 0.375rem 0.75rem;
  --appMenu--subMenu-bg: rgba(255, 255, 255, 0.05);
}
@media screen and (width < 28.75rem) {
  CONFIG[appMenu],
  .appMenu {
    --appMenu--label-font: 0.875rem / 1.5rem 'Fira Sans', sans-serif;
    --appMenu--item-font: 1rem / 2.25rem 'Fira Sans', sans-serif;
    --appMenu--subMenu-item-padding: 0.5rem 0.75rem;
  }
}
.appMenu {
  color: var(--appMenu--color-text);
}
.appMenu a {
  display: block;
  color: var(--appMenu--color-link);
  text-decoration: none;
}
.appMenu a:hover,
.appMenu a:visited:hover {
  color: var(--appMenu--color-link-hover);
}
.appMenu__item {
  font: var(--appMenu--item-font);
  padding: var(--appMenu--item-padding);
  border-bottom: var(--appMenu--item-separator);
  background: transparent;
  transition: all 0.167s ease-out;
}
.appMenu__item:hover {
  background: var(--appMenu--item-bg-hover);
}
.appMenu__item.is\:active a {
  color: var(--appMenu--color-link-active);
}
.appMenu__label {
  font: var(--appMenu--label-font);
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
  z-index: auto;
}
.appMenu__label::before {
  content: "\e912";
  font-size: 1.5rem;
  float: right;
}
.is\:open .appMenu__label::before {
  content: "\e913";
}
.appMenu__section .appMenu__subMenu {
  display: none;
}
.appMenu__section.is\:open .appMenu__subMenu {
  display: block;
}
.appMenu__subMenu {
  padding: var(--appMenu--subMenu-padding);
}
.appMenu__section.is\:open {
  background: var(--appMenu--subMenu-bg);
}
.subMenu__item {
  padding: var(--appMenu--subMenu-item-padding);
  transition: all 0.167s ease-out;
  border-radius: var(--appMenu--subMenu-rounding);
}
.subMenu__item:hover {
  background: var(--appMenu--item-bg-hover);
}
.subMenu__item.is\:active a {
  color: var(--appMenu--color-link-active);
}
.pageMenu a {
  color: #000;
  text-decoration: none;
  display: block;
}
.pageMenu__title {
  font-size: 1.313rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.pageMenu__item {
  padding: 0.5rem 1rem;
  border-top: 0.063rem solid rgba(0, 0, 0, 0.1);
}
.pageMenu__item:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.pageMenu__item.is\:active {
  background-color: var(--color-bg);
}
CONFIG[button],
.button {
  --button--font: inherit;
  --button--font-size: 1rem;
  --button--icon-size: 1.25rem;
  --button--height: var(--input--height);
  --button--padding-y: 0.5rem;
  --button--padding-x: 1.5rem;
  --button--padding-x-icon: 1.125rem;
  --button--icon-spacing: 0.333em;
  --button--spacing: 0.125rem;
  --button--color-bg: #444;
  --button--color-bg-hover: #333;
  --button--color-bg-active: #222;
  --button--color-text: #fff;
  --button--color-text-hover: #fff;
  --button--color-text-active: #fff;
  --buttin--color-icon: inherit;
  --button--color-icon-hover: inherit;
  --button--color-icon-active: inherit;
  --button--rounding: 0.188rem;
  --button--border: none;
  --button--color-border-hover: none;
  --button--color-border-active: none;
  --button--press-depth: 0.063rem;
  --button--transition: transform 0.1s ease-out, color 0.15s ease-out, background 0.25s ease-out, border-color 0.25s ease-out;
}
button {
  border: none;
  font-family: var(--font-family);
}
.button {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: var(--button--height);
  padding: var(--button--padding-y) var(--button--padding-x);
  cursor: pointer;
  backface-visibility: hidden;
  z-index: var(--button--stackingContext);
  transform: translate3d(0, 0, 0);
  transition: var(--button--transition);
  font: var(--button--font);
  font-size: var(--button--font-size);
  white-space: nowrap;
  color: var(--button--color-text);
  background: var(--button--color-bg);
  border: var(--button--border);
  border-radius: var(--button--rounding);
}
a.button {
  text-decoration: none;
}
.button::before {
  color: var(--button--color-icon);
  font-size: var(--button--icon-size);
}
.button.has\:iconOnly {
  width: var(--button--height);
  padding: 0;
}
.button[class*="icon:"]:not(.has\:iconOnly) {
  padding-left: var(--button--padding-x-icon);
}
.button[class*="icon:"]:not(.has\:iconOnly)::before {
  margin-right: var(--button--icon-spacing);
}
.button:focus {
  outline: none;
}
.button:hover {
  color: var(--button--color-text-hover);
  background: var(--button--color-bg-hover);
  border-color: var(--button--color-border-hover);
}
.button:hover::before {
  color: var(--button--color-icon-hover);
}
.button:active,
.button.is\:active {
  color: var(--button--color-text-active);
  background: var(--button--color-bg-active);
  border-color: var(--button--color-border-active);
}
.button:active::before,
.button.is\:active::before {
  transform: translate3d(0, var(--button--press-depth), 0);
  color: var(--button--color-icon-active);
}
.button + .button {
  margin-left: var(--button--spacing);
}
.button\:small {
  --button--font-size: 0.875rem;
  --button--icon-size: 1.125rem;
  --button--height: 1.875rem;
  --button--padding-y: 0.25rem;
  --button--padding-x: 0.75rem;
  --button--padding-x-icon: 0.625rem;
}
.button\:micro {
  --button--font-size: 0.75rem;
  --button--icon-size: 0.75rem;
  --button--height: 1.375rem;
  --button--padding-y: 0.188rem;
  --button--padding-x: 0.5rem;
  --button--padding-x-icon: 0.375rem;
}
.button\:large {
  --button--font-size: 1.25rem;
  --button--icon-size: 1.5rem;
  --button--height: 2.813rem;
  --button--padding-y: 0.625rem;
  --button--padding-x: 2rem;
}
.button\:naked {
  --button--color-bg: transparent;
  --button--color-bg-hover: inherit;
  --button--color-bg-active: transparent;
  --button--color-text: #14161E;
  --button--color-text-hover: #14161E;
  --button--color-text-active: #14161E;
}
.button\:primary {
  --button--color-bg: var(--color-primary);
  --button--color-bg-hover: var(--color-primary-faded);
  --button--color-bg-active: var(--color-primary);
  --button--color-text: var(--color-secondary-muted);
  --button--color-text-hover: var(--color-secondary-muted);
  --button--color-text-active: var(--color-secondary-muted);
}
.button\:secondary {
  --button--color-bg: var(--color-secondary);
  --button--color-bg-hover: var(--color-secondary-muted);
  --button--color-bg-active: var(--color-secondary);
  --button--color-text: #E6EAF0;
  --button--color-text-hover: #E6EAF0;
  --button--color-text-active: #E6EAF0;
}
.button\:danger {
  --button--color-bg: var(--color-text-error-faded);
  --button--color-bg-hover: var(--color-text-error);
  --button--color-bg-active: var(--color-text-error-faded);
  --button--color-text: var(--color-bg);
  --button--color-text-hover: var(--color-bg);
  --button--color-text-active: var(--color-bg);
}
.button\:outline {
  --button--color-bg: transparent;
  --button--color-bg-hover: inherit;
  --button--color-bg-active: transparent;
  --button--color-border: currentColor;
  --button--color-border-hover: currentColor;
  --button--color-border-active: currentColor;
  --button--border: 0.063rem solid currentColor;
  --button--color-text: var(--color-text-muted);
  --button--color-text-hover: var(--color-text-faded);
  --button--color-text-active: var(--color-text-faded);
}
.button\:outline.button\:primary {
  --button--color-text: var(--color-primary);
  --button--color-text-hover: var(--color-primary-faded);
  --button--color-text-active: var(--color-primary-muted);
}
.button\:outline.button\:secondary {
  --button--color-text: var(--color-secondary);
  --button--color-text-hover: var(--color-secondary-faded);
  --button--color-text-active: var(--color-secondary-muted);
}
.buttonGroup {
  display: inline-flex;
  margin: 0 0.25rem;
}
.buttonGroup:last-child {
  margin-right: 0;
}
.buttonGroup:first-child {
  margin-left: 0;
}
.buttonGroup .button {
  border-radius: 0;
}
.buttonGroup .button:not(:first-child) {
  margin: 0 0 0 0.063rem;
}
.buttonGroup .button:first-child {
  border-top-right-radius: var(--button--rounding);
  border-bottom-right-radius: var(--button--rounding);
}
.buttonGroup .button:last-child {
  border-top-left-radius: var(--button--rounding);
  border-bottom-left-radius: var(--button--rounding);
}
CONFIG[select],
.select,
.datepicker {
  --select--stackingContext: var(--viewLayer--inputs, 20);
  --select--font: inherit;
  --select--font-size: 1em;
  --select--line-height: 1.625em;
  --select--height: 2.625em;
  --select--padding-y: 0.5em;
  --select--padding-x: 0.75em;
  --select--menu-min-height: 2.25em;
  --select--border-radius: 0.188em;
  --select--border-width: 0.063rem;
  --select--border: var(--select--border-width) solid var(--select--color-border);
  --select--border-menu: var(--select--border-width) solid var(--select--color-border);
  --select--color-bg: #fff;
  --select--color-bg-hover: #fff;
  --select--color-bg-active: #fff;
  --select--color-border: #999;
  --select--color-border-hover: #999;
  --select--color-border-active: #111;
  --select--color-text: #222;
  --select--color-text-hover: #111;
  --select--color-text-active: #000;
  --select--color-text-error: #c12414;
  --select--color-placeholder: #666;
  --select--icon-size: 1.25em;
  --select--icon-area-width: 1.5em;
  --select--color-icon: #999;
  --select--color-icon-hover: #777;
  --select--color-icon-active: #444;
  --select--item-color-bg: #f6f6f6;
  --select--item-color-bg-hover: #f9f9f9;
  --select--item-color-bg-active: #f9f9f9;
}
.select {
  width: 100%;
  position: relative;
  z-index: var(--select--stackingContext);
}
.select.is\:open {
  --select--stackingContext: var(--viewLayer--isOpen);
}
.select__control {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  min-height: var(--select--height);
  position: relative;
  z-index: 115;
  font: var(--select--font);
  font-size: var(--select--font-size);
  line-height: var(--select--line-height);
  border: var(--select--border);
  border-radius: var(--select--border-radius);
}
.select__icon {
  font-size: var(--select--icon-size);
  width: var(--select--icon-area-width);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.5em;
}
.select__indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: var(--select--padding-x);
}
.select__value {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 120;
  font-size: var(--select--font-size);
  line-height: var(--select--line-height);
  padding: 0 var(--select--padding-x);
}
.select__value .button {
  position: relative;
  z-index: 125;
}
.select__valueLabel {
  display: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.select__input {
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  height: auto;
  box-sizing: border-box;
  font-size: var(--select--font-size);
  line-height: var(--select--line-height);
  padding: 0 1.5em 0 var(--select--padding-x);
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select__input:focus {
  cursor: initial;
}
.select__menu {
  position: absolute;
  width: var(--select--width);
  z-index: 410;
  background-color: white;
  border: var(--select--border-menu);
  border-radius: var(--select--border-radius);
  overflow: hidden;
  display: none;
  visibility: hidden;
}
.select__menu .menu__items {
  max-height: calc(var(--select--menu-max-height) + 1px);
  overflow-y: auto;
}
.select__menu-notice {
  font-weight: bold;
}
.select__message {
  font-size: var(--select--font-size);
  line-height: var(--select--line-height);
  padding: var(--select--padding-y) var(--select--padding-x);
  cursor: pointer;
}
.select__message:hover {
  background-color: var(--select--item-color-bg-active);
}
.select__option {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: var(--select--font-size);
  line-height: var(--select--line-height);
  padding: var(--select--padding-y) var(--select--padding-x);
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.select__option.is\:focus,
.select__option--is-focused {
  background-color: var(--select--item-color-bg);
}
.select__option.is\:selected {
  font-weight: 700;
}
.select__option:hover {
  background-color: var(--select--item-color-bg-hover);
}
.select__option.has\:conflict {
  color: var(--select--color-text-error);
}
.select__option.has\:conflict::before {
  float: right;
}
.select__moreItemsHint {
  font-size: var(--select--font-size);
  line-height: var(--select--line-height);
  padding: var(--select--padding-y) var(--select--padding-x);
  border-top: 1px solid #a6a6a6;
  cursor: none;
}
.select.has\:value .select__input {
  opacity: 0;
}
.select.has\:value .select__input::placeholder {
  color: inherit;
}
.select.has\:value .select__input:disabled::placeholder {
  color: var(--select--color-text);
}
.select.has\:value .select__value {
  color: initial;
}
.select.has\:value .select__valueLabel {
  display: inline-block;
}
.select.is\:open .select__input {
  opacity: 1;
}
.select.is\:open .select__value {
  visibility: hidden;
  pointer-events: none;
}
.select.is\:open .select__menu {
  display: block;
  visibility: visible;
}
.select.is\:focus .select__control {
  border-color: var(--select--color-border-active);
}
.select.has\:noOptions .select__menu {
  display: none !important;
}
.select.is\:disabled {
  pointer-events: none;
}
.select--entity.has\:value .select__input::placeholder {
  color: var(--select--color-placeholder);
}
.select--multi .select__value {
  display: none;
}
.select--multi .select__input {
  padding: 0;
}
.select--multi.has\:value .select__input {
  opacity: 1;
  padding-left: 0.333em;
}
.select--multi.has\:value .select__input::placeholder,
.select--multi.has\:value .select__input:disabled::placeholder {
  color: var(--select--color-placeholder);
}
.select--compact {
  --select--menu-width-min: 21em;
  --select--menu-width-max: 36em;
  width: auto;
  display: block;
  white-space: nowrap;
}
.select--compact .select__control {
  border: none;
  min-height: auto;
  display: inline-flex;
  flex-wrap: nowrap;
}
.select--compact .select__input {
  opacity: 1;
}
.select--compact .select__indicators {
  position: static;
}
.select--compact .select__value {
  position: static;
  display: block;
  height: auto;
  width: auto;
}
.select--compact .select__valueCount {
  font-weight: bold;
}
.select--compact .select__menu {
  min-width: var(--select--menu-width-min);
  max-width: var(--select--menu-width-max);
}
.select--compact .select__search {
  border: none;
  border-bottom: 1px solid #a6a6a6;
  border-radius: 0;
}
.select--compact .select__search .select__input {
  margin: 0.25em;
  margin-left: 0;
}
.select--compact.is\:focus .select__value {
  display: block;
}
.select--compact .tagPicker__tagLabel {
  max-width: 8rem;
}
.statusSelect .statusTag {
  margin-right: 0.188rem;
}
.statusSelect .select__option {
  white-space: nowrap;
}
@container fieldTable (43.75rem <= width) {
}
@container listTable (51.25rem <= width) {
}
CONFIG[table],
.table {
  --table--border-bottom: var(--row--separator);
  --table--control-color: #aaa;
  --table--control-disabled: #e6e6e6;
  --table--control-hover: #333;
  --table--blockgap: 3.75rem;
  --table--titlegap: 0.75rem;
  --thead--color-text: #777;
  --thead--separator: 3px solid #aaa;
  --thead--font: 500 var(--font--label);
  --thead--cell--padding: calc(var(--cell--pad-y) * 1.5) calc(var(--cell--pad-x)) calc(var(--cell--pad-y) * 0.5);
  --addButton--padding: 0.75rem;
  --addButton--color-text: #999;
  --row--color-bg-hover: var(--color-highlight-faint);
  --row--color-bg-dragged: #eee;
  --row--separator: 1px solid #ccc;
  --cell--pad-y: 0.5em;
  --cell--pad-x: 0.75em;
  --cell--padding: var(--cell--pad-y) var(--cell--pad-x);
  --cell--color-bg: #ffff;
  --cell--color-bg-hover: #f0f0f0;
  --cell--color-bg-disabled: #f0f0f0;
  --cell--separator: 1px solid #efefef;
  --td--3XS: 2rem;
  --td--2XS: 3rem;
  --td--XS: 4.5rem;
  --td--SM: 6.25rem;
  --td--std: 7.5rem;
  --td--MD: 9.375rem;
  --td--LG: 11.25rem;
  --td--XL: 12.5rem;
  --td--2XL: 15rem;
  --td--3XL: 16.25rem;
  --td--hand: 2.25rem;
  --td--ctrl: 2.625rem;
  --td--menu: 6rem;
  --row--height: 2.625rem;
  --tr--max: 2.625rem;
  --row--height--collapsed: 2.625rem;
  --row--height--collapsed-primary: 2.625rem;
  --thead--height: 2.625rem;
  --tr--head--max: 2.625rem;
  --dragDropZone--height: var(--row--height);
  --dragDropZone--shading: inset 0 0 0 3px #fff;
  --dragDropZone--bg: repeating-linear-gradient(-45deg, transparent, transparent 3px, var(--row--color-bg-dragged) 3px, var(--row--color-bg-dragged) 5px);
  --ctrl: var(--td--ctrl);
  --dnd: var(--td--hand);
}
.table {
  container-name: table;
  container-type: inline-size;
  position: relative;
  z-index: var(--viewLayer--static);
  width: 100%;
  border-bottom: var(--table--border-bottom);
}
.table:has(.is\:open) {
  z-index: var(--viewLayer--promoted);
}
.table--listTable {
  container-name: table listTable;
}
.table--fieldTable {
  container-name: table fieldTable;
}
.table.is\:hidden {
  display: none;
}
:not(:is(.is\:hidden, label)) ~ .table {
  margin-top: var(--table--blockgap);
}
.section__content .table:first-child {
  margin-top: var(--table--titlegap);
}
.table__row,
.table__header {
  display: grid;
  z-index: auto;
  transition: background-color 0.2s ease-out;
}
.table__row:hover,
.table__header:hover {
  background-color: var(--row--color-bg-hover);
}
.table__row:not(:nth-child(1)),
.table__header:not(:nth-child(1)) {
  border-top: var(--row--separator);
}
.table__cell {
  display: flex;
  align-items: center;
  padding: var(--cell--padding);
}
.table__cell:not(:first-child) {
  border-left: var(--cell--separator);
}
.table .for\:setOpen {
  display: none;
}
@container listTable (51.25rem <= width) {
  .table {
    --table--layoutState: expanded;
  }
  .table__row,
  .table__header {
    grid-template-columns: [id] minmax(var(--td--3XS), var(--td--2XS)) [main] minmax(var(--td--XL), 1.5fr) repeat(auto-fill, minmax(var(--td--std), 1fr)) [menu] minmax(var(--td--menu), min-content);
  }
  .table__header {
    grid-template-rows: minmax(var(--thead--height), min-content);
  }
  .table__row {
    grid-template-rows: minmax(var(--row--height), min-content);
  }
  .table__cell.for\:dragHandle {
    grid-column: hand;
  }
  .table__cell.for\:onDelete {
    grid-column: ctrl;
  }
  .table__cell.has\:menu {
    grid-column: menu;
  }
}
@container fieldTable (43.75rem <= width) {
  .table {
    --table--layoutState: expanded;
  }
  .table__row,
  .table__header {
    grid-template-columns: repeat(auto-fit, minmax(var(--td--std), 1fr)) [ctrl] minmax(var(--td--menu), min-content);
  }
  .has\:dragDrop .table__row,
  .has\:dragDrop .table__header {
    grid-template-columns: [hand] var(--td--ctrl) repeat(auto-fit, minmax(var(--td--std), 1fr)) [menu] minmax(var(--td--menu), min-content);
  }
  .table__header {
    grid-template-rows: minmax(var(--thead--height), min-content);
  }
  .table__row {
    grid-template-rows: minmax(var(--row--height), min-content);
  }
  .table__cell.for\:dragHandle {
    grid-column: hand;
  }
  .table__cell.for\:onDelete {
    grid-column: ctrl;
  }
  .table__cell.has\:menu {
    grid-column: menu;
  }
}
@container listTable (width < 51.25rem) {
  .table {
    --table--layoutState: collapsed;
  }
  .table .for\:setOpen {
    display: block;
  }
  .table__row:not(.is\:open ) {
    max-height: var(--row--height--collapsed-primary);
    overflow: hidden;
  }
  .table__header > .is\:primary {
    grid-column: 1 / span all;
  }
  .table__header > :not(.is\:primary) {
    display: none;
  }
  .table__row {
    grid-template-columns: [ctrl] var(--td--ctrl) [A] 1fr [menu] auto;
    grid-template-rows: [header] var(--row--height--collapsed-primary) repeat(auto-fill, var(--row--height--collapsed)) [footer] var(--row--height--collapsed);
  }
  :is( .table__cell) {
    grid-column: A;
    border-top: var(--cell--separator);
    border-left: var(--cell--separator);
  }
  :is( .table__cell).for\:dragHandle {
    grid-column: hand;
    grid-row: header;
  }
  :is( .table__cell).for\:setOpen {
    grid-column: ctrl;
    grid-row: header;
  }
  :is( .table__cell).for\:onDelete {
    grid-column: 1;
    grid-row: 2;
  }
  :is( .table__cell).has\:menu {
    grid-column: menu;
    grid-row: footer;
  }
  :is( .table__cell).is\:primary:not(.is\:primary ~ .is\:primary) {
    grid-row: header;
  }
  .table__row:not(:has(.for\:setOpen))::before {
    font: 1.5rem / 1 "showmanager-icons";
    content: "\e916";
    grid-column: ctrl;
    place-self: center center;
    transition: all 0.2s ease-out;
    color: var(--thead--color-text);
  }
  .table__row:not(:has(.for\:setOpen)).is\:open::before {
    transform: scale(1.5) rotate(-90deg);
    color: var(--color-primary);
  }
  .table__cell.has\:control:first-child {
    grid-column: hand;
  }
  .table__cell.has\:control:last-child {
    grid-column: ctrl;
  }
  .table__cell.has\:menu:last-child {
    grid-column: menu;
  }
  .table__cell.for\:setOpen {
    display: none !important;
    visibility: hidden !important;
  }
  .table__cell.for\:dragHandle {
    grid-column: hand;
  }
  .table__cell.for\:onDelete {
    grid-column: ctrl;
  }
}
@container fieldTable (width < 43.75rem) {
  .table {
    --table--layoutState: collapsed;
  }
  .table .for\:setOpen {
    display: block;
  }
  .table__row:not(.is\:open ) {
    max-height: var(--row--height--collapsed-primary);
    overflow: hidden;
  }
  .table__header > .is\:primary {
    grid-column: 1 / span all;
  }
  .table__header > :not(.is\:primary) {
    display: none;
  }
  .table__row {
    grid-template-columns: [ctrl] var(--ctrl) [A] 1fr [menu] auto;
    grid-template-rows: [header] var(--row--height--collapsed-primary) repeat(auto-fill, var(--row--height--collapsed));
  }
  .has\:dragDrop .table__row {
    grid-template-columns: [hand] var(--td--ctrl) [A] 1fr [ctrl] auto;
  }
  :is( .table__cell) {
    grid-column: A;
    border-top: var(--cell--separator);
    border-left: var(--cell--separator);
  }
  :is( .table__cell).is\:primary:not(.is\:primary ~ .is\:primary) {
    grid-row: header;
  }
  :is( .table__cell).for\:dragHandle {
    grid-column: hand;
    grid-row: header;
  }
  :is( .table__cell).for\:setOpen {
    grid-column: ctrl;
    grid-row: header;
  }
  :is( .table__cell).for\:onDelete {
    grid-column: 1;
    grid-row: 2;
  }
  :is( .table__cell).has\:menu {
    grid-column: menu;
    grid-row: footer;
  }
}
.table__header {
  text-align: left;
  color: var(--thead--color-text);
  border-bottom: var(--thead--separator);
}
.table__header .table__cell {
  --cell--separator: none;
  --cell--separator: var(--thead--cell--padding);
  font: var(--thead--font);
  background: none;
}
.table__header .table__cell.is\:sortable {
  cursor: pointer;
}
.table__footer {
  display: flex;
  justify-content: space-between;
  border-top: var(--row--separator);
}
.table__footer:hover {
  background-color: var(--row--color-bg-hover);
}
.table__action {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  padding: var(--addButton--padding);
  color: var(--addButton--color-text);
}
.table__dropZone {
  position: absolute;
  height: var(--dragDropZone-height);
  background: var(--dragDropZone--bg);
  border-top: var(--row--separator);
  box-shadow: var(--dragDropZone--shading);
}
.table__row.has\:error {
  border-left: 4px solid var(--color-text-error);
  background-color: #ffefed;
}
.table__row:has(.for\:onDelete:hover) {
  background-color: var(--color-bg-error-faded);
}
.table__row.is\:dragged {
  background-color: var(--color-bg);
  outline: 1px dashed #ccc;
  border-top: none;
  border-bottom: none;
}
.table__row .for\:setOpen [class*="icon:"] {
  background-color: transparent !important;
  color: currentColor;
}
.table__row .for\:setOpen [class*="icon:"],
.table__row .for\:setOpen [class*="icon:"]::before {
  transition: all 0.2s ease-out;
}
.table__row.is\:open .for\:setOpen [class*="icon:"] {
  color: var(--color-secondary);
}
.table__row.is\:open .for\:setOpen [class*="icon:"]::before {
  transform: scale(1.5);
}
.table__row .contextMenu\:tooltip {
  --cMenu--color-bg: #fff;
  --cMenu--color-text: #000;
  --cMenu--panel-shadow: 0 0.25rem 0.2rem rgba(0, 0, 0, 0.1), 0 0.5rem 0.666rem rgba(0, 0, 0, 0.2);
  --cMenu--tooltip-offset-y: -125%;
}
.table__cell a {
  text-decoration: none;
  color: inherit;
}
.table__cell.is\:dirty {
  border-bottom: 1px solid orange;
}
.table__cell.is\:dragging .linkedEntity__editLink {
  opacity: 0;
}
.table__cell.has\:error {
  border-bottom: 1px solid var(--color-text-error);
  background-color: var(--color-bg-error);
}
.table__cell.has\:value {
  cursor: not-allowed;
}
.table__cell.has\:value .value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table__cell.has\:control .button,
.table__cell.has\:menu .button {
  padding: var(--cell--pad-y);
}
.table__cell.has\:control [class^='icon:'],
.table__cell.has\:menu [class^='icon:'] {
  color: var(--table--control-color);
}
.table__cell.has\:control [class^='icon:']:hover,
.table__cell.has\:menu [class^='icon:']:hover {
  color: var(--table--control-hover);
}
.table__cell.has\:control .icon\:handle,
.table__cell.has\:menu .icon\:handle {
  color: var(--table--control-disabled);
}
.table__cell.for\:onDelete .button {
  --button--color-text: var(--color-text-faded);
  --button--color-text-hover: var(--color-text-error);
}
.table__cell.has\:input,
.table__cell.has\:control {
  padding: 0;
}
.table__cell.has\:input {
  align-items: stretch;
}
.table__cell.has\:input .input {
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  padding: var(--cell--pad-x);
}
.table__cell.has\:input .timeInput {
  --timeInput--icon-width: 1.75rem;
  --timeInput--input-margin: 0.375rem;
  --timeInput--icon-separator: none;
  --timeInput--icon-spacing: 0.125rem;
}
.table__cell.has\:input .select__control {
  border: none;
}
.table__cell.has\:input .field__input--checkbox {
  width: 100%;
  justify-content: center;
  font-size: 1rem;
}
.table__cell.has\:input .input--checkbox {
  margin: 0;
}
.table__cell.field\:status .entity {
  --entity--title-font: 500 0.875rem / 1.2857 var(--font-family);
  --entity--meta-font: 0.75rem / 1.5 var(--font-family);
}
.table__cell.field\:status .entity__title[class*="icon:"] {
  margin-left: -0.2em;
}
.table__cell.field\:status .entity__title.icon\:help,
.table__cell.field\:status .entity__title.icon\:accept {
  font-weight: 400;
  color: var(--color-text-muted);
}
.table__cell.field\:status .entity__meta {
  color: var(--color-text-faded);
}
.table__cellfield\:status.has\:statusTag {
  gap: 0.5rem;
}
.table__cellfield\:status.has\:statusTag .statusTag {
  --statusTag--font-size: 0.813rem;
  --statusTag--width: 2.5rem;
  --statusTag--height: 1.5rem;
}
.table__cell:first-child.has\:statusTag--ribbon {
  gap: 0.5rem;
  --cell--padding: 0.125rem var(--cell--pad-x) 0.125rem 0rem;
}
.table__cell:first-child.has\:statusTag--ribbon .statusTag {
  --statusTag--font-size: 0.75rem !important;
  --statusTag--width: 1.375rem;
  --statusTag--height: auto;
  align-self: stretch;
}
.table__cell:first-child.has\:statusTag--ribbon .statusTag + .statusTag {
  margin-left: -0.5rem;
}
.table__cell.has\:userEntities {
  --table-padding-y: 0.25rem;
  flex-flow: row wrap;
  gap: 0.375rem 0.188rem;
}
@container listTable (width < 51.25rem) {
  .table__cell.has\:userEntities .avatar {
    --avatar--size: 1.625rem;
    --avatar--placeholder-size: 0.75em;
    --avatar--spacing: 0;
  }
}
@container listTable (51.25rem <= width) {
  .table__cell.has\:userEntities .avatar {
    --avatar--size: 2rem;
    --avatar--placeholder-size: 0.938em;
    --avatar--spacing: 0;
  }
}
@media screen and (width < 64rem) {
  /* @AppUI--filterBar--fluid */
}
@media screen and (64rem <= width) {
  /* @AppUI--filterBar--fixed */
}
@media screen and (width < 33.75rem) {
  /* @filterUI--minimal */
}
@media screen and (33.75rem <= width < 50.625rem) {
  /* @filterUI--compact */
}
@media screen and (50.625rem <= width < 64rem) {
  /* @filterUI--hybrid */
}
@media screen and (64rem <= width < 90rem) {
  /* @filterUI--flexible */
}
@media screen and (90rem <= width < 120rem) {
  /* @filterUI--sectioned */
}
@media screen and (120rem <= width) {
  /* @filterUI--fullform */
}
CONFIG[filterBar],
.filterBar,
.showCalendar {
  --filterSlot--height: 3.375em;
  --filterSlot--padding-y: 0.5em;
  --filterSlot--padding-x: 0.5em;
  --filterSlot--separator-yPos: 3.313em;
  --filterBar--color-separator: var(--color-bg-faded);
  --input--rounding: 0.188em;
  --filterBar--bgPattern: repeating-linear-gradient(to bottom, var(--color-bg) 0, var(--color-bg) var(--filterSlot--separator-yPos), var(--filterBar--color-separator) var(--filterSlot--height));
}
.filterBar {
  display: flex;
  position: relative;
  z-index: var(--filterBar--stackingContext);
  border-bottom: 1px solid var(--filterBar--color-separator);
}
@media screen and (64rem <= width) {
  .filterBar {
    flex-flow: row nowrap;
    align-items: center;
  }
}
.filterBar__content {
  width: 100%;
  height: 100%;
}
@media screen and (width < 64rem) {
  .filterBar__content {
    max-height: var(--filterSlot--height);
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: var(--filterSlot--height) 4em auto;
    grid-template-areas: "searchBox touchControls toolbarMenu" "dateRange dateRange dateRange" "queryBuilder queryBuilder queryBuilder";
  }
  .is\:open .filterBar__content {
    max-height: 100%;
    overflow: visible;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  }
  .view--calendar .filterBar__content {
    grid-template-rows: var(--filterSlot--height) auto;
    grid-template-areas: "searchBox touchControls toolbarMenu" "queryBuilder queryBuilder queryBuilder";
  }
}
@media screen and (64rem <= width) {
  .filterBar__content {
    max-height: auto;
    overflow: visible;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: minmax(var(--filterSlot--height), auto);
    grid-template-areas: "searchBox dateRange queryBuilder toolbarMenu";
    background: repeating-linear-gradient(to bottom, var(--color-bg) 0, var(--color-bg) var(--filterSlot--separator-yPos), var(--filterBar--color-separator) var(--filterSlot--height));
  }
}
.filterBar__section--searchBox {
  grid-area: searchBox;
}
.filterBar__section--toolbarMenu {
  grid-area: toolbarMenu;
}
.filterBar__section--touchControls {
  grid-area: touchControls;
}
.filterBar__section--dateRange {
  grid-area: dateRange;
}
.filterBar__section--queryBuilder {
  grid-area: queryBuilder;
}
@media screen and (64rem <= width) {
  .filterBar__section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: flex-start;
  }
}
@media screen and (width < 64rem) {
  .filterBar__exportMenu.is\:open .contextMenu__panel {
    position: fixed !important;
    top: calc(var(--viewMobile--toolbar--height) + var(--filterSlot--height)) !important;
    left: auto !important;
    right: var(--filterSlot--padding-x) !important;
    transform: none !important;
  }
}
@media screen and (width < 43.75rem) {
  .filterBar__exportMenu.is\:open .contextMenu__panel {
    width: 94vw;
    max-width: 17.5rem;
  }
}
@media screen and (43.75rem <= width) {
  .filterBar__exportMenu.is\:open .contextMenu__panel {
    width: auto;
  }
}
@media screen and (width < 23.75rem) {
  .filterBar__mobileToggle.button {
    --button--icon-spacing: 0;
    --button--padding-x-icon: var(--button--padding-y);
    --button--padding-x: var(--button--padding-y);
  }
  .filterBar__mobileToggle span {
    display: none;
  }
}
@media screen and (33.75rem <= width < 50.625rem) {
  .filterBar__section--queryBuilder {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.25em, 1fr));
  }
  .filterBar__section--queryBuilder .filterBar__slot--action {
    grid-column: 1 / -1;
  }
}
@media screen and (50.625rem <= width < 64rem) {
  .filterBar__content {
    max-height: var(--filterSlot--height);
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: var(--filterSlot--height) auto;
    grid-template-areas: "searchBox dateRange touchControls toolbarMenu" "queryBuilder queryBuilder queryBuilder queryBuilder";
  }
  .is\:open .filterBar__content {
    max-height: 100%;
    overflow: visible;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
  }
  .filterBar__section--touchControls .filterBar__action,
  .filterBar__section--touchControls .filterBar__action .button {
    width: 100%;
    justify-content: flex-start;
  }
  .filterBar__section--queryBuilder {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
    border-top: 0.313em solid #A5CC2E;
  }
  .filterBar__section--dateRange {
    border-top: none !important;
  }
  .filterBar .swapFilter__body {
    width: 16.8em !important;
  }
  .filterBar .swapFilter__button {
    border: 1px solid #999 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background: rgba(0, 0, 0, 0.05) !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .filterBar .swapFilter .dateInput {
    max-width: 16em !important;
  }
}
@media screen and (64rem <= width < 90rem) {
  .filterBar__content {
    display: flex;
    flex-flow: row wrap;
  }
  .filterBar__section {
    display: contents !important;
  }
  .filterBar__section--queryBuilder {
    flex-flow: row wrap;
  }
  .filterBar__section--queryBuilder .filterBar__slot {
    order: 10;
  }
  .filterBar__slot {
    order: 5;
  }
  .filterBar__section--toolbarMenu .filterBar__slot {
    order: 1;
    border-right: 1px solid var(--filterBar--color-separator);
  }
  .filterBar__section--touchControls > * {
    display: none !important;
    visibility: hidden !important;
  }
}
@media screen and (90rem <= width < 120rem) {
  .filterBar {
    position: sticky;
  }
  .filterBar__content {
    max-width: 100%;
    max-height: auto;
    overflow: visible;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: minmax(var(--filterSlot--height), auto);
    grid-template-areas: "searchBox dateRange queryBuilder toolbarMenu";
    background: repeating-linear-gradient(to bottom, var(--color-bg) 0, var(--color-bg) var(--filterSlot--separator-yPos), var(--filterBar--color-separator) var(--filterSlot--height));
  }
}
.filterBar__section--toolbarMenu {
  margin-left: auto;
  margin-right: 0;
}
.filterBar.is\:open .filterBar__section--touchControls .filterBar__slot {
  background-color: #A5CC2E;
}
.filterBar.is\:open .filterBar__section--touchControls .filterBar__slot .button {
  color: var(--color-secondary);
}
.filterBar.is\:open .filterBar__section--touchControls .filterBar__slot .button::before {
  content: "\e925";
}
@media screen and (64rem <= width) {
  .filterBar__section--touchControls {
    display: none;
    visibility: hidden;
  }
  .filterBar__section--queryBuilder {
    display: flex;
    flex-flow: row wrap;
  }
  .filterBar__section--queryBuilder .filterBar__slot {
    order: 10;
  }
  .filterBar__section--queryBuilder .filterBar__slot:has(.is\:pinned) {
    order: 9;
  }
}
.filterBar__slot {
  display: flex;
  align-items: center;
  height: var(--filterSlot--height);
  min-height: var(--filterSlot--height);
  padding: var(--filterSlot--padding-y) var(--filterSlot--padding-x);
  border-left: 1px solid var(--filterBar--color-separator);
  transition: all 0.16s ease-out;
}
.filterBar__section:first-child .filterBar__slot:first-child {
  border-left: 0;
}
.filterBar__section--queryBuilder .filterBar__slot {
  padding: 0.375em 0;
}
.filterBar__section--queryBuilder .filterBar__slot:hover,
.filterBar__section--queryBuilder .filterBar__slot:has(.contextMenu.is\:open) {
  background-color: rgba(255, 244, 199, 0.25);
}
.filterBar__section--queryBuilder .filterBar__slot--action:hover,
.filterBar__section--queryBuilder .filterBar__slot--action:has(.contextMenu.is\:open) {
  background-color: rgba(165, 204, 46, 0.25);
}
@media screen and (width < 64rem) {
  .filterBar__section--queryBuilder .filterBar__slot {
    border-top: 1px solid var(--filterBar--color-separator);
  }
  .filterBar__section--queryBuilder .filterBar__slot--action {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.filterBar__addMenu {
  flex: 1;
}
.filterBar__addMenu.contextMenu {
  --cMenu--font-size: 1em;
  --cMenu--color-bg: var(--color-bg);
  --cMenu--color-bg-hover: rgba(165, 204, 46, 0.25);
  --cMenu--color-text: var(--color-text);
  --cMenu--color-text-hover: var(--color-secondary);
  --cMenu--color-icon: rgba(165, 204, 46, 0.4);
  --cMenu--color-icon-hover: var(--color-primary);
  --cMenu--panel-rounding: 0.333em;
  --cMenu--item-padding-x: 1em;
  --cMenu--item-icon-padding-x: 0.625em;
  --cMenu--item-padding-y: 0.625em;
  --cMenu--item-touch-min-height: 3em;
  --cMenu--item-firstlast-padding-y: 0.75em;
}
.filterBar__addMenu .button {
  color: #006A58;
}
.filterBar__addMenu.contextMenu.is\:open .contextMenu__trigger {
  background: transparent;
}
.filterBar__filter {
  display: flex;
  align-items: center;
  height: var(--filterSlot--height);
  min-height: var(--filterSlot--height);
}
.filterBar__filter.is\:pinned {
  --marker-size: 0.375rem;
  --marker-color: #A5CC2E;
  --inset: 1.5px;
  position: relative;
}
.filterBar__filter.is\:pinned::before {
  content: '';
  display: block;
  position: absolute;
  top: var(--inset);
  left: var(--inset);
  border: var(--marker-size) solid var(--marker-color);
  border-right-color: transparent;
  border-bottom-color: transparent;
  box-sizing: border-box;
}
.filterBar__filter .select__control {
  display: flex;
  height: 100%;
}
.filterBar__filter:focus-within .filterBar__filterMenu,
.filterBar__filter:hover .filterBar__filterMenu {
  opacity: 1;
  visibility: visible;
}
@media screen and (width < 64rem) {
  .filterBar__filter {
    width: 100%;
  }
  .filterBar__filter .select {
    flex: 1;
    padding: 0 0.666em 0 1em;
    margin: 0 !important;
  }
  .filterBar__filter .select__value {
    flex: 1;
    display: flex !important;
    justify-content: space-between;
    padding-right: 0 !important;
  }
  .filterBar__filter .select__value:last-child {
    padding-right: 1.625em !important;
  }
  .filterBar__filter .select__valueCount {
    margin-left: auto;
    margin-right: 0;
  }
  .filterBar__filter .select__indicators {
    width: 1.25em;
    margin-left: 0.375em;
  }
}
.filterBar__filterMenu {
  transition: all 0.2s ease-out;
}
.filterBar__filterMenu.is\:open {
  z-index: var(--viewLayer--isOpen);
}
.filterBar__filterMenu.contextMenu {
  --cMenu--color-bg: #fff;
  --cMenu--color-bg-hover: rgba(165, 204, 46, 0.15);
  --cMenu--color-text: #006A58;
  --cMenu--color-text-hover: #006A58;
  --cMenu--color-icon: #A5CC2E;
  --cMenu--color-icon-hover: #006A58;
  --cMenu--panel-rounding: 0.333em;
  --cMenu--item-padding-y: 0.714em;
  --cMenu--item-firstlast-padding-y: 0.857em;
}
.filterBar__filterMenu .contextMenu__item--unpin {
  --cMenu--color-bg: rgba(0, 106, 88, 0.1) !important;
  --cMenu--color-icon: #006A58;
  --cMenu--color-icon-hover: rgba(0, 106, 88, 0.2);
}
@media screen and (width < 64rem) {
  .filterBar__filterMenu {
    margin-right: 0;
    margin-left: auto;
  }
}
@media screen and (64rem <= width) {
  .filterBar__filterMenu {
    opacity: 0;
    visibility: hidden;
  }
}
.filterBar__filterMenu .contextMenu__trigger .button {
  height: var(--filterSlot--height);
  border-radius: 0;
  background-color: rgba(255, 244, 199, 0.2);
  color: #999;
  transition: all 0.13s ease-out;
}
@media screen and (width < 64rem) {
  .filterBar__filterMenu .contextMenu__trigger .button {
    width: var(--filterSlot--height);
  }
}
@media screen and (64rem <= width) {
  .filterBar__filterMenu .contextMenu__trigger .button {
    width: 1.5em;
    margin-left: 0.333em;
  }
}
.filterBar__filterMenu:hover .contextMenu__trigger .button {
  background-color: #e9f2cb;
  color: var(--color-secondary);
}
.filterBar__filterMenu.contextMenu.is\:open .contextMenu__trigger {
  background: transparent;
}
@media screen and (width < 64rem) {
  .filterBar__section--dateRange {
    border-top: 0.313em solid #A5CC2E;
    padding-bottom: 0;
  }
  .filterBar__section--dateRange .filterBar__slot {
    height: 100%;
  }
  .filterBar .filter--dateRange {
    width: 100%;
  }
  .filterBar .filter--dateRange .filter__body {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }
  .filterBar .filter--dateRange .filter__body .dateInput {
    flex: 1;
    max-width: none !important;
  }
}
.filterBar .swapFilter,
.filterBar .swapFilter__body {
  display: flex;
  flex-flow: row nowrap;
}
@media screen and (width < 64rem) {
  .filterBar .swapFilter,
  .filterBar .swapFilter__body {
    width: 100%;
  }
  .filterBar .swapFilter__button {
    display: none;
    visibility: hidden;
  }
}
@media screen and (64rem <= width) {
  .filterBar .swapFilter__button {
    height: auto;
    width: var(--button--height);
    color: var(--color-primary);
    border: 1px solid #bbb;
    background-color: transparent;
    transition: all 0.2s ease-out;
  }
  .filterBar .swapFilter__button:hover {
    background-color: var(--color-primary-soft);
    color: var(--color-secondary);
  }
  .filterBar .swapFilter__button::before {
    font-size: 1.313em;
  }
  .filterBar .swapFilter.is\:open .swapFilter__button {
    border: 1px solid #999;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.25);
  }
  .filterBar .swapFilter__body {
    width: 0px;
    overflow: hidden;
  }
  .filterBar .swapFilter__body > *:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
  .filterBar .swapFilter.is\:open .swapFilter__body {
    width: 16em;
    overflow: visible;
  }
}
.filterBar .dateInput:first-of-type {
  border-right: none;
  border-radius: 0;
}
@media screen and (width < 64rem) {
  .filterBar .dateInput:first-of-type {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}
@media screen and (64rem <= width) {
  .filterBar .dateInput:first-of-type {
    max-width: 8em;
    border-left: 0;
  }
  .filterBar .dateInput:first-of-type::before {
    display: none;
  }
}
.filterBar .dateInput:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filterBar .dateInput:last-of-type::before {
  display: none;
}
@media screen and (64rem <= width) {
  .filterBar .dateInput:last-of-type {
    max-width: 8em;
  }
}
.filterBar .pagination {
  margin-bottom: 0;
  margin-right: 0;
  margin-left: auto;
}
.filterBar .input--search {
  max-width: 100%;
  width: 100%;
}
.filterBar .select {
  --valueCount--bg-color: #e0e0e0;
  --valueCount--text-color: #666;
  cursor: pointer;
}
@media screen and (64rem <= width) {
  .filterBar .select {
    margin-left: 0.313em;
  }
}
.filterBar .select__value {
  padding-right: 0.25em;
}
.filterBar .select__valueCount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.75em;
  border-radius: 0.188em;
  margin-left: 0.313em;
  background-color: var(--valueCount--bg-color);
  color: var(--valueCount--text-color);
  opacity: 0.5;
}
.filterBar .select.has\:value .select__valueCount {
  --valueCount--text-color: #000;
  --valueCount--bg-color: #FFF4C7;
  opacity: 1;
}
.filterBar .select__indicators {
  display: none;
  visibility: hidden;
}
.filterBar .select.is\:open .select__value {
  visibility: visible;
}
.filterBar input {
  height: 100%;
  min-height: var(--input--height);
}
CONFIG[pagingBar],
.pagingBar {
  --pagingBar--top-separator: 1px solid var(--color-bg-faded);
  --pagingBar--top-padding: 0.375em 1em 0.375em 1em;
  --pagingBar--bottom-separator: 1px solid var(--color-bg-faded);
  --pagingBar--bottom-padding: 0.5em 1em 0.25em 1em;
}
.pagingBarTable {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  min-height: 100%;
}
.pagingBar {
  container: pagingBar / inline-size;
  position: relative;
  z-index: var(--pagingBar--stackingContext, 1);
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding: var(--pagingBar--top-padding);
  border-bottom: var(--pagingBar--top-separator);
}
.pagingBar--bottom {
  padding: var(--pagingBar--top-padding);
  margin-top: 2em;
  border-bottom: none;
  border-top: var(--pagingBar--bottom-separator);
}
.pagingBar .pageSize {
  max-width: 3.75em;
}
.pagingBar .pageSize .select__control {
  border: none;
  min-height: 1.5em;
}
.pagingBar__indicator {
  color: #999;
  font-size: 0.875rem;
}
.pagingBar__indicator em,
.pagingBar__indicator b {
  font-weight: normal;
  text-decoration: none;
}
.pagingBar__indicator em {
  font-weight: 500;
}
@container pagingBar (width < 40rem) {
  .pagingBar .pageSize {
    margin-left: -0.5rem;
  }
  .pagingBar b {
    display: none;
  }
  .pagingBar em::before {
    content: "(";
  }
  .pagingBar em::after {
    content: ")";
  }
}
.pagingBar .pagination {
  margin-left: auto;
  margin-bottom: 0;
}
.entity {
  flex-flow: column nowrap;
  align-items: flex-start;
  --entity--title-font: bold 1rem / 1.25 var(--font-family);
  --entity--meta-font: 0.8125rem / 1.5385 var(--font-family);
  --entity--soldOut-icon: "\e929";
  --entity--soldOut-color: var(--color-status-con);
  --entity--soldOut-font-size: 1rem;
  --entity--showSold-icon: "\e92c";
  --entity--showSold-color: var(--color-text-faded);
  --entity--showSold-font-size: 1rem;
}
.entity__title {
  font: var(--entity--title-font);
}
.entity__title[class*="icon:"] {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.3em;
}
.entity__title[class*="icon:"]::before {
  font-size: 1.25em !important;
  line-height: 0.875 !important;
}
.entity__meta {
  font: var(--entity--meta-font);
  color: var(--color-text-muted);
}
.entity__meta em {
  font-weight: 500;
}
.entity__title.icon\:help::before {
  color: var(--palette--neutral-200);
}
.entity__title.icon\:accept::before {
  color: var(--palette--neutral-350);
}
.entity__title.icon\:date::before {
  color: var(--color-text-highlight-faded);
}
.entity__title.icon\:public::before {
  color: var(--color-primary);
}
.entity__title.icon\:directions::before {
  color: var(--color-primary);
}
.entity.is\:soldOut .entity__title,
.entity.is\:OwnershipSold .entity__title {
  display: flex;
  align-items: center;
  gap: 0.125em;
}
.entity.is\:soldOut .entity__title::before,
.entity.is\:OwnershipSold .entity__title::before,
.entity.is\:soldOut .entity__title::after,
.entity.is\:OwnershipSold .entity__title::after {
  order: 1;
  margin-top: -0.1em;
}
.entity.is\:soldOut .entity__title::after {
  content: var(--entity--soldOut-icon);
  color: var(--entity--soldOut-color);
  font-size: var(--entity--soldOut-font-size);
  line-height: 1 !important;
}
.entity.is\:OwnershipSold .entity__title::before {
  content: var(--entity--showSold-icon);
  color: var(--entity--showSold-color);
  font-size: var(--entity--showSold-font-size);
  line-height: 1 !important;
  margin-left: 0.125em;
}
.messageBox {
  grid-column: 1 / span all;
  background-color: #FFF4C7;
  color: #978017;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 1rem 1.5rem;
  margin: 0.5rem;
}
.messageBox__header {
  margin: -1rem -1.5rem 0.625rem;
  padding: 0.313rem 0.75rem;
  background-color: #978017;
  color: #FFF4C7;
}
.messageBox__items {
  list-style: inside;
}
.messageBox label {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.messageBox label:hover {
  text-decoration: none;
}
.messageBox--error {
  background-color: #ffdeda;
  color: #c12414;
  border-color: #c12414;
}
.messageBox--error .messageBox__header {
  background-color: #c12414;
  color: #ffdeda;
}
CONFIG[showCalendar],
.showCalendar {
  --showCalendar--border: 1px solid #ccc;
  --showCalendar--header-font: bold 1.1875rem / 1.5789 'Fira Sans', sans-serif;
  --showCalendar--header-padding: 0.5rem 0.75rem;
  --showCalendar--header-height: 3rem;
  --showCalendar--nav-icon-size: 1.75rem;
  --showCalendar--dateNum-font: bold 1.125rem / 1.3333 'Fira Sans', sans-serif;
  --showCalendar--weekDay-font: 0.875rem / 1.7143 'Fira Sans', sans-serif;
  --showCalendar--weekDay-color: var(--color-text-muted);
  --showCalendar--otherMonth-color: var(--color-text-faded);
  --showCalendar--day-padding: 0.375rem;
  --showCalendar--day-label-padding: 0.375rem;
  --showCalendar--week-separator: 0.188rem solid #ccc;
  --showCalendar--color-bg: #f9f9f9;
  --showCalendar--color-bg-header: var(--color-bg);
  --showCalendar--color-bg-day: var(--color-bg);
  --showCalender--color-bg-dayEmpty: var(--color-bg);
  --showCalendar--entry-font: 0.875rem / 1.2857 'Fira Sans', sans-serif;
  --showCalendar--entry-padding: 0.375rem 0.5rem;
  --showCalendar--entry-rounding: 0.1em;
  --showCalendar--entry-spacing: 0.188rem;
}
.showCalendar {
  container-name: showCalendar;
  container-type: inline-size;
  position: relative;
  z-index: var(--viewLayer--static);
  min-height: 100%;
  background: var(--showCalendar--color-bg);
}
.showCalendar__toolbar {
  padding: var(--showCalendar--header-padding);
  height: var(--showCalendar--header-height);
  background: var(--showCalendar--color-bg-header);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.showCalendar__currentMonth {
  font: var(--showCalendar--header-font);
  margin: 0 0.5rem;
}
.showCalendar__navButton:before {
  font-size: var(--showCalendar--nav-icon-size);
  cursor: pointer;
}
@container showCalendar (68.75rem <= width) {
  .showCalendar__grid {
    --chrome: calc(var(--viewStatic--toolbar--height) + var(--filterSlot--height) + var(--showCalendar--header-height));
    --weekMinH: calc(calc(100vh - var(--chrome)) / 6);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(var(--weekMinH, 6rem), auto);
  }
}
@container showCalendar (50rem <= width < 68.75rem) {
  .showCalendar__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(3rem, auto);
  }
  .showCalendar__day:is(.day\:1, .day\:4, .day\:7) {
    grid-column: 1;
  }
  .showCalendar__day:is(.day\:1, .day\:2, .day\:3) {
    border-top: var(--showCalendar--week-separator);
  }
}
@container showCalendar (37.5rem <= width < 50rem) {
  .showCalendar__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(3rem, auto);
  }
  .showCalendar__day:is(.day\:1, .day\:3, .day\:5, .day\:7) {
    grid-column: 1;
  }
  .showCalendar__day:is(.day\:1, .day\:2) {
    border-top: var(--showCalendar--week-separator);
  }
}
.showCalendar__grid {
  border-bottom: var(--showCalendar--border);
}
.showCalendar__day {
  border-right: var(--showCalendar--border);
  border-top: var(--showCalendar--border);
  padding: var(--showCalendar--day-padding);
  background: var(--showCalendar--color-bg-day);
  overflow: hidden;
}
.showCalendar__dayLabel {
  margin-bottom: 0.25rem;
  padding-left: 0.375rem;
}
.showCalendar__dayNum {
  font: var(--showCalendar--dateNum-font);
  margin-right: 0.25em;
}
.showCalendar__dayShort {
  font: var(--showCalendar--weekDay-font);
  color: var(--showCalendar--weekDay-color);
}
.showCalendar__dayContent {
  overflow-y: auto;
}
.showCalendar__day--otherMonth .showCalendar__dayLabel {
  color: var(--showCalendar--otherMonth-color);
}
.showCalendar__day--today {
  /* TODO */
}
.showCalendar__day:has(.showCalendar__dayContent:empty) {
  background: var(--showCalender--color-bg-dayEmpty);
}
.showCalendar__event {
  font: var(--showCalendar--entry-font);
  padding: var(--showCalendar--entry-padding);
  border-radius: var(--showCalendar--entry-rounding);
  cursor: pointer;
  background-color: #e0e0e0;
  border: none;
}
.showCalendar__event + .showCalendar__event {
  margin-top: var(--showCalendar--entry-spacing);
}
.showCalendar__event.status\:announced {
  background-color: var(--color-status-ann);
  border-color: var(--color-status-ann);
}
.showCalendar__event.status\:pendingAnnouncement {
  background-color: var(--color-status-tba);
  border-color: var(--color-status-tba);
}
.showCalendar__event.status\:pendingConfirmation {
  background-color: var(--color-status-tbc);
  border-color: var(--color-status-tbc);
}
.showCalendar__event.status\:confirmed {
  background-color: var(--color-status-con);
  border-color: var(--color-status-con);
}
.showCalendar__event.status\:canceled {
  background-color: var(--color-status-abs);
  border-color: var(--color-status-ann);
}
.showCalendar__event.is\:announcementDate {
  background-color: transparent;
  border-style: solid;
  border-width: 0.125rem;
  padding: 0.25rem 0.375rem;
  display: flex;
  flex-flow: column-reverse nowrap;
}
.showCalendar__event.is\:announcementDate .showCalendar__eventTitle {
  color: var(--color-text-muted);
  font-weight: normal;
}
.showCalendar__event.is\:announcementDate .showCalendar__eventMeta {
  font-weight: 500;
  opacity: 1;
}
.showCalendar__event a {
  text-decoration: none;
}
.showCalendar__eventTitle {
  font-weight: bold;
  color: var(--color-text);
}
.showCalendar__eventMeta {
  opacity: 0.5;
}
.statusTag {
  --statusTag--font-size: 1.125rem;
  --statusTag--width: 3.375rem;
  --statusTag--height: 2rem;
  --statusTag--padding: 0;
  --statusTag--rounding: 0.313rem;
  --statusTag--bg: #fff;
  --statusTag--text: #000;
  --statusTag--border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--statusTag--rounding);
  width: var(--statusTag--width);
  height: var(--statusTag--height);
  padding: var(--statusTag--padding);
  font-size: var(--statusTag--font-size);
  line-height: var(--statusTag--height);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--statusTag--text);
  background: var(--statusTag--bg);
  border: var(--statusTag--border);
}
.statusTag.TBC,
.statusTag.status\:pendingConfirmation {
  --statusTag--bg: #e0e0e0;
  --statusTag--text: #707070;
}
.statusTag.CON,
.statusTag.status\:confirmed {
  --statusTag--bg: #FFB648;
  --statusTag--text: #805b24;
}
.statusTag.TBA,
.statusTag.status\:pendingAnnouncement {
  --statusTag--bg: #FFE874;
  --statusTag--text: #80743a;
}
.statusTag.ANN,
.statusTag.status\:announced {
  --statusTag--bg: #A6E97A;
  --statusTag--text: #53753d;
}
.statusTag.ARX,
.statusTag.status\:archived {
  --statusTag--bg: #CCBCFF;
  --statusTag--text: #665e80;
}
.statusTag.ABS,
.statusTag.status\:absent {
  --statusTag--bg: #df786c;
  --statusTag--text: #703c36;
}
.statusTag.ANN.status\:pendingUpdate {
  --statusTag--bg: linear-gradient(90deg, #A6E97A 0 50%, #FFE874 50% 100%);
  --statusTag--text: #53753d;
}
.table__cell.has\:statusTag--ribbon .statusTag,
.statusTag--ribbon {
  --statusTag--font-size: 1rem;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  padding: 0.666em 0.333em;
  justify-content: start;
  line-height: 1;
  border-radius: 0;
}
.statusTag--medium {
  --statusTag--padding: 0rem 0.625rem;
  --statusTag--font-size: 1rem;
  --statusTag--height: 1.75rem;
}
.statusTag--small {
  --statusTag--padding: 0rem 0.375rem;
  --statusTag--font-size: 0.875rem;
  --statusTag--height: 1.375rem;
  --statusTag--width: 3rem;
  --statusTag--rounding: 0.188rem;
}
.linkedEntity {
  display: flex;
  align-items: center;
}
.linkedEntity__name {
  margin-right: 0.5rem;
}
.linkedEntity__editLink {
  padding: 0.063rem 0.625rem;
  border-radius: 0.125rem;
  background: #ccc;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
[class*="linkedEntity"]:not(:hover) .linkedEntity__editLink,
[class*="has:linkedEntity"]:not(:hover) .linkedEntity__editLink {
  opacity: 0;
}
CONFIG[avatar],
.avatar {
  --avatar--size: 1.875rem;
  --avatar--size--small: 1.5rem;
  --avatar--size--large: 2.25rem;
  --avatar--spacing: 0.25rem;
  --avatar--placeholder-font: inherit;
  --avatar--placeholder-size: 1em;
  --avatar--placeholder-weight: 500;
  --avatar--color-bg: #ccc;
  --avatar--color-text: #fff;
  --avatar--hover-scale: 1.1;
  --avatar--hover-transition: transform 0.2s ease;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: default;
  border-radius: 100%;
  width: var(--avatar--size);
  height: var(--avatar--size);
  margin-right: var(--avatar--spacing);
  color: var(--avatar--color-text);
  background: var(--avatar--color-bg);
}
.avatar img {
  width: 100%;
  object-fit: cover;
}
.avatar--placeholder {
  font: var(--avatar--placeholder-font);
  font-size: var(--avatar--placeholder-size);
  font-weight: var(--avatar--placeholder-weight);
}
.avatar:hover {
  transform: scale(var(--avatar--hover-scale));
  transition: var(--avatar--hover-transition);
}
.avatar--small {
  --avatar-size: var(--avatar--size--small);
}
.avatar--large {
  --avatar-size: var(--avatar--size--large);
}
[class*="avatarColor--"] {
  --color-marine-bg: hsl(200, 45%, 83%);
  --color-marine-fg: hsl(200, 45%, 63%);
  --color-lagoon-bg: hsl(175, 45%, 82%);
  --color-lagoon-fg: hsl(175, 45%, 62%);
  --color-emerald-bg: hsl(150, 45%, 83%);
  --color-emerald-fg: hsl(150, 45%, 63%);
  --color-spring-bg: hsl(125, 45%, 82%);
  --color-spring-fg: hsl(125, 45%, 62%);
  --color-cedar-bg: hsl(100, 45%, 83%);
  --color-cedar-fg: hsl(100, 45%, 63%);
  --color-flax-bg: hsl(75, 45%, 84%);
  --color-flax-fg: hsl(75, 45%, 64%);
  --color-amber-bg: hsl(50, 45%, 83%);
  --color-amber-fg: hsl(50, 45%, 63%);
  --color-coral-bg: hsl(25, 45%, 82%);
  --color-coral-fg: hsl(25, 45%, 62%);
  --color-terra-bg: hsl(0, 45%, 83%);
  --color-terra-fg: hsl(0, 45%, 63%);
  --color-rose-bg: hsl(335, 45%, 82%);
  --color-rose-fg: hsl(335, 45%, 62%);
  --color-orchid-bg: hsl(310, 45%, 83%);
  --color-orchid-fg: hsl(310, 45%, 63%);
  --color-violet-bg: hsl(285, 45%, 82%);
  --color-violet-fg: hsl(285, 45%, 62%);
  --color-azure-bg: hsl(260, 45%, 83%);
  --color-azure-fg: hsl(260, 45%, 63%);
  --color-cobalt-bg: hsl(235, 45%, 82%);
  --color-cobalt-fg: hsl(235, 45%, 62%);
  --color-ocean-bg: hsl(210, 45%, 83%);
  --color-ocean-fg: hsl(210, 45%, 63%);
  --color-steel-bg: hsl(185, 45%, 82%);
  --color-steel-fg: hsl(185, 45%, 62%);
}
.avatarColor--01 {
  --avatar--color-text: var(--color-marine-fg);
  --avatar--color-bg: var(--color-marine-bg);
}
.avatarColor--02 {
  --avatar--color-text: var(--color-lagoon-fg);
  --avatar--color-bg: var(--color-lagoon-bg);
}
.avatarColor--03 {
  --avatar--color-text: var(--color-emerald-fg);
  --avatar--color-bg: var(--color-emerald-bg);
}
.avatarColor--04 {
  --avatar--color-text: var(--color-spring-fg);
  --avatar--color-bg: var(--color-spring-bg);
}
.avatarColor--05 {
  --avatar--color-text: var(--color-cedar-fg);
  --avatar--color-bg: var(--color-cedar-bg);
}
.avatarColor--06 {
  --avatar--color-text: var(--color-flax-fg);
  --avatar--color-bg: var(--color-flax-bg);
}
.avatarColor--07 {
  --avatar--color-text: var(--color-amber-fg);
  --avatar--color-bg: var(--color-amber-bg);
}
.avatarColor--08 {
  --avatar--color-text: var(--color-coral-fg);
  --avatar--color-bg: var(--color-coral-bg);
}
.avatarColor--09 {
  --avatar--color-text: var(--color-terra-fg);
  --avatar--color-bg: var(--color-terra-bg);
}
.avatarColor--10 {
  --avatar--color-text: var(--color-rose-fg);
  --avatar--color-bg: var(--color-rose-bg);
}
.avatarColor--11 {
  --avatar--color-text: var(--color-orchid-fg);
  --avatar--color-bg: var(--color-orchid-bg);
}
.avatarColor--12 {
  --avatar--color-text: var(--color-violet-fg);
  --avatar--color-bg: var(--color-violet-bg);
}
.avatarColor--13 {
  --avatar--color-text: var(--color-azure-fg);
  --avatar--color-bg: var(--color-azure-bg);
}
.avatarColor--14 {
  --avatar--color-text: var(--color-cobalt-fg);
  --avatar--color-bg: var(--color-cobalt-bg);
}
.avatarColor--15 {
  --avatar--color-text: var(--color-ocean-fg);
  --avatar--color-bg: var(--color-ocean-bg);
}
.avatarColor--16 {
  --avatar--color-text: var(--color-steel-fg);
  --avatar--color-bg: var(--color-steel-bg);
}
.userEntity {
  display: flex;
  align-items: center;
  --unassignedUsers--color-bg: transparent;
  --unassignedUsers--color-text: #c12414;
  --unassignedUsers--color-border: rgba(193, 36, 20, 0.7);
  --additionalUsers--color-bg: transparent;
  --additionalUsers--color-text: #006A58;
  --additionalUsers--color-border: rgba(0, 106, 88, 0.6);
}
.userEntity__name {
  font-weight: bold;
}
.userEntity--infoCounter .avatar {
  border: 0.125em solid var(--avatar--color-border);
}
.userEntity--infoCounter.is\:unassigned .avatar {
  --avatar--color-bg: var(--unassignedUsers--color-bg);
  --avatar--color-text: var(--unassignedUsers--color-text);
  --avatar--color-border: var(--unassignedUsers--color-border);
  border-style: dotted;
}
.userEntity--infoCounter.is\:additional .avatar {
  --avatar--color-bg: var(--additionalUsers--color-bg);
  --avatar--color-text: var(--additionalUsers--color-text);
  --avatar--color-border: var(--additionalUsers--color-border);
}
.userEntity--infoCounter.is\:additional .avatar > span {
  margin-left: -0.125em;
}
.userEntity--avatar + .userEntity--infoCounter {
  margin-left: 0.5rem;
}
CONFIG[contextMenu],
.contextMenu,
[class*='contextMenu:'],
[data-tooltip] {
  --cMenu--stackingContext: var(--viewLayer--inputs);
  --tooltip--stackingContext: var(--viewLayer--tooltip);
  --cMenu--font: inherit;
  --cMenu--font-size: 0.875rem;
  --cMenu--panel-padding: 0;
  --cMenu--panel-border: none;
  --cMenu--panel-rounding: 0.214em;
  --cMenu--panel-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.2);
  --cMenu--item-separator: none;
  --cMenu--item-padding-x: 1em;
  --cMenu--item-icon-padding-x: 0.666em;
  --cMenu--item-padding-y: 0.75em;
  --cMenu--item-firstlast-padding-y: 1em;
  --cMenu--item-touch-min-height: 3.429em;
  --cMenu--color-bg: #e0e0e0;
  --cMenu--color-bg-hover: #aaa;
  --cMenu--color-text: #000;
  --cMenu--color-text-hover: #000;
  --cMenu--color-icon: #666;
  --cMenu--color-icon-hover: #000;
  --cMenu--transition-bg: background 0.12s ease-in-out;
  --cMenu--transition: color 0.12s ease-in-ou-textt;
  --cMenu--tooltip-font-size: 0.875rem;
  --cMenu--tooltip-min-width: min-content;
  --cMenu--tooltip-max-width: 30rem;
  --cMenu--tooltip-offset-y: -150%;
  --cMenu--tooltip-padding: 0.571em 0.857em 0.571em;
}
.contextMenu,
[class*='contextMenu:'] {
  position: relative;
}
.contextMenu {
  z-index: var(--cMenu--stackingContext);
}
.contextMenu__panel {
  font: var(--cMenu--font);
  font-size: var(--cMenu--font-size);
  padding: var(--cMenu--panel-padding);
  background: var(--cMenu--color-bg);
  color: var(--cMenu--color-text);
  border: var(--cMenu--panel-border);
  border-radius: var(--cMenu--panel-rounding);
  box-shadow: var(--cMenu--panel-shadow);
  z-index: var(--cMenu--stackingContext);
  display: none;
  visibility: hidden;
}
.contextMenu.is\:open {
  --cMenu--stackingContext: var(--viewLayer--isOpen);
}
.contextMenu.is\:open .contextMenu__panel {
  display: block;
  visibility: visible;
}
.contextMenu a {
  text-decoration: none;
  color: var(--cMenu--color-text);
}
.contextMenu a:hover {
  color: var(--cMenu--color-text-hover);
}
.contextMenu__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  transition: var(--cMenu--transition-bg), var(--cMenu--transition-text);
  padding: var(--cMenu--item-padding-y) var(--cMenu--item-padding-x);
  min-height: var(--cMenu--item-touch-min-height);
  background: var(--cMenu--color-bg);
}
@media (min-width: 1366px) {
  .contextMenu__item {
    min-height: auto;
  }
}
.contextMenu__item:first-child {
  padding-top: var(--cMenu--item-firstlast-padding-y);
  border-top-left-radius: var(--cMenu--panel-rounding);
  border-top-right-radius: var(--cMenu--panel-rounding);
}
.contextMenu__item:last-child {
  padding-bottom: var(--cMenu--item-firstlast-padding-y);
  border-bottom-left-radius: var(--cMenu--panel-rounding);
  border-bottom-right-radius: var(--cMenu--panel-rounding);
}
.contextMenu__item:not(:first-child) {
  border-top: var(--cMenu--item-separator);
}
.contextMenu__item[class*="icon:"] {
  padding-left: var(--cMenu--item-icon-padding-x);
}
.contextMenu__item::before {
  margin-right: 0.333em;
  color: var(--cMenu--color-icon) !important;
  transition: var(--cMenu--text-transition);
}
.contextMenu__item:hover::before {
  color: var(--cMenu--color-icon-hover) !important;
}
.contextMenu__item:hover {
  cursor: pointer;
  background: var(--cMenu--color-bg-hover);
}
.contextMenu__trigger {
  background-color: transparent;
}
.contextMenu.is\:open .contextMenu,
.punchCard[checked='checked'] + .contextMenu > .contextMenu,
.contextMenu\:tooltip:hover .contextMenu {
  --cMenu--stackingContext: var(--viewLayer--popover);
}
.contextMenu.is\:open .contextMenu__panel,
.punchCard[checked='checked'] + .contextMenu > .contextMenu__panel,
.contextMenu\:tooltip:hover .contextMenu__panel {
  visibility: visible;
}
.contextMenu.is\:open .contextMenu__trigger,
.punchCard[checked='checked'] + .contextMenu > .contextMenu__trigger,
.contextMenu\:tooltip:hover .contextMenu__trigger {
  background: var(--cMenu--color-bg);
  color: var(--cMenu--color-text);
}
.contextMenu\:tooltip .contextMenu__panel {
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  background: var(--cMenu--color-bg);
}
@media (max-width: 811px) {
  .contextMenu\:tooltip .contextMenu__panel {
    position: fixed;
    z-index: var(--tooltip--stackingContext);
    top: 10vh;
    left: 50%;
    bottom: auto;
    transform: translateX(-50%);
    width: 80vw;
    max-height: 15vh;
    padding: 1em;
    box-shadow: 0.25rem 0.5rem 2rem rgba(0, 0, 0, 0.4);
  }
}
@media (min-width: 812px) {
  .contextMenu\:tooltip .contextMenu__panel {
    position: absolute;
    z-index: var(--tooltip--stackingContext);
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(var(--cMenu--tooltip-offset-y));
    min-width: var(--cMenu--tooltip-min-width);
    max-width: var(--cMenu--tooltip-max-width);
    padding: var(--cMenu--tooltip-padding);
    border-radius: var(--cMenu--panel-rounding);
    font-size: var(--cMenu--tooltip-font-size);
    line-height: 1;
  }
  .contextMenu\:tooltip .contextMenu__panel::before {
    content: '';
    background: var(--cMenu--color-bg);
    width: 0.625rem;
    height: 0.625rem;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 75%;
    transform: translateX(-80%) rotate(45deg);
  }
}
.contextMenu\:tooltip--infoList .contextMenu__panel {
  --cMenu--tooltip-min-width: max-content;
  --cMenu--tooltip-max-width: 20rem;
  --cMenu--tooltip-offset-y: -100%;
  --cMenu--tooltip-padding: 0.143em 0.286em;
  --cMenu--item-separator: 1px solid rgba(0, 0, 0, 0.2);
  --cMenu--item-padding-y: 0.5em;
  --cMenu--item-padding-x: 0.75em;
  --cMenu--item-firstlast-padding-y: 0.5em;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: left;
}
.contextMenu\:tooltip--infoList .contextMenu__listTitle {
  font-size: 0.857em;
  padding: var(--cMenu--item-padding-y) var(--cMenu--item-padding-x);
  border-bottom: var(--cMenu--item-separator);
  color: var(--color-text-faded);
}
.contextMenu\:tooltip--infoList .contextMenu__item:hover {
  background-color: initial;
}
[data-tooltip] {
  --cMenu--tooltip-offset-y: -125%;
  --cMenu--font-size: 0.75rem;
}
@media screen and (51.25rem <= width) {
  [data-tooltip]:hover {
    position: relative;
  }
  [data-tooltip]:hover::after {
    display: block;
    content: attr(data-tooltip);
    white-space: nowrap;
    z-index: var(--tooltip--stackingContext);
    position: absolute;
    top: 0;
    left: 50%;
    transform: scale(1) translateX(-50%) translateY(var(--cMenu--tooltip-offset-y));
    min-width: var(--cMenu--tooltip-min-width);
    max-width: var(--cMenu--tooltip-max-width);
    padding: var(--cMenu--tooltip-padding);
    font: var(--cMenu--font) !important;
    font-size: var(--cMenu--font-size) !important;
    font-weight: 400 !important;
    line-height: 1;
    color: var(--color-text) !important;
    background-color: var(--color-bg);
    border-radius: var(--cMenu--panel-rounding);
    box-shadow: var(--cMenu--panel-shadow);
  }
}
.tagPicker {
  --tagPicker-padding: 0.125rem 0.125rem;
  --tag--color-bg: var(--palette--neutral-200);
  --tag--color-text: var(--color-text);
  --tag--color-icon: var(--palette--neutral-500);
  --tag--rounding: 0.125rem;
  --tag--outer-spacing: 0.188rem;
  --tag--font-size: 0.875rem;
  --tag--label-padding: 0.313rem 0.5rem;
  --tag--label-max-width: 9.063rem;
  --tag--label-height: 1rem;
  --tag--control-separator: 1px solid var(--palette--neutral-100);
  --tag--control-color-bg-hover: var(--palette--neutral-300);
  --tag--control-width: 1.75rem;
  --tag--control-icon-size: 1.063rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  height: auto;
  padding: var(--tagPicker-padding);
}
.tagPicker__tag {
  display: inline-flex;
  white-space: nowrap;
  margin: var(--tag--outer-spacing);
  font-size: var(--tag--font-size);
  line-height: var(--tag--label-height);
  color: var(--tag--color-text);
  background: var(--tag--color-bg);
  border-radius: var(--tag--rounding);
}
.tagPicker__tagLabel {
  white-space: nowrap;
  padding: var(--tag--label-padding);
  max-width: var(--tag--label-max-width);
  text-overflow: ellipsis;
  overflow: hidden;
}
.tagPicker__tagRemove {
  border-left: var(--tag--control-separator);
  height: auto;
  display: flex;
  align-items: center;
  width: var(--tag--control-width) !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tagPicker__tagRemove:hover {
  background-color: var(--tag--control-color-bg-hover);
}
.tagPicker__tagRemove::before {
  color: var(--tag--color-icon);
  font-size: var(--tag--control-icon-size);
}
.tagPicker__inlineSearch.select__input {
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  flex: 1;
  min-width: 6rem;
  min-height: auto;
  margin: var(--tag--outer-spacing);
  padding-left: calc(var(--input--padding-x) - var(--tag--outer-spacing));
}
.tagPicker__inlineSearch.select__input::placeholder {
  color: var(--input--color-placeholder);
}
.tagPicker__inlineSearch.select__input:focus {
  outline: none;
}
.tagPicker__action {
  margin-left: auto;
}
.tagPicker__autocomplete {
  display: none;
  left: 0;
  top: calc(var(--input--height) - 2px);
  position: absolute;
  width: 100%;
  background: var(--input--color-bg);
  border: var(--input--border);
  border-top-color: #eee;
}
.is\:open .tagPicker__autocomplete {
  display: block;
}
.autocomplete__option {
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}
.autocomplete__option:hover {
  background: var(--palette--neutral-200);
}
.autocomplete__search {
  padding: 0.5rem 0.5rem 0.375rem;
}
.autocomplete__search input {
  padding: 0.125rem 0.5rem;
  height: auto;
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  border-bottom: 2px solid #ccc;
}
CONFIG[fileUpload],
.fileUpload {
  --fileUpload--border: 1px solid #ccc;
  --fileUpload--background: #cfcfcf;
  --fileUpload--border-radius: 0.375rem;
  --fileUpload--thumb-background: #aaa;
  --fileUpload--thumb-color: #ccc;
  --fileUpload--dropZone-color: #222;
  --fileUpload--dropZone-background: #eee;
  --fileUpload--dropZone-blur: 0.125rem;
  --fileUpload--dropZone-opacity: 0.7;
  --fileUpload--controls-heigth: 2.25rem;
}
.fileUpload {
  padding: 0.75rem;
  border: var(--fileUpload--border);
  background: var(--fileUpload--background);
  border-radius: var(--fileUpload--border-radius);
  position: relative;
}
.fileUpload__button {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: center;
}
.fileUpload__button:not(:first-child) {
  margin-top: 1rem;
}
.fileUpload__button i {
  margin-right: 0.25rem;
}
.fileUpload__dropZone {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--fileUpload--stackingContext);
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: var(--fileUpload--border-radius);
  color: var(--fileUpload--dropZone-color);
  background: var(--fileUpload--dropZone-background);
  outline: 2px var(--fileUpload--dropZone-color) dashed;
  outline-offset: -0.75rem;
  opacity: var(--fileUpload--dropZone-opacity);
}
.fileUpload__dropZone::before {
  font-family: "showmanager-icons";
  content: "\e907";
  font-size: 3rem;
  line-height: 3rem;
}
.fileUpload.has\:dropZone .fileUpload__dropZone {
  display: flex;
}
.fileUpload.has\:dropZone > *:not(.fileUpload__dropZone) {
  filter: blur(var(--fileUpload--dropZone-blur));
}
.fileUpload__files {
  min-height: 5rem;
}
.fileUpload__file {
  background: var(--color-bg);
  border-radius: calc(var(--fileUpload--border-radius) * 0.75);
  display: grid;
  grid-template-areas: 'thumb desc desc' 'thumb menu action';
  grid-template-columns: 6rem 1fr var(--fileUpload--controls-heigth);
  grid-template-rows: auto var(--fileUpload--controls-heigth);
}
.fileUpload__file + .fileUpload__file {
  margin-top: 0.625rem;
}
.file__illustration {
  grid-area: thumb;
  padding: 0.625rem;
  border-right: 1px solid var(--fileUpload--background);
}
.file__thumb {
  height: 100%;
  overflow: hidden;
  background: var(--fileUpload--thumb-background);
  color: var(--fileUpload--thumb-color);
}
.file__thumb img {
  width: 100%;
  min-height: 100%;
  object-fit: contain;
}
.file__thumb:empty::before {
  font-family: "showmanager-icons";
  content: "\e90f";
  font-size: 2rem;
  line-height: 2rem;
}
.file__desc {
  grid-area: desc;
  justify-self: stretch;
  align-self: center;
  padding: 0.5rem;
  border-bottom: 1px solid var(--fileUpload--background);
  overflow: hidden;
  white-space: nowrap;
}
.file__menu {
  grid-area: menu;
  justify-self: stretch;
  align-self: stretch;
  height: var(--fileUpload--controls-heigth);
  border-right: 1px solid var(--fileUpload--background);
}
.file__menu .select {
  --select--height: var(--fileUpload--controls-heigth);
  --select--border: none;
  --select--line-height: var(--fileUpload--controls-heigth);
}
.file__menu .select__control {
  min-height: var(--fileUpload--controls-heigth);
}
.file__menu .select__input,
.file__menu .select__option,
.file__menu .select__value {
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
}
.file__action {
  grid-area: action;
  justify-self: center;
  align-self: center;
  width: 100%;
}
.file__action .contextMenu__trigger {
  width: 100%;
  height: var(--fileUpload--controls-heigth);
}
.file__action .contextMenu__trigger .button {
  --button--height: var(--fileUpload--controls-heigth);
  --button--padding-y: 0;
  --button--padding-x: 0;
}
.file__action::before {
  color: #222;
  background: none;
}
.file__action.is\:open::before {
  background-color: var(--color-bg);
}
.file__name {
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
  font-weight: bold;
}
.file__meta {
  font-size: 0.813rem;
  line-height: 1.125rem;
  color: var(--color-text-muted);
}
.form.is\:disabled .fileUpload {
  pointer-events: none;
}
.form.is\:disabled .fileUpload .file__illustration {
  pointer-events: all;
}
.form.is\:disabled .fileUpload .file__name {
  color: var(--color-text-muted);
}
.richText {
  display: flex;
  flex-flow: column;
}
.richText__editorToggle,
.richText__langToggle {
  font-size: 0.875rem;
  line-height: 1.375rem;
  align-self: flex-end;
  cursor: pointer;
}
.richText__editorToggle:hover,
.richText__langToggle:hover {
  text-decoration: underline;
}
.field__label + .richText {
  margin-top: -1.375em;
}
.richText__editor {
  height: auto;
  padding: 0;
  min-height: 10rem;
}
.richText__htmlEditor {
  min-height: 20rem;
  resize: vertical;
}
.richText.is\:focus .richText__editor,
.richText.is\:focus .richText__htmlEditor {
  border-color: #000;
}
.richText .ql-container,
.richText .ql-toolbar {
  border: none !important;
}
.richText .ql-editor p + p {
  margin-top: 1.5em;
}
.richText .ql-editor ul,
.richText .ql-editor ol {
  margin-top: 1.5em;
}
.richText .ql-editor h2 {
  font-weight: 600;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.richText .ql-editor h3 {
  font-weight: 600;
  margin-top: 0.75em;
  margin-bottom: 0.25em;
}
.richText.is\:disabled {
  pointer-events: none;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}
.pagination__item {
  display: inline-flex;
  align-items: center;
  border-radius: 0.188rem;
  min-width: 1.875rem;
  border: 1px solid #ccc;
  cursor: pointer;
}
.pagination__item a,
.pagination__item a:hover {
  color: #1f1f1f;
}
.pagination__item:not(:last-child) {
  margin-right: 0.125rem;
}
.pagination__item:hover {
  background: #eee;
  color: #666;
}
.pagination__item :focus {
  outline: none;
}
.pagination__item.is\:active {
  font-weight: bold;
  background: #eee;
  cursor: normal;
}
.pagination__item.disabled {
  border: 1px solid #eee;
  font-weight: bold;
  cursor: normal;
}
.pagination__item.disabled a {
  color: #999 !important;
}
@media (pointer: coarse) and (hover: none) and screen and (width < 43.75rem) {
  .pagination__item {
    line-height: 2.5rem;
    min-width: 2.5rem;
  }
}
@media screen and (width < 34.375rem) {
  .pagination__item {
    line-height: 2rem;
    min-width: 2.125rem;
  }
}
@container pagingBar (28.125rem <= width < 40rem) {
  .pagination .pagination__item,
  .pagination .break {
    display: none !important;
    visibility: hidden !important;
  }
  .pagination .pagination__item.is\:active,
  .pagination .pagination__item.is\:active + .pagination__item,
  .pagination .pagination__item:has(+ .is\:active),
  .pagination .pagination__next,
  .pagination .pagination__prev {
    display: inline-flex !important;
    visibility: visible !important;
  }
}
@container pagingBar (width < 28.125rem) {
  .pagination .pagination__item,
  .pagination .break {
    display: none !important;
    visibility: hidden !important;
  }
  .pagination .pagination__next,
  .pagination .pagination__prev,
  .pagination .pagination__item.is\:active {
    display: inline-flex !important;
    visibility: visible !important;
  }
}
.pagination__prevLink,
.pagination__nextLink,
.pagination__pageLink {
  display: inline-flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  padding: 0rem 0.375rem;
}
.spinner {
  transform: scale(0.5);
  transition: all 0.1s;
  opacity: 0;
  font-family: 'Fira Sans', sans-serif;
}
.spinner:before {
  display: none;
  margin: 0;
  height: 1em;
  width: 1em;
  content: '';
  background: transparent;
  border-radius: 50%;
  border: 0 solid currentColor;
}
.spinner.is\:animating {
  transform: scale(0.9);
  opacity: 1;
}
.spinner.is\:animating:after {
  opacity: 1;
}
.spinner.is\:animating:before {
  display: flex;
  border-width: 2px;
  animation: fragSpinner 2s infinite ease-out 0.1s;
}
@keyframes fragSpinner {
  0% {
    transform: rotatez(0deg);
    border-color: transparent transparent transparent transparent;
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  25% {
    transform: rotatez(90deg);
    border-color: currentColor transparent transparent transparent;
  }
  50% {
    transform: rotatez(180deg);
    border-color: currentColor currentColor transparent transparent;
  }
  75% {
    transform: rotatez(270deg);
    border-color: currentColor currentColor currentColor transparent;
  }
  90% {
    border-color: currentColor currentColor currentColor currentColor;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotatez(360deg);
    border-color: currentColor currentColor currentColor currentColor;
  }
}
.button.is\:loading {
  position: relative;
}
.button.is\:loading .spinner--overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
}
.button.is\:loading .spinner--inline {
  position: relative;
  margin-right: 0.5em;
  margin-left: -0.25em;
  left: -2px;
  top: -1px;
  height: 20px;
  width: 20px;
}
CONFIG[modalBackdrop],
.modalBackdrop {
  --modal--backdrop-stackingContext: calc(var(--appLayer--sidePanel) - 1);
  --modal--backdrop-bg: rgba(0, 0, 0, 0.33);
  --modal--backdrop-filter: none;
}
CONFIG[modal],
.modal {
  --modal--stackingContext: var(--appLayer--modal, 1450);
  --modal--min-width: 26.25rem;
  --modal--max-width: 38.75rem;
  --modal--min-height: 25vh;
  --modal--max-height: 90vh;
  --modal--rounding: 0.5rem;
  --modal--border: none;
  --modal--shadow: 0 0.75em 1.5em rgba(0, 0, 0, 0.333), 0 0.5em 0.6em rgba(0, 0, 0, 0.125);
  --modal--color-bg: var(--color-bg);
  --modal--color-text: var(--color-text);
  --modal--header-padding: 1.125rem 1.5rem 1rem;
  --modal--header-color-bg: var(--modal--color-bg);
  --modal--header-color-text: var(--modal--color-text);
  --modal--title-font: 500 1.313rem /1.25 'Fira Sans', sans-serif;
  --modal--title-subtitle-spacing: 0.25rem;
  --modal--subtitle-font: 0.875rem /1.333 'Fira Sans', sans-serif;
  --modal--subtitle-color-text: var(--color-color-text);
  --modal--content-padding: 1rem 1rem;
  --modal--content-separator: 1px solid #ccc;
  --modal--actions-margin: 1rem;
  --modal--actions-padding: 1rem 1rem;
  --modal--actions-distribute: space-between;
  --modal--actions-color-bg: var(--modal--color-bg);
  --modal--actions-color-text: var(--modal--color-text);
}
@media screen and (width < 34.375rem) {
  CONFIG[modal],
  .modal {
    --modal--min-width: none;
    --modal--max-width: 98vw;
    --modal--content-padding: 1rem 0.75rem;
  }
}
@media screen and (width < 43.75rem) {
  body.has\:modal .viewport {
    overflow: hidden;
  }
}
.modalBackdrop {
  position: fixed;
  z-index: var(--modal--backdrop-stackingContext);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--modal--backdrop-bg);
  backdrop-filter: var(--modal--backdrop-filter);
}
.modal {
  position: fixed;
  z-index: var(--modal--stackingContext);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  pointer-events: none;
}
.modal__content {
  margin: 0 auto;
  min-width: var(--modal--min-width);
  max-width: 96vw;
  min-height: var(--modal--min-height);
  max-height: var(--modal--max-height);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  pointer-events: auto;
  overflow: auto;
  border: var(--modal--border);
  border-radius: var(--modal--rounding);
  background: var(--modal--color-bg);
  color: var(--modal--color-text);
  box-shadow: var(--modal--shadow);
}
@media screen and (51.25rem <= width) {
  .modal__content {
    max-width: var(--modal--max-width);
  }
}
@media screen and (width < 34.375rem) {
  .modal__content {
    --modal--min-width: none !important;
    --modal--max-width: 100vw !important;
  }
}
.modal__header {
  text-align: center;
  padding: var(--modal--header-padding);
  background: var(--modal--header-color-bg);
  color: var(--modal--header-color-text);
}
.modal__title {
  font: var(--modal--title-font);
}
.modal__subtitle {
  font: var(--modal--subtitle-font);
  color: var(--modal--subtitle-color-text);
}
.modal__title + .modal__subtitle {
  margin-top: var(--modal--title-subtitle-spacing);
}
.modal__section {
  display: block;
  border-top: var(--modal--content-separator);
  padding: var(--modal--content-padding);
}
.modal__section:has( + .modal__actions) {
  margin-bottom: var(--modal--actions-margin);
}
.modal__actions {
  margin-top: auto;
  display: flex;
  justify-content: var(--modal--actions-distribute);
  color: var(--modal--actions-color-text);
  background: var(--modal--actions-color-bg);
}
.modal .is\:open .select__menu,
.modal .is\:open .contextMenu__panel,
.modal .is\:open .dateInput__calendar,
.modal .is\:open .datepicker {
  z-index: var(--appLayer--overlay);
}
.showChangeModal {
  --modal--min-width: 30rem;
  --modal--min-height: 22.5rem;
  --modal--content-padding: 1rem 2rem;
}
.showChangeModal label:has([id="showChange.tbd"]) {
  margin-right: 1em;
}
.showChangeModal label:has([id="showChange.corona"]) {
  font-size: 0.875rem;
  color: var(--color-text-faded);
}
.showChangeModal :has([id="showChange.tbd"]:checked) .field:has([id="showChange.newDate"]) {
  opacity: 0.5;
}
.imageEditModal .modal__content {
  display: flex;
  max-width: none;
  width: 100%;
  overflow: visible;
  user-select: none;
  pointer-events: none;
  background: transparent;
  margin: 1.5rem;
  box-shadow: none;
}
.imageEditModal .modal__side {
  width: 32rem;
  background-color: var(--modal--color-bg);
  padding: 0.5rem;
  pointer-events: auto;
}
.imageEditModal .imageCropper {
  margin: 0 auto;
  pointer-events: auto;
  box-shadow: var(--modal--shadow);
  max-height: 98vh;
}
.imageEditModal .ReactCrop__image {
  max-height: 98vh;
}
.imageEditModal .loader {
  margin: 0 auto;
  display: flex;
  background-color: white;
  pointer-events: auto;
  color: #259887;
  border-radius: 0.375rem;
  padding: 1.125rem 1.5rem;
}
.imageEditModal .loader__label {
  display: flex;
  align-items: center;
  margin-left: 1.25rem;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1;
  color: #259887;
}
.imageEditModal .loader::before {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 0.25rem;
}
.promptModal {
  margin: 0.5rem;
}
.promptModal .modal__content {
  min-height: auto;
}
.promptModal--delete {
  --modal--title-subtitle-spacing: 0.75rem;
  --modal--subtitle-font: 0.875rem /1.666 'Fira Sans', sans-serif;
}
.promptModal--delete .modal__title code {
  background-color: transparent;
}
.calendarSyncModal {
  --modal--actions-margin: 0;
  --modal--actions-distribute: center;
  --modal--header-color-bg: var(--palette--neutral-800);
  --modal--header-color-text: var(--palette--neutral-100);
  --modal--subtitle-color-text: var(--palette--neutral-300);
}
.calendarSyncModal .modal__title {
  margin-left: -1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendarSyncModal .modal__title::before {
  content: "\e9b5";
  font-size: 1.125em;
  margin-right: 0.375em;
  color: var(--color-primary);
  font-weight: normal;
}
@media screen and (width < 34.375rem) {
  .calendarSyncModal .editorialCopy {
    --copy--padding-x: 0.125em;
  }
}
.calendarSyncModal h2[class*="icon:"],
.calendarSyncModal h3[class*="icon:"],
.calendarSyncModal h4[class*="icon:"] {
  display: flex;
  align-items: center;
  margin-left: -0.25em;
}
.calendarSyncModal h2[class*="icon:"]::before,
.calendarSyncModal h3[class*="icon:"]::before,
.calendarSyncModal h4[class*="icon:"]::before {
  color: var(--color-primary-muted);
  font-size: 1.5em;
}
.calendarSyncModal__feed {
  text-align: center;
}
.calendarSyncModal__feed .button\:primary {
  font-weight: 500;
}
@media screen and (51.25rem <= width) {
  .calendarSyncModal--viewFeedURL {
    --modal--min-width: 38.75rem;
  }
}
.calendarSyncModal__feedActions {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.calendarSyncModal__feedActions a[class*="icon:"] {
  text-decoration: none;
}
.calendarSyncModal__feedActions a[class*="icon:"]::before {
  margin-right: 0.25em;
}
.calendarSyncModal__successTitle {
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.125em;
  margin-bottom: 0.25em;
  color: var(--color-text-success);
}
.calendarSyncModal__successTitle::before {
  font-size: 1.1em !important;
  margin-right: 0.2em;
  color: inherit !important;
}
.calendarSyncModal__successDesc {
  margin-top: 0.5em;
  font-size: 0.875em;
  color: var(--color-text-muted);
  padding: 0 2em;
}
.errorReportModal {
  --modal--max-width: 45rem;
}
.schedulingModal {
  --modal--actions-margin: 0;
}
.schedulingModal .field__input--radio {
  font: 1.125rem /1.333 'Fira Sans', sans-serif;
  font-weight: 500;
  min-height: 0;
}
.schedulingModal .field__input--radio input {
  height: auto;
}
.schedulingModal__desc {
  margin: 0.5rem 0 0.5rem 1.5rem;
  font: 0.875rem /1.5 'Fira Sans', sans-serif;
  color: var(--color-text-muted);
}
.schedulingModal__dateSelection {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 22.5rem;
  margin: 0 auto;
  grid-template-columns: auto auto;
  grid-template-areas: 'picker picker' 'date time';
  justify-content: center;
  padding: 0.5rem 0.5rem;
}
.schedulingModal__dateSelection .field + .field {
  margin-left: 0.25rem;
}
.schedulingModal__dateSelection input {
  min-width: none;
}
.schedulingModal .datepicker {
  grid-area: picker;
  border: none;
  --datePicker--shadow: none;
}
.schedulingModal__date {
  grid-area: date;
}
.schedulingModal__time {
  grid-area: time;
}
CONFIG[sidePanel],
.sidePanel {
  --sidePanel--max-width: 60rem;
}
.sidePanel {
  container: sidePanel / inline-size;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.sidePanel.modal {
  --modal--stackingContext: var(--appLayer--sidePanel, 1400);
  --modal--max-height: 100vh;
  --modal--rounding: 0;
}
@media (min-width: 1366px) {
  .sidePanel {
    max-width: var(--sidePanel--max-width);
  }
}
.sidePanel .modal__content {
  max-width: 90vw;
  width: 100%;
  height: 100%;
  margin-right: 0;
  overflow-x: hidden;
  padding-top: 2.5rem;
}
.sidePanel .modal__content .form {
  display: flex;
  flex-direction: column;
}
.sidePanel .modal__content .section--projectSummary .section__content {
  padding: 0 !important;
}
.sidePanel .modal__content .section--projectSummary .showSummary {
  padding: 0.063rem !important;
}
.sidePanel .modal__content .section--projectSummary {
  margin-top: 0.5rem;
  order: 1;
}
.sidePanel .modal__content .section--projectSummary .table {
  z-index: calc(var(--viewLayer--static));
}
.sidePanel .modal__content .section--projectPersonnel {
  margin-top: 0;
  order: 2;
}
.sidePanel .modal__content .section--projectPersonnel .table {
  z-index: calc(var(--viewLayer--static) + 3);
}
.sidePanel .modal__content .section--projectAssignees {
  order: 3;
}
.sidePanel .modal__content .section--projectAssignees .table {
  z-index: calc(var(--viewLayer--static) + 2);
}
.sidePanel .modal__content .section--projectContacts {
  order: 4;
}
.sidePanel .modal__content .section--projectContacts .table {
  z-index: calc(var(--viewLayer--static) + 1);
}
.sidePanel.modal .showSummary {
  width: 100%;
  max-width: 100%;
}
.sidePanel.modal .showSummary__eventTitle {
  padding-top: 1.5rem;
}
.sidePanel.modal .showSummary__dateLoc {
  padding-bottom: 1rem;
}
.sidePanel.modal .showSummary__headerGrouped {
  margin-bottom: 0;
}
.sidePanel.modal .showSummary__metaLines {
  --metaLines--separator: 0.063rem solid var(--color-bg-faded);
}
@container sidePanel (40rem <= width) {
  .sidePanel.modal .showSummary__layout {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 2rem;
    display: grid;
    align-items: center;
  }
  .sidePanel.modal .showSummary__headerGrouped {
    grid-column: 1;
    height: 100%;
    --summaryGrid--width: 2.625rem;
    --summaryGrid--spacing: 1rem;
    --summaryGrid--statusTag--font-size: 1.5rem;
  }
  .sidePanel.modal .showSummary__metaLines {
    grid-column: 2;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
}
@container sidePanel (width < 40rem) {
  .sidePanel.modal .showSummary__headerGrouped {
    --summaryGrid--width: 1.5rem;
    --summaryGrid--spacing: 0.5rem;
    --summaryGrid--statusTag--font-size: 1.125rem;
    --summaryGrid--offset-left: 0rem;
  }
  .sidePanel.modal .showSummary__metaLines,
  .sidePanel.modal .showSummary__headerGrouped {
    padding-right: 1rem;
  }
  .sidePanel.modal .showSummary__metaLines {
    grid-column: 2;
    margin-top: 0.063rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    --metaLines--padding: 0.375rem 1rem 0.375rem 0.375rem;
  }
}
.sidePanel .toolbar {
  background-color: #43454b;
  padding: 0.188rem 0.75rem 0.188rem;
  color: var(--color-bg);
  position: fixed;
  z-index: calc(var(--appLayer--sidePanel) + 10);
  right: 0;
  top: 0;
  width: 100%;
  max-width: 90vw;
}
@media (min-width: 1366px) {
  .sidePanel .toolbar {
    max-width: var(--sidePanel--max-width);
  }
}
.sidePanel--personnel {
  width: 100%;
  max-width: 90vw;
}
@media (min-width: 1366px) {
  .sidePanel--personnel {
    max-width: var(--sidePanel--max-width);
  }
}
.dateInput {
  --dateInput--stackingContext: var(--viewLayer--inputs);
  --dateInput--icon-size: 1.25rem;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0;
  z-index: var(--dateInput--stackingContext);
  padding-bottom: 0;
}
.dateInput.is\:open {
  --dateInput--stackingContext: var(--viewLayer--isOpen);
}
.dateInput::before {
  content: "\e90e";
  display: flex;
  padding-right: var(--input--padding-x);
  margin-right: var(--input--padding-x);
  border-right: 0.063rem solid var(--input--color-border);
  font-size: var(--dateInput--icon-size);
  line-height: var(--input--height);
  color: var(--input--color-border);
  opacity: 0.5;
}
.table__cell .dateInput::before {
  border: none;
  margin-right: 0rem;
}
.dateInput__calendar {
  display: none;
  overflow: hidden;
  position: absolute;
  z-index: var(--dateInput--stackingContext);
  visibility: hidden;
}
.dateInput.is\:open .dateInput__calendar {
  display: block;
  visibility: visible;
}
.dateInput__value {
  font-size: var(--input--font-size);
  line-height: var(--input--height);
  display: none;
  width: 100%;
  white-space: nowrap;
}
.dateInput__input {
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
  height: auto;
  font-size: var(--input--font-size);
  line-height: var(--input--height);
}
.dateInput.has\:value .dateInput__value {
  display: block;
}
.dateInput.has\:value .dateInput__input {
  opacity: 0;
}
.dateInput.has\:value .dateInput__input::placeholder {
  color: inherit;
}
.dateInput.is\:focus .dateInput__value {
  display: none;
}
.dateInput.is\:focus .dateInput__input {
  opacity: 1;
}
.dateInput.is\:focus {
  border-color: var(--input--color-border-focus);
}
.has\:error > .dateInput {
  border-color: var(--color-text-error);
  background-color: var(--color-bg-error);
}
CONFIG[timeInput],
.timeInput,
.dateTimeInput__time {
  --timeInput--icon-size: 1.25rem;
  --timeInput--icon-width: 2.25rem;
  --timeInput--icon-separator: 0.125rem solid var(--palette--neutral-200);
  --timeInput--icon-spacing: 0.5rem;
  --timeInput--input-width: 1.125rem;
  --timeInput--input-margin: 0.5rem;
  --timeInput--icon-opacity: 0.5;
  --timeInput--separator-font-size: 1rem;
  --timeInput--separator-color-line: var(--palette--neutral-200);
  --timeInput--separator-color-text: var(--palette--neutral-400);
}
.timeInput {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  flex-flow: row nowrap;
  width: auto;
  align-self: flex-start;
  padding: 0;
  z-index: 1;
  padding-right: var(--input--padding-x);
}
.timeInput::before {
  content: "\e90d";
  font-size: var(--timeInput--icon-size);
  height: var(--input--height);
  width: var(--timeInput--icon-width);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--input--color-border);
  opacity: var(--timeInput--icon-opacity);
  margin-right: var(--timeInput--icon-spacing);
  border-right: var(--timeInput--icon-separator);
}
.timeInput__input {
  width: 100%;
  background: none;
  border-radius: 0;
  outline: none;
  width: var(--timeInput--input-width);
  border: none;
  background-color: transparent;
  padding: 0;
  margin-left: var(--timeInput--input-margin);
  margin-right: var(--timeInput--input-margin);
  text-align: center;
  height: auto;
}
.timeInput__input:first-of-type {
  margin-left: 0;
}
.timeInput__input:last-of-type {
  margin-right: 0;
}
.timeInput__input:focus {
  outline: none;
}
.timeInput__separator {
  font-size: var(--timeInput--separator-font-size);
  line-height: var(--input--line-height);
  padding: var(--input--padding-y) 0;
  border-right: 0.063rem solid var(--timeInput--separator-color-line);
}
.timeInput__separator::before {
  content: ':';
  display: block;
  background-color: var(--input--color-bg);
  color: var(--timeInput--separator-color-text);
  margin-right: -0.125rem;
}
.table__cell .timeInput__separator {
  border: 0;
}
.table__cell .timeInput__separator::before {
  background-color: transparent;
}
.timeInput.is\:focus {
  border-color: var(--input--color-border-focus);
}
.dateTimeInput {
  --dateTime--date-flex-basis: 12.375rem;
  --dateTime--date-spacing: 1.5rem;
  --dateTime--date-icon-size: 1.25rem;
  --dateTime--time-flex-basis: 5.25rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
}
.dateTimeInput.is\:open {
  z-index: var(--viewLayer--isOpen);
}
.dateTimeInput__date,
.dateTimeInput__time {
  display: inline-flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
}
.dateTimeInput__date {
  width: auto;
  margin-right: var(--dateTime--date-spacing);
  flex-basis: var(--dateTime--date-flex-basis);
}
.dateTimeInput__date::before {
  content: "\e90e";
  color: var(--input--color-border);
  font-size: var(--dateTime--date-icon-size);
  margin-right: var(--input--padding-x);
  line-height: 1;
  display: block;
  opacity: 0.5;
}
.table__cell .dateTimeInput__date::before {
  border: none;
}
.dateTimeInput__date .dateInput__calendar {
  display: none;
  overflow: hidden;
}
.dateTimeInput__date.is\:open .dateInput__calendar {
  display: block;
  visibility: visible;
}
.dateTimeInput__date.has\:value .dateInput__value {
  display: block;
}
.dateTimeInput__date.has\:value .dateInput__input::placeholder {
  color: inherit;
}
.dateTimeInput__date.has\:value .dateInput__input {
  display: none;
}
.dateTimeInput__date.is\:focus .dateInput__value {
  display: none;
}
.dateTimeInput__date.is\:focus .dateInput__input {
  display: block;
}
.has\:error > .dateTimeInput__date {
  border-color: var(--color-text-error);
  background-color: var(--color-bg-error);
}
.dateTimeInput__time {
  flex-basis: var(--dateTime--time-flex-basis);
  padding-right: 1rem;
}
.dateTimeInput__time::before {
  content: "\e90d";
  font-size: var(--timeInput--icon-size);
  color: var(--input--color-border);
  margin-right: 0.313rem;
  opacity: 0.5;
}
.dateTimeInput.is\:focus {
  border-color: var(--input--color-border-focus);
}
CONFIG[datepicker],
.datepicker {
  --datePicker--font-size: 1rem;
  --datePicker--color-bg: var(--select--color-bg);
  --datePicker--color-text: var(--select--color-text);
  --datePicker--border: var(--select--border-width) solid var(--select--color-border);
  --datePicker--rounding: var(--input--rounding);
  --datePicker--shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.2), 0 0.3em 0.9em rgba(0, 0, 0, 0.2);
  --datePicker--header-padding: 0.375rem 0.75rem;
  --datePicker--label-font-size: 0.875rem;
  --datePicker--label-height: 1.75rem;
  --datePicker--label-color-text: var(--color-bg-muted);
  --datePicker--day-width: 3rem;
  --datePicker--day-height: 2rem;
  --datePicker--day-border-width: 0.063rem;
  --datePicker--day-border-width-focus: 0.063rem;
  --datePicker--day-color-border: var(--palette--neutral-300);
  --datePicker--day-color-border-focus: var(--color-text);
  --datePicker--day-color-bg: var(--color-bg);
  --datePicker--day-color-bg-hover: var(--color-bg-highlight);
  --datePicker--day-color-bg-selected: var(--color-bg-faded);
  --datePicker--day-color-bg-disabled: #f6f6f6;
  --datePicker--day-color-text: var(--color-text);
  --datePicker--day-color-text-hover: var(--color-text);
  --datePicker--day-color-text-selected: var(--color-text);
  --datePicker--day-color-text-disabled: var(--color-text-faded);
}
.datepicker {
  width: auto;
  background-color: var(--datePicker--color-bg);
  border-radius: var(--datePicker--rounding);
  border: var(--datePicker--border);
  box-shadow: var(--datePicker--shadow);
}
.datepicker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--datePicker--header-padding);
}
.datepicker__currentMonth {
  font-weight: bold;
}
.datepicker__navButton {
  font-size: 1.875rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.datepicker__columnLabels,
.datepicker__days {
  display: grid;
  grid-template-columns: repeat(7, var(--datePicker--day-width));
  grid-auto-rows: var(--datePicker--day-height);
  text-align: center;
}
.datepicker__columnLabels > * {
  font-size: var(--datePicker--label-font-size);
  line-height: var(--datePicker--label-height);
  text-transform: uppercase;
  color: var(--datePicker--label-color-text);
}
.datepicker__day {
  font-size: var(--datePicker--font-size);
  line-height: calc(var(--datePicker--day-height) - var(--datePicker--day-border-width) * 2);
  border-top: var(--datePicker--day-border-width) solid var(--datePicker--day-color-border);
  border-right: var(--datePicker--day-border-width) solid var(--datePicker--day-color-border);
  color: var(--datePicker--day-color-text);
  cursor: pointer;
}
.datepicker__day:nth-child(7n) {
  border-right: none;
}
.datepicker__day:hover {
  background-color: var(--datePicker--day-color-bg-hover);
  color: var(--datePicker--day-color-text-hover);
}
.datepicker__day.is\:focus {
  border: var(--datePicker--day-border-width-focus) solid var(--datePicker--day-color-border-focus);
}
.datepicker__day.is\:selected {
  font-weight: bold;
  cursor: default;
  background-color: var(--datePicker--day-color-bg-selected);
  color: var(--datePicker--day-color-text-selected);
}
.datepicker__day--otherMonth {
  background-color: var(--datePicker--day-color-bg-disabled);
}
.datepicker__day.is\:disabled,
.datepicker__day .is\:disabled:hover {
  background-color: var(--datePicker--day-color-bg-disabled);
  color: var(--datePicker--day-color-text-disabled);
}
.publishingTargets__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.publishingTargets .select .select__input {
  padding-left: 0;
}
.imageCropper {
  display: inline-flex;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAAAAACo4kLRAAAAH0lEQVQY02OcxQADZ+AsJgYsYKgIsiD8YTJInEShIAA1NwKQeKc4/QAAAABJRU5ErkJggg==');
}
.imageCropper__image {
  display: block;
  max-width: 100%;
  max-height: inherit;
  touch-action: none;
  max-height: 95vh;
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}
.ReactCrop *,
.ReactCrop *::before,
.ReactCrop *::after {
  box-sizing: border-box;
}
.ReactCrop--disabled,
.ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__child-wrapper {
  max-height: inherit;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: none;
  border: 1px dashed #fff;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #fff, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}
.ReactCrop__crop-selection:focus {
  outline: none;
  border-color: blue;
  border-style: solid;
}
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: 33.33333333%;
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: 66.66666667%;
}
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: 33.33333333%;
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: 66.66666667%;
}
.ReactCrop__drag-handle {
  position: absolute;
}
.ReactCrop__drag-handle::after {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  outline: 1px solid transparent;
}
.ReactCrop__drag-handle:focus::after {
  border-color: blue;
  background: #2dbfff;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-n::after {
  top: 0;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-e::after {
  right: 0;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-s::after {
  bottom: 0;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop .ord-w::after {
  left: 0;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }
  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}
CONFIG[toast],
.toast {
  --toast--font: 500 1em / 1.333 'Fira Sans', sans-serif;
  --toast--padding: 0.5rem 0.5rem 0.5rem 0.25rem;
  --toast--rounding: 0.188rem;
  --toast--viewport-offset: 1.5rem;
  --toast--color-bg: var(--color-bg);
  --toast--color-text: var(--color-text);
  --toast--shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
}
.toasts {
  display: flex;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: var(--toasts--stackingContext, 600);
}
.toast {
  position: absolute;
  right: var(--toast--viewport-offset);
  bottom: var(--toast--viewport-offset);
  padding: var(--toast--padding);
  border-radius: var(--toast--rounding);
  box-shadow: var(--toast--shadow);
  transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1);
  font: var(--toast--font);
  color: var(--toast--color-text);
  background-color: var(--toast--color-bg);
}
.toast__content {
  display: flex;
  align-items: center;
}
.toast__message {
  padding: 0 0.5em;
}
.toast__message::before {
  margin-right: 0.5em;
}
.toast--success {
  --toast--color-bg: var(--color-bg-success);
  --toast--color-text: var(--color-text-success);
}
.toast--error {
  --toast--color-bg: var(--color-bg-error);
  --toast--color-text: var(--color-text-error);
}
.showSummary {
  --metaLines--separator: 0.063rem solid var(--color-bg-muted);
  --metaLines--padding: 0.375rem 0.75rem;
  --summaryGrid--width: 2rem;
  --summaryGrid--spacing: 0.75rem;
  --summaryGrid--statusTag--font-size: 1.25rem;
  --summaryGrid--offset-left: 0rem;
}
.modal .showSummary {
  width: 33.75rem;
  max-width: 90vw;
  padding: 0.625rem 1.875rem;
}
.showSummary__eventTitle {
  font: 700 var(--font--xlarge);
  padding: 0.5rem 0rem 0.375rem;
}
@media screen and (width < 43.75rem) {
  .showSummary__eventTitle {
    font: 700 var(--font--large);
  }
}
.showSummary__dateLoc {
  font: var(--font--medium);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.showSummary__venue {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: 0.063rem solid var(--color-bg-muted);
}
.showSummary__meta {
  border-top: var(--metaLines--separator);
  padding: var(--metaLines--padding);
}
.showSummary__begin {
  margin-left: 0.5rem;
}
.showSummary__showStatus {
  background-color: #fff;
  margin: 0.25rem 0rem 0.813rem;
  padding: 0.75rem 0.75rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.75rem;
}
.showSummary__showStatus .statusTag {
  grid-area: tag;
  place-self: center start;
}
.showSummary__showStatus .entity__title {
  grid-area: status;
  font-weight: 500;
  place-self: center start;
}
.showSummary__showStatus .entity__meta {
  grid-area: changes;
}
.showSummary__headerGrouped {
  display: grid;
  margin-bottom: 1rem;
  grid-gap: 0 var(--summaryGrid--spacing);
  margin-left: var(--summaryGrid--offset-left);
  grid-template: "statusTag eventTitle" auto "statusTag statusLines" auto "statusTag dateLoc" auto / var(--summaryGrid--width) 1fr;
}
@media screen and (width < 28.75rem) {
  .showSummary__headerGrouped {
    --summaryGrid--spacing: 0.5rem;
  }
  .modal .showSummary__headerGrouped {
    --summaryGrid--offset-left: -2.5rem;
  }
}
.showSummary__headerGrouped .showSummary__eventTitle {
  grid-area: eventTitle;
  padding: 0.125em 0 0;
}
.showSummary__headerGrouped .showSummary__dateLoc {
  grid-area: dateLoc;
  padding: 0.25em 0 0.125em;
  margin: 0;
}
.showSummary__headerGrouped .showSummary__eventDate {
  font-weight: 500;
}
.showSummary__headerGrouped .showSummary__venue,
.showSummary__headerGrouped .showSummary__eventDate {
  display: flex;
  align-items: center;
}
.showSummary__headerGrouped .showSummary__showStatus {
  display: contents;
}
.showSummary__headerGrouped .statusLines {
  padding: 0.333em 0.25em;
  grid-area: statusLines;
  border-top: 0.063rem solid var(--color-bg-faded);
  border-bottom: 0.063rem solid var(--color-bg-faded);
  color: var(--palette--neutral-600);
  display: block;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  gap: 0.25em 0.5em;
}
.showSummary .statusTag--newStyle {
  grid-area: statusTag;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  width: 100%;
  height: 100%;
  padding: 0.666em 0.333em;
  justify-content: start;
  font-size: var(--summaryGrid--statusTag--font-size);
  line-height: 1;
  border-radius: 0;
}
.showSummary__assignees {
  display: flex;
  align-items: center;
}
.showSummary__assignees .assigneeList {
  margin: 0 0.5rem;
  display: flex;
}
.showStatusSection__title {
  padding: 0.5rem 0.75rem 0.625rem;
  font-weight: bold;
}
@media screen and (width < 80rem) {
  .showStatusSection {
    max-height: 14em;
    overflow: hidden;
    position: relative;
    margin-bottom: 0;
  }
  .showStatusSection::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8em;
    background: linear-gradient(rgba(255, 255, 255, 0), var(--color-theme-bg-main));
  }
}
.showStatusForm {
  background-color: #fff;
}
.showStatusForm:focus-within {
  background-color: #FFF4C7;
  transition: background-color 0.3s ease-out;
}
.showStatusForm__field {
  border-top: 0.063rem solid var(--color-bg-muted);
}
.showStatusForm__field textarea {
  border: none;
  background-color: transparent;
  height: 3.375rem;
  padding: 0.625rem 1rem;
}
.showStatusForm__field textarea:focus {
  outline: none;
}
.showStatusForm__field textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.showStatusForm__actions {
  padding: 0rem 0.625rem 0.75rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.showStatusForm__actions .field__label {
  padding: 0;
}
.showStatusForm__actions .field__input--checkbox {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.showStatusMessage {
  background-color: #fff;
  padding: 0.75rem 1rem;
  white-space: pre-line;
}
.showStatusMessage__meta {
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
}
.showStatusMessage__author {
  color: #999;
  font-weight: 500;
}
.showStatusMessage__timestamp {
  margin-left: 0.375rem;
  color: #999;
  float: right;
}
.showStatusMessage__message {
  margin-top: 0.125rem;
}
.showStatusHistory__label {
  margin-top: 1rem;
  font-weight: bold;
  color: #666;
}
.showStatusHistory .showStatusMessage {
  opacity: 0.85;
  margin-top: 0.375rem;
}
.showStatusHistory .showStatusMessage:hover {
  opacity: 1;
}
.showStatusHistory__footer {
  display: flex;
  justify-content: center;
  padding: 0.75rem 1.25rem;
}
.dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5rem 0 0.75rem;
}
.dashboard__title {
  font-size: 2rem;
  line-height: 1.275;
  font-weight: 600;
}
.dashboard__jobInfo {
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 1.3125rem 1.3125rem;
  grid-template-areas: "num label" "num link";
  column-gap: 0.625rem;
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
.dashboard__jobInfo .bigNumber {
  color: #259887;
  grid-area: num;
  text-align: right;
}
.dashboard__group {
  background-color: #fff;
}
.compactShowList__header {
  display: flex;
  align-items: baseline;
  padding: 0.75rem 1.25rem;
  border-bottom: 0.063rem solid var(--color-bg-faded);
  color: #005B47;
}
.compactShowList__header .bigNumber {
  color: #259887;
}
.compactShowList__title {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-left: 0.75rem;
  font-weight: 500;
}
.compactShowList__footer {
  display: flex;
  justify-content: center;
  padding: 0.75rem 1.25rem;
}
.compactShowList__item > a.blockLink {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-bottom: 0.063rem solid var(--color-bg-faded);
}
.compactShowList__item > a.blockLink:hover {
  background-color: #f6f6f6;
}
.compactShowList__itemMeta {
  margin-right: 1rem;
  width: 5.625rem;
}
.compactShowList__itemContent {
  flex: 1 1 100%;
  border-left: 0.063rem solid var(--color-bg-faded);
  padding-left: 0.75rem;
}
.compactShowList__itemContent .statusTag {
  float: right;
}
.compactShowList__itemDate {
  font-weight: bold;
  font-size: 1.188rem;
  line-height: 1.5rem;
}
.compactShowList__itemName {
  font-weight: bold;
  font-size: 1.188rem;
  line-height: 1.5rem;
}
.compactShowList__itemDateLoc {
  color: #666;
}
.compactShowList__itemStatus {
  background-color: #FFF4C7;
  color: #978017;
  font-size: 0.875rem;
  line-height: 1.188rem;
  margin-top: 0.25rem;
  margin-left: -0.125rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.188rem;
  border: 1px solid #fff;
}
.compactShowList__itemStatus .entity__title::before {
  display: none !important;
}
.table--internals\.jobs .has\:conflict {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table--internals\.jobs .has\:conflict a:hover,
.table--internals\.jobs .has\:conflict strong {
  color: #c12414;
}
.table--showPersonnel .showStatus {
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
  max-height: 4em;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.75;
}
.jobsPreview {
  --jobCount--bg: #f0f0f0;
  --jobCount--text: #d1d1d1;
  --jobCount--border: none;
  --jobCount--font-size: 1.125rem;
  --jobCount--height: 2em;
  --jobCount--min-width: 2.5em;
  --jobCount--padding: 0.375em 0.75em;
}
.jobsPreview,
.jobsPreview__counter {
  display: flex;
  place-self: center start;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.375em 0.25em;
  padding-right: 0.5em;
}
@media (min-width: 1060px) {
  .jobsPreview,
  .jobsPreview__counter {
    flex-flow: row nowrap;
  }
}
.jobsPreview__count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--jobCount--font-size);
  line-height: 1;
  font-weight: 600;
  min-width: var(--jobCount--min-width);
  max-width: 100%;
  height: var(--jobCount--height);
  border-radius: 0.25rem;
  padding: var(--jobCount--padding);
  cursor: default;
  background: var(--jobCount--bg);
  color: var(--jobCount--text);
  border: var(--jobCount--border);
}
.jobsPreview__count [class*="icon:"]::before {
  margin-left: -0.25rem;
  margin-right: 0.2rem;
  opacity: 0.5;
  font-size: 0.875rem;
}
.jobsPreview__count.count\:assigned,
.jobsPreview__count.count\:missing {
  transition: transform 0.16s ease-out;
}
.jobsPreview__count.count\:assigned:not(.is\:active, .is\:empty),
.jobsPreview__count.count\:missing:not(.is\:active, .is\:empty) {
  cursor: pointer;
}
.jobsPreview__count.count\:assigned:not(.is\:active, .is\:empty):hover,
.jobsPreview__count.count\:missing:not(.is\:active, .is\:empty):hover {
  transform: scale(1.075);
}
.jobsPreview__count.count\:assigned.is\:active[data-tooltip]::after,
.jobsPreview__count.count\:missing.is\:active[data-tooltip]::after {
  display: none;
}
.jobsPreview__count.count\:assigned {
  --jobCount--bg: #dbf6ca;
  --jobCount--text: #53aa69;
}
.jobsPreview__count.count\:assigned.is\:active {
  --jobCount--text: #006A58;
  --jobCount--border: 0.188rem solid #85d073;
}
.jobsPreview__count.count\:missing {
  --jobCount--bg: #FFF4C7;
  --jobCount--text: #978017;
}
.jobsPreview__count.count\:missing.is\:active {
  --jobCount--text: #6a5a10;
  --jobCount--border: 0.188rem solid #d5c681;
}
.jobsPreview__count.count\:conflict {
  --jobCount--bg: #ffe8e5;
  --jobCount--text: #c12414;
}
.jobsPreview__count.is\:empty {
  --jobCount--bg: #f0f0f0;
  --jobCount--text: #d1d1d1;
  --jobCount--border: none !important;
}
.jobsPreview__count .contextMenu__panel {
  white-space: nowrap;
}
.jobsPreview__desc {
  color: var(--color-text-muted);
  min-height: 100%;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}
.jobsPreview__descAssigned {
  display: flex;
  flex-flow: row wrap;
}
.jobsPreview__descAssigned .has\:conflict > .avatar {
  outline: 0.125rem solid #c12414;
  outline-offset: 0.125rem;
  margin-right: 0.25rem;
  transform: scale(90%);
  opacity: 0.666;
}
.jobsPreview__descAssigned .contextMenu\:tooltip:has(.has\:conflict) + .contextMenu\:tooltip:has(.has\:conflict) {
  margin-left: 0.125rem;
}
.jobsPreview__descMissing:not(.is\:open),
.jobsPreview__descAssigned:not(.is\:open) {
  display: none;
}
.editChanges {
  grid-column: span 2;
  margin-top: 1.5rem;
  padding: 0.375rem 0.375rem 0.375rem 1rem;
  border-radius: 0.188rem;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}
.editChanges.is\:changed {
  background-color: #FFF4C7;
  border-color: #978017;
}
.editChanges.is\:canceled {
  background-color: #ffdeda;
  border-color: #c12414;
}
.editChanges__changes {
  flex: 1 1 100%;
}
.editChanges__action {
  flex: 1 1 15rem;
  align-self: flex-start;
  margin-left: 1.5rem;
}
.editChanges__change {
  padding: 0.25rem 0rem;
}
.editChanges__change + .editChanges__change {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.editChanges__change em {
  font-style: italic;
  color: #666;
}
.editChanges__change b {
  text-decoration: line-through;
}
.announcements__head {
  position: relative;
  padding: 0.5rem 1rem 0.5rem 4rem;
}
.announcements__head .statusTag {
  position: absolute;
  z-index: auto;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.announcements__head.status\:announced {
  background-color: var(--color-status-ann);
}
.announcements__head.status\:pendingAnnouncement {
  background-color: var(--color-status-tba);
}
.announcements__head.status\:pendingConfirmation {
  background-color: var(--color-status-tbc);
}
.announcements__head.status\:confirmed {
  background-color: var(--color-status-con);
}
.announcements__head.status\:archived {
  background-color: var(--color-status-arx);
}
.announcements__head:not(.is\:active) {
  background-color: var(--color-bg);
}
.announcements__label,
.announcements__meta {
  padding-left: 0.75rem;
}
.announcements__label {
  grid-area: label;
  display: flex;
  justify-content: space-between;
}
.announcements__meta {
  grid-area: meta;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #666;
}
.announcements__meta * + * {
  padding-left: 0.313rem;
}
.announcements__labelName {
  font-weight: bold;
}
.announcements__changes {
  background-color: #f0f0f0;
  overflow: hidden;
  height: 0;
}
.metaBox__section.is\:open .announcements__changes {
  height: auto;
}
.errorReport__context {
  display: flex;
  flex-flow: row wrap;
}
.errorReport__contextItem {
  width: 100%;
}
.errorReport__contextItem + .errorReport__contextItem {
  margin-top: 0.75rem;
}
.errorReport__contextLabel {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
}
.errorReport__contextData {
  margin-top: 0.25rem;
  font-family: monospace;
  font-size: 0.75rem;
  line-height: 1rem;
  word-break: break-all;
}
.stacktrace {
  display: flex;
  flex-flow: column;
  font: var(--font-small);
}
.stacktrace__item {
  font-family: monospace;
  white-space: nowrap;
  text-align: left;
}
.stacktrace__column,
.stacktrace__line,
.stacktrace__method {
  font-weight: bold;
}
.optionsMatrix {
  margin: 1.5rem 0 1.5rem;
}
.optionsMatrix__header {
  font-weight: bold;
}
.optionsMatrix__header,
.optionsMatrix__row {
  display: grid;
  grid-template-columns: 1fr repeat(3, 14rem);
  grid-template-rows: 1fr;
}
.optionsMatrix__row {
  border-top: 0.063rem solid var(--color-bg-muted);
}
.optionsMatrix__label,
.optionsMatrix__option {
  display: flex;
  align-items: center;
}
.optionsMatrix__label {
  justify-content: flex-start;
  padding: 0.625rem 0.75rem 0.625rem;
}
.optionsMatrix__label .statusTag {
  margin-right: 0.5rem;
}
.optionsMatrix__option {
  justify-content: center;
  text-align: center;
}
.optionsMatrix__option:nth-child(2) {
  background-color: #fcfcfc;
}
.optionsMatrix__option:nth-child(3) {
  background-color: #f0f0f0;
}
.optionsMatrix__option:nth-child(4) {
  background-color: #e6e6e6;
}
.optionsMatrix .field--radio .field__label {
  display: none;
}
.optionsMatrix .field--radio .field__input {
  padding: 0.625rem 0.75rem 0.625rem;
  justify-content: center;
}
.copyBox {
  --copyBox--font: inherit;
  --copyBox--color-bg: #f2f7e0;
  --copyBox--color-text: #738f20;
  --copyBox--border: 0.125rem solid #bccb90;
  --copyBox--color-bg: #e3f8d4;
  --copyBox--color-text: #005f4f;
  --copyBox--border: 0.125rem solid #99c3bc;
  --copyBox--padding-y: 0.625rem;
  --copyBox--padding-x: 0.75rem;
  --copyBox--rounding: 0.313rem;
  color: var(--copyBox--color-text);
  background: var(--copyBox--color-bg);
  border: var(--copyBox--border);
  border-radius: var(--copyBox--rounding);
  padding: var(--copyBox--padding-y) var(--copyBox--padding-x);
  position: relative;
  z-index: 1;
}
.copyBox__action {
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  right: var(--copyBox--padding-y);
  transition: all 0.25s ease-out;
}
.copyBox .button {
  --button--color-bg: transparent;
  --button--color-text: #005f4f;
  --button--border: 1px solid transparent;
  --button--color-bg-hover: var(--copyBox--color-text);
  --button--color-text-hover: var(--copyBox--color-bg);
  --button--color-border-hover: var(--copyBox--color-text);
  --button--color-bg-active: var(--copyBox--color-text);
  --button--color-text-active: var(--copyBox--color-bg);
  --button--color-border-active: var(--copyBox--color-text);
}
@media (hover: hover) {
  .copyBox__action {
    opacity: 0.75;
  }
  .copyBox:hover .copyBox__action {
    opacity: 1;
  }
}
.copyBox__payload {
  width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 2rem;
  height: 2rem;
  padding: 0 0 0 !important;
  border: 0 !important;
  background: transparent !important;
  outline: none !important;
  color: var(--copyBox--color-text);
}
.copyBox__payload::selection {
  background: var(--copyBox--color-text);
  color: var(--copyBox--color-bg);
}
.copyBox::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-right-radius: var(--copyBox--rounding);
  border-bottom-right-radius: var(--copyBox--rounding);
  width: 5rem;
  background: linear-gradient(to right, transparent, var(--copyBox--color-bg) 50%);
}
.propList {
  --propList--container-min: 26.25rem;
  --propList--label-min: 11.25rem;
  --propList--label-icon: "\e953";
  --propList--item--gap: 1em;
  --propList--item--padding: 0.5em;
  --propList--item--separator: 1px solid var(--color-theme-bg-faded);
  --propList--color-label: var(--color-text);
  --propList--color-icon: var(--color-primary);
  --propList--color-desc: var(--color-text);
  container-name: propList;
  container-type: inline-size;
  display: block;
  width: 100%;
  list-style: none !important;
}
* + .propList {
  margin-top: 1em;
}
.propList__item {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: var(--propList--item--padding) 0;
  border-top: var(--propList--item--separator);
}
.propList__itemLabel {
  flex: 0 1 var(--propList--label-min);
  min-width: var(--propList--label-min);
  color: var(--propList--color-label);
  display: inline-flex;
  align-items: center;
}
.propList__itemLabel::before {
  content: var(--propList--label-icon);
  color: var(--propList--color-icon);
  margin: 0 0.35em;
}
.propList__itemDesc {
  flex: 1 1 calc(var(--propList--container-min) - var(--propList--label-min));
  min-width: calc(var(--propList--container-min) - var(--propList--label-min));
  padding-left: 2.2em;
  color: var(--propList--color-desc);
}
.pills {
  display: flex;
  gap: 0.188rem;
}
CONFIG[pill],
.pill {
  --pill--font: 500 0.75rem / 1.3333 'Fira Sans', sans-serif;
  --pill--icon-size: 0.875rem;
  --pill--height: 1.25rem;
  --pill--padding: 0 0.5em;
  --pill--rounding: 0.188rem;
  --pill--border: 0.063rem solid var(--pill--color-border);
  --pill--color-bg: transparent;
  --pill--color-text: #738f20;
  --pill--color-icon: inherit;
  --pill--color-border: #bccb90;
  --pill--icon: none;
}
.pill {
  display: inline-flex;
  align-items: center;
  gap: 0 0.333em;
  font: var(--pill--font);
  height: var(--pill--height);
  padding: var(--pill--padding);
  white-space: nowrap;
  border-radius: var(--pill--rounding);
  color: var(--pill--color-text);
  background: var(--pill--color-bg);
  border: var(--pill--border);
}
.pill::before {
  content: var(--pill--icon);
  font-size: var(--pill--icon-size);
  color: var(--pill--color-icon);
  margin-left: -0.125em;
}
.pill\:soldOut {
  --pill--icon: "\ea20";
  --pill--border: 0.063rem solid rgba(199, 174, 64, 0.5);
  --pill--color-bg: #FFF4C7;
  --pill--color-icon: #c7ae40;
  --pill--color-text: #978017;
}
.pill\:sold {
  --pill--icon: "\ea22";
}
.pill\:coop {
  --pill--icon: "\e9e0";
}
.pill:\onTour {
  --pill--icon: "\e9f0";
}
.pill\:sold,
.pill\:coop,
.pill\:onTour {
  --pill--color-border: var(--palette--neutral-300);
  --pill--color-icon: var(--palette--neutral-300);
  --pill--color-text: var(--palette--neutral-450);
}
.fancyTime {
  width: 7.125rem;
  padding: 0 0 0 0.625rem !important;
  /* Hour */
  /* Minute */
  /* 'X' button for resetting/clearing time */
  /* Up/Down arrows for incrementing/decrementing the value */
}
.fancyTime::-webkit-datetime-edit-fields-wrapper {
  display: flex;
  align-items: center;
}
.fancyTime::-webkit-datetime-edit-text {
  padding: 0rem 0.125rem;
  background: var(--input--color-bg);
  position: relative;
  z-index: 10;
}
.fancyTime::-webkit-datetime-edit-hour-field,
.fancyTime::-webkit-datetime-edit-minute-field,
.fancyTime::-webkit-datetime-edit-ampm-field {
  border-left: 1px solid var(--input--color-border);
  text-align: center;
  letter-spacing: 0.1em;
  padding: 0.5rem 0.313rem;
  min-width: 1.875rem;
}
.fancyTime::-webkit-datetime-edit-minute-field {
  margin-left: -0.25rem;
  position: relative;
  z-index: 0;
}
.fancyTime::-webkit-clear-button {
  display: none;
}
.fancyTime::-webkit-inner-spin-button {
  display: none;
}
CONFIG[view],
.view__sidebar,
.showCalendar,
.view {
  --viewMobile--toolbar--height: 3rem;
  --viewMobile--sidebar--width: 80vw;
  --viewMobile--sidebar--width-max: 80vw;
  --viewMobile--sidebar--height: var(--viewMobile--toolbar--height);
  --viewStatic--toolbar--height: 3.375rem;
  --viewStatic--sidebar--width: 13vw;
  --viewStatic--sidebar--width-min: 15rem;
  --viewStatic--sidebar--width-max: 20rem;
}
.viewport {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
}
.view {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;
}
.view__sidebar {
  flex: 0 0 var(--viewMobile--sidebar--height);
  order: 1;
  padding-bottom: 2.25rem;
}
.view__toolbar {
  flex: 0 0 var(--viewMobile--toolbar--height);
  order: 2;
}
.view__main {
  flex: 1 0 100%;
  background: var(--color-theme-bg-faded);
  order: 3;
  padding: 0.75rem 0;
}
.view--editEvent .form {
  order: 3;
}
@media (min-width: 812px) {
  .view__main {
    padding: 0.938rem 1.25rem;
  }
}
@media screen and (90rem <= width) {
  .view {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-rows: 1fr;
    grid-template-columns: minmax(var(--viewStatic--sidebar--width-min), var(--viewStatic--sidebar--width)) 1fr;
    padding-top: var(--viewStatic--toolbar--height);
  }
  .view__toolbar {
    width: 100%;
    position: fixed;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    z-index: var(--viewToolbar--stackingContext);
    height: var(--viewStatic--toolbar--height);
  }
  .view__sidebar {
    grid-area: sidebar;
    position: relative;
    z-index: var(--viewMain--stackingContext);
  }
  .view__main {
    grid-area: main;
    padding: 1.875rem;
    position: relative;
    z-index: var(--viewMain--stackingContext);
  }
  .view__main--flush {
    padding: 0;
  }
}
.view__menuTrigger .button {
  --button--color-text-hover: var(--color-primary-faded);
  --button--color-text-active: var(--color-primary-faded);
}
@media screen and (width < 90rem) {
  .view__menuTrigger {
    position: absolute;
    top: 0.375rem;
    left: 0.25rem;
    z-index: var(--appLayer--toolbar, 400);
    color: var(--viewSidebar--color-menuToggle);
  }
}
@media screen and (90rem <= width) {
  .view__menuTrigger {
    display: none;
    visibility: hidden;
  }
}
.layout__main {
  grid-area: form;
  height: auto;
}
.layout__side {
  grid-area: meta;
}
@media screen and (width < 64rem) {
  .layout__side {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
.layout\:page .layout__main {
  margin: 0 auto;
  max-width: 68.75rem;
}
.layout\:table {
  padding: 0;
  padding-bottom: 1.875rem;
  background-color: var(--color-theme-bg);
}
.layout\:table .pagingBar {
  --pagingBar--top-separator: none;
}
.layout\:forms {
  display: flex;
  flex-flow: column nowrap;
}
.layout\:forms .layout__main {
  order: 2;
}
.layout\:forms .layout__side {
  order: 1;
  margin-bottom: 1rem;
}
.layout\:forms .table {
  --table--blockgap: 1.5rem;
}
@media screen and (51.25rem <= width) {
  .layout\:forms {
    display: grid;
    grid-template-areas: "meta" "form";
    grid-template-rows: auto 1fr;
    grid-gap: 1.5rem;
  }
}
@media screen and (80rem <= width) {
  .layout\:forms {
    grid-template-areas: "form meta";
    grid-template-columns: minmax(860px, 1100px) minmax(380px, 1fr);
  }
}
.layout\:myshows {
  display: flex;
  flex-flow: column nowrap;
}
.layout\:myshows .layout__main {
  order: 2;
}
.layout\:myshows .layout__side {
  order: 1;
  margin-bottom: 1rem;
}
@media (min-width: 812px) {
  .layout\:myshows {
    display: grid;
    grid-template-areas: "meta" "form";
    grid-template-rows: 3.75rem, 1fr;
    grid-gap: 1.5rem;
  }
  .layout\:myshows .layout__main {
    padding-right: 1.5rem;
    padding-left: 0.75rem;
    border-right: 1px solid var(--color-bg-muted);
  }
}
@media (min-width: 1366px) {
  .layout\:myshows {
    grid-template-areas: "form meta";
    grid-template-columns: minmax(960px, 2fr) minmax(360px, 1fr);
  }
  .layout\:myshows .layout__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "header header" "list-01 list-02";
    column-gap: 1.5rem;
  }
  .layout\:myshows .layout__main .dashboard__header {
    grid-area: header;
  }
}
@media (min-width: 812px) {
  .layout\:settings {
    display: grid;
    grid-template-areas: "meta" "form";
    grid-template-rows: 3.75rem, 1fr;
    grid-gap: 1.5rem;
  }
}
@media (min-width: 1366px) {
  .layout\:settings {
    grid-template-areas: "meta form";
    grid-template-columns: minmax(15rem, 20rem) 1fr;
  }
}
.main__group {
  background: var(--color-theme-bg);
  padding-bottom: 1.25rem;
}
.view__main--flush .main__group {
  padding: 0;
}
.main__group + .main__group {
  margin-top: 5rem;
}
@media (min-width: 812px) {
  .main {
    margin: 0 auto;
  }
}
@media (min-width: 1366px) {
  .main {
    margin: 0;
  }
}
.group__tabNav {
  background: var(--color-theme-bg-faded);
}
.group__section:first-of-type {
  margin-top: 0.625rem;
}
.group__section + .group__section {
  margin-top: 2.25rem;
  border-top: 1px solid var(--color-ui-border);
}
#edit-userProfile + .group__section {
  margin-top: 1rem;
  border-top: 1px solid var(--color-ui-border);
}
.group__header {
  background-color: var(--color-bg-muted);
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.313rem;
  padding: 0.25rem 0.188rem 0.25rem 0.75rem;
}
.group__header .button\:outline {
  border-color: currentColor;
  color: inherit;
}
.group__header .button\:outline::before {
  color: inherit;
}
.group__header.status\:announced {
  background-color: var(--color-status-ann);
  color: rgba(0, 0, 0, 0.5);
}
.group__header.status\:pendingAnnouncement {
  background-color: var(--color-status-tba);
  color: rgba(0, 0, 0, 0.5);
}
.group__header.status\:pendingConfirmation {
  background-color: var(--color-status-tbc);
  color: rgba(0, 0, 0, 0.5);
}
.group__header.status\:confirmed {
  background-color: var(--color-status-con);
  color: rgba(0, 0, 0, 0.5);
}
.group__header.status\:archived {
  background-color: var(--color-status-arx);
  color: rgba(0, 0, 0, 0.5);
}
.group__toolbar {
  display: flex;
  flex-flow: space-between;
  align-items: center;
}
.section__label {
  font: 0.875em / 1.2857 'Fira Sans', sans-serif;
  color: var(--color-text-faded);
  padding: 0.5rem 1rem 0;
}
.section__content {
  padding: 0.5rem 1rem 1rem;
}
@media (min-width: 812px) {
  .section__content {
    padding: 0.5rem 3rem 1rem;
  }
}
@media (min-width: 1366px) {
  .section__content {
    padding: 0.5rem 3.75rem 1rem;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .section__content {
    padding: 0.5rem 2.25rem 1rem;
  }
}
@media (min-width: 1366px) {
  .layout\:table .section__content {
    padding: 0.75rem 3rem 1rem;
  }
}
.view--error {
  display: grid;
  place-items: center;
  grid-template-areas: 'main';
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  background-color: #14161E;
  margin-top: 0;
}
.view--error .view__main {
  background-color: rgba(255, 255, 255, 0.01);
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  max-width: 43.75rem;
  padding: 2rem;
  color: #aaa;
}
.view--error .error__description {
  color: #aaa;
}
.view--error .error__code {
  color: #A5CC2E;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: bold;
  margin-right: 0.375rem;
}
.view--error .error__message {
  color: #799621;
  font-size: 1.375rem;
  line-height: 2.25rem;
}
.view--error .error__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}
.view--error .error__actions .button.button\:naked {
  color: #799621;
}
.view--error .errorReport {
  margin-top: 1.125rem;
}
.view--error .errorReport__contextLabel {
  color: #e0e0e0;
}
.view--login {
  display: grid;
  place-items: center;
  grid-template-areas: 'main';
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  background-color: var(--invert-color-bg);
  margin-top: 0;
}
.view--login .view__main {
  background-color: var(--invert-color-bg);
}
.view .loginBox {
  background-color: var(--invert-color-bg-muted);
  min-width: 17.5rem;
  width: 22.5rem;
  max-width: 80vw;
  padding: 2.625rem 2.25rem 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5em 0.5em rgba(0, 0, 0, 0.35), 0 0.75em 1.75em rgba(0, 0, 0, 0.25);
}
.view .loginBox__header {
  display: flex;
  justify-content: center;
}
.view .loginBox__header img {
  max-width: 13.75rem;
}
.view--redirect {
  color: var(--invert-color-text);
}
.view--redirect a:visited {
  color: var(--invert-color-text-faded);
}
.view--redirect a:hover {
  color: var(--invert-color-text);
}
.form--login {
  margin-top: 1.125rem;
}
.form--login .field__label {
  color: var(--invert-color-text-faded);
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 0.857rem;
  padding-left: 0.25rem;
}
.form--login .input {
  --input--color-bg: var(--palette--neutral-850);
  color: var(--palette--neutral-200);
  box-shadow: inset 0 0 0.5em rgba(0, 0, 0, 0.35);
  --input--color-border: var(--palette--neutral-650);
  --input--color-border-focus: var(--palette--neutral-600);
}
.form--login .input:focus {
  --input--color-bg: var(--palette--neutral-900);
}
.form--login .has\:error .input {
  --input--color-placeholder: var(--color-text-error);
  color: var(--color-text-error);
}
.form--login .has\:error .field__errors {
  min-height: 1.125rem;
  background-color: transparent;
  right: 0;
  left: auto;
}
.form--login .form__actions {
  margin-top: 1.5rem;
}
.form--login .form__actions .button {
  width: 100%;
  font-weight: 500;
}
.ms-login {
  width: 100%;
}
.login-separator {
  color: var(--invert-color-text-muted);
  text-align: center;
  margin: 0.375rem 0.375rem;
}
.redirect-message {
  margin: 0.75rem 0rem 0.75rem 0rem;
}
.view--editEvent .eventStartEndTimes {
  display: flex;
  gap: 1rem;
}
.view--editEvent .eventStartEndTimes > * {
  flex: 1;
}
@media screen and (width < 23.75rem) {
  .view--editEvent .eventStartEndTimes {
    flex-wrap: wrap;
  }
}
@container listTable (51.25rem <= width) {
  .table--rowControls .table__row {
    grid-template-columns: [hand] var(--td--hand) repeat(auto-fit, minmax(var(--td--std), 1fr)) [ctrl] var(--td--ctrl);
  }
  .table--withPrimary .table__row {
    grid-template-columns: [hand] var(--td--hand) [primary] minmax(var(--td--LG), 2fr) repeat(auto-fit, minmax(var(--td--std), 1fr)) [ctrl] var(--td--ctrl);
  }
  .table--contactList .table__row,
  .table--contactList .table__header {
    --row--height: 3rem;
    grid-template-columns: [id] var(--td--ctrl) [mail] minmax(var(--td--LG), 2fr) [name] minmax(var(--td--LG), 2fr) [menu] var(--td--XS);
  }
  .table--announcementList .table__row,
  .table--announcementList .table__header {
    grid-template-columns: [status] minmax(var(--td--2XL), 4fr) [show] minmax(var(--td--2XL), 4fr) [date] minmax(var(--td--LG), 2.5fr) repeat(auto-fit, minmax(var(--td--std), 2fr)) [menu] minmax(var(--td--menu), 1fr);
  }
  .table--announcementList .table__row {
    grid-template-rows: 3.75rem;
  }
  .table--calendarTokenList .table__row,
  .table--calendarTokenList .table__header {
    grid-template-columns: [token] minmax(var(--td--LG), 2.5fr) [created] minmax(var(--td--std), 1fr) [edited] minmax(var(--td--std), 1fr) [menu] var(--td--menu);
  }
  .table--calendarTokenList + .pagingBar {
    border-top: 0.125rem solid var(--color-ui-border);
    padding-top: 1em;
  }
}
@container fieldTable (43.75rem <= width) {
  .table--internals\.ticketOffers .table__row {
    grid-template-columns: [hand] var(--td--hand) [category] minmax(var(--td--std), 1fr) [status] minmax(var(--td--std), 1fr) [availability] minmax(var(--td--2XS), 1.2fr) [basePrice] minmax(var(--td--std), 0.4fr) [finalPrice] minmax(var(--td--std), 0.4fr) [ctrl] var(--td--ctrl);
  }
  .table--stages .table__row,
  .table--stages .table__header,
  .table--socials .table__row,
  .table--socials .table__header {
    grid-template-columns: [hand] var(--td--hand) [name] minmax(var(--td--LG), 2fr) [type] minmax(var(--td--LG), 2fr) [ctrl] var(--td--ctrl);
  }
  .table--properties .table__row,
  .table--properties .table__header {
    grid-template-columns: [label] minmax(var(--td--XL), 1fr) [value] minmax(var(--td--3XL), 2.5fr) [ctrl] var(--td--ctrl);
  }
}
@container listTable (51.25rem <= width) {
  :is(
			.table--entityList,
			.table--venueList,
			.table--artistList,
			.table--companyList,
			.table--imageFormatsList,
			.table--eventBrandsList,
			.table--publishingTargetsList
		) .table__row {
    --entityRow-id-min: 1.875rem;
    --entityRow-id-max: 3rem;
    --entityRow-menu-min: 3.75rem;
    --entityRow-menu-max: 4.5rem;
    grid-template-columns: [id] minmax(var(--entityRow-id-min), var(--entityRow-id-max)) repeat(auto-fit, minmax(var(--td--std), 1fr)) [menu] minmax(var(--entityRow-menu-min), var(--entityRow-menu-max));
  }
}
@container fieldTable (31.25rem <= width < 51.25rem) {
  .table--sponsors .table__row {
    grid-template-columns: [ctrl] var(--ctrl) [A] 1.25fr [B] 1fr;
    grid-template-rows: [header] var(--row--height--collapsed-primary);
  }
  .table--sponsors .table__cell.field\:name {
    grid-column: A;
  }
  .table--sponsors .table__cell.field\:type {
    grid-column: B;
  }
  .table--sponsors .table__cell.for\:setOpen {
    display: none !important;
  }
  .table--sponsors .table__cell.for\:onDelete {
    grid-row: header;
    grid-column: ctrl;
  }
}
.table--showList {
  --row--height--collapsed-primary: 3.75rem;
  --row--height--collapsed: 3.75rem;
  --row--height--statusLineSolo: 3rem;
  --row--height: 3.375rem;
  --td--team: 16.25rem;
}
.table--showList .label\:lastEdited,
.table--showList .field\:lastEdited {
  justify-content: center;
}
.table--showList .field\:lastEdited {
  color: var(--color-text-faded);
  font-size: 0.875rem;
}
@container listTable (51.25rem <= width) {
  .table--showList .table__row,
  .table--showList .table__header {
    grid-template-columns: [date] minmax(var(--td--LG), 2.75fr) [show] minmax(var(--td--LG), 3.5fr) [status] minmax(var(--td--LG), 3.5fr) [assignees] minmax(var(--td--2XL), 3fr) [edited] minmax(var(--td--std), 1.5fr) [menu] minmax(var(--td--ctrl), var(--td--menu));
  }
  .table--showList .table__cell.field\:lastEdited {
    white-space: nowrap;
  }
  .table--showList .table__cell.has\:infoPills {
    padding: 0.1em 0.333em 0.25em var(--cell--pad-x);
  }
  .table--showList .table__cell.has\:infoPills .entity {
    padding-top: 0.25em;
    padding-bottom: 0.125em;
  }
  .table--showList .table__cell.has\:infoPills .pills {
    margin: 0 0 0 auto;
    margin-left: auto;
    margin-right: 0;
    flex-flow: column;
    align-items: flex-end;
  }
}
@container listTable (51.25rem <= width < 66.25rem) {
  .table--showList .table__row,
  .table--showList .table__header {
    grid-template-columns: [date] minmax(var(--td--LG), 2.5fr) [show] minmax(var(--td--LG), 3.5fr) [status] minmax(var(--td--LG), 3.5fr) [assignees] minmax(var(--td--2XL), 3fr) [menu] minmax(var(--td--ctrl), var(--td--menu));
  }
  .table--showList .label\:lastEdited,
  .table--showList .field\:lastEdited {
    display: none;
    visibility: hidden;
  }
  .table--showList .field\:status.has\:infoPills {
    padding: 0.25em var(--cell--pad-x);
    flex-flow: column;
    gap: 0.333em;
    align-items: start;
    justify-content: center;
  }
  .table--showList .field\:status.has\:infoPills .pills {
    margin: 0 0 0 -0.2em;
    flex-flow: row wrap;
  }
}
@container listTable (51.25rem <= width < 55rem) {
  .table--showList .table__cell.for\:editActions > .icon\:edit {
    display: none;
    visibility: hidden;
  }
}
@container listTable (width < 51.25rem) {
  .table--showList .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [date] minmax(var(--row--height--collapsed), min-content) [status] minmax(var(--row--height--collapsed), min-content) [footer] minmax(var(--row--height--collapsed), min-content);
  }
  .table--showList .table__row {
    background: transparent;
  }
  .table--showList .table__row.is\:open .field\:assignees,
  .table--showList .table__row.is\:open .field\:date .entity,
  .table--showList .table__row.is\:open .field\:status,
  .table--showList .table__row.is\:open .has\:menu {
    background-color: rgba(224, 224, 224, 0.2);
  }
  .table--showList .table__cell.field\:lastEdited,
  .table--showList .table__cell.has\:menu .contextMenu,
  .table--showList .table__cell.is\:primary .contextMenu__panel {
    display: none;
    visibility: hidden;
  }
  .table--showList .table__cell.field\:title,
  .table--showList .table__cell.field\:date {
    grid-column: A / span all;
  }
  .table--showList .table__cell.field\:status {
    grid-row: status;
    grid-column: A / span 2;
    flex-flow: column;
    gap: 0.25em;
    align-items: start;
    justify-content: center;
  }
  .table--showList .table__cell.field\:status .entity {
    --entity--title-font: 500 0.875rem / 1.2857 var(--font-family);
    --entity--meta-font: 0.75rem / 1.5 var(--font-family);
  }
  .table--showList .table__cell.field\:date {
    display: contents;
  }
  .table--showList .table__cell.field\:date .statusTag {
    grid-column: menu;
    grid-row: header;
    place-self: center center;
    writing-mode: horizontal-tb;
    justify-content: center;
    --statusTag--font-size: 0.875rem;
    --statusTag--width: 2.25rem;
    --statusTag--height: 1.75rem;
  }
  .table--showList .table__cell.field\:date .entity {
    grid-row: date;
    grid-column: A / span 2;
    height: var(--row--height--collapsed-primary);
    width: 100%;
    border-left: var(--cell--separator);
    border-top: var(--cell--separator);
    padding: var(--cell--pad-x);
    display: flex;
    align-items: start;
    justify-content: center;
    text-align: left;
  }
  .table--showList .table__cell.has\:userEntities {
    grid-column: A;
    grid-row: footer;
  }
}
@container listTable (34.375rem <= width < 51.25rem) {
  .table--showList .table__header,
  .table--showList .table__row {
    grid-template-columns: [ctrl] var(--td--ctrl) [A] minmax(var(--td--2XL), 1.5fr) [B] minmax(var(--td--XL), 1fr) [menu] auto;
  }
  .table--showList .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [footer] minmax(var(--row--height--collapsed), min-content);
  }
  .table--showList .table__cell.field\:title {
    grid-column: A;
    grid-row: header;
  }
  .table--showList .table__cell.field\:date .entity {
    grid-column: B;
    grid-row: header;
    border-right: var(--cell--separator);
    background: transparent !important;
  }
  .table--showList .table__cell.field\:status {
    grid-column: B;
    grid-row: footer;
  }
  .table--showList .table__cell.field\:assignees {
    grid-column: A ;
    grid-row: footer;
  }
}
@container listTable (width < 26.25rem) {
  .table--showList .table__cell.has\:statusTag .statusTag {
    place-self: center end;
    margin-right: 0.25em;
    --statusTag--font-size: 0.75rem;
    --statusTag--width: 2rem;
    --statusTag--height: 1.25rem;
  }
}
.table--announcementList .field\:announcementInfo .entity {
  --entity--meta-font: 500 0.9375rem / 1.6 var(--font-family);
  --entity--title-font: 0.75rem / 1.5 var(--font-family);
}
.table--announcementList .field\:announcementInfo .entity__title {
  color: var(--color-text-muted);
}
.table--announcementList .field\:announcementInfo .entity__title::before {
  display: none;
}
.table--announcementList .field\:announcementInfo .entity__meta {
  color: var(--color-text);
}
.table--showPersonnel {
  --td--menu: 7.5rem;
  --row--height: 3.75rem;
  --tr--max: auto;
  --row--height--collapsed-primary: 3.75rem;
  --row--height--collapsed: 3rem;
}
@container listTable (51.25rem <= width) {
  .table--showPersonnel .table__row,
  .table--showPersonnel .table__header {
    grid-template-columns: [date] minmax(var(--td--XS), var(--td--XS)) [show] minmax(var(--td--LG), 2fr) [team] minmax(var(--td--2XL), 2fr) [jobs] minmax(var(--td--3XL), 4fr) [menu] minmax(var(--td--ctrl), min-content);
  }
  .table--showPersonnel .table__header {
    grid-template-rows: 2.25rem;
  }
  .table--showPersonnel .table_cell .field\:actions .button {
    padding-right: 0.875rem !important;
    grid-template-rows: 2.25rem;
  }
}
@container listTable (51.25rem <= width < 60rem) {
  .table--showPersonnel .table__row,
  .table--showPersonnel .table__header {
    grid-template-columns: [date] minmax(var(--td--XS), var(--td--XS)) [show] minmax(var(--td--LG), 2fr) [team] minmax(var(--td--XL), 2fr) [jobs] minmax(var(--td--3XL), 5fr) [menu] minmax(var(--td--ctrl), min-content);
  }
  .table--showPersonnel .table__cell .jobsPreview__counter {
    --jobCount--font-size: 0.875rem;
    --jobCount--min-width: 2em;
    --jobCount--height: 1.75em;
    --jobCount--padding: 0;
  }
  .table--showPersonnel .table__cell .jobsPreview__counter [class*="icon:"]::before {
    display: none;
  }
  .table--showPersonnel .table__cell.field\:assignees {
    overflow: hidden;
    flex-flow: row nowrap;
  }
  .table--showPersonnel .table__cell.field\:actions .button span {
    display: none;
  }
  .table--showPersonnel .table__cell.field\:actions .button::before {
    margin-right: 0;
  }
}
@container listTable (width < 51.25rem) {
  .table--showPersonnel .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [jobs] minmax(var(--row--height--collapsed), auto) repeat(auto-fit, minmax(var(--row--height--collapsed), auto)) [footer] minmax(var(--row--height--collapsed), min-content);
  }
  .table--showPersonnel .table__cell .jobsPreview__descMissing {
    display: block !important;
  }
  .table--showPersonnel .table__cell .jobsPreview__descAssigned {
    display: flex !important;
  }
  .table--showPersonnel .table__cell .jobsPreview__count {
    border: none;
    transform: none !important;
  }
  .table--showPersonnel .table__cell.field\:jobs,
  .table--showPersonnel .table__cell .jobsPreview {
    display: contents;
  }
  .table--showPersonnel .table__cell .jobsPreview__counter {
    grid-row: header;
    grid-column: menu;
    place-self: center end;
    flex-flow: row-reverse;
    padding: 0.25em 0.5em;
    --jobCount--font-size: 0.875rem;
    --jobCount--min-width: 1.75em;
    --jobCount--height: 2em;
    --jobCount--padding: 0;
  }
  .table--showPersonnel .table__cell .jobsPreview__counter [class*="icon:"]::before {
    display: none;
  }
  .table--showPersonnel .table__cell .jobsPreview__desc {
    grid-row: jobs;
    grid-column: A;
    font-size: 0.813rem;
    padding: var(--cell--pad-y) var(--cell--pad-x);
    border-left: var(--cell--separator);
    border-top: var(--cell--separator);
  }
  .table--showPersonnel .table__cell .jobsPreview__desc strong {
    display: block;
  }
  .table--showPersonnel .table__cell.field\:date {
    grid-row: jobs;
    grid-column: menu;
    align-items: flex-start;
    justify-content: center;
  }
  .table--showPersonnel .table__cell.field\:assignees::before,
  .table--showPersonnel .table__cell .jobsPreview__descAssigned::before {
    flex: 0 1 100%;
    font: 500 0.8125rem / 1.5385 var(--font-family);
    color: var(--color-text-muted);
  }
  .table--showPersonnel .table__cell.field\:assignees::before {
    content: "Zuständige:";
  }
  .table--showPersonnel .table__cell .jobsPreview__descAssigned:not(:empty)::before {
    content: "Personal:";
    margin-bottom: 0.25em;
  }
  .table--showPersonnel .table__cell .jobsPreview__descMissing + .jobsPreview__descAssigned {
    margin-top: 0.25em;
  }
  .table--showPersonnel .table__cell .jobsPreview__descMissing + .jobsPreview__descAssigned::before {
    display: none;
  }
  .table--showPersonnel .table__cell.field\:assignees {
    grid-column: A;
    grid-row: footer;
  }
  .table--showPersonnel .table__cell.field\:actions {
    place-content: center end;
  }
  .table--showPersonnel .table__cell.field\:actions .button {
    --button--padding-x-icon: calc(var(--button--padding-x) * 0.8);
  }
  .table--showPersonnel .table__cell.field\:actions .button span {
    display: none;
  }
  .table--showPersonnel .table__cell.field\:actions .button::before {
    margin-right: 0;
  }
}
@container listTable (37.5rem <= width < 51.25rem) {
  .table--showPersonnel .table__row {
    grid-template-columns: [ctrl] var(--td--ctrl) [A] 2fr [aside] minmax(var(--td--XL), 1fr) [menu] minmax(var(--td--SM), min-content);
    grid-template-rows: [header] var(--row--height--collapsed-primary) [jobs] minmax(var(--row--height--collapsed), auto);
  }
  .table--showPersonnel .table__cell .jobsPreview__desc {
    grid-column: A;
  }
  .table--showPersonnel .table__cell.field\:assignees {
    grid-row: jobs;
    grid-column: aside;
    place-self: start start;
  }
  .table--showPersonnel .table__cell.field\:date {
    grid-row: header;
    grid-column: aside;
    align-items: center;
    justify-content: flex-end;
    border-left: none;
    border-right: var(--cell--separator);
  }
  .table--showPersonnel .table__cell.field\:actions {
    grid-row: jobs;
  }
}
@container listTable (width < 26.25rem) {
  .table--showPersonnel .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [jobs] minmax(var(--row--height--collapsed), auto) [team] minmax(var(--row--height--collapsed), auto) [footer] minmax(var(--row--height--collapsed), min-content);
  }
  .table--showPersonnel .table__cell .jobsPreview__counter {
    --jobCount--font-size: 0.75rem;
    --jobCount--min-width: 1.25em;
    --jobCount--height: 1.5em;
    gap: 0.167em;
    place-self: start end;
  }
  .table--showPersonnel .table__cell .jobsPreview__desc {
    grid-column: A / span all;
  }
  .table--showPersonnel .table__cell.field\:assignees {
    grid-row: team;
    grid-column: A / span all;
  }
  .table--showPersonnel .table__cell.field\:date {
    grid-row: footer;
    grid-column: A;
    justify-content: flex-start;
  }
}
.table--days .dateInput__value {
  font-weight: 500;
}
@container fieldTable (43.75rem <= width) {
  .table--days .table__header,
  .table--days .table__row {
    grid-template-columns: [label] minmax(var(--td--std), 2fr) [date] minmax(var(--td--LG), 3fr) repeat(3, minmax(var(--td--SM), 1.5fr)) [extra] minmax(var(--td--ctrl), min-content) [ctrl] var(--td--ctrl);
  }
  .table--days .table__row {
    grid-template-rows: var(--row--height);
  }
  .table--days .table__cell.field\:label {
    grid-column: label;
  }
  .table--days .table__cell.field\:date {
    grid-column: date;
  }
  .table--days .table__cell.field\:extra {
    grid-column: extra;
  }
  .table--days .table__cell.for\:onDelete {
    grid-column: ctrl;
  }
}
@container fieldTable (width < 43.75rem) {
  .table--days .table__row {
    grid-template-columns: [hand] var(--ctrl) [A] 1.125fr [B] 1fr [C] 1fr [ctrl] var(--ctrl);
    grid-template-rows: [header] var(--row--height--collapsed-primary) [info] var(--row--height--collapsed);
  }
  .table--days .table__cell.for\:onDelete {
    grid-column: hand;
    grid-row: 1;
  }
  .table--days .table__cell.for\:setOpen {
    grid-column: ctrl;
  }
  .table--days .table__cell.field\:date {
    grid-column: A;
    grid-row: header;
  }
  .table--days .table__cell.field\:label {
    grid-column: B;
    grid-row: header;
  }
  .table--days .table__cell.field\:extra {
    grid-column: C;
    grid-row: header;
  }
  .table--days .table__cell.field\:doorsTime {
    grid-column: A;
    grid-row: info;
  }
  .table--days .table__cell.field\:startTime {
    grid-column: B;
    grid-row: info;
  }
  .table--days .table__cell.field\:endTime {
    grid-column: C;
    grid-row: info;
  }
  .table--days :is([class*="field"][class*="Time"], .field\:extra) {
    display: flex;
    align-items: center;
  }
  .table--days :is([class*="field"][class*="Time"], .field\:extra)::after {
    padding: 0.125em 0.5em 0;
    font-size: 0.813rem;
    color: var(--color-text-faded);
  }
  .table--days :is([class*="field"][class*="Time"], .field\:extra) .timeInput {
    flex: 0 1 5rem;
    padding-right: 0.125em;
    padding-left: 0.5em;
  }
  .table--days :is([class*="field"][class*="Time"], .field\:extra) .field__input--checkbox {
    width: 2em;
  }
  .table--days .field\:doorsTime::after {
    content: "Doors";
  }
  .table--days .field\:startTime::after {
    content: "Start";
  }
  .table--days .field\:endTime::after {
    content: "Curfew";
  }
  .table--days .field\:extra::after {
    content: "Extra Day";
  }
}
@container fieldTable (width < 36.25rem) {
  .table--days .table__row {
    grid-template-columns: [hand] var(--ctrl) [A] 1fr [B] 1fr [ctrl] var(--ctrl);
    grid-template-rows: [header] var(--row--height--collapsed-primary) repeat(2, var(--row--height--collapsed));
  }
  .table--days .table__cell.field\:date {
    grid-column: A;
    grid-row: header;
  }
  .table--days .table__cell.field\:label {
    grid-column: B;
    grid-row: header;
  }
  .table--days .table__cell.field\:doorsTime {
    grid-column: A;
    grid-row: 2;
  }
  .table--days .table__cell.field\:extra {
    grid-column: B;
    grid-row: 2;
  }
  .table--days .table__cell.field\:startTime {
    grid-column: A;
    grid-row: 3;
  }
  .table--days .table__cell.field\:endTime {
    grid-column: B;
    grid-row: 3;
  }
}
@container fieldTable (width < 24.375rem) {
  .table--days .table__row {
    grid-template-columns: [hand] var(--ctrl) [A] 1fr [ctrl] var(--ctrl);
    grid-template-rows: [header] var(--row--height--collapsed-primary) repeat(5, var(--row--height--collapsed));
  }
  .table--days .table__cell[class*="field:"] {
    grid-column: A / ctrl -1;
    grid-row: auto;
  }
  .table--days .table__cell.field\:date {
    grid-row: header;
  }
}
@container fieldTable (50rem <= width) {
}
@container fieldTable (width < 50rem) {
  .table--shows .table__row {
    grid-template-columns: [hand] var(--dnd) [A] 1fr [B] 1fr [ctrl] var(--ctrl);
  }
}
@container fieldTable (width < 23.75rem) {
  .table--shows .table__row {
    grid-template-columns: [hand] var(--dnd) [A] 1fr [ctrl] var(--ctrl);
  }
}
@container fieldTable (34.375rem <= width < 50rem) {
  .table--shows .table__row {
    grid-template-columns: [hand] var(--dnd) [A] 2fr [B] 1fr [C] 1fr [ctrl] var(--ctrl);
  }
}
.table--shows .table__cell.is\:primary {
  font-weight: 500;
}
@container fieldTable (50rem <= width) {
  .table--shows .table__cell .field\:artist {
    grid-column: artist;
  }
  .table--shows .table__cell .field\:day {
    grid-column: day;
  }
  .table--shows .table__cell .field\:venue {
    grid-column: venue;
  }
  .table--shows .table__cell .field\:billing {
    grid-column: billing;
  }
  .table--shows .table__cell .field\:startTime {
    grid-column: start;
  }
  .table--shows .table__cell .field\:endTime {
    grid-column: end;
  }
  .table--shows .table__cell .field\:stage {
    grid-column: stage;
  }
}
@container fieldTable (width < 50rem) {
  .table--shows .table__cell[class*="field:"] {
    grid-column: A / ctrl -1;
  }
  .table--shows .table__cell.field\:startTime {
    grid-column: A / span 1;
    grid-row: times;
  }
  .table--shows .table__cell.field\:endTime {
    grid-column: B;
    grid-row: times;
  }
  .table--shows .table__cell.for\:dragHandle {
    grid-column: hand;
    grid-row: header;
  }
  .table--shows .table__cell.for\:setOpen {
    grid-column: ctrl;
    grid-row: header;
  }
  .table--shows .table__cell.field\:endTime,
  .table--shows .table__cell.field\:billing {
    border-right: var(--cell--separator);
  }
  .table--shows .table__cell.field\:endTime,
  .table--shows .table__cell.field\:startTime {
    border-top: var(--cell--separator);
  }
}
@container fieldTable (width < 23.75rem) {
  .table--shows .table__cell[class*="field:"] {
    grid-column: A / ctrl -1 !important;
  }
  .table--shows .table__cell.field\:startTime,
  .table--shows .table__cell.field\:endTime {
    grid-row: auto !important;
  }
}
@container fieldTable (50rem <= width) {
  .table--shows.is\:show .table__row,
  .table--shows.is\:show .table__header {
    grid-template-columns: [hand] var(--td--hand) [artist] minmax(var(--td--std), 2.5fr) [order] minmax(var(--td--std), 1.5fr) repeat(auto-fit, minmax(var(--td--std), 1fr)) [ctrl] var(--td--ctrl);
  }
}
@container fieldTable (width < 50rem) {
  .table--shows.is\:show .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [order] minmax(var(--row--height--collapsed), auto) [times] var(--row--height--collapsed);
  }
}
@container fieldTable (50rem <= width) {
  .table--shows.is\:show .table__cell.for\:dragHandle {
    grid-column: hand;
  }
}
@container fieldTable (width < 50rem) {
  .table--shows.is\:show .table__cell.field\:startTime {
    grid-column: A;
  }
  .table--shows.is\:show .table__cell.field\:endTime {
    grid-column: B;
  }
}
@container fieldTable (50rem <= width) {
  .table--shows.is\:festival .table__row,
  .table--shows.is\:festival .table__header {
    grid-template-columns: [hand] var(--td--hand) [artist] minmax(var(--td--LG), 5fr) [day] minmax(var(--td--SM), 2fr) [stage] minmax(var(--td--std), 3.5fr) [order] minmax(var(--td--std), 3fr) [start] minmax(var(--td--SM), 1fr) [end] minmax(var(--td--SM), 1fr) [ctrl] var(--td--ctrl);
  }
}
@container fieldTable (width < 50rem) {
  .table--shows.is\:festival .table__header > :not(.is\:primary) {
    display: none;
  }
}
@container fieldTable (width < 50rem) {
  .table--shows.is\:festival .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [stage] minmax(var(--row--height--collapsed), auto) [order] minmax(var(--row--height--collapsed), auto) [times] var(--row--height--collapsed);
  }
  .table--shows.is\:festival .table__row:not(.is\:open ) {
    max-height: var(--row--height--collapsed-primary);
    overflow: hidden;
  }
}
@container fieldTable (width < 23.75rem) {
  .table--shows.is\:festival .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [stage] minmax(var(--row--height--collapsed), auto) [order] minmax(var(--row--height--collapsed), auto) [day] minmax(var(--row--height--collapsed), auto) [times] var(--row--height--collapsed);
  }
}
@container fieldTable (34.375rem <= width < 50rem) {
  .table--shows.is\:festival .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [times] minmax(var(--row--height--collapsed), auto);
  }
}
@container fieldTable (50rem <= width) {
}
@container fieldTable (width < 50rem) {
  .table--shows.is\:festival .table__cell.field\:day {
    grid-column: A;
    grid-row: order;
  }
  .table--shows.is\:festival .table__cell.field\:billing {
    grid-column: B;
    grid-row: order;
  }
  .table--shows.is\:festival .table__cell.field\:venue {
    grid-row: stage;
    border-right: var(--cell--separator);
  }
}
@container fieldTable (34.375rem <= width < 50rem) {
  .table--shows.is\:festival .table__cell.for\:dragHandle {
    grid-column: hand;
    grid-row: header;
  }
  .table--shows.is\:festival .table__cell.for\:onDelete {
    grid-column: hand;
    grid-row: 2;
  }
  .table--shows.is\:festival .table__cell.for\:setOpen {
    display: block;
    visibility: visible;
    grid-column: ctrl;
    grid-row: 1;
  }
  .table--shows.is\:festival .table__cell.field\:artist {
    grid-column: A;
    grid-row: header;
  }
  .table--shows.is\:festival .table__cell.field\:day {
    grid-column: B;
    grid-row: header;
  }
  .table--shows.is\:festival .table__cell.field\:billing {
    grid-column: C;
    grid-row: header;
  }
  .table--shows.is\:festival .table__cell.field\:venue {
    grid-column: A;
    grid-row: times;
  }
  .table--shows.is\:festival .table__cell.field\:startTime {
    grid-column: B;
    grid-row: times;
  }
  .table--shows.is\:festival .table__cell.field\:endTime {
    grid-column: C;
    grid-row: times;
  }
}
@container fieldTable (width < 23.75rem) {
  .table--shows.is\:festival .table__cell.field\:day {
    grid-row: day;
  }
}
@container fieldTable (43.75rem <= width) {
  .table--ticketVendors .table__header,
  .table--ticketVendors .table__row {
    grid-template-columns: [hand] var(--td--hand) [name] minmax(var(--td--std), 1.5fr) [link] minmax(var(--td--2XL), 3fr) [date] minmax(var(--td--2XL), 2fr) [ctrl] var(--td--ctrl);
  }
  .table--ticketVendors .table__row {
    grid-template-rows: var(--row--height);
  }
  .table--ticketVendors .table__cell.field\:label {
    grid-column: label;
  }
  .table--ticketVendors .table__cell.field\:date {
    grid-column: date;
  }
  .table--ticketVendors .table__cell.field\:extra {
    grid-column: extra;
  }
  .table--ticketVendors .table__cell.for\:onDelete {
    grid-column: ctrl;
  }
}
@container fieldTable (width < 43.75rem) {
  .table--ticketVendors .table__row {
    grid-template-columns: [hand] var(--td--hand) [A] 1fr [B] 2fr [ctrl] var(--td--ctrl);
    grid-template-rows: [header] var(--row--height--collapsed-primary) [date] var(--row--height--collapsed);
  }
  .table--ticketVendors .table__cell.field\:vendor {
    grid-column: A;
    border-bottom: var(--cell--separator);
  }
  .table--ticketVendors .table__cell.field\:url {
    grid-column: B;
  }
  .table--ticketVendors .table__cell.field\:availableFrom {
    grid-column: B;
    grid-row: date;
  }
  .table--ticketVendors .table__cell.field\:availableFrom,
  .table--ticketVendors .table__cell.for\:onDelete {
    border-right: var(--cell--separator);
  }
}
@container fieldTable (width < 26.25rem) {
  .table--ticketVendors .table__row {
    grid-template-columns: [hand] var(--td--hand) [A] 1fr [ctrl] var(--td--ctrl);
    grid-template-rows: [header] var(--row--height--collapsed-primary) repeat(auto-fill, var(--row--height--collapsed));
  }
  .table--ticketVendors .table__cell.has\:input {
    grid-column: A;
    grid-row: auto;
  }
  .table--ticketVendors .table__cell.field\:url {
    grid-row: header;
  }
}
.table--personRoles .table__cell.field\:role {
  grid-column: role;
}
.table--personRoles .table__cell.field\:user {
  grid-column: user;
  font-weight: 500;
}
.table--personRoles .table__cell.field\:role,
.table--personRoles .table__cell.field\:mail,
.table--personRoles .table__cell.field\:phone {
  font-size: 0.938rem;
}
.table--personRoles .table__cell.has\:conflict {
  grid-column: span 2;
}
@container fieldTable (43.75rem <= width) {
  .table--personRoles .table__header,
  .table--personRoles .table__row {
    grid-template-columns: [role] minmax(var(--td--XL), 1.5fr) [user] minmax(var(--td--2XL), 2fr) [mail] minmax(var(--td--std), 2fr) [phon] minmax(var(--td--std), 2fr) [ctrl] var(--td--ctrl);
  }
  .table--personRoles .table__row {
    grid-template-rows: var(--row--height);
  }
}
@container fieldTable (width < 51.25rem) {
  .table--personRoles .table__header,
  .table--personRoles .table__row {
    grid-template-columns: [ctrl] var(--td--ctrl) [role] minmax(var(--td--LG), 2fr) [user] minmax(var(--td--LG), 2fr) [mail] minmax(var(--td--std), 2fr);
  }
  .table--personRoles .table__header :first-child {
    grid-column: role;
  }
  .table--personRoles .table__header :nth-child(4) {
    display: none;
  }
  .table--personRoles .table__row {
    grid-template-rows: [header] var(--row--height--collapsed-primary) [contact] var(--row--height--collapsed);
  }
  .table--personRoles .table__row:not(.is\:open ) {
    max-height: var(--row--height--collapsed-primary);
    overflow: hidden;
  }
  .table--personRoles .table__cell.for\:setOpen {
    display: block;
    visibility: visible;
  }
  .table--personRoles .table__cell.for\:onDelete {
    grid-column: ctrl;
    grid-row: 2;
  }
}
@container fieldTable (width < 43.75rem) {
  .table--personRoles .table__row {
    grid-template-columns: [ctrl] var(--td--ctrl) [role] minmax(var(--td--std), 2fr) [user] minmax(var(--td--LG), 2.5fr);
    grid-template-rows: [header] var(--row--height--collapsed-primary) [contact] var(--row--height--collapsed);
  }
  .table--personRoles .table__cell.field\:mail {
    grid-column: 2;
    grid-row: contact;
  }
  .table--personRoles .table__cell.field\:phone {
    grid-column: 3;
    grid-row: contact;
  }
  .table--personRoles .table__cell.has\:conflict {
    white-space: normal;
  }
}
@container fieldTable (width < 26.25rem) {
  .table--personRoles .table__row {
    grid-template-columns: [ctrl] var(--td--ctrl) [A] minmax(var(--td--std), 2fr);
    grid-template-rows: [header] var(--row--height--collapsed-primary) repeat(3, var(--row--height--collapsed));
  }
  .table--personRoles .table__cell[class*="field:"] {
    grid-column: A;
    grid-row: auto;
  }
  .table--personRoles .table__cell.field\:user {
    grid-row: header;
  }
  .table--personRoles .table__cell.has\:conflict {
    grid-column: A;
    grid-row: 3 / span 2;
    white-space: normal;
  }
}
@media screen and (width < 34.375rem) {
  .select.is\:open .select__menu,
  .contextMenu.is\:open .contextMenu__panel {
    position: fixed !important;
    z-index: var(--appLayer--overlay) !important;
    top: 50vh !important;
    transform: translateY(-50%) translate3d(0, 0, 0) !important;
    bottom: auto !important;
    max-height: 80vh !important;
    max-height: 80svh !important;
    left: 2em !important;
    right: 2em !important;
    width: auto !important;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.1), 0 0.5em 2em rgba(0, 0, 0, 0.2) !important;
  }
  .dateInput.is\:open .datepicker {
    z-index: var(--appLayer--overlay) !important;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.1), 0 0.5em 2em rgba(0, 0, 0, 0.2) !important;
  }
  .select.is\:open::before,
  .dateInput.is\:open::before,
  .contextMenu.is\:open::before {
    pointer-events: none;
    content: '';
    position: fixed;
    z-index: calc(var(--appLayer--overlay) - 10);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0.05em);
  }
}
.metaBox {
  background: #fff;
}
.metaBox + .metaBox {
  margin-top: 1.5rem;
}
.metaBox__label {
  background-color: #000;
  color: #e0e0e0;
  padding: 0.25rem 0.75rem;
}
.metaBox__content {
  padding: 0.75rem 1rem 1rem;
}
.metaBox__section + .metaBox__section {
  border-top: 1px solid #ccc;
}
.metaBox__mainAction {
  display: flex;
}
.metaBox__mainAction .button {
  width: 100%;
}
@media (max-width: 811px) {
  .metaBox__content,
  .metaBox__section {
    display: none;
    visibility: hidden;
  }
  .metaBox__label {
    position: relative;
  }
  .metaBox__label::after {
    content: "\e912";
    font-size: 1rem;
    margin-left: 0.625rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .metaBox.is\:open .metaBox__label::after {
    content: "\e913";
  }
  .metaBox.is\:open .metaBox__content,
  .metaBox.is\:open .metaBox__section {
    display: block;
    visibility: visible;
  }
}
.changes__items {
  font-size: 0.75rem;
  line-height: 1.313rem;
}
.is\:open .changes__items {
  padding: 0.5rem 1rem;
}
.changes__item {
  color: #666;
}
.changes__item + .changes__item {
  margin-top: 0.188rem;
  padding-top: 0.188rem;
  border-top: 0.063rem solid var(--color-bg-fade);
}
.changes__field {
  padding-left: 0.5rem;
}
.changes__values {
  padding-left: 1.5rem;
}
.activeMarker {
  background: #aaa;
  padding: 0.125rem 1rem;
}
.view__tabs {
  background-color: #14161E;
}
.view__tabs .tabNav__item {
  display: flex;
  align-items: center;
  padding: 0.5rem 2.25rem;
  background-color: #a3a3a6;
}
.view__tabs .tabNav__item.is\:active {
  background-color: #e0e0e0;
}
.view__tabs .tabNav__item.has\:error {
  border-top: 0.25rem solid var(--color-text-error);
  padding-top: 0.25rem;
  padding-left: 0.625rem;
}
.view__tabs .tabNav__item.has\:error::before {
  content: "\e900";
  color: var(--color-text-error);
  margin-right: 0.625rem;
}
.entityVariants__item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-bottom: 0.063rem solid #ccc;
  font-size: 1rem;
  line-height: 1.875rem;
}
.entityVariants__item:hover {
  background-color: #f6f6f6;
}
.entityVariants__item.is\:active {
  background-color: #A5CC2E;
  color: #000;
  cursor: default;
}
.entityVariants__edit {
  margin-left: 0.5rem;
  visibility: hidden;
}
.entityVariants__item:hover:not(.is\:active) .entityVariants__edit {
  visibility: visible;
}
.entityVariants__delete {
  margin-left: auto !important;
  margin-right: 0;
  color: #999;
}
.is\:active .entityVariants__delete {
  color: #799621;
}
.resetAppearance {
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}
.coverParent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
