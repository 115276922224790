// main: ../build.less
@import (reference) '../config.less';

.editChanges {
	// background-color: red;
	grid-column: span 2;
	margin-top: .rem(24)[];
	padding: .rem(6)[] .rem(6)[] .rem(6)[] .rem(16)[];
	border-radius: .rem(3)[];

	display: flex;
	align-items: center;

	border: 1px solid #ccc;

	&.is\:changed { 
		background-color: @color-bg-highlight;
		border-color: @color-text-highlight;
	}
	&.is\:canceled { 
		background-color: @color-bg-error;
		border-color: @color-text-error;
	}

	&__changes { flex: 1 1 100%; }
	&__action { 
		flex: 1 1 .rem(240)[]; 
		align-self: flex-start;
		margin-left: .rem(24)[];
	}

	&__change {
		padding: .rem(4)[] .rem(0)[];
		& + & {
			border-top: 1px solid rgba(0,0,0,.2);
		}
		em { 
			font-style: italic;
			color: @color-text-muted; 
		}
		b { 
			// font-weight: 600; 
			text-decoration: line-through;
		}
	}
}