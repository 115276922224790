// main: ../build.less
@import (reference) '../config';

.dateInput 
{
	&:extend(CONFIG[input] all);
	&:extend( PROTO[input] all);

	--dateInput--stackingContext: var(--viewLayer--inputs);
	--dateInput--icon-size: .rem(20)[];

	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	padding-top: 0;
	z-index: var(--dateInput--stackingContext);

	&.is\:open {
		--dateInput--stackingContext: var(--viewLayer--isOpen);
	}
	padding-bottom: 0;

	&::before {
		&:extend(.iconLike all);
		content: @icon-date;
		display: flex;
		padding-right: var(--input--padding-x);
		margin-right: var(--input--padding-x);
		border-right: .rem(1)[] solid var(--input--color-border);
		font-size: var(--dateInput--icon-size);
		line-height: var(--input--height);
		color: var(--input--color-border);
		opacity: 0.5;

		.table__cell & {
			border: none;
			margin-right: .rem(0)[];
		}
	}

	&__calendar {
		display: none;
		overflow: hidden;
		position: absolute;
		z-index: var(--dateInput--stackingContext);
		visibility: hidden;
	}
	&.is\:open &__calendar {
		display: block;
		visibility: visible;
	}

	&__value {
		font-size: var(--input--font-size);
		line-height: var(--input--height);
		display: none;
		width: 100%;
		white-space: nowrap;
	}

	&__input {
		.resetAppearance;
		padding: 0;
		height: auto;
		font-size: var(--input--font-size);
		line-height: var(--input--height);
	}

	&.has\:value &__value {
		display: block;
	}

	&.has\:value &__input {
		opacity: 0;
	}

	&.has\:value &__input::placeholder {
		color: inherit;
	}

	&.is\:focus &__value {
		display: none;
	}

	&.is\:focus &__input {
		opacity: 1;
	}

	&.is\:focus {
		border-color: var(--input--color-border-focus);
	}



	.has\:error > & {
		border-color: var(--color-text-error);
		background-color: var(--color-bg-error);
	}
}
