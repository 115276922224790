// main: ../build.less
@import (reference) "../config";


.showStatusSection {
	// margin-top: .rem(24)[];
	&__title {
		padding: .rem(8)[] .rem(12)[] .rem(10)[];
		font-weight: bold;
		// color: @color-text-muted;
		// padding-left: .rem(12)[];
	}
	@media @AppUI--layoutSide--stacked {
		max-height: 14em;
		overflow: hidden;
		position: relative;
		margin-bottom: 0;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 8em;
			background: linear-gradient(rgba(255,255,255,0), var(--color-theme-bg-main));
		}
	}
}
.showStatusForm
{
	background-color: @color-bg;
	// margin-top: .rem(8)[];

	&:focus-within {
		background-color: @color-bg-highlight;
		transition: background-color .3s ease-out;
	}

	&__field {
		border-top: .rem(1)[] solid var(--color-bg-muted);

		textarea {
			border: none;
			background-color: transparent;
			height: .rem(54)[];
			padding: .rem(10)[] .rem(16)[];

			&:focus {
				outline: none;
			}
			&::placeholder {
				color: rgba(0,0,0,.5);
			}
		}
	}

	&__actions {
		padding: .rem(0)[] .rem(10)[] .rem(12)[];
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		.field__label { padding: 0; }
		.field__input--checkbox {
			font-size: .rem(14)[];  line-height: .rem(24)[];
		}
	}
}

.showStatusMessage {
	background-color: @color-bg;
	padding: .rem(12)[] .rem(16)[];
	white-space: pre-line;

	&__meta {
		.font--small;
	}
	&__author {
		color: @color-text-faded;
		font-weight: 500;
	}
	&__timestamp {
		margin-left: .rem(6)[];
		color: @color-text-faded;
		float: right;
	}
	&__message {
		margin-top: .rem(2)[];
	}
}

.showStatusHistory {
	
	&__label {
		margin-top: .rem(16)[];
		font-weight: bold;
		color: @color-text-muted;
	}
	.showStatusMessage {
		opacity: 0.85;
		&:hover { opacity: 1; }
		margin-top: .rem(6)[];
		// background: transparent;
		// #set.border( bottom, 1, solid @color-bg-muted );
	}
	&__footer {
		display: flex;
		justify-content: center;
		padding: .rem(12)[] .rem(20)[];
	}
}
