// main: ../build.less
@import (reference) "../config";

.jobsPreview
{
	--jobCount--bg: mix( @color-bg, @color-bg-faded, 50%);
	--jobCount--text: mix( @color-bg, @color-text-muted, 70%);
	--jobCount--border: none;

	--jobCount--font-size: .rem(18)[];
	--jobCount--height: 2em;
	--jobCount--min-width: 2.5em;
	--jobCount--padding: .em(6)[] .em(12)[];

	&, &__counter {
		display: flex;
		place-self: center start;
		align-items: center;
		flex-flow: row wrap;
		gap: .em(6)[] .em(4)[];
		padding-right: 0.5em;
	
		@media @tablet-large {
			flex-flow: row nowrap;
		}
	}

	&__count {
		display: flex;
		align-items: center;
		justify-content: center;

		font-size: var(--jobCount--font-size);
		line-height: 1;
		font-weight: 600;

		min-width: var(--jobCount--min-width);
		max-width: 100%;
		height: var(--jobCount--height);
		border-radius: 0.25rem;
		// margin-right: .rem(4)[];
		padding: var(--jobCount--padding);
		cursor: default;

		background: var(--jobCount--bg);
		color: var(--jobCount--text);
		border: var(--jobCount--border);

		[class*="icon:"]::before {	
			margin-left: -0.25rem;
			margin-right: 0.2rem;
			opacity: 0.5;
			font-size: .rem(14)[];
		}

		&.count\:assigned,
		&.count\:missing {
			transition: transform 0.16s ease-out;
			&:not(.is\:active, .is\:empty) {
				cursor: pointer;
				&:hover { transform: scale(1.075); }
			}
			&.is\:active[data-tooltip]::after {
				display: none;
			}
		}

		&.count\:assigned {
			--jobCount--bg: mix( @color-status-ann, @color-bg, 40% );
			--jobCount--text: mix( @color-status-ann, @color-secondary, 50% );
			&.is\:active {
				--jobCount--text: @color-secondary;
				--jobCount--border: .rem(3)[] solid mix( @color-status-ann, @color-secondary, 80% );
			}
		}
		&.count\:missing {
			--jobCount--bg: @color-bg-highlight;
			--jobCount--text:@color-text-highlight;
			
			&.is\:active {
				--jobCount--text: mix( @color-text, @color-text-highlight, 30% );
				--jobCount--border: .rem(3)[] solid mix(  @color-bg-highlight, @color-text-highlight, 60% );
			}
		}
		&.count\:conflict {
			--jobCount--bg: mix( @color-bg-error, @color-bg, 70% );
			--jobCount--text: @color-text-error;
			// margin-left: .rem(-3)[];
			// --jobCount--border: .rem(2)[] solid mix( @color-text-error, @color-bg, 50% );
		}

		&.is\:empty {
			--jobCount--bg: mix( @color-bg, @color-bg-faded, 50%);
			--jobCount--text: mix( @color-bg, @color-text-muted, 70%);
			--jobCount--border: none !important;
		} // default colors are now empty state

		.contextMenu__panel { white-space: nowrap;}
	}
	
	&__desc {
		color: var(--color-text-muted);
		min-height: 100%;
		font-size: .rem(14)[];
		line-height: .rem(18)[];

		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: center;	
	}


	&__descAssigned {
		display: flex;
		flex-flow: row wrap;

		.has\:conflict {
			> .avatar {
				outline: .rem(2)[] solid @color-text-error;
				outline-offset: .rem(2)[];
				margin-right: .rem(4)[];
				transform: scale(90%);
				opacity: 0.666;
			}
		}
		.contextMenu\:tooltip:has(.has\:conflict) {
			+ .contextMenu\:tooltip:has(.has\:conflict) {
				margin-left: .rem(2)[];
			}
		}
	}

	&__descMissing:not(.is\:open),
	&__descAssigned:not(.is\:open) {
		display: none;
	}

	// Removed, but kept in Component code for now
	// &__descConflict {
	// 	color: @color-text-error;
	// }
}
