// out: .test/table--entityList.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";



.table__row 
{
	@container listTable (@listTable--expanded)
	{
		:is(
			.table--entityList,
			.table--venueList,
			.table--artistList,
			.table--companyList,
			.table--imageFormatsList,
			.table--eventBrandsList,
			.table--publishingTargetsList
		) & {
			--entityRow-id-min: .rem(30)[];
			--entityRow-id-max: .rem(48)[];
			--entityRow-menu-min: .rem(60)[];
			--entityRow-menu-max: .rem(72)[];
	
			grid-template-columns:
				[id]   minmax(var(--entityRow-id-min), var(--entityRow-id-max))
				  repeat(auto-fit, minmax(var(--td--std), 1fr ))
				[menu] minmax(var(--entityRow-menu-min), var(--entityRow-menu-max));
		}
	}
}

.table--sponsors {
	@sponsorTable--adaptive:  #inline.minmax( 500px, 820px )[];

	@container fieldTable (@sponsorTable--adaptive) {
		.table__row {
			grid-template-columns: [ctrl] var(--ctrl) [A] 1.25fr [B] 1fr;
			grid-template-rows: [header] var(--row--height--collapsed-primary);
		}
		.table__cell {
			&.field\:name { grid-column: A; }
			&.field\:type { grid-column: B; }
			&.for\:setOpen { display: none !important; }
			&.for\:onDelete { 
				grid-row: header; 
				grid-column: ctrl
			}
		}
	}
}

