// out: .test/table--days.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


.table--days {
	@daysTable--compact:  #inline.max( 580px )[];
	@daysTable--minimal:  #inline.max( 390px )[];

	.dateInput__value { font-weight: 500;}

	@container fieldTable (@fieldTable--expanded) {
		.table__header,
		.table__row {
			grid-template-columns:
				[label] minmax(var(--td--std), 2fr)
				[date]  minmax(var(--td--LG), 3fr)
				  repeat( 3, minmax(var(--td--SM), 1.5fr ))
				[extra] minmax(var(--td--ctrl), min-content)
				[ctrl]  var(--td--ctrl);
		}
		.table__row {
			grid-template-rows: var(--row--height);
		}

		.table__cell {
			&.field\:label  { grid-column: label; }
			&.field\:date   { grid-column: date; }
			&.field\:extra  { grid-column: extra; }
			&.for\:onDelete { grid-column: ctrl; }
		}
	}

	@container fieldTable (@fieldTable--collapsed) {
		.table__row {
			grid-template-columns:
				[hand] var(--ctrl) 
				[A] 1.125fr [B] 1fr [C] 1fr 
				[ctrl] var(--ctrl);

			grid-template-rows: 
				[header] var(--row--height--collapsed-primary)
				[info]   var(--row--height--collapsed);
		}
		.table__cell {
			&.for\:onDelete { grid-column: hand; grid-row: 1; }
			&.for\:setOpen  { grid-column: ctrl;  }
	
			&.field\:date  { grid-column: A; grid-row: header; }
			&.field\:label { grid-column: B; grid-row: header; }
			&.field\:extra { grid-column: C; grid-row: header; }
	
			&.field\:doorsTime { grid-column: A; grid-row: info}
			&.field\:startTime { grid-column: B; grid-row: info}
			&.field\:endTime   { grid-column: C; grid-row: info}
		}

		:is([class*="field"][class*="Time"], .field\:extra) {
			display: flex;
			align-items: center;
			&::after { 
				padding: 0.125em 0.5em 0; 
				font-size: .rem(13)[];
				color: var(--color-text-faded);
			}
			.timeInput { 
				flex: 0 1 .rem(80)[]; 
				padding-right: 0.125em; 
				padding-left: 0.5em;
			}
			.field__input--checkbox { width: 2em;}
		}
		.field\:doorsTime::after { content: "Doors"; }
		.field\:startTime::after { content: "Start"; }
		.field\:endTime::after { content: "Curfew"; }
		.field\:extra::after { content: "Extra Day"; }
	}

	@container fieldTable (@daysTable--compact) {
		.table__row {
			grid-template-columns:
				[hand] var(--ctrl) 
				[A] 1fr [B] 1fr
				[ctrl] var(--ctrl);
				
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary)
				repeat(2, var(--row--height--collapsed));
		}
		.table__cell {
			&.field\:date      { grid-column: A; grid-row: header; }
			&.field\:label     { grid-column: B; grid-row: header; }
			&.field\:doorsTime { grid-column: A; grid-row: 2; }
			&.field\:extra     { grid-column: B; grid-row: 2; }
			&.field\:startTime { grid-column: A; grid-row: 3; }
			&.field\:endTime   { grid-column: B; grid-row: 3; }
		}
	}

	@container fieldTable (@daysTable--minimal) {
		.table__row {
			grid-template-columns:
				[hand] var(--ctrl) [A] 1fr [ctrl] var(--ctrl);
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary)
				repeat(5, var(--row--height--collapsed));
		}
		.table__cell {
			&[class*="field:"] { 
				grid-column: A / ctrl -1; 
				grid-row: auto;
			}
			&.field\:date { grid-row: header; }
		}
	}
}