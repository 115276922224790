// out: .test/table--entityList.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


// [class*="table--internals."],
.table--personRoles
{
	@AssigneeTable--trimmed:  #inline.max( 820px )[];

	.table__cell {
		&.field\:role  { grid-column: role; }
		&.field\:user  { 
			grid-column: user;
			font-weight: 500;
		}
		&.field\:role,
		&.field\:mail,
		&.field\:phone { 
			font-size: .rem(15)[];
		}
		&.has\:conflict {
			grid-column: span 2;
		}
	}

	@container fieldTable (@fieldTable--expanded) {
		.table__header,
		.table__row {
			grid-template-columns:
				[role] minmax(var(--td--XL), 1.5fr)
				[user] minmax(var(--td--2XL), 2fr)
				[mail] minmax(var(--td--std), 2fr)
				[phon] minmax(var(--td--std), 2fr)
				[ctrl] var(--td--ctrl);
		}
		.table__row {
			grid-template-rows: var(--row--height);
		}
		&.table--table--internals.jobs {}

		.table__cell {
		}
	}

	@container fieldTable (@AssigneeTable--trimmed) {
		.table__header,
		.table__row {
			grid-template-columns:
				[ctrl] var(--td--ctrl)
				[role] minmax(var(--td--LG), 2fr)
				[user] minmax(var(--td--LG), 2fr)
				[mail] minmax(var(--td--std), 2fr);
		}
		.table__header {
			:first-child { grid-column: role; }
			:nth-child(4) { display: none;}
		}
		.table__row {
			&:not(.is\:open ) {
				max-height: var(--row--height--collapsed-primary);
				overflow: hidden;
			}
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary)
				[contact] var(--row--height--collapsed);
		}
		.table__cell {
			&.for\:setOpen { display: block; visibility: visible; }
			&.for\:onDelete { grid-column: ctrl; grid-row: 2; }
		}
	}

	@container fieldTable (@fieldTable--collapsed) {
		.table__row {
			grid-template-columns:
				[ctrl] var(--td--ctrl)
				[role] minmax(var(--td--std), 2fr)
				[user] minmax(var(--td--LG), 2.5fr);
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary)
				[contact] var(--row--height--collapsed);
		}
		.table__cell {
			&.field\:mail  { grid-column: 2; grid-row: contact; }
			&.field\:phone { grid-column: 3; grid-row: contact; }
			&.has\:conflict { 
				// grid-column: A;
				// grid-row: 3 / span 2; 
				white-space: normal;
			}
		}
	}
	@container fieldTable (@fieldTable--minimal) {
		.table__row {
			grid-template-columns:
				[ctrl] var(--td--ctrl)
				[A] minmax(var(--td--std), 2fr);
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary)
				repeat(3, var(--row--height--collapsed));
		}
		.table__cell {
			&[class*="field:"] { grid-column: A; grid-row: auto; }
			&.field\:user  { grid-row: header; }
			&.has\:conflict { 
				grid-column: A;
				grid-row: 3 / span 2; 
				white-space: normal;
			}
		}
	}

}