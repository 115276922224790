// out: .test/typography.css
// main: ../build.less
@import (reference) "../config";

body {
	font-family:  var(--font-family);
	background-color: var(--color-theme-bg-faded);
	color: var(--color-text);
}

// Inject presets for font shorthands into :root variable declarations
#AddVars() {
	.root() {
		/* Typographic Presets */
		--font--mini:    .fslh(12, 16, em)[] @font-family;
		--font--small:   .fslh(14, 18, em)[] @font-family;
		--font--normal:  .fslh(16, 24, em)[] @font-family;
		--font--label:   .fslh(15, 19, em)[] @font-family;
		--font--medium:  .fslh(18, 24, em)[] @font-family;
		--font--large:   .fslh(21, 28, em)[] @font-family;
		--font--xlarge:  .fslh(24, 30, em)[] @font-family;
		--font--heading: .fslh(28, 36, em)[] @font-family;
		--font--display: .fslh(40, 60, em)[] @font-family;
	}
};

// Link Styles
a:not(.blockLink, .button) { color: var(--color-secondary); }
a:not(.blockLink, .button):hover { color: var(--color-secondary-faded); }

a.blockLink {
	color: inherit;
	text-decoration: inherit;
}

a[class*="icon:"] { 
	text-decoration: none;
	&::before { 
		margin-right: 0.2em; 
		font-size: inherit;
	}
}

// Text Formatting
strong { font-weight: bold; }

b { font-weight: 500; }
i { font-style: italic;}

del {
	text-decoration: line-through; 
	opacity: 0.666;
}

code {
	font-family: monospace;
	// font-size: 0.95em;
	background-color: rgba(0,0,0,0.08);
	padding: 0.125em 0.333em 0.125em;
	border-radius: 0.125em;
}

// Utility Classes for Styling Text
.nobr { white-space: nowrap; }

.timestamp {
	> * { letter-spacing: +0.05em; }
	b { font-weight: bold; opacity: 0.25; }
}

.dateTimeStamp {
	font-size: .em(14)[];
	font-weight: 500; 
	letter-spacing: +0.0333em;
	em { color: var(--palette--neutral-500); }
	i  { color: var(--palette--neutral-200); }
	b  { color: var(--palette--neutral-300);}
	b[class*='icon:']::before { 
		font-size: 1em !important;
		margin: 0 0.2em 0 0.5em;
		opacity: 0.666;
	}
}

.bigNumber {
	font-weight: 900;
	font-size: .rem(42)[];
	line-height: 1;
}

// Utility classes for sizing text blocks
.copy\:micro { font-size: .rem(12)[]; }
.copy\:small { font-size: .rem(14)[]; }
.copy\:large { font-size: .rem(19)[]; }


// Wrapper to apply editorial typography to a block of content
.editorialCopy 
{
	--copy--padding-x: .rem(16)[];

	padding: 0 var(--copy--padding-x);

	:where(h2) { font: 500 var(--font--large); }
	:where(h3) { font: 500 .fslh(20, 24, em)[] var(--font-family); }
	:where(h4) { font: 500 var(--font--normal); }

	:where(h2, h3, h4) { 
		+ p { margin-top: .rem(10)[]; }
		&::before { margin-right: 0.33rem; }
	}
	[class*='icon:']::before { margin-right: 0.2em; }

	em { font-style: italic; }

	p + p, p + ul, p + ol,
	ul + p, ul + ul,
	ol + p, ol + ol 
	{	
		margin-top: 1em;
	}

	ul:not(.propList) {
		list-style: circle;
		margin-left: 1em;
		li::marker { 
			color: var(--color-text-muted);
		}
	}
}


// 2-up instruction list with nested step numbers
.instructions 
{
	&__stepLabel {
		margin-bottom: 0.5em;
	}
	&__step {
		border-top: 1px solid var(--color-ui-border);
		padding: 1em 0.75em;

		@media @tablet {
			display: grid;
			grid-template-columns: .rem(120)[] 1fr;
			gap: 1em;
		}

		p { margin-top: 0;}

		ol {
			list-style-type: decimal;
			list-style-position: outside;
			margin-left: 1.5em;
	
			li { 
				padding-left: 0.5em;
				& + li { margin-top: 0.5em; }
			}
			li::marker { 
				color: var(--color-text-faded); 
				font-weight: 500;
			}
		}
	}
}

// Legacy font-size utility classes
.font--mini { font:   .fslh(12, 16, em)[] @font-family; }
.font--small { font:  .fslh(14, 18, em)[] @font-family; }
.font--normal { font: .fslh(16, 24, em)[] @font-family; }
.font--label { font:  .fslh(15, 19, em)[] @font-family; }
.font--medium { font: .fslh(18, 24, em)[] @font-family; }
.font--large { font:  .fslh(21, 28, em)[] @font-family; }
.font--heading { font:.fslh(28, 36, em)[] @font-family; }
.font--display { font:.fslh(40, 60, em)[] @font-family; }
// .font--label  { #set.text( 15, 19 ); }
// .font--mini    { #set.text( 12, 16 ); }
// .font--small   { #set.text( 14, 18 ); }
// .font--normal  { #set.text( 16, 24 ); }
// .font--medium  { #set.text( 18, 24 ); }
// .font--large   { #set.text( 21, 28 ); }
// .font--heading { #set.text( 28, 36 ); }
// .font--display { #set.text( 40, 60 ); }

