// main: ../build.less
@import (reference) "../config";

CONFIG[appMenu] {
	--appMenu--color-text: var(--invert-color-text-muted);
	--appMenu--color-link: var(--invert-color-text-subtle);
	--appMenu--color-link-hover: var(--invert-color-text);
	--appMenu--color-link-active: var(--color-primary);

	--appMenu--label-font: .rem(14)[] ~"/" .rem(24)[] @font-family;
	--appMenu--item-font: .rem(15)[] ~"/" .rem(22)[] @font-family;

	--appMenu--item-padding: .rem(6)[] .rem(8)[];
	--appMenu--item-separator: 1px solid rgba(255,255,255,.15);
	--appMenu--item-bg: rgba(255,255,255,.05);
	--appMenu--item-bg-hover: rgba(255,255,255,.1);

	--appMenu--subMenu-padding: .rem(6)[] 0 .rem(16)[];
	--appMenu--subMenu-rounding: .rem(3)[];
	--appMenu--subMenu-item-padding: .rem(6)[] .rem(12)[];
	--appMenu--subMenu-bg: rgba(255,255,255,.05);
	
	@media @FormFactor--pocket {
		--appMenu--label-font: .rem(14)[] ~"/" .rem(24)[] @font-family;
		--appMenu--item-font: .rem(16)[] ~"/" .rem(36)[] @font-family;
		--appMenu--subMenu-item-padding: .rem(8)[] .rem(12)[];
	}
}

@appMenuSelector: .appMenu;

@{appMenuSelector} {

	&:extend( CONFIG[appMenu] all );

	color: var(--appMenu--color-text);

	a {
		display: block;
		color: var(--appMenu--color-link);
		text-decoration: none;

		&:hover,
		&:visited:hover { color: var(--appMenu--color-link-hover); }
	}

	&__item {
		font: var(--appMenu--item-font);
		padding: var(--appMenu--item-padding);
		border-bottom: var(--appMenu--item-separator);
		background: transparent;
		transition: all .167s ease-out;

		&:hover { background: var(--appMenu--item-bg-hover); }
		&.is\:active a { color: var(--appMenu--color-link-active); }
	}

	&__label {
		font: var(--appMenu--label-font);
		overflow: hidden;
		cursor: pointer;
		opacity: 0.7;
		z-index: @z-auto;
		// #icon.set( drop-down, 24 );
		&::before {
			&:extend(.iconLike all);
			content: @icon-drop-down;
			font-size: .rem(24)[];
			float: right;
		}
	}
	.is\:open &__label::before { content: @icon-drop-up; }


	&__section &__subMenu { display: none; }
	&__section.is\:open &__subMenu { display: block; }

	&__subMenu { padding: var(--appMenu--subMenu-padding); }
	&__section.is\:open { background: var(--appMenu--subMenu-bg); }
}

.subMenu {
	&__item {
		padding: var(--appMenu--subMenu-item-padding);
		transition: all .167s ease-out;
		border-radius: var(--appMenu--subMenu-rounding);

		&:hover { background: var(--appMenu--item-bg-hover); }
		&.is\:active a { color: var(--appMenu--color-link-active); }
	}
}
