// out: .test/table--eventShows.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


.table--shows 
{
	@ShowTable--expanded:  #inline.min( 800px )[];
	@ShowTable--collapsed: #inline.max( 800px )[];
	@ShowTable--adaptive:  #inline.minmax( 550px, 800px )[];
	@ShowTable--minimal:   #inline.max( 380px )[];

	.table__row,
	.table__header{
		
		@container fieldTable (@ShowTable--expanded) {}

	}
	.table__row {
		@container fieldTable (@ShowTable--collapsed) {
			grid-template-columns: 
				[hand] var(--dnd) [A] 1fr [B] 1fr [ctrl] var(--ctrl);
		}
		@container fieldTable (@ShowTable--minimal) {
			grid-template-columns: 
				[hand] var(--dnd) [A] 1fr [ctrl] var(--ctrl);
		}
		@container fieldTable (@ShowTable--adaptive) {
			grid-template-columns: 
				[hand] var(--dnd) [A] 2fr [B] 1fr [C] 1fr [ctrl] var(--ctrl);
		}
		
	}

	.table__cell {

		&.is\:primary { font-weight: 500; }

		@container fieldTable (@ShowTable--expanded) {
			.field {
				&\:artist    { grid-column: artist; }
				&\:day       { grid-column: day; }
				&\:venue     { grid-column: venue; }
				&\:billing   { grid-column: billing; }
				&\:startTime { grid-column: start; }
				&\:endTime   { grid-column: end; }
				&\:stage     { grid-column: stage; }
			}
		}

		@container fieldTable (@ShowTable--collapsed) {

			&[class*="field:"] { grid-column: A / ctrl -1; }
			&.field\:startTime { grid-column: A / span 1; grid-row: times; }
			&.field\:endTime   { grid-column: B; grid-row: times; }
			&.for\:dragHandle  { grid-column: hand; grid-row: header; }
			&.for\:setOpen     { grid-column: ctrl; grid-row: header; }	
			
			&.field\:endTime,
			&.field\:billing { border-right: var(--cell--separator); }
			&.field\:endTime,
			&.field\:startTime { border-top: var(--cell--separator); }
		}

		@container fieldTable (@ShowTable--minimal) {
			&[class*="field:"] { grid-column: A / ctrl -1 !important; }
			&.field\:startTime,
			&.field\:endTime { grid-row: auto !important; }
		}
	}

	&.is\:show {
		.table__row,
		.table__header	{
			@container fieldTable (@ShowTable--expanded) {
				grid-template-columns:
					[hand]  var(--td--hand)
					[artist]  minmax(var(--td--std), 2.5fr)
					[order]   minmax(var(--td--std), 1.5fr)
					  repeat(auto-fit, minmax( var(--td--std), 1fr ))
					[ctrl] var(--td--ctrl);
			}
		}
		.table__row {
			@container fieldTable (@ShowTable--collapsed) {
				grid-template-rows: 
					[header] var(--row--height--collapsed-primary)
					[order] minmax(var(--row--height--collapsed), auto)
					[times] var(--row--height--collapsed);
			}
		}
	
		.table__cell {
			@container fieldTable (@ShowTable--expanded) {
				&.for\:dragHandle { grid-column: hand; }
			}
	
			@container fieldTable (@ShowTable--collapsed) {
				&.field\:startTime { grid-column: A; }
				&.field\:endTime   { grid-column: B; }
			}
		}
	}
	
	&.is\:festival {
	
		.table__row, 
		.table__header {
			@container fieldTable (@ShowTable--expanded) {
				grid-template-columns:
					[hand]   var(--td--hand)
					[artist] minmax(var(--td--LG), 5fr)
					[day]    minmax(var(--td--SM), 2fr)
					[stage]  minmax(var(--td--std), 3.5fr)
					[order]  minmax(var(--td--std), 3fr)
					[start]  minmax(var(--td--SM), 1fr)
					[end]    minmax(var(--td--SM), 1fr)
					[ctrl]   var(--td--ctrl);
				
			}
		}
		.table__header {
			@container fieldTable (@ShowTable--collapsed) {
				> :not(.is\:primary) { display: none; }
			}
		}
		.table__row {
			@container fieldTable (@ShowTable--collapsed) {

				&:not(.is\:open ) {
					max-height: var(--row--height--collapsed-primary);
					overflow: hidden;
				}
				grid-template-rows: 
					[header] var(--row--height--collapsed-primary)
					[stage] minmax(var(--row--height--collapsed), auto)
					[order] minmax(var(--row--height--collapsed), auto)
					[times] var(--row--height--collapsed);
				
			}
			@container fieldTable (@ShowTable--minimal) {	
				grid-template-rows: 
					[header] var(--row--height--collapsed-primary)
					[stage] minmax(var(--row--height--collapsed), auto)
					[order] minmax(var(--row--height--collapsed), auto)
					[day]   minmax(var(--row--height--collapsed), auto)
					[times] var(--row--height--collapsed);
			}
			@container fieldTable (@ShowTable--adaptive) {	
				grid-template-rows: 
					[header] var(--row--height--collapsed-primary)
					[times] minmax(var(--row--height--collapsed), auto);
					// [stage] minmax(var(--row--height--collapsed), auto)
			}
		}
		.table__cell {
	
			@container fieldTable (@ShowTable--expanded) {
			}
	
			@container fieldTable (@ShowTable--collapsed) {

				

				&.field\:day     { grid-column: A; grid-row: order; }
				&.field\:billing { grid-column: B; grid-row: order; }
				&.field\:venue   { grid-row: stage; border-right: var(--cell--separator); }
			}
	
			@container fieldTable (@ShowTable--adaptive) {
				.setCollapsedTableControls();

				&.field\:artist  { grid-column: A; grid-row: header; }
				&.field\:day     { grid-column: B; grid-row: header; }
				&.field\:billing { grid-column: C; grid-row: header; }
				
				&.field\:venue     { grid-column: A; grid-row: times; }
				&.field\:startTime { grid-column: B; grid-row: times; }
				&.field\:endTime   { grid-column: C; grid-row: times; }
			}
	
			@container fieldTable (@ShowTable--minimal) {
				&.field\:day { grid-row: day; }
			}
		}
	}
}



