// out: .test/filterBar.css
// main: ../build.less
@import (reference) "../config";


@AppUI--filterBar--fluid: #viewport.max( 1024px )[];
@AppUI--filterBar--fixed: #viewport.min( 1024px )[];

@filterUI--minimal:   #viewport.max( 540px )[];
@filterUI--compact:   #viewport.minmax( 540px, 810px )[];
@filterUI--hybrid:    #viewport.minmax( 810px, 1024px )[];
@filterUI--flexible:  #viewport.minmax( 1024px, 1440px )[];
@filterUI--sectioned: #viewport.minmax( 1440px, 1920px )[];
@filterUI--fullform:  #viewport.min( 1920px )[];


& when (@__DEBUG = true ) {
	@media @AppUI--filterBar--fluid { /* @AppUI--filterBar--fluid */ }
	@media @AppUI--filterBar--fixed { /* @AppUI--filterBar--fixed */ }
	@media @filterUI--minimal   { /* @filterUI--minimal */ } 
	@media @filterUI--compact   { /* @filterUI--compact */ } 
	@media @filterUI--hybrid    { /* @filterUI--hybrid */ } 
	@media @filterUI--flexible  { /* @filterUI--flexible */ } 
	@media @filterUI--sectioned { /* @filterUI--sectioned */ } 
	@media @filterUI--fullform  { /* @filterUI--fullform */ } 
}

#AddVars() {.root() {
	--filterBar--stackingContext: var(--viewLayer--toolbar, 400);
}}

CONFIG[filterBar] {
	--filterSlot--height: .em(54)[];
	--filterSlot--padding-y: .em(8)[];
	--filterSlot--padding-x: .em(8)[];
	
	--filterSlot--separator-yPos: .em(53)[];
	--filterBar--color-separator: var(--color-bg-faded);

	--input--rounding: .em(3)[];

	--filterBar--bgPattern: repeating-linear-gradient( 
		to bottom, 
		var(--color-bg) 0, 
		var(--color-bg) var(--filterSlot--separator-yPos), 
		var(--filterBar--color-separator) var(--filterSlot--height),
	);
}


// main show list filterbars
.filterBar 
{
	&:extend( CONFIG[filterBar] );

	display: flex;
	position: relative;
	z-index: var(--filterBar--stackingContext);
	border-bottom: 1px solid var(--filterBar--color-separator);

	@media @AppUI--filterBar--fixed {
		flex-flow: row nowrap;
		align-items: center; 
	}

	&__content {
		width: 100%;
		height: 100%;
		
		@media @AppUI--filterBar--fluid 
		{
			// collapsible behavior is controlled by cropping the content area
			max-height: var(--filterSlot--height);
			overflow: hidden;
			display: grid;
			grid-template-columns:  1fr auto auto;
			grid-template-rows: var(--filterSlot--height) .em(64)[] auto;
			grid-template-areas: 
				"searchBox touchControls toolbarMenu"
				"dateRange dateRange dateRange"
				"queryBuilder queryBuilder queryBuilder";

			.is\:open & {
				max-height: 100%;
				overflow: visible;
				box-shadow: 0 0.5rem 0.5rem rgba( 0, 0, 0, .2 );
			}

			// Calendar fitlerBar has no dateRange slot
			.view--calendar & {
				grid-template-rows: var(--filterSlot--height) auto;
				grid-template-areas: 
					"searchBox touchControls toolbarMenu"
					"queryBuilder queryBuilder queryBuilder";
			}
		}
		@media @AppUI--filterBar--fixed 
		{
			max-height: auto;
			overflow: visible;
			
			// display: flex;
			// flex-flow: row nowrap;
			// align-items: stretch;
			
			// Draft: grid might work better for desktop aswell
			display: grid;
			grid-template-columns: auto auto 1fr auto;
			grid-template-rows: minmax(var(--filterSlot--height), auto);
			grid-template-areas: "searchBox dateRange queryBuilder toolbarMenu";

			background: repeating-linear-gradient( 
				to bottom, 
				var(--color-bg) 0, 
				var(--color-bg) var(--filterSlot--separator-yPos), 
				var(--filterBar--color-separator) var(--filterSlot--height),
			);
		}
	}

	// Sectioning Blocks for toolbar regions
	&__section {
		// Draft: grid might work better for desktop aswell
		// @media @AppUI--filterBar--fluid {
			&--searchBox { grid-area: searchBox; }
			&--toolbarMenu { grid-area: toolbarMenu; }
			&--touchControls { grid-area: touchControls; }
			&--dateRange { grid-area: dateRange; }
			&--queryBuilder { grid-area: queryBuilder; }
		// }
		
		@media @AppUI--filterBar--fixed {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			// height: 100%;
			align-self: flex-start;
		}
	}

	// fluid positioning override for exportMenu to
	// break out of overflow:hidden collapsed filterBar
	&__exportMenu.is\:open {
		.contextMenu__panel {
			@media @AppUI--filterBar--fluid {
				position: fixed !important;
				top: calc(var(--viewMobile--toolbar--height) + var(--filterSlot--height)) !important;
				left: auto !important;
				right: var(--filterSlot--padding-x) !important;
				transform: none !important;
			}
			@media @FormFactor--compact {
				width: 94vw;
				max-width: .rem(280)[];
			}
			@media @FormFactor--medium {
				width: auto;
			}
		}
	}

	@media @FormFactor--narrow {
		&__mobileToggle {
			&.button {
				--button--icon-spacing: 0;
				--button--padding-x-icon: var(--button--padding-y);
				--button--padding-x: var(--button--padding-y);
			}
			span { display: none; }
		}
	}

	@media @filterUI--compact 
	{
		&__section--queryBuilder {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(.em(260)[], 1fr));
		}
		&__section--queryBuilder &__slot--action { grid-column: ~"1 / -1"; }
	}

	// DRAFT!
	@media @filterUI--hybrid {
		&__content {
			max-height: var(--filterSlot--height);
			overflow: hidden;
			display: grid;
			grid-template-columns:  auto auto 1fr auto;
			grid-template-rows: var(--filterSlot--height) auto;
			grid-template-areas: 
				"searchBox dateRange touchControls toolbarMenu"
				"queryBuilder queryBuilder queryBuilder queryBuilder";

			.is\:open & {
				max-height: 100%;
				overflow: visible;
				box-shadow: 0 0.5rem 0.5rem rgba( 0, 0, 0, .2 );
			}
		}
		&__section--touchControls &__action { 
			&, .button { 
				width: 100%;
				justify-content: flex-start;
			}
		}
		&__section--queryBuilder {
			// display: flex;
			// flex-flow: row wrap;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(.em(240)[], 1fr));
			border-top: .em(5)[] solid @color-primary;
		}
		&__section--dateRange {
			border-top: none !important;
		}
		.swapFilter {
			&__body {
				width: .em(@swapFilter-fixedWidth * 1.05)[] !important;
				// width: 100% !important;
			}
			&__button {
				border: 1px solid #999 !important;
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
				background: fade(@color-text, 5) !important;
				color: fade(@color-text, 25) !important;
			}
			.dateInput {
				
				max-width: .em((@swapFilter-fixedWidth))[] !important;
			}
		}
	}

	@media @filterUI--flexible {

		&__content {
			display: flex;
			flex-flow: row wrap;
		}
		
		&__section {
			display: contents !important;
		}
		&__section--queryBuilder {
			flex-flow: row wrap;
		}
		&__section--queryBuilder &__slot { order: 10; }
		&__slot { order: 5; }
		&__section--toolbarMenu &__slot {
			order: 1;
			border-right: 1px solid var(--filterBar--color-separator);
		}

		&__section--touchControls > * {
			display: none !important;
			visibility: hidden !important;
		}
	}

	@media @filterUI--sectioned {
		position: sticky;

		&__content {
			max-width: 100%;
			max-height: auto;
			overflow: visible;
			
			// display: flex;
			// flex-flow: row nowrap;
			// align-items: stretch;
			
			// Draft: grid might work better for desktop aswell
			display: grid;
			grid-template-columns: auto auto 1fr auto;
			grid-template-rows: minmax(var(--filterSlot--height), auto);
			grid-template-areas: "searchBox dateRange queryBuilder toolbarMenu";

			background: repeating-linear-gradient( 
				to bottom, 
				var(--color-bg) 0, 
				var(--color-bg) var(--filterSlot--separator-yPos), 
				var(--filterBar--color-separator) var(--filterSlot--height),
			);
		}
		

	}
	@media @filterUI--fullform {

	}

	&__section--toolbarMenu {
		margin-left: auto;
		margin-right: 0;
	}

	// Toggle controls for collapsed layout
	&.is\:open &__section--touchControls &__slot {
		background-color: @color-primary;
		.button { 
			color: var(--color-secondary); 
			&::before { content: @icon-clear; }
		}
	}
	@media @AppUI--filterBar--fixed { 
		&__section--touchControls {	
			display: none;
			visibility: hidden;
		}
		&__section--queryBuilder {
			display: flex;
			flex-flow: row wrap;
		}
		&__section--queryBuilder &__slot { order: 10; }
		&__section--queryBuilder &__slot:has(.is\:pinned) { order: 9; }
	}

	// Layout Containers of individual section modules
	&__slot {
		display: flex;
		align-items: center;
		height: var(--filterSlot--height);
		min-height: var(--filterSlot--height);
		padding: var(--filterSlot--padding-y) var(--filterSlot--padding-x);
		border-left: 1px solid var(--filterBar--color-separator);
		transition: all 0.16s ease-out;
	}
	&__section:first-child &__slot:first-child { border-left: 0; }

	// Tweaks for queryBuilder Slots
	&__section--queryBuilder &__slot {
		padding: .em(6)[] 0;

		&:hover,
		&:has(.contextMenu.is\:open) {
			background-color: fade(@color-bg-highlight, 25);
		}
		&--action:hover,
		&--action:has(.contextMenu.is\:open) {
			background-color: fade(@color-primary, 25);
		}

		@media @AppUI--filterBar--fluid {
			// use real borders instead of bg gradient hack in 
			// collapsed state to allow height variation
			border-top: 1px solid var(--filterBar--color-separator);

			&--action {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	// QueryBuilder Filter Selection Menu
	&__addMenu {
		// ContextMenu Styling Overrides
		&.contextMenu {
			--cMenu--font-size: .em(16)[];
			--cMenu--color-bg: var(--color-bg);
			--cMenu--color-bg-hover: fade(@color-primary, 25);
			--cMenu--color-text: var(--color-text);
			--cMenu--color-text-hover: var(--color-secondary);
			--cMenu--color-icon: fade(@color-primary, 40);
			--cMenu--color-icon-hover: var(--color-primary);
			--cMenu--panel-rounding: 0.333em;
			--cMenu--item-padding-x: 1em;
			--cMenu--item-icon-padding-x: .em(10)[];
			--cMenu--item-padding-y: .em(10)[];
			--cMenu--item-touch-min-height: .em(48)[];
			--cMenu--item-firstlast-padding-y: .em(12)[];
		}

		flex: 1;
		.button { color: @color-secondary; }

		&.contextMenu.is\:open .contextMenu__trigger { 
			background: transparent; 
		}
	}

	// Wrapper for actual filters + their additional controls
	&__filter {
		display: flex;
		align-items: center;
		height: var(--filterSlot--height);
		min-height: var(--filterSlot--height);

		&.is\:pinned {
			--marker-size: 0.375rem;
			--marker-color: @color-primary;
			--inset: 1.5px;
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: var(--inset); left: var(--inset);
				border: var(--marker-size) solid var(--marker-color);
				border-right-color: transparent;
				border-bottom-color: transparent;
				box-sizing: border-box;
			}
		}
		.select__control {
			display: flex;
			height: 100%;
		}
		&:focus-within &Menu,
		&:hover &Menu {
			opacity: 1;
			visibility: visible;
		}

		// tweaks for full-width stack
		@media @AppUI--filterBar--fluid {		
			width: 100%;
			.select {
				flex: 1;
				padding: 0 0.666em 0 1em;
				margin: 0 !important;
			}
			.select__value {
				flex: 1;
				display: flex !important; // otherwise overrided when focused
				justify-content: space-between;
				padding-right: 0 !important;

				&:last-child {
					padding-right: .em(26)[] !important;
				}
			}
			.select__valueCount {
				margin-left: auto;
				margin-right: 0;
			}
			.select__indicators {
				width: .em(20)[];
				margin-left: .em(6)[];
			}
		}
	}



	// Controls for pinning/removing filters
	&__filterMenu {
		// ContextMenu Styling Overrides
		&.is\:open {
			z-index: var(--viewLayer--isOpen);
		}
		&.contextMenu {
			--cMenu--color-bg: @color-bg;
			--cMenu--color-bg-hover: fade(@color-primary, 15);
			// --color-bg-hover: fade(@color-bg-highlight, 45);
			--cMenu--color-text: @color-secondary;
			--cMenu--color-text-hover: @color-secondary;
			--cMenu--color-icon: @color-primary;
			--cMenu--color-icon-hover: @color-secondary;
			--cMenu--panel-rounding: 0.333em;
			--cMenu--item-padding-y: .em(10, 14)[];
			--cMenu--item-firstlast-padding-y: .em(12, 14)[];
		}
		.contextMenu__item--unpin {
			--cMenu--color-bg: fade(@color-secondary, 10) !important;
			--cMenu--color-icon: @color-secondary;
			--cMenu--color-icon-hover: fade(@color-secondary, 20);
		}

		@media @AppUI--filterBar--fluid {
			margin-right: 0;
			margin-left: auto;
		}
		@media @AppUI--filterBar--fixed {
			opacity: 0;
			visibility: hidden;
		}
		transition: all 0.2s ease-out;
		
		.contextMenu__trigger .button {
			height: var(--filterSlot--height);
			border-radius: 0;
			background-color: fade(@color-bg-highlight, 20);
			color: @color-text-faded;
			transition: all 0.13s ease-out;

			@media @AppUI--filterBar--fluid {
				width: var(--filterSlot--height);
			}
			@media @AppUI--filterBar--fixed {
				width: .em(24)[];
				margin-left: 0.333em;
			}
		}
		&:hover .contextMenu__trigger .button {
			background-color: mix(@color-primary, @color-bg, 25);
			color: var(--color-secondary);
		}
		&.contextMenu.is\:open .contextMenu__trigger { 
			background: transparent;
		}
	}

	// DRAFT: Full width DateRange in collapsed State
	@media @AppUI--filterBar--fluid {
		&__section--dateRange {
			// display: none;
			border-top: .em(5)[] solid @color-primary;
			padding-bottom: 0;
		}
		&__section--dateRange &__slot {
			height: 100%;
		}
		.filter--dateRange {
			width: 100%;
			.filter__body {
				width: 100%;
				display: flex;
				flex-flow: row nowrap;
				.dateInput { 
					flex: 1; 
					max-width: none !important;
				}
			}
		}
	}

	// DRAFT: Toggleable persistent Slots
	// - toggle searchBox & dateRange picker to maximize composable filter area
	// - enforce fixed total width to avoid layout/row shifts in queryBuilder
	// - only for expanded layout, dateRange is positioned in collapsible zone on mobile
	@swapFilter-fixedWidth: 256; // (in px, unitless)
	
	.swapFilter 
	{
		&, &__body {
			display: flex;
			flex-flow: row nowrap;
		}
		@media @AppUI--filterBar--fluid {
			&, &__body {
				width: 100%;
			}
			&__button {
				display: none;
				visibility: hidden;
			}
		}
		@media @AppUI--filterBar--fixed {
			&__button {
				height: auto;
				width: var(--button--height);
				color: var(--color-primary);
				border: 1px solid #bbb;
				background-color: transparent;
				transition: all 0.2s ease-out;
				&:hover {
					background-color: var(--color-primary-soft);
					color: var(--color-secondary);
				}
				&::before { font-size: .em(21)[]; }
			}
			&.is\:open .swapFilter__button {
				border: 1px solid #999;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				background: fade(@color-text, 5);
				color: fade(@color-text, 25);
			}
			&__body {
				width: 0px;
				overflow: hidden;
	
				> *:first-child {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-left: 0;
				}
			}
			&.is\:open .swapFilter__body {
				width: .em(@swapFilter-fixedWidth)[];
				overflow: visible;
			}
		}
	}

	// dateInput combobox styling & tweaks for swapFilter usage
	.dateInput {
		&:first-of-type { 
			
			border-right: none; 
			border-radius: 0;
			// adjusting for swapFilter behavior
			@media @AppUI--filterBar--fluid {
				border-top-left-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
			}
			@media @AppUI--filterBar--fixed {
				max-width: .em((@swapFilter-fixedWidth / 2))[];
				border-left: 0;
				&::before { display: none; }
			}
		}
		&:last-of-type {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&::before { display: none; }

			@media @AppUI--filterBar--fixed {
				max-width: .em((@swapFilter-fixedWidth / 2))[];
			}
		}
	}

	// Tweaks & Adaptation for generic components
	.pagination {
		margin-bottom: 0;
		margin-right: 0;
		margin-left: auto;
	}

	.input--search {
		max-width: 100%;
		width: 100%;
	}

	.select {
		--valueCount--bg-color: @color-bg-faded;
		--valueCount--text-color: @color-text-muted;
		cursor: pointer;

		@media @AppUI--filterBar--fixed { margin-left: .em(5)[]; }

		&__value { padding-right: .em(4)[]; }
		&__valueCount {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: 1.75em;
			border-radius: .em(3)[];
			margin-left: .em(5)[];
	
			background-color: var(--valueCount--bg-color);
			color: var(--valueCount--text-color);
			opacity: 0.5;
		}
		&.has\:value .select__valueCount {
			--valueCount--text-color: @color-text;
			--valueCount--bg-color: @color-bg-highlight;
			opacity: 1;
		}

		&__indicators {
			display: none;
			visibility: hidden;
		}

		// "value" is used a label in filterBar select, gets hidden
		// in regular selects for inline-autocomplete search
		&.is\:open .select__value { visibility: visible; } 
	}
	input {
		height: 100%;
		min-height: var(--input--height);
	}
}


// // Siloing of main Layout Patterns
// @AppUI--filterBar--fluid: %(~"(max-width: %s)", .rem((1024 - 1))[]);   // minimal toolbar, filters in drawer
// @AppUI--filterBar--fixed:  %(~"(min-width: %s)", .rem((1024))[]);    // default toolbar, filters inline
// // 0-700: full-width stacking for small handhelds
// @filterUI--minimal: %(~"(max-width: %s)", .rem((540 - 1))[]);
// // 700-960: fixed grid panel for medium handhelds
// @filterUI--compact: %(~"(min-width:%s) and (max-width:%s)", .rem(540)[], .rem((810 - 1))[]);
// // 960-1280: layout optimization for larger handhelds & small screens
// @filterUI--hybrid: %(~"(min-width:%s) and (max-width:%s)", .rem(810)[], .rem((1024 - 1))[]);
// // 1280-1440: fluidly wrapping full toolbar for medium screens
// @filterUI--flexible: %(~"(min-width:%s) and (max-width:%s)", .rem(1024)[], .rem((1440 - 1))[]);
// // 1440-1920: clearer area separation for medium-large screens
// @filterUI--sectioned: %(~"(min-width:%s) and (max-width:%s)", .rem(1440)[], .rem((1920 - 1))[]);
// // >1920: clean, grid-enforced layouting for full format screens
// @filterUI--fullform:  %(~"(min-width:%s)", .rem(1920)[]);

// 	@media @AppUI--filterBar--fluid { /* @AppUI--filterBar--fluid */ }
// 	@media @AppUI--filterBar--fixed { /* @AppUI--filterBar--fixed */ }

// 	@media @filterUI--minimal { /* @filterUI--minimal */ }
// 	@media @filterUI--compact { /* @filterUI--compact */ }
// 	@media @filterUI--hybrid  { /* @filterUI--hybrid */ }
// 	@media @filterUI--flexible  { /* @filterUI--flexible */ }
// 	@media @filterUI--sectioned { /* @filterUI--sectioned */ }
// 	@media @filterUI--fullform  { /* @filterUI--fullform */ }


// DRAFT CODE, makes breakpoints easily adjustable 
// but intransparent. Will likely be removed
// .filterBarMediaQueriesConfig() {
// 	// MediaQueries for Layout control
// 	// Breapoint Limits (as pixel values)
// 	@BP-LAYOUT: 960;
// 	@BP-XS: 600;
// 	@BP-SM: 960;
// 	@BP-MD: 1024;
// 	@BP-LG: 1440;
// 	@BP-XL: 1920;
	
// 	// min/max boundaries converted to rem
// 		@BP-collapsed:.rem((@BP-LAYOUT - 1))[];
// 		@BP-expanded: .rem((@BP-LAYOUT))[];
// 		@BP-mini-max: .rem((@BP-XS - 1))[];
// 		@BP-comp-min: .rem(@BP-XS)[];
// 		@BP-comp-max: .rem((@BP-SM - 1))[];
// 		@BP-hybr-min: .rem(@BP-SM)[];
// 		@BP-hybr-max: .rem((@BP-MD - 1))[];
// 		@BP-flex-min: .rem(@BP-MD)[];
// 		@BP-flex-max: .rem((@BP-LG - 1))[];
// 		@BP-sect-min: .rem(@BP-LG)[];
// 		@BP-sect-max: .rem((@BP-XL - 1))[];
// 		@BP-full-min: .rem(@BP-XL)[];
	
// 	// Siloing of main Layout Patterns
// 		@AppUI--filterBar--fluid: ~"(max-width: @{BP-collapsed})";   // minimal toolbar, filters in drawer
// 		@AppUI--filterBar--fixed:  ~"(min-width: @{BP-expanded})";    // default toolbar, filters inline
// 	// 0-700: full-width stacking for small handhelds
// 		@filterUI--minimal: ~"(max-width: @{BP-mini-max})";
// 	// 700-960: fixed grid panel for medium handhelds
// 		@filterUI--compact: ~"(min-width: @{BP-comp-min}) and (max-width: @{BP-comp-max})";
// 	// 960-1280: layout optimization for larger handhelds & small screens
// 		@filterUI--hybrid:  ~"(min-width: @{BP-hybr-min}) and (max-width: @{BP-hybr-max})";
// 	// 1280-1440: fluidly wrapping full toolbar for medium screens
// 		@filterUI--flexible:  ~"(min-width: @{BP-flex-min}) and (max-width: @{BP-flex-max})";
// 	// 1440-1920: clearer area separation for medium-large screens
// 		@filterUI--sectioned: ~"(min-width: @{BP-sect-min}) and (max-width: @{BP-sect-max})";
// 	// >1920: clean, grid-enforced layouting for full format screens
// 		@filterUI--fullform:  ~"(min-width: @{BP-full-min})";
// }
