// out: .test/toasts.css
// main: ../build.less
@import (reference) "../config.less";

#AddVars() { .root() {
	--toasts--stackingContext: var(--appLayer--notif, 600);
}}

CONFIG[toast] 
{
	--toast--font: 500 1em / 1.333 @font-family;
	--toast--padding: .rem(8)[] .rem(8)[] .rem(8)[] .rem(4)[];
	--toast--rounding: .rem(3)[];
	--toast--viewport-offset: .rem(24)[];
	
	--toast--color-bg: var(--color-bg);
	--toast--color-text: var(--color-text);
	--toast--shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
}
// toasts area fix to view bottom
.toasts 
{
	// &:extend(CONFIG[toast]);
	display: flex;
	width: 100%;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: var(--toasts--stackingContext, 600);
}

// individual toast
.toast 
{
	&:extend(CONFIG[toast]);

	position: absolute;
	right: var(--toast--viewport-offset);
	bottom: var(--toast--viewport-offset);
	
	padding: var(--toast--padding);
	border-radius: var(--toast--rounding);
	box-shadow: var(--toast--shadow);
	transition: all 230ms cubic-bezier(.21,1.02,.73,1);

	font: var(--toast--font);
	color: var(--toast--color-text);
	background-color: var(--toast--color-bg);

	&__content {
		display: flex;
		align-items: center;
	}
	&__message { 
		padding: 0 0.5em; 
		&::before { margin-right: 0.5em;}
	}

	// STATES
	&--success {
		--toast--color-bg: var(--color-bg-success);
		--toast--color-text: var(--color-text-success);
	}
	&--error {
		--toast--color-bg: var(--color-bg-error);
		--toast--color-text: var(--color-text-error);
	}
}

