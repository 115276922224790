// out: .test/table.css
// main: ../build.less
@import (reference) '../config';

// Table Layout Patterns
// - Minimal:   Tweakpoint for further compatcing collapsed layout
// - Collapsed: Rows become accordeons with primary cell as headers
// - Adaptive:  Tweakpoint to maximize visible data in collapsed headers
// - Expanded:  Default Table layout with all cells visible in a row



	
	@fieldTable--expanded:  #inline.min( 700px )[];
	@fieldTable--collapsed: #inline.max( 700px )[];
	@fieldTable--adaptive:  #inline.minmax( 550px, 700px )[];
	@fieldTable--minimal:   #inline.max( 420px )[];
	
	@listTable--expanded:  #inline.min( 820px )[];
	@listTable--collapsed: #inline.max( 820px )[];
	@listTable--adaptive:  #inline.minmax( 600px, 820px )[];
	@listTable--minimal:   #inline.max( 420px )[];

	@container fieldTable (@fieldTable--expanded) {/*demo*/}
	@container listTable (@listTable--expanded) {/*demo*/}



CONFIG[table] 
{
	
	
	--table--border-bottom: var(--row--separator);
	--table--control-color: #aaa;
	--table--control-disabled: #e6e6e6;
	--table--control-hover: #333;
	--table--blockgap: .rem(60)[];
	--table--titlegap: .rem(12)[];

	--thead--color-text: #777;
	--thead--separator: 3px solid #aaa;
	--thead--font: 500 var(--font--label);
	--thead--cell--padding: 
		calc(var(--cell--pad-y) * 1.5)
		calc(var(--cell--pad-x)) 
		calc(var(--cell--pad-y) * 0.5);

	--addButton--padding: .rem(12)[];
	--addButton--color-text: #999;

	--row--color-bg-hover: var(--color-highlight-faint);
	--row--color-bg-dragged: #eee;
	--row--separator: 1px solid #ccc;

	--cell--pad-y: .em(8)[];
	--cell--pad-x: .em(12)[];
	--cell--padding: var(--cell--pad-y) var(--cell--pad-x);

	--cell--color-bg: #ffff;
	--cell--color-bg-hover: #f0f0f0;
	--cell--color-bg-disabled: #f0f0f0;
	--cell--separator: 1px solid #efefef;

	--td--3XS: .rem(32)[];
	--td--2XS: .rem(48)[];
	--td--XS:  .rem(72)[];
	--td--SM:  .rem(100)[];
	--td--std: .rem(120)[];
	--td--MD:  .rem(150)[];
	--td--LG:  .rem(180)[];
	--td--XL:  .rem(200)[];
	--td--2XL: .rem(240)[];
	--td--3XL: .rem(260)[];

	--td--hand: .rem(36)[];
	--td--ctrl: .rem(42)[];
	--td--menu: .rem(96)[];
	
	--row--height: .rem(42)[];
	--tr--max: .rem(42)[];
	--row--height--collapsed: .rem(42)[];
	--row--height--collapsed-primary: .rem(42)[];
	--thead--height: .rem(42)[];
	--tr--head--max: .rem(42)[];

	--dragDropZone--height: var(--row--height);
	--dragDropZone--shading: inset 0 0 0 3px #fff;
	--dragDropZone--bg: repeating-linear-gradient(
			-45deg,
			transparent,
			transparent 3px,
			var(--row--color-bg-dragged) 3px,
			var(--row--color-bg-dragged) 5px
		);		

	
	// shorthands
	--ctrl: var(--td--ctrl);
	--dnd: var(--td--hand);
}



.table 
{
	// ----------------------------------------------------
	// COMPONENT SETUP
	// Access to table layout variables
	&:extend( CONFIG[table] );

	// Container Declaration
	container-name: table;
	container-type: inline-size;
	position: relative;
	z-index: var(--viewLayer--static);
	&:has(.is\:open) {
		z-index: var(--viewLayer--promoted);
	}
	
	// Variant descriptors for container queries
	&--listTable  { container-name: table listTable; }
	&--fieldTable { container-name: table fieldTable; }


	// ----------------------------------------------------
	// GENERIC TABLE CONTAINER STYLES

	width: 100%;
	border-bottom: var(--table--border-bottom);

	&.is\:hidden { display: none; }

	:not(:is(.is\:hidden, label)) ~ & { 
		margin-top: var(--table--blockgap); 
	}
	.section__content &:first-child {
		margin-top: var(--table--titlegap);
	}

	// ----------------------------------------------------
	// SHARED LAYOUT-RELEVANT DEFAULTS

	&__row, 
	&__header {
		display: grid;
		z-index: @z-auto;
		transition: background-color .2s ease-out;

		&:hover { background-color: var(--row--color-bg-hover); }
		&:not(:nth-child(1)) { border-top: var(--row--separator); }
	}
	
	&__cell {
		display: flex;
		align-items: center;
		padding: var(--cell--padding);

		&:not(:first-child) { border-left: var(--cell--separator); }
	}

	.for\:setOpen { display: none; }

	// ----------------------------------------------------
	// EXPANDED LAYOUT DEFAULTS
	// ----------------------------------------------------
	// ListTable exclusive expanded-layout defaults
	@container listTable (@listTable--expanded)
	{
		--table--layoutState: expanded;

		&__row,
		&__header {
			grid-template-columns:
				[id]    minmax(var(--td--3XS), var(--td--2XS))
				[main]  minmax(var(--td--XL), 1.5fr)
				repeat( auto-fill, minmax(var(--td--std), 1fr ))
				[menu]  minmax(var(--td--menu), min-content);
		}
		&__header { grid-template-rows: minmax(var(--thead--height), min-content); }
		&__row    { grid-template-rows: minmax(var(--row--height), min-content); }

		&__cell {
			&.for\:dragHandle { grid-column: hand; }
			&.for\:onDelete   { grid-column: ctrl; }
			&.has\:menu       { grid-column: menu; }
		}
	}

	// ----------------------------------------------------
	// FieldTable exclusive expanded-layout defaults
	@container fieldTable (@fieldTable--expanded) 
	{
		--table--layoutState: expanded;

		&__row,
		&__header {
			grid-template-columns: 
				repeat(auto-fit, minmax(var(--td--std), 1fr))
				[ctrl]  minmax(var(--td--menu), min-content);

			.has\:dragDrop & {
				grid-template-columns:
					[hand] var(--td--ctrl)
					repeat(auto-fit, minmax(var(--td--std), 1fr))
					[menu]  minmax(var(--td--menu), min-content);
			}
		}
			
		&__header { grid-template-rows: minmax(var(--thead--height), min-content); }
		&__row    { grid-template-rows: minmax(var(--row--height), min-content); }

		&__cell {
			&.for\:dragHandle { grid-column: hand; }
			&.for\:onDelete   { grid-column: ctrl; }
			&.has\:menu       { grid-column: menu; }
		}
	}

	// ----------------------------------------------------
	// COLLAPSED LAYOUT DEFAULTS
	// ----------------------------------------------------
	// ListTable exclusive collapsed-layout defaults
	@container listTable (@listTable--collapsed) 
	{
		--table--layoutState: collapsed;

		.for\:setOpen { display: block; }
		&__row:not(.is\:open ) {
			max-height: var(--row--height--collapsed-primary);
			overflow: hidden;
		}

		&__header { 
			> .is\:primary { grid-column: 1 / span all; }
			> :not(.is\:primary) { display: none; }
		}

		&__row {

			grid-template-columns: 
				[ctrl] var(--td--ctrl) 
				[A] 1fr 
				[menu] auto;
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary) 
				  repeat(auto-fill, var(--row--height--collapsed))
				[footer] var(--row--height--collapsed);
		}
	
		:is( &__cell ) {
			grid-column: A; // default placement of content
			border-top: var(--cell--separator);
			border-left: var(--cell--separator);

			&.for\:dragHandle { grid-column: hand; grid-row: header; }
			&.for\:setOpen    { grid-column: ctrl; grid-row: header; }
			&.for\:onDelete   { grid-column: 1; grid-row: 2; }
			&.has\:menu       { grid-column: menu; grid-row: footer; }

			&.is\:primary:not(.is\:primary ~ .is\:primary) { grid-row: header; }
		}

		&__row:not(:has(.for\:setOpen)) {
			&::before {
				font: .fslh(24, 24)[] @__ICON_FONT_NAME;
				content: @icon-chevron-down;
				grid-column: ctrl;
				place-self: center center;
				transition: all .2s ease-out;
				color: var(--thead--color-text);
			}	
			&.is\:open::before { 
				transform: scale(1.5) rotate(-90deg);
				color: var(--color-primary);
			}
		}
		// Carried over from old tables.less, check if still needed
		&__cell.has\:control {
			&:first-child { grid-column: hand; }
			&:last-child  { grid-column: ctrl; }
		}
		&__cell.has\:menu {
			&:last-child  { grid-column: menu; }
		}
		// hide collapsed row control
		&__cell.for\:setOpen {
			display: none !important;
			visibility: hidden !important;
		}
		&__cell.for\:dragHandle { grid-column: hand; }
		&__cell.for\:onDelete   { grid-column: ctrl; }
	}

	// ----------------------------------------------------
	// ListTable exclusive collapsed-layout defaults
	@container fieldTable (@fieldTable--collapsed)
	{
		--table--layoutState: collapsed;

		.for\:setOpen { display: block; }
		&__row:not(.is\:open ) {
			max-height: var(--row--height--collapsed-primary);
			overflow: hidden;
		}

		&__header { 
			> .is\:primary { grid-column: 1 / span all; }
			> :not(.is\:primary) { display: none; }
		}

		&__row {
			grid-template-columns: 
				[ctrl] var(--ctrl) 
				[A] 1fr 
				[menu] auto;
			grid-template-rows: 
				[header] var(--row--height--collapsed-primary) 
				  repeat(auto-fill, var(--row--height--collapsed));	

			.has\:dragDrop & {
				grid-template-columns: [hand] var(--td--ctrl) [A] 1fr [ctrl] auto;
			}
		}

		:is( &__cell ) {
			grid-column: A; // default placement of content
			border-top: var(--cell--separator);
			border-left: var(--cell--separator);

			&.is\:primary:not(.is\:primary ~ .is\:primary) { grid-row: header; }

			&.for\:dragHandle { grid-column: hand; grid-row: header; }
			&.for\:setOpen    { grid-column: ctrl; grid-row: header; }
			&.for\:onDelete   { grid-column: 1; grid-row: 2; }
			&.has\:menu       { grid-column: menu; grid-row: footer; }
		}
	}



	// ----------------------------------------------------
	// HEADER & FOOTER STYLES
	&__header {
		text-align: left;
		color: var(--thead--color-text);
		border-bottom: var(--thead--separator);
	}
	&__header &__cell {
		--cell--separator: none;
		--cell--separator: var(--thead--cell--padding);

		font: var(--thead--font);
		background: none;
		&.is\:sortable { cursor:pointer; }
	}

	// Footer & Main (Add Row) Button
	&__footer {
		display: flex;
		justify-content: space-between;
		border-top: var(--row--separator);
		&:hover { background-color: var(--row--color-bg-hover); }
	}
	&__action {
		flex: 1 1 100%;
		display: flex;
		align-items: center;
		padding: var(--addButton--padding);
		color: var(--addButton--color-text);
	}

	// Row placeholder for drag & drop interactions
	&__dropZone {
		position: absolute;
		height: var(--dragDropZone-height);
		background: var(--dragDropZone--bg);
		border-top: var(--row--separator);
		box-shadow: var(--dragDropZone--shading);
	}


	// ----------------------------------------------------
	// TABLE ROW MODIFIERS
	&__row {

		&.has\:error {
			border-left: 4px solid var(--color-text-error);
			background-color: mix( @color-bg-error, @color-bg, 50%);
		}

		&:has(.for\:onDelete:hover) {
			background-color: var(--color-bg-error-faded);
		}

		&.is\:dragged {
			background-color: var(--color-bg);
			outline: 1px dashed #ccc;
			border-top: none;
			border-bottom: none;
		}

		.for\:setOpen [class*="icon:"] {
			&, &::before { transition: all .2s ease-out; }
			background-color: transparent !important;
			color: currentColor;
		}
		&.is\:open .for\:setOpen [class*="icon:"] {
			// transform: translateY(25%);
			color: var(--color-secondary);
			&::before { transform: scale(1.5); }
		}
		
		.contextMenu\:tooltip {
			--cMenu--color-bg: @color-bg;
			--cMenu--color-text: @color-text;
			--cMenu--panel-shadow: 
				0 0.25rem 0.2rem rgba(0, 0, 0, 0.1),
				0 0.5rem 0.666rem rgba(0, 0, 0, 0.2);
			--cMenu--tooltip-offset-y: -125%;
		}
	}

	// ----------------------------------------------------
	// TABLE CELL MODIFIERS
	&__cell {

		a { text-decoration: none; color: inherit; }

		&.is\:dirty { border-bottom: 1px solid orange; }

		&.is\:dragging {
			.linkedEntity__editLink { opacity: 0; }
		}

		&.has\:error {
			border-bottom: 1px solid var(--color-text-error);
			background-color: var(--color-bg-error);
		}

		&.has\:value {
			cursor: not-allowed;
			.value {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		&.has\:control,
		&.has\:menu {

			.button { padding: var(--cell--pad-y); }
			[class^='icon:'] {
				color: var(--table--control-color);
				&:hover { color: var(--table--control-hover); }
			}
			.icon\:handle { color: var(--table--control-disabled); }
		}

		&.for\:onDelete .button {
			--button--color-text: var(--color-text-faded);
			--button--color-text-hover: var(--color-text-error);
		}

		&.has\:input,
		&.has\:control { padding: 0; }

		&.has\:input {
			align-items: stretch;
			.input {
				.resetAppearance;
				padding: var(--cell--pad-x);
			}
			.timeInput {
				--timeInput--icon-width: .rem(28)[];
				--timeInput--input-margin: .rem(6)[];
				--timeInput--icon-separator: none;
				--timeInput--icon-spacing: .rem(2)[];
			}
			.select__control { border: none; }

			.field__input--checkbox { 
				width: 100%;
				justify-content: center;
				font-size: .rem(16)[];
			}
			.input--checkbox { margin: 0; }
		}
		&.field\:status {
			
			.entity { 
				--entity--title-font: 500 .fslh(14, 18)[] var(--font-family);
				--entity--meta-font: .fslh(12, 18)[] var(--font-family);
				&__title{
					&[class*="icon:"] { margin-left: -0.2em; }
					&.icon\:help,
					&.icon\:accept { 
						font-weight: 400;
						color: var(--color-text-muted);
					}
				}
				&__meta { color: var(--color-text-faded);  }
				// &__title[class*="icon:"] + .entity__meta { padding-left: 1.25em;}
			}
		}

		&field\:status.has\:statusTag {
			gap: .rem(8)[];
			
			.statusTag {
				--statusTag--font-size: .rem(13)[];
				--statusTag--width: .rem(40)[];
				--statusTag--height: .rem(24)[];
			}
		}
		&:first-child.has\:statusTag--ribbon {
			gap: .rem(8)[];
			--cell--padding: .rem(2)[] var(--cell--pad-x) .rem(2)[] .rem(0)[];
			.statusTag {
				--statusTag--font-size: .rem(12)[] !important;
				--statusTag--width: .rem(22)[];
				--statusTag--height: auto;
				align-self: stretch;
			}
			.statusTag + .statusTag {
				margin-left: .rem(-8)[];
			}
		}

		&.has\:userEntities {
			--table-padding-y: .rem(4)[];
			flex-flow: row wrap;
			gap: .rem(6)[] .rem(3)[];

			.avatar {
				@container listTable (@listTable--collapsed) {
					--avatar--size: .rem(26)[];
					--avatar--placeholder-size: .em(12)[];
					--avatar--spacing: 0;
				}
				@container listTable (@listTable--expanded) {
					--avatar--size: .rem(32)[];
					--avatar--placeholder-size: .em(15)[];
					--avatar--spacing: 0;
				}
			}
		}
	}
}




.setExpandedTableControls() {
	&.for\:setOpen {
		display: none;
		visibility: hidden;
	}
	&.for\:dragHandle { grid-column: hand; }
	&.for\:onDelete   { grid-column: ctrl; }
}

.setCollapsedTableControls() {
	&.for\:dragHandle { grid-column: hand; grid-row: header; }
	&.for\:onDelete   { grid-column: hand; grid-row: 2; }
	&.for\:setOpen    { 
		display: block;
		visibility: visible;
		grid-column: ctrl; 
		grid-row: 1; 
	}
}

.addRowCollapsingStyles() {
	&:not(.is\:open ) {
		max-height: var(--row--height--collapsed-primary);
		overflow: hidden;

		.for\:onDelete { 
			transition: all .3s ease-out;
			opacity: 0;
		}
	}
	&.is\:open { 
		.for\:onDelete { 
			opacity: 1;
		}
	}
}

// #__sharedTableStyles() {
// 	.collapsed() 
// 	{
// 		.table__header { 
// 			display: block; 
// 			:not(.is\:primary) { display: none; }
// 			.is\:primary { 
// 				grid-column: 1 / span all;
// 				padding-left: var(--cell--pad-x);
// 			}
// 		}
		
		
// 		.table__row {
// 			border-left:  var(--row--separator);
// 			border-right: var(--row--separator);
// 		}

// 		:is(& .table__cell) {
// 			grid-column: A / span 2;
// 			border-top: var(--cell--separator);
// 			border-left: var(--cell--separator);
// 			position: relative;

			
// 			&:last-child   { grid-column: 1; grid-row: 2; }
// 			&[title="id"] { grid-column: A; grid-row: footer; }
// 		}
// 	}
// 	.expanded() {	
// 	}
// }
