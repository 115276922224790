// out: .test/statusTag.css
// main: ../build.less
@import (reference) "../config.less";

.statusTag
{
	@statusTag-text-opacity: 50;

	--statusTag--font-size: .rem(18)[];
	--statusTag--width: .rem(54)[];
	--statusTag--height: .rem(32)[];
	--statusTag--padding: 0;
	--statusTag--rounding: .rem(5)[];
	--statusTag--bg: @color-bg;
	--statusTag--text: @color-text;
	--statusTag--border: none;
	
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--statusTag--rounding);

	width: var(--statusTag--width);
	height: var(--statusTag--height);
	padding: var(--statusTag--padding);
	
	font-size: var(--statusTag--font-size);
	line-height: var(--statusTag--height);
	text-transform: uppercase;
	font-weight: 600;

	color: var(--statusTag--text);
	background: var(--statusTag--bg);
	border: var(--statusTag--border);

	// STATE: Colors
	&.TBC, &.status\:pendingConfirmation { 
		--statusTag--bg: @color-status-tbc; 
		--statusTag--text: mix( @color-text, @color-status-tbc, @statusTag-text-opacity ); 
	}
	&.CON, &.status\:confirmed { 
		--statusTag--bg: @color-status-con; 
		--statusTag--text: mix( @color-text, @color-status-con, @statusTag-text-opacity ); 
	}
	&.TBA, &.status\:pendingAnnouncement { 
		--statusTag--bg: @color-status-tba; 
		--statusTag--text: mix( @color-text, @color-status-tba, @statusTag-text-opacity ); 
	}
	&.ANN, &.status\:announced { 
		--statusTag--bg: @color-status-ann; 
		--statusTag--text: mix( @color-text, @color-status-ann, @statusTag-text-opacity ); 
	}
	&.ARX, &.status\:archived { 
		--statusTag--bg: @color-status-arx; 
		--statusTag--text: mix( @color-text, @color-status-arx, @statusTag-text-opacity ); 
	}
	&.ABS, &.status\:absent { 
		--statusTag--bg: @color-status-abs;
		--statusTag--text: mix( @color-text, @color-status-abs, @statusTag-text-opacity ); 
	}
	&.ANN.status\:pendingUpdate {
		--statusTag--bg: linear-gradient(90deg, @color-status-ann 0 50%, @color-status-tba 50% 100%);
		--statusTag--text: mix( @color-text, @color-status-ann, @statusTag-text-opacity );
	}

	// Variant: Vertical Status	
	.table__cell.has\:statusTag--ribbon &,
	&--ribbon {
		--statusTag--font-size: .rem(16)[];
		writing-mode: vertical-lr;
		text-orientation: mixed;
		padding: 0.666em 0.333em;
		justify-content: start;
		line-height: 1;
		border-radius: 0;

		// grid-area: statusTag;
		// width: 100%;
		// height: 100%;
		// position: absolute;
		// 		top: .rem(1)[];
		// 		bottom: .rem(1)[];
		// right: .rem(1)[];
		// transform: rotate(180deg);
	}

	// VARIANT: Sizes
	&--medium {
		--statusTag--padding: .rem(0)[] .rem(10)[];
		--statusTag--font-size: .rem(16)[];
		--statusTag--height: .rem(28)[];
	}
	&--small {
		--statusTag--padding: .rem(0)[] .rem(6)[];
		--statusTag--font-size: .rem(14)[];
		--statusTag--height: .rem(22)[];
		--statusTag--width: .rem(48)[];
		--statusTag--rounding: .rem(3)[];
	}
}
