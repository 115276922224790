// main: ../build.less
@import (reference) "../config";


.dashboard
{
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		padding: .rem(8)[] 0 .rem(12)[];
	}
	&__title {
		font-size: .rem(32)[];
		line-height: 1.275;
		font-weight: 600;
	}

	&__actions {}

	&__jobInfo {
		display: grid;
		grid-template-columns: 3rem 1fr;
		grid-template-rows: 1.3125rem 1.3125rem;
		grid-template-areas: "num label" "num link";
		column-gap: .rem(10)[];
		.font--small;

		.bigNumber {
			color:  @color-secondary-faded;
			grid-area: num;
			text-align: right;
		}

		background-color: @color-bg;
		padding: .rem(8)[] .rem(12)[];
		border-radius: .rem(4)[];
	}

	&__group {
		background-color: @color-bg;
	}
}

.compactShowList
{
	&__header {
		display: flex;
		align-items: baseline;
		padding: .rem(12)[] .rem(20)[];
		border-bottom: .rem(1)[] solid var(--color-bg-faded);

		color: @color-secondary-muted;

		.bigNumber { color: @color-secondary-faded; }
	}
	&__title {
		font-size: .rem(24)[];  line-height: .rem(36)[];
		margin-left: .rem(12)[];
		font-weight: 500;
	}
	&__footer {
		display: flex;
		justify-content: center;
		padding: .rem(12)[] .rem(20)[];
	}

	&__item > a.blockLink {
		display: flex;
		flex-flow: row nowrap;
		width: 100%;
		padding: .rem(12)[] .rem(24)[];
		border-bottom: .rem(1)[] solid var(--color-bg-faded);


		&:hover {
			background-color: mix( @color-bg-faded, @color-bg, 30% );
		}
	}

	&__itemMeta {
		margin-right: .rem(16)[];
		// text-align: center;
		width: .rem(90)[];
	}
	&__itemContent {
		flex: 1 1 100%;
		border-left: .rem(1)[] solid var(--color-bg-faded);

		padding-left: .rem(12)[];

		.statusTag { float: right; }
	}

	&__itemDate {
		font-weight: bold;
		font-size: .rem(19)[];  line-height: .rem(24)[];
	}
	&__itemName {
		font-weight: bold;
		font-size: .rem(19)[];  line-height: .rem(24)[];
	}
	&__itemDateLoc {
		color: @color-text-muted;
	}
	&__itemStatus {

		.entity__title {
			&::before { display: none !important;}
		}
		background-color: @color-bg-highlight;
		color: @color-text-highlight;
		font-size: .rem(14)[];  line-height: .rem(19)[];
		margin-top: .rem(4)[];
		margin-left: .rem(-2)[];
		padding: .rem(4)[] .rem(8)[] .rem(4)[] .rem(8)[];
		border-radius: .rem(3)[];
		border: 1px solid @color-bg;
	}
}
