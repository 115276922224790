// main: ../build.less
@import (reference) "../config";

@linkedEntity: linkedEntity;
@linkedEntitySelector: ~".@{linkedEntity}";

@{linkedEntitySelector}
{
	display: flex;
	align-items: center;

	&__name { margin-right: .rem(8)[]; }
	&__editLink
	{
		padding: .rem(1)[] .rem(10)[];
		border-radius: .rem(2)[];
		background: #ccc;

		font-size: .rem(12)[];  line-height: .rem(18)[];
		color: #333;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;

		[class*="@{linkedEntity}"]:not(:hover) &,
		[class*="has:@{linkedEntity}"]:not(:hover) & {
			opacity: 0;
		}
	}
}
