// out: .test/icons.css
// main: ../build.less
@import (reference) "../config";


// detached ruleset to print icon @font-face declaration
// at top of stylesheet with other fonts in app/fonts.less
@DETACHED_ICONFONT_REGISTRATION: {
	@font-face {
		font-family: '@{__ICON_FONT_NAME}';
		src:
			url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.woff2#v12') format('woff2'),
			url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.ttf#v12') format('truetype'),
			url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.woff#v12') format('woff'),
			url('@{__ICON_FONT_PATH}/@{__ICON_FONT_NAME}.svg#v12') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: block;
	}
}


// Import generic Icons declarations with the following config
@import (once) "../_shared/icons";

@iconNamespace: icon;			// classname base
@iconDelimiter: ~":";			// bem-style delimiter (character used to separate {icon} & {name} in plain text)
@iconDelimiterSanitized: ~"\:"; // sanitized delimiter for usage as class (example: escaping ":")

@iconEnableParticles: 1;		// enable usage of <i>glyphName</i> with css ligatures
@iconParticleSelector: ~"i.icon";		// css selector to be used for defining particles

@icon-size-default: 18;			// default size for icons

// Print actual icon classes
@import (reference) "../lib/icons";

#icon.registerList( 
	@icon-names-list, 
	@iconNamespace, 
	@iconDelimiterSanitized 
);

// Available Icons
@icon-names-list: 

explicit,
fast_forward,
fast_rewind,
library_add,
library_books,
repeat,
subtitles,
fiber_new,
art_track,
subscriptions,
playlist_add_check,
chat,
comment,
contacts,
email,
message,
chat_bubble,
chat_bubble_outline,
mail_outline,
alternate_email,
cancel_presentation,
pause_presentation,
list_alt,
add1,
add_circle,
add_circle_outline,
block,
clear1,
content_copy,
content_paste,
filter_list,
forward1,
link1,
remove_circle,
remove_circle_outline,
reply,
reply_all,
save1,
select_all,
send1,
delete_sweep,
low_priority,
link_off,
save_alt,
ballot,
file_copy,
how_to_vote,
add_link,
add_alarm,
devices,
dvr,
now_wallpaper,
now_widgets,
add_to_home_screen,
mobile_friendly,
insert_comment,
insert_invitation,
monetization_on,
insert_chart_outlined,
cloud_off,
cloud_queue,
file_download,
file_upload,
folder_open,
folder_shared,
create_new_folder,
keyboard_arrow_down,
keyboard_arrow_left,
keyboard_arrow_right,
keyboard_arrow_up,
laptop_chromebook,
memory,
phone_android,
security,
assistant,
camera_front,
camera_rear,
center_focus_strong,
center_focus_weak,
collections,
color_lens,
compare,
control_point_duplicate,
crop,
crop_free,
crop_original,
filter_center_focus,
filter_frames,
flip,
picture_as_pdf,
portrait,
visible,
style,
tune,
view_comfy,
view_compact,
collections_bookmark,
crop_rotate,
photo_filter,
burst_mode,
add_photo_alternate,
image_search,
directions,
directions_subway,
local_atm,
local_attraction,
local_bar,
local_grocery_store,
local_offer,
local_printshop,
pin_drop,
near_me,
zoom_out_map,
category,
apps,
arrow_back,
arrow_forward,
fullscreen,
fullscreen_exit,
keyboard_control,
more_vert,
unfold_less,
unfold_more,
arrow_upward,
arrow_downward,
arrow_back_ios,
arrow_forward_ios,
do_not_disturb_alt,
event_available,
event_busy,
event_note,
folder_special,
confirmation_number,
ondemand_video,
enhanced_encryption,
no_encryption,
pie_chart,
pie_chart_outlined,
notifications,
notifications_none,
notifications_off,
notifications_on,
notifications_paused,
people_outline,
person,
person_add,
person_outline,
public,
share,
check_box,
check_box_outline_blank,
star,
star_half,
star_outline,
alarm_off,
assignment,
assignment_returned,
assignment_turned_in,
book,
bookmark,
bookmark_outline,
cached,
chrome_reader_mode,
code,
dashboard,
delete,
description,
done,
done_all,
exit_to_app,
favorite,
favorite_outline,
flip_to_back,
flip_to_front,
highlight_remove,
history,
hourglass_empty,
https,
info,
info_outline,
input,
label,
label_outline,
lock_open,
lock_outline,
open_in_browser,
perm_media,
settings1,
settings_applications,
settings_backup_restore,
settings_input_svideo,
swap_horiz,
swap_vert,
system_update_tv,
today,
verified_user,
view_headline,
view_module,
visibility_off,
zoom_in,
zoom_out,
indeterminate_check_box,
copyright,
fingerprint,
lightbulb_outline,
touch_app,
date_range,
line_style,
line_weight,
update,
watch_later,
pan_tool,
delete_forever,
check_circle_outline,
delete_outline,
offline_bolt,
calendar_today,
drag_indicator,
horizontal_split,
label_important_outline,
vertical_split,
account_tree,
add_chart,
add_ic_call,
bookmarks,
circle_notifications,
dashboard_customize,
drive_file_move_outline,
drive_file_rename_outline,
grid_view,
logout,
margin,
padding,
thumb_down_off_alt,
thumb_up_off_alt,
toggle_off,
toggle_on,
height,
sync_alt,
menu_book,
post_add,
flip_camera_android,
double_arrow,
add_location_alt,
addchart,
app_registration,
arrow_circle_down,
arrow_circle_up,
article,
auto_awesome,
auto_awesome_motion,
auto_stories,
backup_table,
corporate_fare,
forward_to_inbox,
help_center,
hourglass_bottom,
hourglass_top,
local_police,
mediation,
miscellaneous_services,
multiple_stop,
push_pin,
qr_code,
disabled_by_default,
warning,
add-alert,
notification-alert,
refresh,
library-add,
publishing,
add-circle,
add,
clear,
copy,
edit,
flag,
link,
save,
send,
alarm,
time,
date,
photo,
download,
adjust,
add-photo,
handle,
back,
drop-down,
drop-up,
forward,
cancel,
check,
chevron-up,
chevron-down,
menu,
more,
fold,
unfold,
date-remove,
notification,
star-on,
star-off,
user,
accept,
link-out,
extension,
help,
remove,
search,
settings,
euro
;

@icon-explicit: "\e9ef";
@icon-fast_forward: "\e9f0";
@icon-fast_rewind: "\e9f1";
@icon-library_add: "\e9f2";
@icon-library_books: "\e9f3";
@icon-repeat: "\e95e";
@icon-subtitles: "\e9f4";
@icon-fiber_new: "\e9f5";
@icon-art_track: "\e9f6";
@icon-subscriptions: "\e9f7";
@icon-playlist_add_check: "\e9f8";
@icon-chat: "\e9f9";
@icon-comment: "\e9fa";
@icon-contacts: "\e9fb";
@icon-email: "\e9fc";
@icon-message: "\e9fd";
@icon-chat_bubble: "\e9fe";
@icon-chat_bubble_outline: "\e9ff";
@icon-mail_outline: "\ea00";
@icon-alternate_email: "\ea01";
@icon-cancel_presentation: "\ea02";
@icon-pause_presentation: "\ea03";
@icon-list_alt: "\e9ca";
@icon-add1: "\ea04";
@icon-add_circle: "\e934";
@icon-add_circle_outline: "\e935";
@icon-block: "\e936";
@icon-clear1: "\ea05";
@icon-content_copy: "\e937";
@icon-content_paste: "\ea06";
@icon-filter_list: "\e938";
@icon-forward1: "\ea07";
@icon-link1: "\e9cb";
@icon-remove_circle: "\e939";
@icon-remove_circle_outline: "\e93a";
@icon-reply: "\e93b";
@icon-reply_all: "\ea08";
@icon-save1: "\ea09";
@icon-select_all: "\e9cc";
@icon-send1: "\e93c";
@icon-delete_sweep: "\ea0a";
@icon-low_priority: "\ea0b";
@icon-link_off: "\e9cd";
@icon-save_alt: "\e93d";
@icon-ballot: "\e9ce";
@icon-file_copy: "\ea0c";
@icon-how_to_vote: "\ea0d";
@icon-add_link: "\e93e";
@icon-add_alarm: "\ea0e";
@icon-devices: "\ea0f";
@icon-dvr: "\ea10";
@icon-now_wallpaper: "\e93f";
@icon-now_widgets: "\e9cf";
@icon-add_to_home_screen: "\e940";
@icon-mobile_friendly: "\e941";
@icon-insert_comment: "\e942";
@icon-insert_invitation: "\e943";
@icon-monetization_on: "\ea11";
@icon-insert_chart_outlined: "\ea12";
@icon-cloud_off: "\e9d0";
@icon-cloud_queue: "\e9d1";
@icon-file_download: "\e944";
@icon-file_upload: "\e945";
@icon-folder_open: "\e946";
@icon-folder_shared: "\e9d2";
@icon-create_new_folder: "\e9d3";
@icon-keyboard_arrow_down: "\e947";
@icon-keyboard_arrow_left: "\e948";
@icon-keyboard_arrow_right: "\e949";
@icon-keyboard_arrow_up: "\e94a";
@icon-laptop_chromebook: "\e94b";
@icon-memory: "\ea13";
@icon-phone_android: "\e94c";
@icon-security: "\ea14";
@icon-assistant: "\ea15";
@icon-camera_front: "\ea16";
@icon-camera_rear: "\ea17";
@icon-center_focus_strong: "\e9d4";
@icon-center_focus_weak: "\e9d5";
@icon-collections: "\e94d";
@icon-color_lens: "\ea18";
@icon-compare: "\e94e";
@icon-control_point_duplicate: "\e94f";
@icon-crop: "\e950";
@icon-crop_free: "\e9d6";
@icon-crop_original: "\e951";
@icon-filter_center_focus: "\e9d7";
@icon-filter_frames: "\e9d8";
@icon-flip: "\ea19";
@icon-picture_as_pdf: "\ea1a";
@icon-portrait: "\e9d9";
@icon-visible: "\e952";
@icon-style: "\e9da";
@icon-tune: "\e953";
@icon-view_comfy: "\e9db";
@icon-view_compact: "\e954";
@icon-collections_bookmark: "\e955";
@icon-crop_rotate: "\e956";
@icon-photo_filter: "\ea1b";
@icon-burst_mode: "\ea1c";
@icon-add_photo_alternate: "\e957";
@icon-image_search: "\e958";
@icon-directions: "\ea1d";
@icon-directions_subway: "\ea1e";
@icon-local_atm: "\ea1f";
@icon-local_attraction: "\ea20";
@icon-local_bar: "\ea21";
@icon-local_grocery_store: "\ea22";
@icon-local_offer: "\e9dc";
@icon-local_printshop: "\ea23";
@icon-pin_drop: "\e959";
@icon-near_me: "\e95a";
@icon-zoom_out_map: "\e95b";
@icon-category: "\ea24";
@icon-apps: "\e964";
@icon-arrow_back: "\e95c";
@icon-arrow_forward: "\e95d";
@icon-fullscreen: "\e960";
@icon-fullscreen_exit: "\e961";
@icon-keyboard_control: "\e962";
@icon-more_vert: "\e963";
@icon-unfold_less: "\e965";
@icon-unfold_more: "\e966";
@icon-arrow_upward: "\e967";
@icon-arrow_downward: "\e968";
@icon-arrow_back_ios: "\e969";
@icon-arrow_forward_ios: "\e96a";
@icon-do_not_disturb_alt: "\e96b";
@icon-event_available: "\e96c";
@icon-event_busy: "\e96d";
@icon-event_note: "\e9c1";
@icon-folder_special: "\e9dd";
@icon-confirmation_number: "\e96e";
@icon-ondemand_video: "\e96f";
@icon-enhanced_encryption: "\e9de";
@icon-no_encryption: "\e9df";
@icon-pie_chart: "\e970";
@icon-pie_chart_outlined: "\e971";
@icon-notifications: "\e972";
@icon-notifications_none: "\e973";
@icon-notifications_off: "\e974";
@icon-notifications_on: "\e975";
@icon-notifications_paused: "\e976";
@icon-people_outline: "\e9e0";
@icon-person: "\e977";
@icon-person_add: "\e978";
@icon-person_outline: "\e979";
@icon-public: "\e97a";
@icon-share: "\e97b";
@icon-check_box: "\e97c";
@icon-check_box_outline_blank: "\e97d";
@icon-star: "\e97e";
@icon-star_half: "\e97f";
@icon-star_outline: "\e980";
@icon-alarm_off: "\e981";
@icon-assignment: "\e982";
@icon-assignment_returned: "\e9c2";
@icon-assignment_turned_in: "\e9c3";
@icon-book: "\e9e1";
@icon-bookmark: "\e983";
@icon-bookmark_outline: "\e984";
@icon-cached: "\e9c4";
@icon-chrome_reader_mode: "\e985";
@icon-code: "\e9c5";
@icon-dashboard: "\e986";
@icon-delete: "\e9e2";
@icon-description: "\e9c6";
@icon-done: "\e987";
@icon-done_all: "\e988";
@icon-exit_to_app: "\e989";
@icon-favorite: "\e98a";
@icon-favorite_outline: "\e98b";
@icon-flip_to_back: "\e98c";
@icon-flip_to_front: "\e98d";
@icon-highlight_remove: "\e98e";
@icon-history: "\e98f";
@icon-hourglass_empty: "\e990";
@icon-https: "\e930";
@icon-info: "\e991";
@icon-info_outline: "\e931";
@icon-input: "\e9e3";
@icon-label: "\e992";
@icon-label_outline: "\e993";
@icon-lock_open: "\e932";
@icon-lock_outline: "\e933";
@icon-open_in_browser: "\e994";
@icon-perm_media: "\e9e4";
@icon-settings1: "\e995";
@icon-settings_applications: "\e996";
@icon-settings_backup_restore: "\ea25";
@icon-settings_input_svideo: "\e9e5";
@icon-swap_horiz: "\e997";
@icon-swap_vert: "\e998";
@icon-system_update_tv: "\e95f";
@icon-today: "\e9e6";
@icon-verified_user: "\e999";
@icon-view_headline: "\e99a";
@icon-view_module: "\e99b";
@icon-visibility_off: "\e99c";
@icon-zoom_in: "\e99d";
@icon-zoom_out: "\e99e";
@icon-indeterminate_check_box: "\e99f";
@icon-copyright: "\e9a0";
@icon-fingerprint: "\e9a1";
@icon-lightbulb_outline: "\e9a2";
@icon-touch_app: "\e9a3";
@icon-date_range: "\e9e7";
@icon-line_style: "\e9a4";
@icon-line_weight: "\e9c7";
@icon-update: "\e9a5";
@icon-watch_later: "\e9a6";
@icon-pan_tool: "\e9a7";
@icon-delete_forever: "\e9a8";
@icon-check_circle_outline: "\e9a9";
@icon-delete_outline: "\ea26";
@icon-offline_bolt: "\ea27";
@icon-calendar_today: "\ea28";
@icon-drag_indicator: "\e9aa";
@icon-horizontal_split: "\ea29";
@icon-label_important_outline: "\ea2a";
@icon-vertical_split: "\e9ab";
@icon-account_tree: "\ea2b";
@icon-add_chart: "\e9e8";
@icon-add_ic_call: "\e9ac";
@icon-bookmarks: "\ea2c";
@icon-circle_notifications: "\e9ad";
@icon-dashboard_customize: "\e9c8";
@icon-drive_file_move_outline: "\ea2d";
@icon-drive_file_rename_outline: "\ea2e";
@icon-grid_view: "\e9ae";
@icon-logout: "\e9af";
@icon-margin: "\e9e9";
@icon-padding: "\e9ea";
@icon-thumb_down_off_alt: "\e9b0";
@icon-thumb_up_off_alt: "\e9b1";
@icon-toggle_off: "\e9b2";
@icon-toggle_on: "\e9b3";
@icon-height: "\e9b4";
@icon-sync_alt: "\e9b5";
@icon-menu_book: "\e9b6";
@icon-post_add: "\e9eb";
@icon-flip_camera_android: "\ea2f";
@icon-double_arrow: "\ea30";
@icon-add_location_alt: "\e9b7";
@icon-addchart: "\ea31";
@icon-app_registration: "\ea32";
@icon-arrow_circle_down: "\e9b8";
@icon-arrow_circle_up: "\e9b9";
@icon-article: "\e9ec";
@icon-auto_awesome: "\ea33";
@icon-auto_awesome_motion: "\e9ed";
@icon-auto_stories: "\ea34";
@icon-backup_table: "\ea35";
@icon-corporate_fare: "\e9ba";
@icon-forward_to_inbox: "\ea36";
@icon-help_center: "\e9bb";
@icon-hourglass_bottom: "\e9bc";
@icon-hourglass_top: "\e9bd";
@icon-local_police: "\e9be";
@icon-mediation: "\e9bf";
@icon-miscellaneous_services: "\ea37";
@icon-multiple_stop: "\ea38";
@icon-push_pin: "\e9ee";
@icon-qr_code: "\e9c9";
@icon-disabled_by_default: "\e9c0";
@icon-warning: "\e900";
@icon-add-alert: "\e901";
@icon-notification-alert: "\e902";
@icon-refresh: "\e903";
@icon-library-add: "\e904";
@icon-publishing: "\e905";
@icon-add-circle: "\e906";
@icon-add: "\e907";
@icon-clear: "\e925";
@icon-copy: "\e926";
@icon-edit: "\e908";
@icon-flag: "\e909";
@icon-link: "\e90a";
@icon-save: "\e91f";
@icon-send: "\e90b";
@icon-alarm: "\e90c";
@icon-time: "\e90d";
@icon-date: "\e90e";
@icon-photo: "\e90f";
@icon-download: "\e910";
@icon-adjust: "\e927";
@icon-add-photo: "\e911";
@icon-handle: "\e920";
@icon-back: "\e921";
@icon-drop-down: "\e912";
@icon-drop-up: "\e913";
@icon-forward: "\e922";
@icon-cancel: "\e914";
@icon-check: "\e92f";
@icon-chevron-up: "\e915";
@icon-chevron-down: "\e916";
@icon-menu: "\e917";
@icon-more: "\e918";
@icon-fold: "\e919";
@icon-unfold: "\e91a";
@icon-date-remove: "\e928";
@icon-notification: "\e91b";
@icon-star-on: "\e929";
@icon-star-off: "\e92a";
@icon-user: "\e92b";
@icon-accept: "\e923";
@icon-link-out: "\e92c";
@icon-extension: "\e92d";
@icon-help: "\e91c";
@icon-remove: "\e91d";
@icon-search: "\e92e";
@icon-settings: "\e91e";
@icon-euro: "\e924";

