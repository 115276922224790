// main: ../build.less
// out: .test/pill.test.css
@import (reference) "../config";


.pills {
	display: flex;
	gap: .rem(3)[];
	// * + & { margin-top: 0.25em;}
}

CONFIG[pill] 
{
	--pill--font: 500 .fslh(12, 16)[] @font-family;
	--pill--icon-size: .rem(14)[];
	--pill--height: .rem(20)[];
	--pill--padding: 0 0.5em;
	--pill--rounding: .rem(3)[];
	--pill--border: .rem(1)[] solid var(--pill--color-border);
	--pill--color-bg: transparent;
	--pill--color-text: mix( @color-primary, @color-text, 70 );
	--pill--color-icon: inherit;
	--pill--color-border: mix( @color-primary-muted, @color-bg, 50 );
	--pill--icon: none;
}

.pill {
	&:extend( CONFIG[pill] all );
	
	display: inline-flex;
	align-items: center;
	gap: 0 0.333em;
	font: var(--pill--font);
	height: var(--pill--height);
	padding: var(--pill--padding);
	white-space: nowrap;

	border-radius: var(--pill--rounding);
	color: var(--pill--color-text);
	background: var(--pill--color-bg);
	border: var(--pill--border);


	&::before {
		&:extend( .iconLike all );
		content: var(--pill--icon);
		font-size: var(--pill--icon-size);
		color: var(--pill--color-icon);
		margin-left: -0.125em;
	}

	&\:soldOut {
		// --pill--icon: @icon-star;
		--pill--icon: @icon-local_attraction;
		--pill--border: .rem(1)[] solid fade(@color-text-highlight-faded, 50);
		--pill--color-bg: @color-bg-highlight;
		--pill--color-icon: @color-text-highlight-faded;
		--pill--color-text: @color-text-highlight;
	}
	// &\:sold { --pill--icon: @icon-exit_to_app; }
	// &\:coop { --pill--icon: @icon-control_point_duplicate; }
	&\:sold { --pill--icon: @icon-local_grocery_store; }
	&\:coop { --pill--icon: @icon-people_outline; }
	&:\onTour { --pill--icon: @icon-fast_forward; }

	// &\:sold,
	// &\:coop,
	// &\:onTour {
	// 	--pill--color-border: @color-secondary-faded;
	// 	--pill--color-icon: @color-secondary-faded;
	// 	--pill--color-text: @color-secondary;
	// }
	&\:sold,
	&\:coop,
	&\:onTour {
		--pill--color-border: var(--palette--neutral-300);
		--pill--color-icon: var(--palette--neutral-300);
		--pill--color-text: var(--palette--neutral-450);
	}
}