// indef spinning loader

.spinner {
	transform: scale(0.5);
	transition: all 0.1s;
	opacity: 0;

	font-family: @font-family;

	&:after {
	}

	&:before {
		display: none;
		margin: 0;

		height: 1em;
		width: 1em;

		content: '';
		background: transparent;
		border-radius: 50%;
		border: 0 solid currentColor;
	}

	&.is\:animating {
		transform: scale(0.9);
		opacity: 1;

		&:after {
			opacity: 1;
		}

		&:before {
			display: flex;
			border-width: 2px;
			animation: fragSpinner 2s infinite ease-out 0.1s;
		}
	}
}

@keyframes fragSpinner {
	0% {
		transform: rotatez(0deg);
		border-color: transparent transparent transparent transparent;
		opacity: 0;
	}

	15% {
		opacity: 1;
	}

	25% {
		transform: rotatez(90deg);
		border-color: currentColor transparent transparent transparent;
	}

	50% {
		transform: rotatez(180deg);
		border-color: currentColor currentColor transparent transparent;
	}

	75% {
		transform: rotatez(270deg);
		border-color: currentColor currentColor currentColor transparent;
	}

	90% {
		border-color: currentColor currentColor currentColor currentColor;
	}

	95% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: rotatez(360deg);
		border-color: currentColor currentColor currentColor currentColor;
	}
}

// spinner / button integration

.button.is\:loading {
	position: relative;

	.spinner--overlay {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: inherit;
	}
	.spinner--inline {
		position: relative;
		margin-right: 0.5em;
		margin-left: -0.25em;
		// some hacks to make spinner behave exactly like a ::before icon
		left: -2px;
		top: -1px;
		height: 20px;
		width: 20px;
	}
}
