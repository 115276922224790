// main: ../build.less
@import (reference) "../config";

@overlay-z-index: 900;
@overlay-blur: 0.15rem;
@overlay-background: rgba( 0, 0, 0, .3 );



.view
{
	&.has\:overlay {
		position: relative;

		> *:not(.view__overlay) {
			filter: blur( @overlay-blur );
		}
	}
	&.has\:overlay &__overlay {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-top: 20vh;
	}

	&__overlay {
		.coverParent;
		background: @overlay-background;
		z-index: @overlay-z-index;
		display: none;
	}
}

.overlay
{
	&__dialogue {
		background: @color-bg;
		box-shadow: 0 0.375rem 0.75rem rgba( 0, 0, 0, .25 );
		padding: .rem(30)[];
		min-width: .rem(360)[];
		max-width: .rem(720)[];
	}
}
