// main: ../build.less
@import (reference) "../config";

// VARIABLES

// @iconNamespace: classname base
// @iconDelimiter: bem-style delimiter (character used to separate {icon} & {name} in plain text, like "--" or ":")
// @iconDelimiterSanitized: sanitized delimiter for usage as class (example: escaping "\:")

// @iconEnableParticles: enable usage of <i>glyphName</i> with css ligatures (1|0)
// @iconParticleSelector: css selector to be used for defining particles (eg: "i")

// @icon-size-default: default size for icons

.iconLike 
{
	font-family: '@{__ICON_FONT_NAME}' !important; // override any font changing extensions etc
	speak: never; // hide from screenreaders
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: .rem(@icon-size-default)[];

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// target icon classes without needing bem-style double classification
[class^="@{iconNamespace}@{iconDelimiter}"], 
[class*=" @{iconNamespace}@{iconDelimiter}"] 
{
	&::before {
		&:extend( .iconLike );
	}
}

// conditional icon particles
& when ( @iconEnableParticles = 1 ) 
{
	@{iconParticleSelector} 
	{
		// apply icon font to element itself
		&:extend( .iconLike );

		// enable ligatures for keywords
		letter-spacing: 0;
		-webkit-font-feature-settings: "liga";
		-moz-font-feature-settings: "liga=1";
		-moz-font-feature-settings: "liga";
		-ms-font-feature-settings: "liga" 1;
		font-feature-settings: "liga";
		-webkit-font-variant-ligatures: discretionary-ligatures;
		font-variant-ligatures: discretionary-ligatures;
	}
}


// kept this i case i broke some currently implemented particles
// [class^="@{iconNamespace}@{iconDelimiter}"], 
// [class*=" @{iconNamespace}@{iconDelimiter}"] {
// 	i&, // TODO: remove i
// 	&::before {
// 		&:extend( .iconLike );
// 	}

// 	i& {
// 		width: .rem(20)[];
// 	}
// }