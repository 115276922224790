// main: ../build.less


.HIDE_DEBUG {
	display: none;
}

.view {
	&__sidebar { background: #444; }
	&__toolbar {  background: #efefef; }
	&__main {  }
}

// [id*="rbd-announcement"] {
// 	z-index: @z-auto;
// }