// main: ../index.less
@import (reference) "../config.less";

* { box-sizing: border-box; }

:root,
html {
	font-size: 100%;
	// font-size: percentage((@__REM_SCALING / 16)); // we don't do that shit anymore!
}

body {
	.font--normal;
}

html,
body,
#__next {
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100%;
}
