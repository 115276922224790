// main: ../build.less
@import (reference) "../config.less";

.tabNav
{
	display: flex;
	align-items: center;
	max-width: 100%;
	overflow-x: auto;

	&__item {
		display: flex;
		padding: .rem(12)[] .rem(32)[] .rem(12)[] .rem(12)[];
		margin-right: .rem(3)[];


		cursor: pointer;
		background: rgba(255, 255, 255, 0.3);
		transition: background .3s ease-out;

		&:hover { background: rgba(255, 255, 255, 0.8); }

		&.is\:active {
			cursor: auto;
			background: var(--color-bg);
		}
	}

	&__icon { margin-right: .rem(10)[]; }

	&__label {
		display: flex;
		flex-flow: column nowrap;
	}
	.label {
		&__title,
		&__desc { white-space: nowrap; }

		&__title { 
			font-size: .rem(16)[];
			line-height: .rem(21)[];
			font-weight: bold; 
		}
		&__desc  {  
			font-size: .rem(14)[];
			line-height: .rem(16)[];
			color: var(--color-text-faded); 
		}
	}
}
