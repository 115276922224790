// main: ../build.less
@import (reference) '../config';


#AddVars() { .root() {
}}

CONFIG[modalBackdrop] 
{
	--modal--backdrop-stackingContext: calc(var(--appLayer--sidePanel) - 1);
	--modal--backdrop-bg: rgba(0, 0, 0, 0.33);
	--modal--backdrop-filter: none;
	// --modal--backdrop-filter: blur(1px);
}
CONFIG[modal] 
{
	--modal--stackingContext: var(--appLayer--modal, 1450);
	--modal--min-width: .rem(420)[];
	--modal--max-width: .rem(620)[];
	--modal--min-height: 25vh;
	--modal--max-height: 90vh;
	--modal--rounding: .rem(8)[];
	--modal--border: none;
	--modal--shadow: 
		0 0.75em 1.5em rgba(0, 0, 0, 0.333),
		0 0.5em 0.6em rgba(0, 0, 0, 0.125);
	--modal--color-bg: var(--color-bg);
	--modal--color-text: var(--color-text);

	--modal--header-padding: .rem(18)[] .rem(24)[] .rem(16)[];
	--modal--header-color-bg: var(--modal--color-bg);
	--modal--header-color-text: var(--modal--color-text);
	--modal--title-font: 500 .rem(21)[] ~"/1.25" @font-family;
	--modal--title-subtitle-spacing: .rem(4)[];
	--modal--subtitle-font: .rem(14)[] ~"/1.333" @font-family;
	--modal--subtitle-color-text: var(--color-color-text);
	
	--modal--content-padding: .rem(16)[] .rem(16)[];
	--modal--content-separator: 1px solid #ccc;

	--modal--actions-margin: .rem(16)[];
	--modal--actions-padding: .rem(16)[] .rem(16)[];
	--modal--actions-distribute: space-between;
	--modal--actions-color-bg: var(--modal--color-bg);
	--modal--actions-color-text: var(--modal--color-text);
	
	@media @AppUI--basic {
		--modal--min-width: none;
		--modal--max-width: 98vw;
		--modal--content-padding: .rem(16)[] .rem(12)[];
	}
}

body.has\:modal 
{
	.viewport {
		@media @FormFactor--compact {
			overflow: hidden;
		}
	}
	// TODO: this doesnt help, y u leak clicks to below??
	// .view__main {
	// 	pointer-events: none !important;
	// }
}

.modalBackdrop 
{	
	&:extend( CONFIG[modalBackdrop] all);

	position: fixed;
	z-index: var(--modal--backdrop-stackingContext);
	top: 0; right: 0; bottom: 0; left: 0;

	background-color: var(--modal--backdrop-bg);
	backdrop-filter: var(--modal--backdrop-filter);
}

.modal 
{
	&:extend( CONFIG[modal] all);

	position: fixed;
	z-index: var(--modal--stackingContext);
	top: 0;	right: 0; bottom: 0; left: 0;
	display: flex;
	pointer-events: none;

	&__content {
		margin: 0 auto;
		min-width: var(--modal--min-width);
		max-width: 96vw;
		min-height: var(--modal--min-height);
		max-height: var(--modal--max-height);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-self: center;
		pointer-events: auto;
		overflow: auto;
		
		border: var(--modal--border);
		border-radius: var(--modal--rounding);
		background: var(--modal--color-bg);
		color: var(--modal--color-text);
		box-shadow: var(--modal--shadow);

		@media @FormFactor--tablet {
			max-width: var(--modal--max-width);
		}
		@media @AppUI--basic {
			--modal--min-width: none !important;
			--modal--max-width: 100vw !important;
		}
	}

	&__header {
		text-align: center;
		padding: var(--modal--header-padding);
		background: var(--modal--header-color-bg);
		color: var(--modal--header-color-text);
	}
	&__title { font: var(--modal--title-font); }
	&__subtitle { 
		font: var(--modal--subtitle-font); 
		color: var(--modal--subtitle-color-text)
	}
	&__title + &__subtitle {
		margin-top: var(--modal--title-subtitle-spacing);
	}

	&__section {
		display: block;
		border-top: var(--modal--content-separator);
		padding: var(--modal--content-padding);
	}
	&__section:has(+ &__actions) {
		margin-bottom: var(--modal--actions-margin);
	}
	&__actions {
		margin-top: auto;
		display: flex;
		justify-content: var(--modal--actions-distribute);
		color: var(--modal--actions-color-text);
		background: var(--modal--actions-color-bg);
	}

	// failsafe
	.is\:open .select__menu,
	.is\:open .contextMenu__panel,
	.is\:open .dateInput__calendar,
	.is\:open .datepicker {
		z-index: var(--appLayer--overlay);
	}
}

.showChangeModal {
	--modal--min-width: .rem(480)[];
	--modal--min-height: .rem(360)[];
	--modal--content-padding: .rem(16)[] .rem(32)[];

	label:has([id="showChange.tbd"]) {
		// font-weight: 500;
		// color: var(--color-text-muted);
		margin-right: 1em;
	}
	label:has([id="showChange.corona"]) {
		font-size: .rem(14)[];
		color: var(--color-text-faded);
	}
	:has([id="showChange.tbd"]:checked) .field:has([id="showChange.newDate"]) {
		opacity: 0.5;
	}
}

.imageEditModal {

	.modal__content {
		display: flex;
		max-width: none;
		width: 100%;
		overflow: visible;
		user-select: none;
		pointer-events: none;
		background: transparent;
		margin: 1.5rem;
		box-shadow: none;
	}

	.modal__side {
		width: .rem(512)[];
		background-color: var(--modal--color-bg);
		padding: .rem(8)[];
		pointer-events: auto;
	}

	.imageCropper {
		margin: 0 auto;
		pointer-events: auto;
		box-shadow: var(--modal--shadow);
		max-height: 98vh;
	}
	.ReactCrop__image {
		max-height: 98vh;
	}
	// .imageCropper 
	// .imageFormatSelect {
	// 	background-color: #fff;
	// 	max-width: 20rem;
	// }

	.loader {
		margin: 0 auto;
		display: flex;
		background-color: white;
		pointer-events: auto;
		// color: @color-secondary;
		color: @color-secondary-faded;
		// border: 0.5rem solid @color-bg-faded;
		border-radius: 0.375rem;
		padding: .rem(18)[] .rem(24)[];
		&__label {
			display: flex;
			align-items: center;
			margin-left: .rem(20)[];
			font-weight: 500;
			font-size: .rem(20)[];
			line-height: 1;
			color: @color-secondary-faded;
		}
		&::before {
			height: .rem(24)[];
			width: .rem(24)[];
			border-width: .rem(4)[];
		}
	}

}

.promptModal {
	margin: .rem(8)[];
	
	.modal__content {
		min-height: auto;
	}
	&--delete {
		--modal--title-subtitle-spacing: .rem(12)[];
		--modal--subtitle-font: .rem(14)[] ~"/1.666" @font-family;

		.modal__title {
			code { background-color: transparent;}
		}
	}
}

.calendarSyncModal {
	--modal--actions-margin: 0; 
	--modal--actions-distribute: center;
	--modal--header-color-bg: var(--palette--neutral-800);
	--modal--header-color-text: var(--palette--neutral-100);
	--modal--subtitle-color-text: var(--palette--neutral-300);

	.modal__title {
		margin-left: -1em;
		display: flex;
		justify-content: center;
		align-items: center;
		&::before {
			&:extend(.iconLike all);
			content: @icon-sync_alt;
			font-size: 1.125em;
			margin-right: 0.375em;
			color: var(--color-primary);
			font-weight: normal;
		}
	}
	@media @AppUI--basic {
		.editorialCopy { --copy--padding-x: 0.125em; }
	}

	h2, h3, h4 {
		&[class*="icon:"] {
			display: flex;
			align-items: center;
			margin-left: -0.25em;
			&::before {
				color: var(--color-primary-muted);
				font-size: 1.5em;
			}
		}
	}

	&__feed {
		text-align: center;
		.button\:primary { font-weight: 500; }
	}

	&--viewFeedURL {
		@media @FormFactor--tablet {
			--modal--min-width: .rem(620)[];
		}
	}
	&__feedActions {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		gap: 0.5em;

		a[class*="icon:"] {
			text-decoration: none;
			&::before { 
				margin-right: 0.25em; 
			}
		}
	}
	&__successTitle {
		text-align: center;
		justify-content: center;
		font-weight: 500;
		font-size: .em(18)[];
		margin-bottom: 0.25em;
		color: var(--color-text-success);
		&::before {
			font-size: 1.1em !important;
			margin-right: 0.2em;
			color: inherit !important;
		}
	}
	&__successDesc {
		margin-top: 0.5em;
		font-size: .em(14)[];
		color: var(--color-text-muted);
		padding: 0 2em;
	}
}

.errorReportModal {
	--modal--max-width: .rem(720)[];
	// .modal__content {
	// 	max-width: .rem(720)[];
	// }
}

// this has to move somewhere else, draft code
.schedulingModal 
{
	--modal--actions-margin: 0; 
	.field__input--radio {
		font: .rem(18)[] ~"/1.333" @font-family;
		font-weight: 500;
		min-height: 0;
		input { height: auto; }	
	}
	

	&__desc {
		margin: .rem(8)[] 0 .rem(8)[] .rem(24)[];
		font: .rem(14)[] ~"/1.5" @font-family;
		color: var(--color-text-muted);
	}
	&__dateSelection {
		display: grid;
		gap: .rem(16)[];
		width: 100%;
		max-width: .rem(360)[];
		// place-items: center;
		// place-content: center center;
		margin: 0 auto;
		grid-template-columns: auto auto;
		grid-template-areas: 'picker picker' 'date time';

		justify-content: center;
		padding: .rem(8)[] .rem(8)[];

		.field + .field {
			margin-left: .rem(4)[];
		}

		input {
			min-width: none;
		}
	}
	// .modal__section:has(input:checked){
	// 	background-color: var(--color-bg-highlight);
	// 	color: var(--color-primary-muted);
	// }
	
	.datepicker {
		grid-area: picker;
		border: none;
		--datePicker--shadow: none;
	}
	&__date {
		grid-area: date;
	}
	&__time {
		grid-area: time;
	}

	// &__actions {
	// 	display: flex;
	// 	justify-content: flex-end;
	// }
}

