// main: ../build.less
@import (reference) "../config";

@richTextSelector: .richText;

@{richTextSelector}
{
	display: flex;
	flex-flow: column;

	&__editorToggle,
	&__langToggle {
		font-size: .rem(14)[];
		line-height: .rem(22)[];
		align-self: flex-end;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}

	.field__label + & {
		margin-top: -1.375em;
	}

	// &__langToggle {
	// 	align-self: flex-end;
	// 	cursor: pointer;
	// }

	&__editor {
		&:extend( PROTO[input] all );
		height: auto;
		padding: 0;
		min-height: .rem(160)[];
	}

	&__htmlEditor {
		&:extend( PROTO[input] all );
		min-height: .rem(320)[];
		resize: vertical;
	}

	&.is\:focus &__editor,
	&.is\:focus &__htmlEditor {
		border-color: @input-border-focus-color;
	}

	// move into quill theme
	.ql-container, .ql-toolbar {
		border: none !important;
	}

	.ql-editor {
		p + p {
			margin-top: 1.5em;
		}
		ul, ol {
			margin-top: 1.5em;
		}
		h2 {
			font-weight: 600;
			margin-top: 1em;
			margin-bottom: 0.5em;
		}
		h3 {
			font-weight: 600;
			margin-top: 0.75em;
			margin-bottom: 0.25em;
		}
	}

	&.is\:disabled {
		pointer-events: none;
	}

	// OLD DRAFTJS STYLES

	// &__toolbar {
	// 	// display: none;
	// 	border: none;
	// 	border-bottom: 1px solid @input-border-color;
	// 	padding: .rem(4)[];
	// 	margin-bottom: 0;

	// 	> [class$="wrapper"] {
	// 		margin: 0;
	// 		align-items: stretch;
	// 	}
	// }
	// .rdw-option-wrapper {
	// 	border: none;
	// 	border-radius: 0;
	// 	margin: 0;
	// 	height: auto;
	// 	min-width: .rem(24)[];
	// 	min-height: .rem(24)[];
	// 	padding: .rem(8)[];

	// 	&:hover { 
	// 		box-shadow: none;
	// 		background-color: @color-bg-faded; 
	// 	}
	// 	&.rdw-option-active {
	// 		box-shadow: none;
	// 		background-color: @color-bg-highlight;
	// 	}
	// }

	// &__editor {
	// 	#set.padding( (@input-padding-vertical * 2), @input-padding-horizontal );
	// }
	// .public-DraftStyleDefault-block {
	// 	margin: 0;
	// }
	// .DraftEditor-editorContainer [data-block=true] + [data-block=true] {
	// 		#set.margin ( top, 24 );
	// }

	// .rdw-link-modal {
	// 	height: auto;
		
	// 	label { #set.text(14, 21); }
	// 	input[type=checkbox] { #set.rem( height, 16 ); }
	// }
}
