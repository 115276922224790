// main: ../build.less
@import (reference) "../config";


@TouchDevice--Compact: ~"@{InputMode--touch} and @{FormFactor--compact}";

.pagination
{

	display: flex;
	justify-content: flex-end;
	margin-bottom: .rem(24)[];



	&__item {
		display: inline-flex;
		align-items: center;
		border-radius: .rem(3)[];
		min-width: .rem(30)[];
		border: 1px solid #ccc;
		cursor: pointer;
		a, a:hover { 
			color: mix( @color-text, @color-text-muted, 70% ); 
		}
		
		&:not(:last-child) {
			margin-right: .rem(2)[];
		}
		&:hover {
			background: #eee;
			color: @color-text-muted;
		}
		:focus {
			outline: none;
		}
		&.is\:active {
			font-weight: bold;
			background: #eee;
			cursor: normal;
		}
		&.disabled {
			border: 1px solid #eee;
			font-weight: bold;
			// background: #eee;
			a { color: #999 !important; }
			cursor: normal;
		}
		
	}

	@media @TouchDevice--Compact {
		&__item {
			line-height: .rem(40)[];
			min-width: .rem(40)[];
		}
	}
	@media @AppUI--basic {
		&__item {
			line-height: .rem(32)[];
			min-width: .rem(34)[];
		}
	}

	@container pagingBar (@pagingBar--lessNumbers) {
		.pagination__item,
		.break {
			display: none !important;
			visibility: hidden !important;
			// order: 1;
		}
		.pagination__item.is\:active,
		.pagination__item.is\:active + .pagination__item,
		.pagination__item:has(+ .is\:active),
		.pagination__next,
		.pagination__prev {
			display: inline-flex !important;
			visibility: visible !important;
		}
	}
	@container pagingBar (@pagingBar--prevNextOnly) {
		.pagination__item,
		.break {
			display: none !important;
			visibility: hidden !important;
			// order: 1;
		}
		.pagination__next, 
		.pagination__prev, 
		// .pagination__item:has(.pagination__pageLink[aria-label="Page 1"]), 
		.pagination__item.is\:active {
			display: inline-flex !important;
			visibility: visible !important;
		}
		// .pagination__item:has(.pagination__pageLink[aria-label="Page 1"]) {
		// 	order: 0;
		// }
	}
	

	&__prevLink, &__nextLink, &__pageLink {
		display: inline-flex;
		text-align: center;
		width: 100%;
		justify-content: center;
		// align-items: center;
		padding: .rem(0)[] .rem(6)[];
	}
	&__prev {}
	&__next {}

}
