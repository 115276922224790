// main: ../build.less
@import (reference) "../config";

CONFIG[showCalendar] 
{
	--showCalendar--border: 1px solid #ccc;
	--showCalendar--header-font: bold .fslh(19, 30)[] @font-family;
	--showCalendar--header-padding: .rem(8)[] .rem(12)[];
	--showCalendar--header-height: .rem(48)[];
	--showCalendar--nav-icon-size: .rem(28)[];

	--showCalendar--dateNum-font: bold .fslh(18, 24)[] @font-family;
	--showCalendar--weekDay-font: .fslh(14, 24)[] @font-family;
	--showCalendar--weekDay-color: var(--color-text-muted);
	--showCalendar--otherMonth-color: var(--color-text-faded);
	--showCalendar--day-padding: .rem(6)[];
	--showCalendar--day-label-padding: .rem(6)[];
	--showCalendar--week-separator: .rem(3)[] solid #ccc;
	--showCalendar--color-bg: mix(@color-bg, @color-bg-faded, 80);
	--showCalendar--color-bg-header: var(--color-bg);
	--showCalendar--color-bg-day: var(--color-bg);
	--showCalender--color-bg-dayEmpty: var(--color-bg);

	--showCalendar--entry-font: .fslh(14, 18)[] @font-family;
	--showCalendar--entry-padding: .rem(6)[] .rem(8)[];
	--showCalendar--entry-rounding: 0.1em;
	--showCalendar--entry-spacing: .rem(3)[];
}

@showCalenderLayout--calendarGrid: #inline.min( 1100 )[];
@showCalenderLayout--adaptiveGrid: #inline.minmax( 800, 1100 )[];
@showCalenderLayout--dualGrid: #inline.minmax( 600, 800 )[];
@showCalenderLayout--dateList: #inline.max( 600 )[];

.showCalendar {

	&:extend(CONFIG[showCalendar] all);
	&:extend(CONFIG[filterBar], CONFIG[view]);

	container-name: showCalendar;
	container-type: inline-size;
	position: relative;
	z-index: var(--viewLayer--static);

	min-height: 100%;
	background: var(--showCalendar--color-bg);

	&__toolbar {
		padding: var(--showCalendar--header-padding);
		height: var(--showCalendar--header-height);
		background: var(--showCalendar--color-bg-header);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__currentMonth {
		font: var(--showCalendar--header-font);
		margin: 0 .rem(8)[];
	}
	&__navButton:before {
		font-size: var(--showCalendar--nav-icon-size);
		cursor: pointer;
	}

	@container showCalendar (@showCalenderLayout--calendarGrid) {
		&__grid {
			--chrome: calc(var(--viewStatic--toolbar--height)
				+ var(--filterSlot--height) 
				+ var(--showCalendar--header-height));
			--weekMinH: calc(calc(100vh - var(--chrome)) / 6);

			display: grid;
			grid-template-columns: repeat( 7, 1fr);
			grid-auto-rows: minmax(var(--weekMinH, 6rem), auto);
		}
	}
	@container showCalendar (@showCalenderLayout--adaptiveGrid) {
		&__grid {
			display: grid;
			grid-template-columns: repeat( 3, 1fr);
			grid-auto-rows: minmax(3rem, auto);
		}
		&__day:is(.day\:1, .day\:4, .day\:7) { grid-column: 1; }
		&__day:is(.day\:1, .day\:2, .day\:3) { border-top: var(--showCalendar--week-separator); }
	}
	@container showCalendar (@showCalenderLayout--dualGrid) {
		&__grid {
			display: grid;
			grid-template-columns: repeat( 2, 1fr);
			grid-auto-rows: minmax(3rem, auto);
		}
		&__day:is(.day\:1, .day\:3, .day\:5, .day\:7) { grid-column: 1; }
		&__day:is(.day\:1, .day\:2) { border-top: var(--showCalendar--week-separator); }
	}

	&__grid {
		border-bottom: var(--showCalendar--border);
	}
	&__day {
		border-right: var(--showCalendar--border);
		border-top: var(--showCalendar--border);
		padding: var(--showCalendar--day-padding);
		background: var(--showCalendar--color-bg-day);
		overflow: hidden;

		&Label {
			margin-bottom: .rem(4)[];
			padding-left: .rem(6)[];
		}
		&Num {
			font: var(--showCalendar--dateNum-font);
			margin-right: 0.25em;
		}
		&Short {
			font: var(--showCalendar--weekDay-font);
			color: var(--showCalendar--weekDay-color);
		}

		&Content {
			overflow-y: auto;
		}

		&--otherMonth &Label { color: var(--showCalendar--otherMonth-color); }
		&--today { /* TODO */ }

		&:has(&Content:empty) {
			background: var(--showCalender--color-bg-dayEmpty);
		}
	}

	&__event {
		font: var(--showCalendar--entry-font);
		padding: var(--showCalendar--entry-padding);
		border-radius: var(--showCalendar--entry-rounding);
		cursor: pointer;

		& + & { margin-top: var(--showCalendar--entry-spacing); }

		background-color: @color-bg-faded;
		border: none;
		&.status\:announced {
			background-color: var(--color-status-ann);
			border-color: var(--color-status-ann);
		 }
		&.status\:pendingAnnouncement {
			background-color: var(--color-status-tba);
			border-color: var(--color-status-tba);
		}
		&.status\:pendingConfirmation {
			background-color: var(--color-status-tbc);
			border-color: var(--color-status-tbc);
		}
		&.status\:confirmed {
			background-color: var(--color-status-con);
			border-color: var(--color-status-con);
		}
		&.status\:canceled {
			background-color: var(--color-status-abs);
			border-color: var(--color-status-ann);
		}

		&.is\:announcementDate {
			background-color: transparent;
			border-style: solid;
			border-width: .rem(2)[];
			padding: .rem(4)[] .rem(6)[];


			display: flex;
			flex-flow: column-reverse nowrap;
		}
		&.is\:announcementDate &Title {
			color: var(--color-text-muted);
			font-weight: normal;
		}
		&.is\:announcementDate &Meta {
			font-weight: 500;
			opacity: 1;
		}

		a { text-decoration: none; }

		&Title {
			font-weight: bold;
			color: var(--color-text);
		}
		&Meta { opacity: 0.5; }
	}
}
