// out: .test/table--showPersonnel.test.css
// main: ../build.less
@import (reference) "../../config";
@import (reference) "../../components/table";


.table--showPersonnel
{

	@showPersonnelTable--tweakExpanded:  #inline.minmax( 820px, 960px )[];

	--td--menu: .rem(120)[];
	--row--height: .rem(60)[];
	--tr--max: auto;
	--row--height--collapsed-primary: .rem(60)[];
	--row--height--collapsed: .rem(48)[];

	
	@container listTable (@listTable--expanded) {
		.table__row,
		.table__header {
			grid-template-columns:
				[date] minmax(var(--td--XS), var(--td--XS))
				[show] minmax(var(--td--LG), 2fr)
				[team] minmax(var(--td--2XL), 2fr)
				[jobs] minmax(var(--td--3XL), 4fr)
				[menu] minmax(var(--td--ctrl), min-content);
		}
		.table__header {
			grid-template-rows: .rem(36)[];
		}
		// TODO: update cell refencing with for: & field: classes
		.table_cell {
			.field\:actions .button {
				padding-right: 0.875rem !important;
				grid-template-rows: .rem(36)[];
			}
		}
	}

	@container listTable (@showPersonnelTable--tweakExpanded) {
		.table__row,
		.table__header {
			grid-template-columns:
				[date] minmax(var(--td--XS), var(--td--XS))
				[show] minmax(var(--td--LG), 2fr)
				[team] minmax(var(--td--XL), 2fr)
				[jobs] minmax(var(--td--3XL), 5fr)
				[menu] minmax(var(--td--ctrl), min-content);
		}
		.table__cell {
			.jobsPreview__counter {

				--jobCount--font-size: .rem(14)[];
				--jobCount--min-width: 2em;
				--jobCount--height: 1.75em;
				--jobCount--padding: 0;

				[class*="icon:"]::before { display: none; }
			}
			&.field\:assignees {
				overflow: hidden;
				flex-flow: row nowrap;
			}
			&.field\:actions {
				.button {
					span { display: none; }
					&::before { margin-right: 0; }
				}
			}
		}
	}


	@container listTable (@listTable--collapsed) {
		.table__row {
			grid-template-rows:
				[header] var(--row--height--collapsed-primary)
				[jobs] minmax(var(--row--height--collapsed), auto)
					repeat(auto-fit, minmax(var(--row--height--collapsed), auto))
				[footer] minmax(var(--row--height--collapsed), min-content);
		}
		.table__cell {
			.jobsPreview__descMissing { display: block !important;}
			.jobsPreview__descAssigned { display: flex !important;	}
			.jobsPreview__count {
				border: none;
				transform: none !important;
			}
			&.field\:jobs,
			.jobsPreview { display: contents; }
			.jobsPreview__counter {
				grid-row: header;
				grid-column: menu;
				place-self: center end;
				flex-flow: row-reverse;
				padding: 0.25em 0.5em;

				--jobCount--font-size: .rem(14)[];
				--jobCount--min-width: 1.75em;
				--jobCount--height: 2em;
				--jobCount--padding: 0;

				[class*="icon:"]::before { display: none; }
			}
			.jobsPreview__desc {
				grid-row: jobs;
				grid-column: A;
				font-size: .rem(13)[];
				padding: var(--cell--pad-y) var(--cell--pad-x);
				border-left: var(--cell--separator);
				border-top: var(--cell--separator);

				strong { display: block; }
			}
			
			&.field\:date {
				grid-row: jobs;
				grid-column: menu;
				align-items: flex-start;
				justify-content: center;
			}
			&.field\:assignees,
			.jobsPreview__descAssigned {
				&::before {
					flex: 0 1 100%;
					font: 500 .fslh(13, 20)[] var(--font-family);
					color: var(--color-text-muted);	
				}
			}
			&.field\:assignees::before {
				content: "Zuständige:";
			}
			.jobsPreview__descAssigned:not(:empty)::before {
				content: "Personal:";
				margin-bottom: 0.25em;
			}
			.jobsPreview__descMissing + .jobsPreview__descAssigned {
				margin-top: 0.25em;
				&::before { display: none; }
			}
			&.field\:assignees {
				grid-column: A;
				grid-row: footer;
			}
			&.field\:actions {
				place-content: center end;
				.button {
					span { display: none; }
					&::before { margin-right: 0; }
					--button--padding-x-icon: calc(var(--button--padding-x) * 0.8);
				}
			}
		}
	}

	@container listTable (@listTable--adaptive) {
		.table__row {
			grid-template-columns: 
				[ctrl] var(--td--ctrl) 
				[A] 2fr
				[aside] minmax(var(--td--XL), 1fr)
				[menu] minmax(var(--td--SM), min-content);

			grid-template-rows:
				[header] var(--row--height--collapsed-primary)
				[jobs] minmax(var(--row--height--collapsed), auto);
		}
		.table__cell {

			.jobsPreview__desc { grid-column: A; }
			
			
			&.field\:assignees {
				grid-row: jobs;
				grid-column: aside;
				place-self: start start;
			}
			

			&.field\:date {
				grid-row: header;
				grid-column: aside;
				align-items: center;
				justify-content: flex-end;
				border-left: none;
				border-right: var(--cell--separator);
			}
			&.field\:actions {
				grid-row: jobs;
			}
		}
	}

	@container listTable (@listTable--minimal) {
		.table__row {
			grid-template-rows:
				[header] var(--row--height--collapsed-primary)
				[jobs] minmax(var(--row--height--collapsed), auto)
				[team] minmax(var(--row--height--collapsed), auto)
				[footer] minmax(var(--row--height--collapsed), min-content);
		}
		
		.table__cell {
			.jobsPreview__counter {
				--jobCount--font-size: .rem(12)[];
				--jobCount--min-width: 1.25em;
				--jobCount--height: 1.5em;
				gap: .em(2, 12)[];
				place-self: start end;
			}
			.jobsPreview__desc {
				grid-column: A / span all;
			}
			&.field\:assignees {
				grid-row: team;
				grid-column: A / span all;
			}
			&.field\:date {
				grid-row: footer;
				grid-column: A;
				justify-content: flex-start;
			}
		}
	}

	
	
	
}