// out: .test/editEvent.test.css
// main: ../build.less
@import (reference) "../config";


// Workaround for z-stacking isolation issues:
.view--editEvent 
{
	// .table:has(.is\:open) {
	// 	z-index: var(--viewLayer--promoted);
	// }

	.eventStartEndTimes {
		display: flex;
		gap: 1rem;
		> * { flex: 1; }

		@media @FormFactor--narrow {
			flex-wrap: wrap;
		}
	}
}

// DRAFT CODE, postponed
// .view--editEvent 
// {
// 	.layout__side {
// 		container: layoutSide / inline-size;
// 	}

// 	@layoutSide--columns: #inline.minmax( 500px, 1200px )[];

// 	@container layoutSide (@layoutSide--columns) {
// 		.layoutSide__wrapper {
// 			display: grid;
// 			grid-template-columns: 1fr 1fr;
// 			grid-gap: 1rem;
// 		}
// 	}
// }
