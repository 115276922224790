// main: ../build.less
@import (reference) '../config';


// indexed class--variants printer
.printIndexedColorClassVariants(
	@names; 
	@fqPropName: color;
	@bgPropName: background-color;
	@i: 1; 
)
{
	@color-prop: ~"@{fqPropName}";
	@background-prop: ~"@{bgPropName}";

	& when (@i <= length(@names)) 
	{
		@swatchName: extract(@names, @i);
		@variantSelector: if(@i < 10, ~"0@{i}", ~"@{i}");

		&--@{variantSelector} {
			@{color-prop}: ~"var(--color-@{swatchName}-fg)";
			@{background-prop}: ~"var(--color-@{swatchName}-bg)";
		}

		.printIndexedColorClassVariants(@names; @fqPropName; @bgPropName; @i + 1);
	}
}


// Color Themes
#AvatarColors() 
{
	.v5() {
		@names:'marine', 'lagoon', 'emerald', 'spring', 'cedar', 'flax', 'amber', 'coral', 'terra', 'rose', 'orchid', 'violet', 'azure', 'cobalt', 'ocean', 'steel';

		// Marine 
		--color-marine-bg: hsl(200, 45%, 83%);
		--color-marine-fg: hsl(200, 45%, 63%);
		// Lagoon 
		--color-lagoon-bg: hsl(175, 45%, 82%);
		--color-lagoon-fg: hsl(175, 45%, 62%);
		// Emerald 
		--color-emerald-bg: hsl(150, 45%, 83%);
		--color-emerald-fg: hsl(150, 45%, 63%);
		// Spring 
		--color-spring-bg: hsl(125, 45%, 82%);
		--color-spring-fg: hsl(125, 45%, 62%);
		// Cedar 
		--color-cedar-bg: hsl(100, 45%, 83%);
		--color-cedar-fg: hsl(100, 45%, 63%);
		// Flax 
		--color-flax-bg: hsl(75, 45%, 84%);
		--color-flax-fg: hsl(75, 45%, 64%);
		// Amber 
		--color-amber-bg: hsl(50, 45%, 83%);
		--color-amber-fg: hsl(50, 45%, 63%);
		// Coral 
		--color-coral-bg: hsl(25, 45%, 82%);
		--color-coral-fg: hsl(25, 45%, 62%);
		// Terra 
		--color-terra-bg: hsl(0, 45%, 83%);
		--color-terra-fg: hsl(0, 45%, 63%);
		// Rose 
		--color-rose-bg: hsl(335, 45%, 82%);
		--color-rose-fg: hsl(335, 45%, 62%);
		// Orchid 
		--color-orchid-bg: hsl(310, 45%, 83%);
		--color-orchid-fg: hsl(310, 45%, 63%);
		// Violet 
		--color-violet-bg: hsl(285, 45%, 82%);
		--color-violet-fg: hsl(285, 45%, 62%);
		// Azure 
		--color-azure-bg: hsl(260, 45%, 83%);
		--color-azure-fg: hsl(260, 45%, 63%);
		// Cobalt 
		--color-cobalt-bg: hsl(235, 45%, 82%);
		--color-cobalt-fg: hsl(235, 45%, 62%);
		// Ocean 
		--color-ocean-bg: hsl(210, 45%, 83%);
		--color-ocean-fg: hsl(210, 45%, 63%);
		// Steel 
		--color-steel-bg: hsl(185, 45%, 82%);
		--color-steel-fg: hsl(185, 45%, 62%);
	}
	// V4 turned out a bit too dull & undersaturated
	.v4() {
		@names:'slate', 'steel', 'sage', 'moss', 'taupe', 'sand', 'clay', 'mauve', 'plum', 'dusk', 'ocean', 'river', 'forest', 'pine', 'storm', 'mist';

		// Slate 
		--color-slate-bg: hsl(210, 30%, 82%);
		--color-slate-fg: hsl(210, 30%, 67%);
		// Steel 
		--color-steel-bg: hsl(200, 25%, 80%);
		--color-steel-fg: hsl(200, 25%, 65%);
		// Sage 
		--color-sage-bg: hsl(150, 25%, 82%);
		--color-sage-fg: hsl(150, 25%, 67%);
		// Moss 
		--color-moss-bg: hsl(140, 22%, 80%);
		--color-moss-fg: hsl(140, 22%, 65%);
		// Taupe 
		--color-taupe-bg: hsl(45, 22%, 82%);
		--color-taupe-fg: hsl(45, 22%, 67%);
		// Sand 
		--color-sand-bg: hsl(35, 28%, 83%);
		--color-sand-fg: hsl(35, 28%, 68%);
		// Clay 
		--color-clay-bg: hsl(10, 25%, 82%);
		--color-clay-fg: hsl(10, 25%, 67%);
		// Mauve 
		--color-mauve-bg: hsl(300, 20%, 83%);
		--color-mauve-fg: hsl(300, 20%, 68%);
		// Plum 
		--color-plum-bg: hsl(285, 25%, 82%);
		--color-plum-fg: hsl(285, 25%, 67%);
		// Dusk 
		--color-dusk-bg: hsl(250, 25%, 83%);
		--color-dusk-fg: hsl(250, 25%, 68%);
		// Ocean 
		--color-ocean-bg: hsl(195, 30%, 82%);
		--color-ocean-fg: hsl(195, 30%, 67%);
		// River 
		--color-river-bg: hsl(185, 28%, 80%);
		--color-river-fg: hsl(185, 28%, 65%);
		// Forest 
		--color-forest-bg: hsl(165, 25%, 80%);
		--color-forest-fg: hsl(165, 25%, 65%);
		// Pine 
		--color-pine-bg: hsl(155, 25%, 82%);
		--color-pine-fg: hsl(155, 25%, 67%);
		// Storm 
		--color-storm-bg: hsl(220, 25%, 82%);
		--color-storm-fg: hsl(220, 25%, 67%);
		// Mist 
		--color-mist-bg: hsl(205, 22%, 83%);
		--color-mist-fg: hsl(205, 22%, 68%);	
	}

	.v3() {
		@names:'red', 'coral', 'orange', 'gold', 'olive', 'green', 'teal', 'cyan', 'azure', 'sky', 'blue', 'indigo', 'purple', 'fuchsia', 'rose', 'pink';

			// Red 
		--color-red-bg: hsl(0, 50%, 82%);
		--color-red-fg: hsl(0, 50%, 65%);
		// Coral 
		--color-coral-bg: hsl(22.5, 50%, 82%);
		--color-coral-fg: hsl(22.5, 50%, 65%);
		// Orange 
		--color-orange-bg: hsl(45, 50%, 82%);
		--color-orange-fg: hsl(45, 50%, 65%);
		// Gold 
		--color-gold-bg: hsl(67.5, 50%, 82%);
		--color-gold-fg: hsl(67.5, 50%, 65%);
		// Olive 
		--color-olive-bg: hsl(90, 50%, 82%);
		--color-olive-fg: hsl(90, 50%, 65%);
		// Green 
		--color-green-bg: hsl(112.5, 50%, 82%);
		--color-green-fg: hsl(112.5, 50%, 65%);
		// Teal 
		--color-teal-bg: hsl(135, 50%, 82%);
		--color-teal-fg: hsl(135, 50%, 65%);
		// Cyan 
		--color-cyan-bg: hsl(157.5, 50%, 82%);
		--color-cyan-fg: hsl(157.5, 50%, 65%);
		// Azure 
		--color-azure-bg: hsl(180, 50%, 82%);
		--color-azure-fg: hsl(180, 50%, 65%);
		// Sky 
		--color-sky-bg: hsl(202.5, 50%, 82%);
		--color-sky-fg: hsl(202.5, 50%, 65%);
		// Blue 
		--color-blue-bg: hsl(225, 50%, 82%);
		--color-blue-fg: hsl(225, 50%, 65%);
		// Indigo 
		--color-indigo-bg: hsl(247.5, 50%, 82%);
		--color-indigo-fg: hsl(247.5, 50%, 65%);
		// Purple 
		--color-purple-bg: hsl(270, 50%, 82%);
		--color-purple-fg: hsl(270, 50%, 65%);
		// Fuchsia 
		--color-fuchsia-bg: hsl(292.5, 50%, 82%);
		--color-fuchsia-fg: hsl(292.5, 50%, 65%);
		// Rose 
		--color-rose-bg: hsl(315, 50%, 82%);
		--color-rose-fg: hsl(315, 50%, 65%);
		// Pink 
		--color-pink-bg: hsl(337.5, 50%, 82%);
		--color-pink-fg: hsl(337.5, 50%, 65%);
	}

	.v2() {
		@names:'red', 'coral', 'orange', 'gold', 'olive', 'green', 'teal', 'cyan', 'azure', 'sky', 'blue', 'indigo', 'purple', 'fuchsia', 'rose', 'pink';

		// Red 
		--color-red-bg: hsl(0, 45%, 85%);
		--color-red-fg: hsl(0, 45%, 70%);
		// Coral 
		--color-coral-bg: hsl(22.5, 45%, 85%);
		--color-coral-fg: hsl(22.5, 45%, 70%);
		// Orange 
		--color-orange-bg: hsl(45, 45%, 85%);
		--color-orange-fg: hsl(45, 45%, 70%);
		// Gold 
		--color-gold-bg: hsl(67.5, 45%, 85%);
		--color-gold-fg: hsl(67.5, 45%, 70%);
		// Olive 
		--color-olive-bg: hsl(90, 45%, 85%);
		--color-olive-fg: hsl(90, 45%, 70%);
		// Green 
		--color-green-bg: hsl(112.5, 45%, 85%);
		--color-green-fg: hsl(112.5, 45%, 70%);
		// Teal 
		--color-teal-bg: hsl(135, 45%, 85%);
		--color-teal-fg: hsl(135, 45%, 70%);
		// Cyan 
		--color-cyan-bg: hsl(157.5, 45%, 85%);
		--color-cyan-fg: hsl(157.5, 45%, 70%);
		// Azure 
		--color-azure-bg: hsl(180, 45%, 85%);
		--color-azure-fg: hsl(180, 45%, 70%);
		// Sky 
		--color-sky-bg: hsl(202.5, 45%, 85%);
		--color-sky-fg: hsl(202.5, 45%, 70%);
		// Blue 
		--color-blue-bg: hsl(225, 45%, 85%);
		--color-blue-fg: hsl(225, 45%, 70%);
		// Indigo 
		--color-indigo-bg: hsl(247.5, 45%, 85%);
		--color-indigo-fg: hsl(247.5, 45%, 70%);
		// Purple 
		--color-purple-bg: hsl(270, 45%, 85%);
		--color-purple-fg: hsl(270, 45%, 70%);
		// Fuchsia 
		--color-fuchsia-bg: hsl(292.5, 45%, 85%);
		--color-fuchsia-fg: hsl(292.5, 45%, 70%);
		// Rose 
		--color-rose-bg: hsl(315, 45%, 85%);
		--color-rose-fg: hsl(315, 45%, 70%);
		// Pink 
		--color-pink-bg: hsl(337.5, 45%, 85%);
		--color-pink-fg: hsl(337.5, 45%, 70%);
	}
}